import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import OrderConfirm from '../../../assets/images/order-confirmed.png';
import AppButton from '../../components/AppButton';
import { Link } from 'react-router-dom';

const OrderConfirmed = () => {
    return (
        <MainLayout>
            <div className="container-xl py-5 px-sm-5">
                <div className="order-confirm-container d-flex flex-column align-items-center py-5">
                    <img
                        src={OrderConfirm}
                        className="mb-5"
                        alt="order-confirmed"
                    />
                    <h2>ORDER CONFIRMED!!</h2>
                    <p
                        className="text-center text-14 text-gray"
                        style={{ maxWidth: '20rem' }}
                    >
                        We have received your payment and your order is been
                        confirmed. Now get fit everyday.
                    </p>
                    <Link to="/dashboard">
                        <AppButton variant="primary">Go to Dashboard</AppButton>
                    </Link>
                </div>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default OrderConfirmed;
