import { FunctionComponent } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { ButtonType } from 'react-bootstrap/esm/Button';
import { ButtonVariant } from 'react-bootstrap/esm/types';

interface IProps {
    children: React.ReactNode;
    variant: ButtonVariant;
    className?: string;
    type?: ButtonType;
    disabled?: boolean;
    onClick?: React.MouseEventHandler;
    loading?: boolean;
    style?: React.CSSProperties;
    loaderSize?: 'sm';
}

const AppButton: FunctionComponent<IProps> = (props) => {
    return (
        <Button
            className={'app-btn text-nowrap ' + props.className}
            variant={props.variant}
            size="lg"
            type={props.type}
            disabled={props.disabled}
            onClick={props.onClick}
            style={props.style}
        >
            {props.loading ? (
                <Spinner animation="border" size={props.loaderSize} />
            ) : (
                props.children
            )}
        </Button>
    );
};

AppButton.defaultProps = {
    className: ''
};

export default AppButton;
