import {
    REGISTER_SUCCESS,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_REQUEST,
    LOGIN_REQUEST,
    REGISTER_FAIL,
    RESET_AUTH_STATE,
    $FIXME
} from '../helpers/constants';
import { Dispatch } from 'redux';
import request from '../helpers/request';
import { ISignUp, ILogin } from '../interfaces/User';
import { AxiosResponse, AxiosError } from 'axios';
import Cookies from 'universal-cookie';
import { api } from '../helpers/api';
import { setErrors } from './errorActions';
import { IAuthUser } from '../reducers/authReducers';
import { getCarts } from './cartActions';

const cookies = new Cookies();

export const register = (body: ISignUp) => async (dispatch: Dispatch) => {
    dispatch({ type: REGISTER_REQUEST });
    request
        .userRegister(body)
        .then((res: AxiosResponse) => {
            if (res.data.status === 'success') {
                dispatch({ type: REGISTER_SUCCESS, payload: res.data });
            } else {
                dispatch({
                    type: REGISTER_FAIL,
                    payload:
                        res.data.message ?? 'Sign up failed. Please try again.'
                });
            }
        })
        .catch((err: AxiosError) => {
            dispatch({
                type: REGISTER_FAIL
            });
            dispatch(
                setErrors(err.response?.data.message ?? 'Something went wrong')
            );
        });
};

export const login = (body: ILogin) => async (dispatch: $FIXME) => {
    dispatch({ type: LOGIN_REQUEST });
    request
        .userLogin(body)
        .then((res: AxiosResponse) => {
            if (res.data.status === 'success') {
                const authData: IAuthUser = res.data.user;

                cookies.set('token', res.data.token, { path: '/' });
                cookies.set('authenticated', true, { path: '/' });
                cookies.set('authUser', JSON.stringify(authData), {
                    path: '/'
                });
                cookies.set('loginSource', 'email', { path: '/' });
                api.defaults.headers.Authorization = 'jwt ' + res.data.token;
                dispatch(getCarts());
                dispatch({ type: LOGIN_SUCCESS, payload: authData });
            } else {
                dispatch({
                    type: LOGIN_FAIL,
                    payload:
                        res.data.message ?? 'Login failed. Please try again.'
                });
            }
        })
        .catch((err: AxiosError) => {
            if (err.response?.data.status === 'auth-error') {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: err.response.data.message
                });
            } else {
                dispatch({
                    type: LOGIN_FAIL
                });
                dispatch(
                    setErrors(
                        err.response?.data.message ?? 'Something went wrong'
                    )
                );
            }
        });
};

export const resetAuthState = () => async (dispatch: Dispatch) => {
    dispatch({ type: RESET_AUTH_STATE });
};

export const googleLogin =
    (accessToken: string) => async (dispatch: $FIXME) => {
        dispatch({ type: LOGIN_REQUEST });
        request
            .googleLogin(accessToken)
            .then((res: AxiosResponse) => {
                if (res.data.status === 'success') {
                    const authData: IAuthUser = res.data.user;

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('authenticated', true, { path: '/' });
                    cookies.set('authUser', authData, { path: '/' });
                    cookies.set('loginSource', 'google', { path: '/' });
                    api.defaults.headers.Authorization =
                        'jwt ' + res.data.token;
                    dispatch(getCarts());
                    dispatch({ type: LOGIN_SUCCESS, payload: authData });
                } else {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload:
                            res.data.message ??
                            'Login failed. Please try again.'
                    });
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.data.status === 'auth-error') {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: err.response.data.message
                    });
                } else {
                    dispatch({
                        type: LOGIN_FAIL
                    });
                    dispatch(
                        setErrors(
                            err.response?.data.message ?? 'Something went wrong'
                        )
                    );
                }
            });
    };

export const facebookLogin =
    (accessToken: string) => async (dispatch: $FIXME) => {
        dispatch({ type: LOGIN_REQUEST });
        request
            .facebookLogin(accessToken)
            .then((res: AxiosResponse) => {
                if (res.data.status === 'success') {
                    const authData: IAuthUser = res.data.user;

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('authenticated', true, { path: '/' });
                    cookies.set('authUser', authData, { path: '/' });
                    cookies.set('loginSource', 'facebook', { path: '/' });
                    api.defaults.headers.Authorization =
                        'jwt ' + res.data.token;
                    dispatch(getCarts());
                    dispatch({ type: LOGIN_SUCCESS, payload: authData });
                } else {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload:
                            res.data.message ??
                            'Login failed. Please try again.'
                    });
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.data.status === 'auth-error') {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: err.response.data.message
                    });
                } else {
                    dispatch({
                        type: LOGIN_FAIL
                    });
                    dispatch(
                        setErrors(
                            err.response?.data.message ?? 'Something went wrong'
                        )
                    );
                }
            });
    };
