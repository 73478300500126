import { FunctionComponent } from 'react';

type TextShadowAlignment = 'left' | 'center';
type TextShadowVariant = 'light' | 'dark';

interface IProps {
    children: React.ReactNode;
    shadowText?: string;
    alignment?: TextShadowAlignment;
    className?: string;
    variant?: TextShadowVariant;
}

const TextShadow: FunctionComponent<IProps> = (props: IProps) => {
    return (
        <div
            className={`position-relative text-shadow-container ${props.className}`}
        >
            <h2
                className={`text-uppercase text-700 text-shadow-main mb-0 ${
                    props.alignment === 'center' ? 'text-center' : 'text-left'
                }`}
            >
                {props.children}
            </h2>
            {props.shadowText ? (
                <span
                    className={`text-shadow-span text-uppercase text-700 d-none d-lg-block ${
                        props.alignment === 'center'
                            ? 'text-center'
                            : 'text-left'
                    } ${
                        props.variant === 'dark'
                            ? 'text-dark-gray'
                            : 'text-light-gray'
                    }`}
                >
                    {props.shadowText}
                </span>
            ) : (
                ''
            )}
        </div>
    );
};

TextShadow.defaultProps = {
    alignment: 'center',
    variant: 'dark',
    className: ''
};

export default TextShadow;
