import { Row, Col, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppButton from '../../components/AppButton';
import AppForumCard from '../../components/AppForumCard';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import Offers from '../home/Offers';
import GetOffers from '../templateSearch/GetOffers';
import FeaturedTopics from './FeaturedTopics';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getAllOffers } from '../../../actions/offerActions';
import {
    getAllForums,
    getFeaturedForums,
    resetForums
} from '../../../actions/forumActions';
import { StateType } from '../../../reducers';
import ForumFilter from './ForumFilter';
import { CgClose } from 'react-icons/cg';
import QuestionModal from './QuestionModal';
import { $FIXME } from '../../../helpers/constants';
import queryString from 'query-string';
import AppNotFound from '../../components/AppNotFound';
import { AppForumCardSkeleton } from '../../components/AppSkeleton';
import { getUserProfile } from '../../../actions/userActions';

interface IProps {}

const ForumListing = (props: IProps) => {
    const [questionModalOpen, setQuestionModalOpen] = useState(false);

    const { authenticated, authUser } = useSelector(
        (state: StateType) => state.auth
    );

    const { user_type } = useSelector((state: StateType) => state.user.user);

    const location = useLocation();

    const query: $FIXME = queryString.parse(location.search);

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Forum',
            link: '/forum',
            active: true
        }
    ];

    const { forums, featured, forumsLoading } = useSelector(
        (state: StateType) => state.forum
    );

    const resultsPerPage = 8;

    const dispatch = useDispatch();

    const getForums = useCallback(
        (page?: number) => {
            dispatch(
                getAllForums({
                    resultsPerPage,
                    author:
                        authUser.id && query.sort === 'my_discussions'
                            ? authUser.id
                            : query.author,
                    category: query.category,
                    sort:
                        query.sort !== 'my_discussions'
                            ? query.sort ?? 'latest'
                            : 'latest',
                    page: page ?? 1
                })
            );
        },
        [query.author, query.category, query.sort, authUser.id, dispatch]
    );

    useEffect(() => {
        dispatch(resetForums());

        getForums();
    }, [dispatch, getForums]);

    useEffect(() => {
        if (authenticated) dispatch(getUserProfile());
    }, [authenticated, dispatch]);

    useEffect(() => {
        dispatch(getFeaturedForums({ resultsPerPage: 4, sort: 'latest' }));
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    const handleLoadMore = () => {
        if (forums.currentPage !== forums.totalPage)
            getForums((forums.currentPage ?? 0) + 1);
    };

    const handleCloseQuestionModal = () => setQuestionModalOpen(false);

    return (
        <MainLayout backgroundColor="#F4F6FC" navBackgroundColor="#F4F6FC">
            <div className="container-xxxl shop-search-container">
                <div className="px-lg-3">
                    <AppBreadcrumbs items={breadcrumbItems} />

                    <Row className="pb-5 mt-3">
                        <Col lg={3} md={12}>
                            <ForumFilter />
                        </Col>

                        <Col>
                            <div className="d-flex mb-3 flex-wrap">
                                <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-2 mr-3">
                                    Latest Discussion
                                </h2>
                                <div className="d-flex flex-grow-1 justify-content-between align-items-center mb-2">
                                    <p className="text-inter text-500 text-14 text-gray mb-0 mr-2">
                                        {forums.totalDocuments ?? 0} Topics
                                    </p>

                                    {authenticated &&
                                        (user_type !== 'Client' ||
                                            authUser.is_active_member ===
                                                true) && (
                                            <div className="d-flex align-items-center">
                                                <AppButton
                                                    variant="primary"
                                                    className="mt-n4"
                                                    onClick={() =>
                                                        setQuestionModalOpen(
                                                            true
                                                        )
                                                    }
                                                >
                                                    + Ask Question
                                                </AppButton>
                                            </div>
                                        )}
                                </div>
                            </div>

                            <hr />

                            {!forumsLoading && forums.forums.length === 0 ? (
                                <AppNotFound type="Forum" />
                            ) : (
                                forums.forums.map((forum, index) => {
                                    return (
                                        <div key={index}>
                                            {forum ? (
                                                <div className="mb-3">
                                                    <AppForumCard
                                                        forum={forum}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {index === 3 &&
                                            featured.forums.length ? (
                                                <div className="mb-3">
                                                    <FeaturedTopics
                                                        forums={featured.forums}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    );
                                })
                            )}

                            {forumsLoading ? (
                                <>
                                    {[...Array(resultsPerPage)].map(
                                        (item, i) => (
                                            <div className="mb-3" key={i}>
                                                <AppForumCardSkeleton />
                                            </div>
                                        )
                                    )}
                                </>
                            ) : (
                                ''
                            )}

                            {forums.currentPage === forums.totalPage ||
                            forums.totalDocuments === 0 ? (
                                ''
                            ) : (
                                <div className="d-flex justify-content-center justify-content-lg-end mt-4">
                                    <AppButton
                                        variant="outline-primary"
                                        onClick={handleLoadMore}
                                        loading={forumsLoading}
                                    >
                                        LOAD MORE FORUMS
                                    </AppButton>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal
                show={questionModalOpen}
                onHide={handleCloseQuestionModal}
                size="lg"
                centered
            >
                <Modal.Header className="px-3">
                    <h2 className="text-24 text-uppercase text-700 text-black mb-0">
                        Ask Question
                    </h2>

                    <CgClose
                        size={28}
                        className="text-gray text-hover-red cursor-pointer"
                        onClick={handleCloseQuestionModal}
                    />
                </Modal.Header>
                <Modal.Body>
                    <QuestionModal
                        closeModal={handleCloseQuestionModal}
                        onPostSuccess={() => {
                            dispatch(resetForums());
                            getForums();
                        }}
                    />
                </Modal.Body>
            </Modal>

            <GetOffers offerType="all" />
            <Offers />
            <GetNotified />
        </MainLayout>
    );
};

export default ForumListing;
