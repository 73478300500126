import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getTrainerCoachingAchievements,
    getTrainerPersonalAchievements
} from '../../../actions/trainerActions';
import { StateType } from '../../../reducers';
import TrainerProfileLayout from '../../layouts/TrainerProfileLayout';
import Masonry from 'react-masonry-css';
import { Image, Spinner } from 'react-bootstrap';

const TrainerProfile = () => {
    const {
        trainer,
        coachingAchievements,
        personalAcievements,
        personalAcievementsLoading,
        coachingAchievementsLoading
    } = useSelector((state: StateType) => state.trainer);

    const breakpointColumnsObj = {
        default: 3,
        1200: 2,
        400: 1
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (trainer._id) {
            dispatch(getTrainerCoachingAchievements(trainer._id));
            dispatch(getTrainerPersonalAchievements(trainer._id));
        }
    }, [dispatch, trainer._id]);

    return (
        <TrainerProfileLayout>
            <div className="py-4 px-3 mb-3 bg-white">
                <h2 className="text-20 mb-3">BACKGROUND</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: trainer.about ?? ''
                    }}
                />
            </div>

            <div className="trainer-personal-achievement py-4 px-3 bg-white mb-3">
                <h2 className="text-20 mb-3">PERSONAL ACHIEVEMENTS</h2>

                {!personalAcievementsLoading && !personalAcievements.length ? (
                    <p>No achievements available</p>
                ) : (
                    <div className="py-2">
                        <ul className="timeline">
                            {personalAcievements.map(
                                ({
                                    achievement,
                                    year,
                                    venue,
                                    sub_achievements
                                }) => (
                                    <li className="timeline-item">
                                        <div className="mb-2">
                                            <p className="text-gray-2 text-600 mb-0">
                                                {achievement}
                                            </p>
                                            <p className="mb-0 text-14 text-gray-1">
                                                {year} | {venue}
                                            </p>
                                        </div>

                                        {sub_achievements?.length ? (
                                            <ul>
                                                {sub_achievements.map(
                                                    (item) => (
                                                        <li
                                                            className="text-gray-2 text-18"
                                                            style={{
                                                                listStyle:
                                                                    'disc'
                                                            }}
                                                        >
                                                            {item}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        ) : (
                                            ''
                                        )}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                )}

                {personalAcievementsLoading ? (
                    <div
                        className="d-flex justify-content-center"
                        style={{ height: 200 }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : (
                    ''
                )}
            </div>

            <div className="py-4 px-3 bg-white">
                <h2 className="text-20">COACHING ACHIEVEMENTS</h2>

                {!coachingAchievementsLoading &&
                !coachingAchievements.length ? (
                    <p>No achievements available</p>
                ) : (
                    <>
                        <p className="mb-4">
                            {trainer.name} is an awesome coach who have helped
                            so many fitness freak to achieve what they desired.
                            Here are some of the successfull coaching
                            achievements of {trainer.name}’s career: Here are
                            the list of individual who took personal training
                            from coach {trainer.name}.
                        </p>
                        <div className="trainer-review py-2">
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="review-masonry-grid"
                                columnClassName="review-masonry-grid_column"
                            >
                                {coachingAchievements.map((item, index) => (
                                    <div className="trainer-review-card">
                                        <div className="p-3">
                                            <div className="trainer-review-user d-flex flex-row">
                                                <Image
                                                    roundedCircle
                                                    width={50}
                                                    height={50}
                                                    className="mr-3"
                                                    src={
                                                        !!item.profile_pic
                                                            ? item.profile_pic
                                                            : 'https://icon-library.com/images/default-user-icon/default-user-icon-4.jpg'
                                                    }
                                                />
                                                <div className="d-flex flex-column">
                                                    <p className="text-gray-2 text-600 text-18 mb-0">
                                                        {item.name}
                                                    </p>
                                                    <p className="text-gray-1 text-14 mb-0">
                                                        {item.designation}
                                                    </p>
                                                </div>
                                            </div>

                                            {item.achievements?.length ? (
                                                <div className="mt-4">
                                                    {item.achievements?.map(
                                                        (achievement) => (
                                                            <p className="text-gray-2 mb-2">
                                                                {achievement}
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </Masonry>
                        </div>
                    </>
                )}

                {coachingAchievementsLoading ? (
                    <div
                        className="d-flex justify-content-center"
                        style={{ height: 200 }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </TrainerProfileLayout>
    );
};

export default TrainerProfile;
