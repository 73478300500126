import { Col, Form, Row } from 'react-bootstrap';
import { phoneRegExp } from '../../../helpers/constants';
import {
    IDeliveryAddress,
    IDeliveryAddressBody
} from '../../../interfaces/DeliveryAddress';
import AppButton from '../../components/AppButton';
import AppInput from '../../components/AppInput';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    saveDeliveryAddress,
    updateDeliveryAddress
} from '../../../actions/deliveryAddressActions';
import { StateType } from '../../../reducers';
import { useEffect } from 'react';
import {
    getDistrictByProvince,
    getProvinces
} from '../../../actions/addressActions';
import AppSelect from '../../components/AppSelect';

interface IProps {
    closeModal: VoidFunction;
    id?: string;
    body?: IDeliveryAddress;
}

const DeliveryAddressModal = ({ closeModal, body, id }: IProps) => {
    const { name, email, mobile_num } = useSelector(
        (state: StateType) => state.auth.authUser
    );
    const { saveDeliveryLoading } = useSelector(
        (state: StateType) => state.deliveryAddress
    );

    const { provinces, districts } = useSelector(
        (state: StateType) => state.address
    );

    const dispatch = useDispatch();

    const deliveryLabel = {
        home: 'Home',
        office: 'Work',
        other: 'Other'
    };

    const initialValues: IDeliveryAddressBody = {
        name: body?.name ?? name ?? '',
        email: body?.email ?? email ?? '',
        label: body?.label ?? deliveryLabel.home,
        mobile_num: body?.mobile_num?.toString() ?? mobile_num ?? '',
        primary: false,
        address: {
            country: body?.address?.country ?? 'Nepal',
            province: body?.address?.province,
            district: body?.address?.district,
            area: body?.address?.area,
            address_line1: body?.address?.address_line1
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        mobile_num: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Phone number is required'),
        address: Yup.object().shape({
            province: Yup.string().required('Region is required'),
            district: Yup.string().required('City is required'),
            area: Yup.string().required('Area is required'),
            address_line1: Yup.string().required('Address is required')
        })
    });

    useEffect(() => {
        dispatch(getProvinces());
    }, [dispatch]);

    return (
        <div className="delivery-modal">
            <h2 className="text-18 text-uppercase mb-4">
                Add new shipping Address
            </h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    dispatch(
                        id
                            ? updateDeliveryAddress(id, values, closeModal)
                            : saveDeliveryAddress(values, closeModal)
                    );
                }}
                isInitialValid={false}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    isValid,
                    errors,
                    setFieldValue
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <label className="text-neutrif text-600 text-14 text-black form-label">
                                        Select a label for effective delivery:
                                    </label>
                                    <div className="d-flex">
                                        {Object.values(deliveryLabel).map(
                                            (value) => (
                                                <div
                                                    className={
                                                        'delivery-label-container mr-3 ' +
                                                        (values.label === value
                                                            ? 'delivery-label-active'
                                                            : 'delivery-label-inactive')
                                                    }
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'label',
                                                            value
                                                        );
                                                    }}
                                                >
                                                    <span className="text-neutrif px-3">
                                                        {value}
                                                    </span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                                <AppInput
                                    type="email"
                                    label="Email"
                                    placeholder="example@gmail.com"
                                    required
                                    fill
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    error={errors.email}
                                />
                                <AppInput
                                    label="Full Name"
                                    placeholder="Enter your name"
                                    required
                                    fill
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                    error={errors.name}
                                />
                                <AppInput
                                    label="Phone Number"
                                    placeholder="Enter your phone number"
                                    required
                                    fill
                                    name="mobile_num"
                                    value={values.mobile_num}
                                    onChange={handleChange}
                                    isInvalid={!!errors.mobile_num}
                                    error={errors.mobile_num}
                                />
                            </Col>
                            <Col>
                                <AppSelect
                                    label="Region"
                                    required
                                    placeholder="Select region"
                                    name="address.province"
                                    selectOptions={provinces.map(
                                        (province) => ({
                                            label: province,
                                            value: province
                                        })
                                    )}
                                    value={{
                                        label: values.address.province,
                                        value: values.address.province
                                    }}
                                    error={errors.address?.province}
                                    onChange={(field, value) => {
                                        if (value?.value) {
                                            dispatch(
                                                getDistrictByProvince(
                                                    value.value
                                                )
                                            );
                                            setFieldValue(field, value.value);
                                        }
                                    }}
                                    disabled={!provinces.length}
                                />
                                <AppSelect
                                    label="City"
                                    placeholder="Select city"
                                    required
                                    name="address.district"
                                    selectOptions={districts.map(
                                        (district) => ({
                                            label: district.name,
                                            value: district.name
                                        })
                                    )}
                                    value={{
                                        label: values.address.district,
                                        value: values.address.district
                                    }}
                                    error={errors.address?.district}
                                    onChange={(field, value) => {
                                        setFieldValue(field, value?.value);
                                    }}
                                    disabled={!districts.length}
                                />

                                <AppInput
                                    label="Area"
                                    placeholder="Enter your area name"
                                    required
                                    fill
                                    name="address.area"
                                    value={values.address.area}
                                    onChange={handleChange}
                                    isInvalid={!!errors.address?.area}
                                    error={errors.address?.area}
                                />
                                <AppInput
                                    label="Address"
                                    placeholder="Example: House 123, Street 123, ABC Road"
                                    required
                                    fill
                                    name="address.address_line1"
                                    value={values.address.address_line1}
                                    onChange={handleChange}
                                    isInvalid={!!errors.address?.address_line1}
                                    error={errors.address?.address_line1}
                                />

                                <div className="d-flex justify-content-end pt-2">
                                    <AppButton
                                        variant="outline-secondary"
                                        className="mr-3"
                                        onClick={() => closeModal()}
                                    >
                                        Cancel
                                    </AppButton>
                                    <AppButton
                                        variant="primary"
                                        type="submit"
                                        loading={saveDeliveryLoading}
                                        disabled={
                                            !isValid || saveDeliveryLoading
                                        }
                                    >
                                        Save
                                    </AppButton>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default DeliveryAddressModal;
