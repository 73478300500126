import {
    Row,
    Col,
    Button,
    Modal,
    Table,
    OverlayTrigger,
    Popover,
    Tabs,
    Tab
} from 'react-bootstrap';
import SettingsNav from './SettingsNav';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { useEffect } from 'react';
import DeliveryAddressModal from '../cart/DeliveryAddressModal';
import { useState } from 'react';
import {
    deleteAddress,
    getDeliveryAddress
} from '../../../actions/deliveryAddressActions';
import { BsPencil, BsX } from 'react-icons/bs';
import AppButton from '../../components/AppButton';
import { IDeliveryAddress } from '../../../interfaces/DeliveryAddress';
import DashboardLayout from '../../layouts/DashboardLayout';

interface IProps {}

interface IDeliveryAddressModalState {
    id?: string;
    show: boolean;
    address?: IDeliveryAddress;
}

const AdddressSettings = (props: IProps) => {
    const [showAddressModal, setShowAddressModal] =
        useState<IDeliveryAddressModalState>({ show: false });

    const { address: deliveryAddress } = useSelector(
        (state: StateType) => state.deliveryAddress
    );

    const handleCloseAddressModal = () => setShowAddressModal({ show: false });
    const handleShowAddressModal = (id?: string, address?: IDeliveryAddress) =>
        setShowAddressModal({ id, show: true, address });

    const deliveryLabel = {
        all: 'All',
        home: 'Home',
        office: 'Work',
        other: 'Other'
    };

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getDeliveryAddress());
    }, [dispatch]);

    return (
        <DashboardLayout>
            <div className="dashboard-container">
                <Row>
                    <Col xl={3} lg={4} className="mb-5">
                        <SettingsNav />
                    </Col>
                    <Col xl={9} lg={8} className="mb-5">
                        <div className="bg-light-gray-3 p-3">
                            <div className="d-flex align-items-center mb-3">
                                <div className="flex-grow-1">
                                    <h2 className="text-600 text-18 text-dark-blue mb-0">
                                        MANAGE SHIPPING ADDRESSES
                                    </h2>
                                </div>
                                <Button
                                    variant="link"
                                    className="text-oswald text-700 text-16 text-red p-0"
                                    onClick={() => handleShowAddressModal()}
                                >
                                    ADD NEW ADDRESS
                                </Button>
                            </div>
                            {deliveryAddress.length ? (
                                <Tabs
                                    defaultActiveKey={deliveryLabel.all}
                                    className="mb-4 address-tabs"
                                >
                                    {Object.values(deliveryLabel).map(
                                        (label) => (
                                            <Tab eventKey={label} title={label}>
                                                {deliveryAddress.filter(
                                                    (item) =>
                                                        item.label === label ||
                                                        label ===
                                                            deliveryLabel.all
                                                ).length ? (
                                                    <Table
                                                        responsive
                                                        borderless
                                                        className="address-table"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th className="text-neutrif text-600 text-14 text-dark-2 text-nowrap bg-light-gray-1">
                                                                    Name/Email
                                                                </th>
                                                                <th className="text-neutrif text-600 text-14 text-dark-2 text-nowrap bg-light-gray-1">
                                                                    Phone Number
                                                                </th>
                                                                <th className="text-neutrif text-600 text-14 text-dark-2 text-nowrap bg-light-gray-1">
                                                                    Province
                                                                </th>
                                                                <th className="text-neutrif text-600 text-14 text-dark-2 text-nowrap bg-light-gray-1">
                                                                    Region
                                                                </th>
                                                                <th className="text-neutrif text-600 text-14 text-dark-2 text-nowrap bg-light-gray-1">
                                                                    Area
                                                                </th>
                                                                <th className="text-neutrif text-600 text-14 text-dark-2 text-nowrap bg-light-gray-1">
                                                                    Address
                                                                </th>
                                                                <th className="text-neutrif text-600 text-14 text-dark-2 text-nowrap bg-light-gray-1">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {deliveryAddress?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    const {
                                                                        _id,
                                                                        name,
                                                                        email,
                                                                        mobile_num,
                                                                        address
                                                                    } = item;

                                                                    if (
                                                                        item &&
                                                                        (item.label ===
                                                                            label ||
                                                                            label ===
                                                                                deliveryLabel.all)
                                                                    ) {
                                                                        return (
                                                                            <tr>
                                                                                <td className="align-middle">
                                                                                    <div>
                                                                                        <p className="text-dark-blue text-600 text-16 mb-0">
                                                                                            {
                                                                                                name
                                                                                            }
                                                                                        </p>
                                                                                        <p className="text-14 mb-0 text-gray-1">
                                                                                            {
                                                                                                email
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    <p className="text-600 text-dark-2 mb-1">
                                                                                        {
                                                                                            mobile_num
                                                                                        }
                                                                                    </p>
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    <p className="text-600 text-dark-2 mb-1 text-nowrap">
                                                                                        {
                                                                                            address?.province
                                                                                        }
                                                                                    </p>
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    <p className="text-600 text-dark-2 mb-1">
                                                                                        {
                                                                                            address?.district
                                                                                        }
                                                                                    </p>
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    <p className="text-600 text-dark-2 mb-1">
                                                                                        {
                                                                                            address?.area
                                                                                        }
                                                                                    </p>
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    <p className="text-600 text-dark-2 mb-1">
                                                                                        {
                                                                                            address?.address_line1
                                                                                        }
                                                                                    </p>
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    <div className="text-gray d-flex">
                                                                                        <div
                                                                                            className="icon-container text-hover-dark mr-2"
                                                                                            onClick={() =>
                                                                                                handleShowAddressModal(
                                                                                                    _id,
                                                                                                    item
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <BsPencil />
                                                                                        </div>
                                                                                        <OverlayTrigger
                                                                                            trigger="click"
                                                                                            rootClose
                                                                                            overlay={
                                                                                                <Popover
                                                                                                    id={
                                                                                                        _id ??
                                                                                                        ''
                                                                                                    }
                                                                                                    show={
                                                                                                        true
                                                                                                    }
                                                                                                >
                                                                                                    <Popover.Title className="bg-white">
                                                                                                        <span className="text-neutrif text-dark-blue">
                                                                                                            Are
                                                                                                            you
                                                                                                            sure
                                                                                                            you
                                                                                                            want
                                                                                                            to
                                                                                                            delete?
                                                                                                        </span>
                                                                                                    </Popover.Title>
                                                                                                    <Popover.Content className="d-flex justify-content-center">
                                                                                                        <AppButton
                                                                                                            variant="outline-secondary py-0 px-4 mr-3"
                                                                                                            style={{
                                                                                                                height: 40
                                                                                                            }}
                                                                                                            onClick={() =>
                                                                                                                document.body.click()
                                                                                                            }
                                                                                                        >
                                                                                                            No
                                                                                                        </AppButton>
                                                                                                        <AppButton
                                                                                                            variant="primary py-0 px-4"
                                                                                                            style={{
                                                                                                                height: 40
                                                                                                            }}
                                                                                                            onClick={async () => {
                                                                                                                if (
                                                                                                                    _id
                                                                                                                )
                                                                                                                    await dispatch(
                                                                                                                        deleteAddress(
                                                                                                                            _id
                                                                                                                        )
                                                                                                                    );
                                                                                                                document.body.click();
                                                                                                            }}
                                                                                                        >
                                                                                                            Yes
                                                                                                        </AppButton>
                                                                                                    </Popover.Content>
                                                                                                </Popover>
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                className="icon-container text-hover-dark"
                                                                                                onClick={() => {}}
                                                                                            >
                                                                                                <BsX />
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                }
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                ) : (
                                                    <p className="text-dark-blue">
                                                        You do not have any{' '}
                                                        {label.toLowerCase()}{' '}
                                                        address.
                                                    </p>
                                                )}
                                            </Tab>
                                        )
                                    )}
                                </Tabs>
                            ) : (
                                <p className="text-dark-blue">
                                    You have not added delivery address.
                                </p>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                show={showAddressModal.show}
                onHide={handleCloseAddressModal}
                size="xl"
                centered
            >
                <Modal.Body>
                    <DeliveryAddressModal
                        closeModal={handleCloseAddressModal}
                        body={showAddressModal.address}
                        id={showAddressModal.id}
                    />
                </Modal.Body>
            </Modal>
        </DashboardLayout>
    );
};

export default AdddressSettings;
