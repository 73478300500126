interface IProps {
    selected: string;
    onChange: (value: string) => void;
    text: string;
    value: string;
    className?: string;
    labelClassName?: string;
}

const AppRadio = (props: IProps) => {
    const { selected, onChange, text, value } = props;

    return (
        <div
            className={
                'radio-container ' + (props.className ? props.className : '')
            }
            onClick={() => {
                onChange(value);
            }}
        >
            <div
                className={`radio-outer-circle ${
                    value !== selected && 'unselected'
                }`}
            >
                <div
                    className={`radio-inner-circle ${
                        value !== selected && 'unselected-circle'
                    }`}
                />
            </div>
            <span
                className={
                    'helper-text ' +
                    (props.labelClassName ? props.labelClassName : '')
                }
            >
                {text}
            </span>
        </div>
    );
};

export default AppRadio;
