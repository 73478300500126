import { AxiosError } from 'axios';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setErrors } from '../../../../actions/errorActions';
import { getSubscribedTemplate } from '../../../../actions/templateActions';
import request from '../../../../helpers/request';
import {
    WeekActivity as IWeekActivity,
    WeekProgress
} from '../../../../interfaces/Template';
import { AppDispatch, StateType } from '../../../../reducers';
import DayActivity from './DayActivity/DayActivity';

interface IProps {
    weekActivities: IWeekActivity;
    templateId: string;
    show: boolean;
}

const WeekActivity = ({ weekActivities, templateId, show }: IProps) => {
    const [values, setValues] = useState<WeekProgress[]>([]);

    const [saving, setSaving] = useState(false);

    const { subscribedTemplateLoading } = useSelector(
        (state: StateType) => state.template
    );

    const dispatch = useDispatch<AppDispatch>();

    const handleChange = (value: WeekProgress) => {
        let index = values.findIndex((v) => v._id === value._id);

        if (index === -1) {
            setValues((prev) => [...prev, value]);
        } else {
            setValues((prev) =>
                prev.map((v) => (v._id === value._id ? { ...v, ...value } : v))
            );
        }
    };

    return (
        <>
            <tr style={{ display: show ? 'table-row' : 'none' }}>
                <td
                    colSpan={10}
                    className="text-center bg-gray text-dark-blue text-700 position-relative"
                >
                    {weekActivities.day}

                    {!!values.filter(
                        (value) =>
                            !weekActivities.day_activities?.some(
                                (day) =>
                                    day._id === value._id &&
                                    (day.actual_rpe === value.actual_rpe ||
                                        ((day.actual_rpe === undefined ||
                                            day.actual_rpe === null) &&
                                            value.actual_rpe === undefined)) &&
                                    day.weight_used === value.weight_used
                            )
                    ).length && (
                        <button
                            className="bg-dark-gray-2 text-white text-neutrif text-14 py-1 border-none mx-3"
                            style={{ position: 'absolute', right: 0, top: 6 }}
                            disabled={
                                saving ||
                                !values.length ||
                                subscribedTemplateLoading
                            }
                            onClick={() => {
                                setSaving(true);

                                request
                                    .bulkUpdateProgress({
                                        subscription: templateId,
                                        week_progresses: values
                                    })
                                    .then(() => {
                                        toast.success(`Day progress saved.`, {
                                            position: toast.POSITION.TOP_CENTER
                                        });

                                        dispatch(
                                            getSubscribedTemplate(templateId)
                                        )
                                            .then(() => {
                                                setSaving(false);

                                                setValues([]);
                                            })
                                            .catch(() => {
                                                setSaving(false);
                                            });
                                    })
                                    .catch((err: AxiosError) => {
                                        dispatch(
                                            setErrors(
                                                err.response?.data?.message ??
                                                    'Something went wrong'
                                            )
                                        );

                                        setSaving(false);
                                    });
                            }}
                        >
                            {saving || (saving && subscribedTemplateLoading) ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                'Save Progress'
                            )}
                        </button>
                    )}
                </td>
            </tr>

            {weekActivities.day_activities?.map((dayActivity, index) => {
                const value = values?.find(
                    (progress) => progress._id === dayActivity._id
                ) ?? {
                    _id: dayActivity._id,
                    weight_used: dayActivity.weight_used,
                    actual_rpe: dayActivity.actual_rpe,
                    feedback: dayActivity.feedback
                };

                return (
                    <DayActivity
                        show={show}
                        dayActivity={dayActivity}
                        key={index}
                        onChange={handleChange}
                        value={value}
                    />
                );
            })}
        </>
    );
};

export default WeekActivity;
