import axios from 'axios';

export const baseURL =
    process.env.REACT_APP_API_BASE_URL ??
    'https://oxstrength-api.asterdio.xyz/api/v1';

export const cellPayURL = 'https://app.cellpay.com.np/';
// const baseURL = 'http://localhost:5050/api/v1';

export const api = axios.create({
    baseURL,
});

export const cellPayApi = axios.create({
    baseURL: cellPayURL,
});
