import { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import TextShadow from '../../components/TextShadow';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';

const Activities = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const medias = useSelector((state: StateType) => state.media.medias);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return medias.length ? (
        <div className="activity-container py-lg-5">
            <div className="container-xxxl py-5">
                <TextShadow shadowText="OUR ACTIVITIES" className="mb-5">
                    Our <span className="text-red"> Activities</span>
                </TextShadow>
                <div className="img-gallery-wrapper">
                    <Gallery
                        photos={
                            medias.length
                                ? medias[0].media.map((media, index) => ({
                                      src: media.url ?? '',
                                      height: index % 2 === 0 ? 9 : 3,
                                      width: index % 2 === 0 ? 16 : 4
                                  }))
                                : []
                        }
                        onClick={openLightbox}
                        margin={5}
                    />

                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={
                                        medias.length
                                            ? medias[0].media.map((x) => ({
                                                  source: x.url ?? ''
                                              }))
                                            : []
                                    }
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </div>
        </div>
    ) : (
        <> </>
    );
};

export default Activities;
