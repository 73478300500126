import { FiChevronRight } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

const CoachingFilter = () => {
    return (
        <div className="template-filter-container h-100">
            <NavLink
                to="/coaching"
                className="d-flex justify-content-between align-items-center mb-4 pr-3 text-hover-red"
                activeClassName="text-red"
            >
                <span className="text-neutrif text-18">One on One Online</span>

                <FiChevronRight className="text-dark-blue" />
            </NavLink>
            <NavLink
                to="/consultation"
                className="d-flex justify-content-between align-items-center mb-4 pr-3 text-hover-red"
                activeClassName="text-red"
            >
                <span className="text-neutrif text-18">
                    One on One Consultation
                </span>

                <FiChevronRight className="text-dark-blue" />
            </NavLink>
        </div>
    );
};

export default CoachingFilter;
