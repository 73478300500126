import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    $FIXME,
    GET_DELIVERY_ADDRESS,
    SAVE_DELIVERY_FAILURE,
    SAVE_DELIVERY_REQUEST,
    SAVE_DELIVERY_SUCCESS
} from '../helpers/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { IDeliveryAddressBody } from '../interfaces/DeliveryAddress';
import { setErrors } from './errorActions';

export const getDeliveryAddress = () => async (dispatch: Dispatch) => {
    request.getDeliveryAddress().then((res: AxiosResponse) => {
        dispatch({ type: GET_DELIVERY_ADDRESS, payload: res.data });
    });
};

export const saveDeliveryAddress =
    (body: IDeliveryAddressBody, closeModal: VoidFunction) =>
    async (dispatch: $FIXME) => {
        dispatch({ type: SAVE_DELIVERY_REQUEST });

        request
            .saveDeliveryAddress(body)
            .then((res: AxiosResponse) => {
                dispatch({ type: SAVE_DELIVERY_SUCCESS });
                dispatch(getDeliveryAddress());
                closeModal();
            })
            .catch((err: AxiosError) => {
                dispatch({ type: SAVE_DELIVERY_FAILURE });
                dispatch(
                    setErrors(
                        err.response?.data.message ?? 'Something went wrong'
                    )
                );
            });
    };

export const deleteAddress = (id: string) => async (dispatch: $FIXME) => {
    request
        .deleteAddress(id)
        .then((res: AxiosResponse) => {
            dispatch(getDeliveryAddress());
        })
        .catch((err: AxiosError) => {
            dispatch(setErrors(err.response?.data.message));
        });
};

export const updateDeliveryAddress =
    (id: string, body: IDeliveryAddressBody, closeModal: VoidFunction) =>
    async (dispatch: $FIXME) => {
        dispatch({ type: SAVE_DELIVERY_REQUEST });

        request
            .updateAddress(id, body)
            .then((res: AxiosResponse) => {
                dispatch({ type: SAVE_DELIVERY_SUCCESS });
                dispatch(getDeliveryAddress());
                closeModal();
            })
            .catch((err: AxiosError) => {
                dispatch({ type: SAVE_DELIVERY_FAILURE });
                dispatch(
                    setErrors(
                        err.response?.data.message ?? 'Something went wrong'
                    )
                );
            });
    };
