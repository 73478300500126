import AppTemplateCard from '../../components/AppTemplateCard';
import DashboardLayout from '../../layouts/DashboardLayout';
import AppButton from '../../components/AppButton';
import { toggleConsultationModal } from '../../../actions/uiActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { StateType } from '../../../reducers';
import {
    getExpiredTemplates,
    getSubscribedTemplates,
    resetTemplateState
} from '../../../actions/templateActions';
import dayjs from 'dayjs';
import { defaultImage } from '../../../helpers/constants';
import { Row } from 'react-bootstrap';
import { AppTemplateCardSkeleton } from '../../components/AppSkeleton';

interface IProps {}

const UserPackages = (props: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const resultsPerPage = 5;

    const {
        subscribed,
        subscribedLoading,
        expiredTemplates,
        expiredTemplatesLoading
    } = useSelector((state: StateType) => state.template);

    useEffect(() => {
        dispatch(getSubscribedTemplates({ resultsPerPage, page: 1 }));
        dispatch(getExpiredTemplates({ resultsPerPage, page: 1 }));

        return () => {
            dispatch(resetTemplateState());
        };
    }, [dispatch]);

    const handleSubscribedLoadMore = () => {
        if (subscribed.currentPage !== subscribed.totalPage)
            dispatch(
                getSubscribedTemplates({
                    resultsPerPage,
                    page: subscribed.currentPage + 1
                })
            );
    };

    const handleExpiredLoadMore = () => {
        if (expiredTemplates.currentPage !== expiredTemplates.totalPage)
            dispatch(
                getExpiredTemplates({
                    resultsPerPage,
                    page: expiredTemplates.currentPage + 1
                })
            );
    };

    return (
        <DashboardLayout>
            <div className="t-search-container">
                <div className="mb-5">
                    <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3 mr-3 flex-grow-1">
                        MY TEMPLATES
                    </h2>

                    {subscribed.templates.length ? (
                        <div>
                            <Row
                                noGutters
                                className="mx-n2"
                                xl={5}
                                md={4}
                                sm={3}
                                xs={2}
                            >
                                {subscribed.templates.map(
                                    (
                                        {
                                            _id,
                                            status,
                                            consultation_date,
                                            template,
                                            createdAt
                                        },
                                        index
                                    ) => {
                                        if (template) {
                                            return (
                                                <AppTemplateCard
                                                    key={index}
                                                    subtitle={`Purchased on: ${dayjs(
                                                        createdAt
                                                    ).format('D MMM, YYYY')}`}
                                                    img={
                                                        !!template.cover
                                                            ? template.cover
                                                            : defaultImage
                                                    }
                                                    className="px-2"
                                                    imgTitle={
                                                        template
                                                            ?.training_category
                                                            ?.name
                                                    }
                                                    imgSubtitle={
                                                        template
                                                            ?.template_category
                                                            ?.name
                                                    }
                                                    title={template.title ?? ''}
                                                    hoverOverlay={
                                                        !(
                                                            status ===
                                                                'Active' ||
                                                            status ===
                                                                'Completed'
                                                        ) ? (
                                                            <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                {status ===
                                                                'Consultation Requested' ? (
                                                                    <>
                                                                        <p className="text-white text-12 text-center">
                                                                            Your
                                                                            consultation
                                                                            date
                                                                            request
                                                                            is
                                                                            set
                                                                            for{' '}
                                                                            {dayjs(
                                                                                consultation_date
                                                                            ).format(
                                                                                'MM/DD/YY'
                                                                            )}
                                                                            .
                                                                            Please
                                                                            wait
                                                                            for
                                                                            approval.
                                                                        </p>
                                                                        <AppButton
                                                                            variant="secondary"
                                                                            className="px-1 w-100"
                                                                            style={{
                                                                                height: 44,
                                                                                fontSize: 12
                                                                            }}
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    toggleConsultationModal(
                                                                                        {
                                                                                            open: true,
                                                                                            consultationType:
                                                                                                'Template',
                                                                                            consultationId:
                                                                                                _id
                                                                                        }
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            Change
                                                                            Date
                                                                        </AppButton>
                                                                    </>
                                                                ) : status ===
                                                                  'Consultation Completed' ? (
                                                                    <p className="text-white text-12 text-center">
                                                                        Your
                                                                        consultation
                                                                        is
                                                                        complete.
                                                                        Please
                                                                        wait for
                                                                        activation
                                                                    </p>
                                                                ) : status ===
                                                                  'Inactive' ? (
                                                                    <p className="text-white text-12 text-center">
                                                                        Your
                                                                        template
                                                                        is
                                                                        inactive.
                                                                        Please
                                                                        wait for
                                                                        activation
                                                                    </p>
                                                                ) : (
                                                                    <>
                                                                        <p className="text-white text-12 text-center">
                                                                            Please
                                                                            set
                                                                            a
                                                                            consultation
                                                                            date
                                                                            for
                                                                            your
                                                                            newly
                                                                            purchased
                                                                            template.
                                                                            You
                                                                            can
                                                                            select
                                                                            the
                                                                            date
                                                                            as
                                                                            per
                                                                            your
                                                                            convenience
                                                                        </p>
                                                                        <AppButton
                                                                            variant="secondary"
                                                                            className="px-1 w-100"
                                                                            style={{
                                                                                height: 44,
                                                                                fontSize: 12
                                                                            }}
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    toggleConsultationModal(
                                                                                        {
                                                                                            open: true,
                                                                                            consultationType:
                                                                                                'Template',
                                                                                            consultationId:
                                                                                                _id
                                                                                        }
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            Set
                                                                            Consultation
                                                                            Date
                                                                        </AppButton>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : undefined
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            status === 'Pending'
                                                        ) {
                                                            dispatch(
                                                                toggleConsultationModal(
                                                                    {
                                                                        open: true,
                                                                        consultationType:
                                                                            'Template',
                                                                        consultationId:
                                                                            _id
                                                                    }
                                                                )
                                                            );
                                                        } else if (
                                                            status === 'Active'
                                                        ) {
                                                            history.push(
                                                                `/dashboard/packages/${_id}`
                                                            );
                                                        }
                                                    }}
                                                />
                                            );
                                        } else {
                                            return '';
                                        }
                                    }
                                )}
                            </Row>

                            {subscribed.currentPage === subscribed.totalPage ||
                            subscribed.totalDocuments === 0 ? (
                                ''
                            ) : (
                                <div className="mb-5 d-flex justify-content-center justify-content-lg-end">
                                    <AppButton
                                        variant="outline-primary"
                                        onClick={handleSubscribedLoadMore}
                                        loading={subscribedLoading}
                                    >
                                        Load More Templates
                                    </AppButton>
                                </div>
                            )}
                        </div>
                    ) : !subscribedLoading ? (
                        <div
                            style={{ height: 180 }}
                            className="d-flex flex-column justify-content-center align-items-center"
                        >
                            <p className="text-20">
                                You do not have any active packages...
                            </p>
                            <Link to="/template">
                                <AppButton variant="secondary">
                                    Continue Shopping
                                </AppButton>
                            </Link>
                        </div>
                    ) : (
                        <Row className="mx-n2" xl={5} md={4} sm={3} xs={2}>
                            {[...Array(resultsPerPage)].map((item, i) => (
                                <AppTemplateCardSkeleton
                                    key={i}
                                    className="px-2"
                                />
                            ))}
                        </Row>
                    )}
                </div>

                {expiredTemplates.templates.length ? (
                    <div className="mb-5">
                        <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-2 mr-3 flex-grow-1">
                            PREVIOUSLY PURCHASED TEMPLATES
                        </h2>

                        <div>
                            <Row
                                noGutters
                                className="mx-n2"
                                xl={5}
                                md={4}
                                sm={3}
                                xs={2}
                            >
                                {expiredTemplates?.templates?.map(
                                    ({ template, createdAt }, index) =>
                                        template ? (
                                            <AppTemplateCard
                                                key={index}
                                                subtitle={`Purchased on: ${dayjs(
                                                    createdAt
                                                ).format('D MMM, YYYY')}`}
                                                img={
                                                    template.cover ??
                                                    'https://infokhabar.com/wp-content/uploads/2019/11/default-image.jpg'
                                                }
                                                className="px-2"
                                                imgTitle={
                                                    template?.training_category
                                                        ?.name
                                                }
                                                imgSubtitle={
                                                    template?.template_category
                                                        ?.name
                                                }
                                                title={template.title ?? ''}
                                                hoverOverlay={
                                                    <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                        <AppButton
                                                            variant="primary"
                                                            className="px-1 w-100"
                                                            style={{
                                                                height: 44,
                                                                fontSize: 12
                                                            }}
                                                            onClick={() => {
                                                                history.push(
                                                                    `/express/checkout/cart?model_id=${template._id}&on_model=template`
                                                                );
                                                            }}
                                                        >
                                                            Purchase Again
                                                        </AppButton>
                                                    </div>
                                                }
                                            />
                                        ) : (
                                            ''
                                        )
                                )}
                            </Row>

                            {expiredTemplates.currentPage ===
                                expiredTemplates.totalPage ||
                            expiredTemplates.totalDocuments === 0 ? (
                                ''
                            ) : (
                                <div className="mb-5 d-flex justify-content-center justify-content-lg-end">
                                    <AppButton
                                        variant="outline-primary"
                                        onClick={handleExpiredLoadMore}
                                        loading={expiredTemplatesLoading}
                                    >
                                        Load More Templates
                                    </AppButton>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </DashboardLayout>
    );
};

export default UserPackages;
