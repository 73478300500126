import MainLayout from '../../layouts/MainLayout';
// import About from './About';
import FeaturedTemplates from './FeaturedTemplates';
import Feedback from './Feedback';
import GetNotified from './GetNotified';
import Hero from './Hero';
import Offers from './Offers';
import TemplateList from './TemplateList';
import TrainerList from './TrainerList';
import AppJourney from './AppJourney';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getTrainers } from '../../../actions/trainerActions';
import { getTestimonials } from '../../../actions/testimonialActions';
import {
    getFeaturedTemplates,
    getTemplates,
    resetTemplateState
} from '../../../actions/templateActions';
import About from './About';

interface IProps {}

const Home = (props: IProps) => {
    const appJourneyEl = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const handleHeroScroll = () => {
        appJourneyEl.current?.scrollIntoView({
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        dispatch(getFeaturedTemplates({}));
        dispatch(getTemplates({}));
        dispatch(getTrainers());
        dispatch(getTestimonials());

        return () => {
            dispatch(resetTemplateState());
        };
    }, [dispatch]);

    return (
        <MainLayout hideNavBorder>
            <Hero onScrollClick={handleHeroScroll} />
            <AppJourney ref={appJourneyEl} />
            <Offers />
            <FeaturedTemplates />
            <TrainerList />
            <TemplateList />
            <About />
            <Feedback />
            <GetNotified />
        </MainLayout>
    );
};

export default Home;
