import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import routes from './helpers/routes';
import { checkAuthentication } from './utils/checkAuthentication';
import Splash from './views/pages/splash/Splash';
import { AppDispatch, StateType } from './reducers';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import { getUserProfile } from './actions/userActions';
import { useCallback } from 'react';
import { getCarts } from './actions/cartActions';
import { getContactInfo } from './actions/contactActions';

const cookies = new Cookies();

export interface RouteType {
    path: string;
    component: React.FunctionComponent<RouteProps>;
    protected?: boolean;
    exact?: boolean;
    initialAction?: any[];
}

interface PrivateRouteProps extends RouteProps {
    component: React.FunctionComponent<RouteProps>;
}

const PrivateRoutes = (props: PrivateRouteProps) => {
    const auth = useSelector((state: StateType) => state.auth);

    const { component: Component, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(props) =>
                auth?.authenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

const App = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch<AppDispatch>();

    const authenticated = cookies.get('authenticated');

    const initialLoad = useCallback(async () => {
        await checkAuthentication();

        if (authenticated) {
            await dispatch(getUserProfile());
            await dispatch(getCarts());
        }

        await dispatch(getContactInfo());

        setLoading(false);
    }, [dispatch, authenticated]);

    useEffect(() => {
        initialLoad();
    }, [initialLoad]);

    return (
        <>
            <Switch>
                {loading ? (
                    <Splash />
                ) : (
                    routes.map((route: RouteType, i: number) => {
                        return route.protected === true ? (
                            <PrivateRoutes
                                key={i}
                                {...route}
                                component={route.component}
                            />
                        ) : (
                            <Route
                                key={i}
                                {...route}
                                component={route.component}
                            />
                        );
                    })
                )}
            </Switch>
            <ToastContainer hideProgressBar />
        </>
    );
};

export default App;
