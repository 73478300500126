import {
    Col,
    Row,
    Table,
    ProgressBar,
    Spinner,
    Modal,
    Form,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { useState } from "react";
import StatusChip from "../../components/StatusChip";
import {
    IoIosArrowDropdownCircle,
    IoIosArrowDroprightCircle,
} from "react-icons/io";
import TrainingLocked, { TableHead } from "../trainingDetails/TrainingLocked";
import AppTemplateCard from "../../components/AppTemplateCard";
import { useDispatch, useSelector } from "react-redux";
import { $FIXME, defaultImage } from "../../../helpers/constants";
import { AppDispatch, StateType } from "../../../reducers";
import { useEffect } from "react";
import { CgCloseO } from "react-icons/cg";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import { Formik } from "formik";
import * as Yup from "yup";
import request from "../../../helpers/request";
import { Motivation } from "../../../assets/images/icons";
import { AxiosError } from "axios";
import { setErrors } from "../../../actions/errorActions";
import {
    getSubscribedCoaching,
    saveUserCoachingProgress,
} from "../../../actions/coachingActions";
import { DayActivity } from "../../../interfaces/Coaching";
import { toggleConsultationModal } from "../../../actions/uiActions";
import DashboardLayout from "../../layouts/DashboardLayout";
import { toast } from "react-toastify";
import WeekActivity from "./components/WeekActivity";

interface IProps {}

interface IConfirmationModalProps {
    week?: string;
    show: boolean;
}

const SubscribedCoaching = (props: IProps) => {
    const { subscribedCoaching: coaching, subscribedCoachingLoading } =
        useSelector((state: StateType) => state.coaching);

    const [expanded, setExpanded] = useState<Array<number>>([]);
    const [showConfirmationModal, setShowConfirmationModal] =
        useState<IConfirmationModalProps>();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState<DayActivity>();

    const handleCloseConfirmationModal = () =>
        setShowConfirmationModal({ show: false });

    const dispatch = useDispatch<AppDispatch>();

    const { id }: $FIXME = useParams();

    useEffect(() => {
        dispatch(getSubscribedCoaching(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (coaching.weeks_completed && coaching.subscribed_weeks) {
            let weeks_completed = coaching.weeks_completed;

            setExpanded((prev) => (prev.length ? prev : [weeks_completed]));
        }
    }, [coaching]);

    return (
        <DashboardLayout>
            <div className="training-details t-search-container">
                <div className="mb-3">
                    <Link to="/dashboard/trainers">
                        <BsArrowLeft size={24} />
                        <span className="text-oswald text-500 text-14 text-gray">
                            BACK
                        </span>
                    </Link>
                </div>
                {id === coaching._id ? (
                    <Row className="mb-5">
                        <Col md={5} lg={4} xl={3} className="mb-5 col-xxl-2">
                            <AppTemplateCard
                                squareImage
                                title={coaching.coaching?.title ?? ""}
                                subtitle=""
                                imgTitle={coaching.coaching_trainer?.name}
                                imgSubtitle={coaching.coaching_plan}
                                img={
                                    !!coaching.coaching?.cover
                                        ? coaching.coaching?.cover
                                        : defaultImage
                                }
                                className="my-n1 py-1"
                            />
                            <div className="bg-light-gray-3 p-3">
                                <h2 className="text-16 text-700 mb-4">
                                    PROGRESS REPORT
                                </h2>
                                <div>
                                    <div className="d-flex mb-3">
                                        <span className="text-gray-2 tet-14 text-500">
                                            Completed
                                        </span>
                                        <span className="text-gray-2 tet-14 flex-grow-1 text-right text-500">
                                            <span className="text-red text-700">
                                                {coaching.weeks_completed}
                                            </span>
                                            /{coaching.subscribed_weeks} Weeks
                                        </span>
                                    </div>
                                    <ProgressBar
                                        now={
                                            ((coaching.weeks_completed ?? 0) /
                                                (coaching.subscribed_weeks ??
                                                    1)) *
                                            100
                                        }
                                        className="training-progress mb-3"
                                    />
                                    <p className="text-dark-3 text-center text-14 mb-4">
                                        {(() => {
                                            if (
                                                coaching.weeks_completed &&
                                                coaching.subscribed_weeks
                                            ) {
                                                if (
                                                    coaching.weeks_completed ===
                                                    coaching.subscribed_weeks
                                                ) {
                                                    return `Training Completed`;
                                                } else {
                                                    return `Currently on: Week 
                                                    ${
                                                        coaching.weeks_completed +
                                                        1
                                                    }`;
                                                }
                                            } else {
                                                return "";
                                            }
                                        })()}
                                    </p>

                                    <div>
                                        <AppButton
                                            variant="outline-secondary"
                                            className="px-1 w-100"
                                            onClick={() => {
                                                dispatch(
                                                    toggleConsultationModal({
                                                        open: true,
                                                        consultationType:
                                                            "Coaching",
                                                        consultationId:
                                                            coaching._id,
                                                    })
                                                );
                                            }}
                                        >
                                            Request Consultation
                                        </AppButton>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={7} lg={8} xl={9} className="col-xxl-10">
                            <h2 className="text-700 text-20 text-dark-blue text-uppercase mb-3">
                                WEEKLY SCHEDULE
                            </h2>
                            <Table
                                responsive
                                className="training-details-table"
                            >
                                <thead>
                                    <tr className="bg-light-gray">
                                        <TableHead>Movement</TableHead>
                                        <TableHead>Intensity</TableHead>
                                        <TableHead>Weight Used</TableHead>
                                        <TableHead>Actual RPE</TableHead>
                                        <TableHead>Sets</TableHead>
                                        <TableHead>Reps</TableHead>
                                        <TableHead>Tempo</TableHead>
                                        <TableHead>Rest</TableHead>
                                        <TableHead>Note</TableHead>
                                        <TableHead>Action</TableHead>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[
                                        ...Array(
                                            coaching.subscribed_weeks ?? 0
                                        ),
                                    ].map((item, index) => {
                                        if (coaching.schedule) {
                                            const schedule =
                                                coaching?.schedule[index];

                                            if (
                                                index <=
                                                (coaching.weeks_completed ?? 0)
                                            ) {
                                                return (
                                                    <>
                                                        <tr
                                                            className="bg-dark-blue cursor-pointer"
                                                            onClick={() =>
                                                                expanded.some(
                                                                    (state) =>
                                                                        state ===
                                                                        index
                                                                )
                                                                    ? setExpanded(
                                                                          expanded.filter(
                                                                              (
                                                                                  state
                                                                              ) =>
                                                                                  state !==
                                                                                  index
                                                                          )
                                                                      )
                                                                    : setExpanded(
                                                                          [
                                                                              ...expanded,
                                                                              index,
                                                                          ]
                                                                      )
                                                            }
                                                        >
                                                            <td colSpan={10}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1 d-flex align-items-center">
                                                                        <p className="text-oswald text-16 text-white text-700 mb-0 mr-4">
                                                                            WEEK{" "}
                                                                            {index +
                                                                                1}
                                                                            {/* <span className="text-neutrif text-400 text-10 ml-2">
                                                                                12
                                                                                July,
                                                                                Sunday
                                                                            </span> */}
                                                                        </p>
                                                                        <StatusChip
                                                                            type={
                                                                                index ===
                                                                                (coaching.weeks_completed ??
                                                                                    0)
                                                                                    ? "warning"
                                                                                    : "success"
                                                                            }
                                                                            label={
                                                                                index ===
                                                                                (coaching.weeks_completed ??
                                                                                    0)
                                                                                    ? "In Progress"
                                                                                    : "Completed"
                                                                            }
                                                                        />
                                                                        {index ===
                                                                        (coaching.weeks_completed ??
                                                                            0) ? (
                                                                            <div className="flex-grow-1 d-flex align-items-center">
                                                                                <p className="flex-grow-1 text-14 text-gray ml-3 mb-0">
                                                                                    Currently
                                                                                    you
                                                                                    are
                                                                                    on
                                                                                    Week{" "}
                                                                                    {index +
                                                                                        1}
                                                                                </p>
                                                                                {coaching
                                                                                    .week_completed_req
                                                                                    ?.weeks_completed ===
                                                                                index +
                                                                                    1 ? (
                                                                                    <button
                                                                                        className="bg-dark-gray-2 text-white text-neutrif text-14 py-1 border-none mx-3"
                                                                                        disabled
                                                                                    >
                                                                                        Completion
                                                                                        Requested
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        className="bg-dark-gray-2 text-white text-neutrif text-14 py-1 border-none mx-3"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation();
                                                                                            setShowConfirmationModal(
                                                                                                {
                                                                                                    week: (
                                                                                                        index +
                                                                                                        1
                                                                                                    ).toString(),
                                                                                                    show: true,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Set
                                                                                        as
                                                                                        completed
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    {expanded.some(
                                                                        (
                                                                            state
                                                                        ) =>
                                                                            state ===
                                                                            index
                                                                    ) ? (
                                                                        <IoIosArrowDropdownCircle
                                                                            size={
                                                                                24
                                                                            }
                                                                            fill="white"
                                                                            fillOpacity="0.41"
                                                                        />
                                                                    ) : (
                                                                        <IoIosArrowDroprightCircle
                                                                            size={
                                                                                24
                                                                            }
                                                                            fill="white"
                                                                            fillOpacity="0.41"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {expanded.some(
                                                            (state) =>
                                                                state === index
                                                        ) ? (
                                                            schedule?.week_activities ? (
                                                                schedule?.week_activities?.map(
                                                                    (
                                                                        weekActivities,
                                                                        weekIndex
                                                                    ) => (
                                                                        <WeekActivity
                                                                            show={expanded.some(
                                                                                (
                                                                                    state
                                                                                ) =>
                                                                                    state ===
                                                                                    index
                                                                            )}
                                                                            weekActivities={
                                                                                weekActivities
                                                                            }
                                                                            key={
                                                                                weekIndex
                                                                            }
                                                                            coachingId={
                                                                                id
                                                                            }
                                                                        />
                                                                    )
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td
                                                                        colSpan={
                                                                            10
                                                                        }
                                                                    >
                                                                        <span className="px-3">
                                                                            Trainer
                                                                            is
                                                                            estimating
                                                                            exercises
                                                                            for
                                                                            you...
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <tr
                                                            style={{
                                                                height: "1rem",
                                                            }}
                                                        />
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <tr
                                                            className="bg-dark-blue cursor-pointer"
                                                            onClick={() =>
                                                                expanded.some(
                                                                    (state) =>
                                                                        state ===
                                                                        index
                                                                )
                                                                    ? setExpanded(
                                                                          expanded.filter(
                                                                              (
                                                                                  state
                                                                              ) =>
                                                                                  state !==
                                                                                  index
                                                                          )
                                                                      )
                                                                    : setExpanded(
                                                                          [
                                                                              ...expanded,
                                                                              index,
                                                                          ]
                                                                      )
                                                            }
                                                        >
                                                            <td colSpan={10}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1 d-flex align-items-center">
                                                                        <p className="text-oswald text-16 text-white text-700 mb-0 mr-4">
                                                                            WEEK{" "}
                                                                            {index +
                                                                                1}
                                                                            {/* <span className="text-neutrif text-400 text-10 ml-2">
                                                                                12
                                                                                July,
                                                                                Sunday
                                                                            </span> */}
                                                                        </p>
                                                                        <StatusChip
                                                                            type="danger"
                                                                            label="Trainning Locked"
                                                                        />
                                                                        <p className="text-14 text-gray ml-3 mb-0">
                                                                            This
                                                                            is
                                                                            currently
                                                                            locked!!
                                                                            You
                                                                            need
                                                                            to
                                                                            complete
                                                                            the
                                                                            previous
                                                                            week
                                                                            training
                                                                            to
                                                                            start
                                                                            with
                                                                            week{" "}
                                                                            {index +
                                                                                1}
                                                                        </p>
                                                                    </div>
                                                                    {expanded.some(
                                                                        (
                                                                            state
                                                                        ) =>
                                                                            state ===
                                                                            index
                                                                    ) ? (
                                                                        <IoIosArrowDropdownCircle
                                                                            size={
                                                                                24
                                                                            }
                                                                            fill="white"
                                                                            fillOpacity="0.41"
                                                                        />
                                                                    ) : (
                                                                        <IoIosArrowDroprightCircle
                                                                            size={
                                                                                24
                                                                            }
                                                                            fill="white"
                                                                            fillOpacity="0.41"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        {expanded.some(
                                                            (state) =>
                                                                state === index
                                                        ) ? (
                                                            <tr>
                                                                <td
                                                                    colSpan={10}
                                                                    className="p-0"
                                                                >
                                                                    <TrainingLocked />
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <tr
                                                            style={{
                                                                height: "1rem",
                                                            }}
                                                        />
                                                    </>
                                                );
                                            }
                                        } else {
                                            return null;
                                        }
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                ) : subscribedCoachingLoading ? (
                    <div
                        className="d-flex justify-content-center mb-5"
                        style={{ height: 400 }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : (
                    ""
                )}
            </div>

            <Modal
                show={showConfirmationModal?.show}
                onHide={handleCloseConfirmationModal}
                size="lg"
                centered
                contentClassName="rounded"
            >
                <Modal.Header className="px-0 mx-3 pb-0 border-0">
                    <span className="text-oswald text-uppercase text-600 text-dark-blue text-18">
                        Week Training completed confirmation
                    </span>
                    <CgCloseO
                        size={28}
                        className="text-gray text-hover-red cursor-pointer"
                        onClick={handleCloseConfirmationModal}
                    />
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="text-14 text-gray-2">
                            You are about to confirm the completion of{" "}
                            <span className="text-600">
                                WEEK {showConfirmationModal?.week}
                            </span>{" "}
                            Training Program. Please share your feedback
                            regarding this week’s training.
                        </p>
                        <Formik
                            initialValues={{ remarks: "" }}
                            validationSchema={Yup.object().shape({
                                remarks: Yup.string().required(
                                    "Remarks is required"
                                ),
                            })}
                            onSubmit={(values) => {
                                request
                                    .coachingWeekCompletionRequest({
                                        subscription: id,
                                        weeks_completed:
                                            showConfirmationModal?.week ?? "",
                                        remarks: values.remarks,
                                    })
                                    .then(() => {
                                        dispatch(getSubscribedCoaching(id));
                                        handleCloseConfirmationModal();
                                        setShowSuccessModal(true);
                                    })
                                    .catch((err: AxiosError) => {
                                        dispatch(
                                            setErrors(
                                                err.response?.data.message ??
                                                    "Something went wrong"
                                            )
                                        );
                                    });
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                isValid,
                                errors,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <AppInput
                                        as="textarea"
                                        required
                                        fill
                                        label="What’s your feedback for the training format?"
                                        rows={4}
                                        placeholder="Share your experience and feedback related to this weeks training."
                                        name="remarks"
                                        value={values.remarks}
                                        onChange={handleChange}
                                        isInvalid={!!errors.remarks}
                                        error={errors.remarks}
                                    />
                                    <div className="d-flex justify-content-end">
                                        <AppButton
                                            variant="primary"
                                            disabled={!isValid}
                                            type="submit"
                                        >
                                            Complete Training
                                        </AppButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={showSuccessModal}
                onHide={() => setShowSuccessModal(false)}
                size="lg"
                centered
                contentClassName="rounded"
            >
                <Modal.Header className="px-0 mx-3 pb-0 border-0">
                    <CgCloseO
                        size={28}
                        className="text-gray text-hover-red cursor-pointer"
                        onClick={() => setShowSuccessModal(false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center">
                        <Motivation className="mb-3" />
                        <h2 className="text-uppercase text-20 text-dark-blue text-600 text-center">
                            Congratulations on Completing week{" "}
                            {showConfirmationModal?.week} training
                        </h2>
                        <p className="text-gray-2 text-center">
                            Your training completion information will be kept
                            under review and your next training will be
                            available after review process. Keep getting fit.
                        </p>
                        <Link to="/coaching" className="mb-3">
                            <AppButton variant="primary">
                                Explore Coachings
                            </AppButton>
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={!!showUpdateModal}
                onHide={() => {
                    setShowUpdateModal(undefined);
                }}
                size="lg"
                centered
                contentClassName="rounded"
            >
                <Modal.Header className="px-0 mx-3 pb-0 border-0">
                    <h2 className="text-uppercase text-20 text-dark-blue text-600">
                        Day Activity
                    </h2>

                    <CgCloseO
                        size={28}
                        className="text-gray text-hover-red cursor-pointer"
                        onClick={() => {
                            setShowUpdateModal(undefined);
                        }}
                    />
                </Modal.Header>

                <Modal.Body>
                    <div className="mb-3">
                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Movement:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {showUpdateModal?.movement ?? "-"}
                            </span>
                        </div>

                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Intensity:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {showUpdateModal?.intensity ?? "-"}
                            </span>
                        </div>

                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Weight Used:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {showUpdateModal?.weight_used ?? "-"}
                            </span>
                        </div>

                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Actual RPE:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {showUpdateModal?.actual_rpe ?? "-"}
                            </span>
                        </div>

                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Sets:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {showUpdateModal?.sets ?? "-"}
                            </span>
                        </div>

                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Reps:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {showUpdateModal?.reps ?? "-"}
                            </span>
                        </div>

                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Tempo:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {showUpdateModal?.tempo ?? "-"}
                            </span>
                        </div>

                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Rest:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {showUpdateModal?.rest ?? "-"}
                            </span>
                        </div>

                        <div>
                            <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                                Note:{" "}
                            </span>
                            <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                                {!!showUpdateModal?.note
                                    ? showUpdateModal.note
                                    : "-"}
                            </span>
                        </div>
                    </div>

                    {!!showUpdateModal?.actual_rpe ||
                    !!showUpdateModal?.weight_used ? (
                        <Formik
                            initialValues={{
                                feedback: showUpdateModal?.feedback ?? "",
                            }}
                            validationSchema={Yup.object().shape({
                                feedback: Yup.string().required(
                                    "Feedback is required"
                                ),
                            })}
                            onSubmit={(values) => {
                                if (showUpdateModal._id)
                                    dispatch(
                                        saveUserCoachingProgress(
                                            {
                                                actual_rpe:
                                                    showUpdateModal.actual_rpe,
                                                weight_used:
                                                    showUpdateModal.weight_used ??
                                                    "",
                                                feedback: values.feedback,
                                            },
                                            showUpdateModal._id
                                        )
                                    ).then(() => {
                                        dispatch(getSubscribedCoaching(id));
                                        setShowUpdateModal(undefined);

                                        toast.success(
                                            "Thank you for your feedback.",
                                            {
                                                position:
                                                    toast.POSITION.TOP_CENTER,
                                            }
                                        );
                                    });
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                isValid,
                                errors,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <AppInput
                                        as="textarea"
                                        required
                                        fill
                                        label="What’s your feedback for the day training?"
                                        rows={4}
                                        placeholder="Share your experience and feedback related to this days training."
                                        name="feedback"
                                        value={values.feedback}
                                        onChange={handleChange}
                                        isInvalid={!!errors.feedback}
                                        error={errors.feedback}
                                    />
                                    <div className="d-flex justify-content-end">
                                        <AppButton
                                            variant="primary"
                                            disabled={!isValid}
                                            type="submit"
                                        >
                                            {!!showUpdateModal.feedback
                                                ? "Update Feedback"
                                                : "Add Feedback"}
                                        </AppButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        ""
                    )}
                </Modal.Body>
            </Modal>
        </DashboardLayout>
    );
};

export default SubscribedCoaching;
