import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Button, Collapse, Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import AppButton from '../../components/AppButton';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import DeliveryAddressModal from './DeliveryAddressModal';
import { initiateOrder } from '../../../actions/paymentActions';
import { useEffect } from 'react';
import AppSelect, { ISelectOptions } from '../../components/AppSelect';
import AppInput from '../../components/AppInput';
import { ChangeEvent } from 'react';
import { formatNumber } from '../../../utils/functions';
import request from '../../../helpers/request';
import { AxiosError, AxiosResponse } from 'axios';
import { setErrors } from '../../../actions/errorActions';
import { ICoupon } from '../../../interfaces/Coupon';
import { getCarts } from '../../../actions/cartActions';
import { toast } from 'react-toastify';
import { ICart } from '../../../interfaces/Cart';

interface IProps {
    cart: ICart;
}

const CheckoutSummary = ({ cart }: IProps) => {
    const [buyDetailOpen, setBuyDetailOpen] = useState(true);
    const [applyDiscountOpen, setApplyDiscountOpen] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [deliveryId, setDeliveryId] = useState<ISelectOptions | null>();
    const [coupon, setCoupon] = useState<string>();
    const [couponSuccess, setCouponSuccess] = useState(false);
    const [couponError, setCouponError] = useState<string>();
    const [couponDetail, setCouponDetail] = useState<ICoupon>();

    const handleCloseAddressModal = () => setShowAddressModal(false);
    const handleShowAddressModal = () => setShowAddressModal(true);

    const auth = useSelector((state: StateType) => state.auth);

    const { carts, sub_total, total_amount, only_templates } = cart;

    const { address } = useSelector(
        (state: StateType) => state.deliveryAddress
    );
    const { initiateOrderLoading } = useSelector(
        (state: StateType) => state.payment
    );

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const handleBuyDetailToggle = () => {
        setBuyDetailOpen(!buyDetailOpen);
    };

    const handleApplyDiscountToggle = () => {
        setApplyDiscountOpen(!applyDiscountOpen);
    };

    const handleCheckout = () => {
        dispatch(
            only_templates
                ? initiateOrder({
                      carts: carts.map((item) => item._id ?? ''),
                      coupon_code: coupon
                  })
                : initiateOrder({
                      delivery_address: deliveryId?.value ?? '',
                      carts: carts.map((item) => item._id ?? ''),
                      coupon_code: coupon
                  })
        ).then(() => {
            history.push('/cart/checkout');
        });
    };

    const handleOnChange = (field: string, value: ISelectOptions | null) => {
        setDeliveryId(value);
    };

    const handleCouponChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCoupon(e.target.value);
    };

    const handleCouponSubmit = () => {
        if (coupon)
            request
                .checkCouponValidity(coupon)
                .then(() => {
                    toast.success('Coupon Applied Successfully', {
                        position: toast.POSITION.TOP_CENTER
                    });

                    setCouponSuccess(true);
                    setCouponError(undefined);

                    request
                        .getCouponByCode(coupon)
                        .then((res: AxiosResponse) => {
                            setCouponDetail(res.data);
                            dispatch(getCarts());
                        });
                })
                .catch((err: AxiosError) => {
                    setCouponSuccess(false);
                    if (err.response?.data?.status === 'error') {
                        setCouponError(err.response?.data?.message);
                    } else {
                        dispatch(
                            setErrors(
                                err.response?.data?.message ??
                                    'Something went wrong'
                            )
                        );
                    }
                });
    };

    useEffect(() => {
        if (address[0]?._id) {
            let { _id, address: add, label } = address[0];
            setDeliveryId({
                value: _id,
                label: `${label} - ${add?.address_line1}, ${add?.area}, ${add?.district}, ${add?.province}, ${add?.country}`
            });
        }
    }, [address]);

    return (
        <>
            <div className="summary-container p-4">
                <h2 className="text-600 text-24 text-dark-blue text-uppercase mb-3">
                    Summary
                </h2>

                {!only_templates ? (
                    <div className="mb-3">
                        {address.length ? (
                            <Form>
                                <AppSelect
                                    label="Delivery Address"
                                    placeholder="Select Delivery Address"
                                    required
                                    selectOptions={address.map(
                                        ({ _id, address, label }) => ({
                                            label: `${label} - ${address?.address_line1}, ${address?.area}, ${address?.district}, ${address?.province}, ${address?.country}`,
                                            value: _id
                                        })
                                    )}
                                    name="delivery_address"
                                    onChange={handleOnChange}
                                    value={deliveryId}
                                />
                            </Form>
                        ) : (
                            ''
                        )}

                        <Button
                            variant="link"
                            className="text-oswald text-700 text-16 text-red px-0"
                            onClick={handleShowAddressModal}
                        >
                            + ADD ADDRESS
                        </Button>
                    </div>
                ) : (
                    ''
                )}

                <div
                    className="d-flex py-2 cursor-pointer text-hover-red"
                    onClick={handleBuyDetailToggle}
                >
                    <span className="text-neutrif text-18 text-dark-blue flex-grow-1">
                        Detail of the buyer
                    </span>
                    {buyDetailOpen ? <FiChevronUp /> : <FiChevronDown />}
                </div>

                <Collapse in={buyDetailOpen}>
                    <div>
                        <div className="d-flex align-items-center mb-2">
                            <span className="text-neutrif text-600 text-14 text-dark-2 mr-2">
                                Email
                            </span>
                            <span className="text-neutrif text-600 text-12 text-dark-2 flex-grow-1 text-right">
                                {auth.authUser.email}
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <span className="text-neutrif text-600 text-14 text-dark-2 mr-2">
                                Full Name
                            </span>
                            <span className="text-neutrif text-600 text-12 text-dark-2 flex-grow-1 text-right">
                                {auth.authUser.name}
                            </span>
                        </div>
                        {auth.authUser.mobile_num ? (
                            <div className="d-flex align-items-center mb-2">
                                <span className="text-neutrif text-600 text-14 text-dark-2 mr-2">
                                    Phone Number
                                </span>
                                <span className="text-neutrif text-600 text-12 text-dark-2 flex-grow-1 text-right">
                                    {auth.authUser.mobile_num}
                                </span>
                            </div>
                        ) : (
                            ''
                        )}

                        <hr />
                    </div>
                </Collapse>

                {!couponSuccess ? (
                    <>
                        <div
                            className="d-flex py-2 cursor-pointer text-hover-red"
                            onClick={handleApplyDiscountToggle}
                        >
                            <span className="text-neutrif text-18 text-dark-blue flex-grow-1">
                                Apply Discount Code
                            </span>
                            {applyDiscountOpen ? (
                                <FiChevronUp />
                            ) : (
                                <FiChevronDown />
                            )}
                        </div>

                        <Collapse in={applyDiscountOpen}>
                            <div>
                                <Form className="py-3">
                                    <AppInput
                                        placeholder="Enter Coupon Code"
                                        fill
                                        onChange={handleCouponChange}
                                        error={couponError}
                                        isInvalid={!!couponError}
                                    />
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            variant="link"
                                            className="text-oswald text-700 text-16 text-red px-1"
                                            disabled={!coupon || coupon === ''}
                                            onClick={handleCouponSubmit}
                                        >
                                            APPLY DISCOUNT
                                        </Button>
                                    </div>
                                </Form>
                                <hr />
                            </div>
                        </Collapse>
                    </>
                ) : (
                    ''
                )}

                <div className="d-flex pt-2">
                    <p className="text-dark-2 text-14 text-600 mb-1">
                        Subtotal
                    </p>
                    <p className="flex-grow-1 text-right text-dark-2 text-14 text-600 mb-1">
                        Rs. {formatNumber(sub_total)}
                    </p>
                </div>
                <p className="text-neutrif text-dark-3 text-12 mb-1">
                    (If you find any difficulty in purchasing the
                    product/template from Ox Strength, please contact us through{' '}
                    <Link
                        to="/contact"
                        className="text-neutrif text-red text-underline"
                    >
                        our support
                    </Link>
                    . We will get back to you as quick as possible.)
                </p>

                {couponDetail?.active &&
                couponDetail?.discount_percentage &&
                couponDetail.expiry_date &&
                new Date(couponDetail.expiry_date) > new Date() ? (
                    <div className="d-flex pt-2">
                        <p className="text-dark-2 text-14 text-600 mb-1">
                            Discount Code ({couponDetail.discount_percentage}%)
                        </p>
                        <p className="flex-grow-1 text-right text-dark-2 text-14 text-600 mb-1">
                            - Rs.{' '}
                            {formatNumber(
                                (total_amount ?? 0) *
                                    (couponDetail.discount_percentage / 100)
                            )}
                        </p>
                    </div>
                ) : (
                    ''
                )}

                <div className="d-flex pt-2">
                    <p className="text-dark-2 text-14 text-600 mb-1">
                        Order Total
                    </p>
                    <p className="flex-grow-1 text-right text-dark-2 text-18 text-600 mb-1">
                        Rs.{' '}
                        {total_amount
                            ? formatNumber(
                                  total_amount -
                                      (couponDetail?.active &&
                                      couponDetail?.discount_percentage &&
                                      couponDetail.expiry_date &&
                                      new Date(couponDetail.expiry_date) >
                                          new Date()
                                          ? (total_amount *
                                                couponDetail.discount_percentage) /
                                            100
                                          : 0)
                              )
                            : 0}
                    </p>
                </div>
                <p className="text-gray text-12 mb-1">
                    * Price is inclusive of all taxes
                </p>

                <AppButton
                    variant="primary"
                    className="w-100 mt-4"
                    onClick={
                        !address.length && !only_templates
                            ? handleShowAddressModal
                            : handleCheckout
                    }
                    disabled={initiateOrderLoading}
                    loading={initiateOrderLoading}
                >
                    Continue to Checkout
                </AppButton>
            </div>
            <Modal
                show={showAddressModal}
                onHide={handleCloseAddressModal}
                size="xl"
                centered
            >
                <Modal.Body>
                    <DeliveryAddressModal
                        closeModal={handleCloseAddressModal}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CheckoutSummary;
