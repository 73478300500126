import { Row, Col, Dropdown, DropdownButton, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppCheckbox from '../../components/AppCheckbox';
import AppInput from '../../components/AppInput';
import MainLayout from '../../layouts/MainLayout';
import GetOffers from '../templateSearch/GetOffers';
import Slider from 'react-slick';
import { ReactComponent as ArrowNext } from '../../../assets/images/icons/arrow-next.svg';
import AppVideoCard from '../../components/AppVideoCard';
import { BiCustomize } from 'react-icons/bi';
import { BsPeople } from 'react-icons/bs';
import GetNotified from '../home/GetNotified';
import { useEffect } from 'react';
import { getAllOffers } from '../../../actions/offerActions';
import { useDispatch } from 'react-redux';

interface IProps {}

const VideoList = (props: IProps) => {
    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Videos',
            link: '/videos',
            active: true
        }
    ];

    var settings = {
        infinite: true,
        speed: 500,
        nextArrow: <ArrowNext fill="#ed2b30" />,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    return (
        <MainLayout backgroundColor="#F4F6FC" navBackgroundColor="#F4F6FC">
            <div className="container-xxl container-xl shop-search-container">
                <AppBreadcrumbs items={breadcrumbItems} />

                <Row className="mb-5">
                    <Col lg={3} md={12}>
                        <div className="shop-filter-container mb-3">
                            <div className="shop-filter-head p-3 d-flex justify-content-between">
                                <h2 className="text-600 text-18 text-uppercase mb-0">
                                    FILTER BY BRANDS
                                </h2>
                                <BiCustomize size={18} className="text-gray" />
                            </div>
                            <div className="py-3">
                                <AppCheckbox
                                    containerClassName="px-3 py-2"
                                    label="Allmax"
                                />
                                <AppCheckbox
                                    containerClassName="px-3 py-2"
                                    label="Bpi Sports"
                                />
                                <AppCheckbox
                                    containerClassName="px-3 py-2"
                                    label="Bsn"
                                />
                                <AppCheckbox
                                    containerClassName="px-3 py-2"
                                    label="Dymatize"
                                />
                                <AppCheckbox
                                    containerClassName="px-3 py-2"
                                    label="Grenade"
                                />
                            </div>
                        </div>
                        <div className="shop-filter-container mb-3">
                            <div className="shop-filter-head p-3">
                                <h2 className="text-600 text-18 text-uppercase mb-0">
                                    Filter By Category
                                </h2>
                            </div>
                            <div className="py-3">
                                <Link
                                    to="/shop/search"
                                    className="d-flex justify-content-between px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <span className="text-16">Fitness</span>
                                    <span className="text-14">20</span>
                                </Link>
                                <Link
                                    to="/shop/search"
                                    className="d-flex justify-content-between px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <span className="text-16">Cardio</span>
                                    <span className="text-14">1k</span>
                                </Link>
                                <Link
                                    to="/shop/search"
                                    className="d-flex justify-content-between px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <span className="text-16">
                                        Heavy Lifting
                                    </span>
                                    <span className="text-14">1k</span>
                                </Link>
                                <Link
                                    to="/shop/search"
                                    className="d-flex justify-content-between px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <span className="text-16">
                                        Gym and Jumba
                                    </span>
                                    <span className="text-14">3k</span>
                                </Link>
                                <Link
                                    to="/shop/search"
                                    className="d-flex justify-content-between px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <span className="text-16">Others</span>
                                    <span className="text-14">5k</span>
                                </Link>
                            </div>
                        </div>
                        <div className="shop-filter-container mb-3">
                            <div className="shop-filter-head p-3 d-flex justify-content-between">
                                <h2 className="text-600 text-18 text-uppercase mb-0">
                                    VIDEOS BY TRAINERS
                                </h2>
                                <BsPeople size={18} className="text-gray" />
                            </div>
                            <div className="py-3">
                                <Link
                                    to="/trainer/profile"
                                    className="d-flex align-items-center px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <Image
                                        width={32}
                                        roundedCircle
                                        src="https://randomuser.me/api/portraits/women/75.jpg"
                                        className="mr-3"
                                    />
                                    <span className="text-16">Jerome Bell</span>
                                </Link>
                                <Link
                                    to="/trainer/profile"
                                    className="d-flex align-items-center px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <Image
                                        width={32}
                                        roundedCircle
                                        src="https://randomuser.me/api/portraits/women/17.jpg"
                                        className="mr-3"
                                    />
                                    <span className="text-16">Jane Cooper</span>
                                </Link>
                                <Link
                                    to="/trainer/profile"
                                    className="d-flex align-items-center px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <Image
                                        width={32}
                                        roundedCircle
                                        src="https://randomuser.me/api/portraits/men/32.jpg"
                                        className="mr-3"
                                    />
                                    <span className="text-16">
                                        Theresa Webb
                                    </span>
                                </Link>
                                <Link
                                    to="/trainer/profile"
                                    className="d-flex align-items-center px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <Image
                                        width={32}
                                        roundedCircle
                                        src="https://randomuser.me/api/portraits/women/75.jpg"
                                        className="mr-3"
                                    />
                                    <span className="text-16">
                                        Ronald Richards
                                    </span>
                                </Link>
                                <Link
                                    to="/trainer/profile"
                                    className="d-flex align-items-center px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red"
                                >
                                    <Image
                                        width={32}
                                        roundedCircle
                                        src="https://randomuser.me/api/portraits/women/17.jpg"
                                        className="mr-3"
                                    />
                                    <span className="text-16">Robert Fox</span>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={12} className="shop-search-main">
                        <div className="d-flex flex-wrap mb-3">
                            <div className="d-flex align-items-center mr-3 mb-3">
                                <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-0 mr-3">
                                    All Videos
                                </h2>
                                <p className="text-inter text-500 text-14 text-gray mb-0">
                                    Found{' '}
                                    <span className="text-dark-blue text-700">
                                        25
                                    </span>{' '}
                                    Results
                                </p>
                            </div>
                            <div className="d-flex mb-3 flex-grow-1 justify-content-end">
                                <div className="d-flex align-items-center">
                                    <span className="mr-3 text-inter text-500 text-14 text-gray text-nowrap">
                                        Sort By
                                    </span>
                                    <DropdownButton
                                        menuAlign="right"
                                        title="Price"
                                        variant="outline-secondary"
                                        size="sm"
                                        className="template-filter-dropdown mr-4"
                                    >
                                        <Dropdown.Item eventKey="1">
                                            Price
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="2">
                                            Popularity
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="3">
                                            Recent
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                                <div
                                    style={{ maxWidth: 320 }}
                                    className="w-100"
                                >
                                    <AppInput
                                        placeholder="Search videos"
                                        className="w-100 mb-0"
                                        fill
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="py-3 mb-3">
                            <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3">
                                Crossfit challenges
                            </h2>
                            <Slider {...settings} className="video-list-slider">
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                            </Slider>
                        </div>
                        <div className="py-3 mb-3">
                            <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3">
                                Crossfit challenges
                            </h2>
                            <Slider {...settings} className="video-list-slider">
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                            </Slider>
                        </div>
                        <div className="py-3 mb-5">
                            <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3">
                                VIDEOS BY TRAINERS
                            </h2>
                            <div className="overflow-hidden">
                                <div className="v-trainers-container">
                                    <Link
                                        to="/trainer/profile"
                                        className="mr-3"
                                    >
                                        <Image
                                            width={72}
                                            roundedCircle
                                            src="https://randomuser.me/api/portraits/women/75.jpg"
                                        />
                                    </Link>
                                    <Link
                                        to="/trainer/profile"
                                        className="mr-3"
                                    >
                                        <Image
                                            width={72}
                                            roundedCircle
                                            src="https://randomuser.me/api/portraits/women/17.jpg"
                                        />
                                    </Link>
                                    <Link
                                        to="/trainer/profile"
                                        className="mr-3"
                                    >
                                        <Image
                                            width={72}
                                            roundedCircle
                                            src="https://randomuser.me/api/portraits/men/32.jpg"
                                        />
                                    </Link>
                                    <Link
                                        to="/trainer/profile"
                                        className="mr-3"
                                    >
                                        <Image
                                            width={72}
                                            roundedCircle
                                            src="https://randomuser.me/api/portraits/women/75.jpg"
                                        />
                                    </Link>
                                    <Link
                                        to="/trainer/profile"
                                        className="mr-3"
                                    >
                                        <Image
                                            width={72}
                                            roundedCircle
                                            src="https://randomuser.me/api/portraits/women/17.jpg"
                                        />
                                    </Link>
                                    <Link
                                        to="/trainer/profile"
                                        className="mr-3"
                                    >
                                        <Image
                                            width={72}
                                            roundedCircle
                                            src="https://randomuser.me/api/portraits/men/32.jpg"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 mb-3">
                            <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3">
                                Crossfit challenges
                            </h2>
                            <Slider {...settings} className="video-list-slider">
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                                <AppVideoCard
                                    url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    title="Daily crossfit challenge Day1"
                                    img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                                />
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </div>

            <GetOffers offerType="all" />

            <GetNotified />
        </MainLayout>
    );
};

export default VideoList;
