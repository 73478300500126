import { Container, Nav, Navbar, NavItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FiMenu, FiUser } from 'react-icons/fi';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import Logo from '../../assets/images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../actions/uiActions';
import { StateType } from '../../reducers';

interface IProps {
    hideNavBorder?: boolean;
    navBackgroundColor?: string;
}

const NavBar = (props: IProps) => {
    const auth = useSelector((state: StateType) => state.auth);
    const { carts } = useSelector((state: StateType) => state.cart.carts);

    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(toggleDrawer(true));
    };

    // const [showLoginModal, setShowLoginModal] = useState(false);

    // const handleCloseLoginModal = () => setShowLoginModal(false);
    // const handleShowLoginModal = () => setShowLoginModal(true);

    return (
        <>
            <Navbar
                expand="lg"
                className="py-0 nav-container"
                style={{ backgroundColor: props.navBackgroundColor }}
            >
                <Container
                    fluid
                    className={
                        'py-3 px-0 ml-lg-3' +
                        (!props.hideNavBorder ? ' nav-container-border' : '')
                    }
                >
                    <Navbar.Brand>
                        <NavLink to="/">
                            <img
                                src={Logo}
                                height="44"
                                className="d-inline-block align-top"
                                alt="OxStrength logo"
                            />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Collapse>
                        <Nav className="text-uppercase app-nav-links mx-auto">
                            <NavItem className="px-4">
                                <NavLink
                                    exact
                                    to="/about"
                                    activeClassName="text-red"
                                >
                                    About
                                </NavLink>
                            </NavItem>
                            <NavItem className="px-4">
                                <NavLink
                                    to="/template"
                                    activeClassName="text-red"
                                >
                                    Templates
                                </NavLink>
                            </NavItem>
                            {/* <NavItem className="px-4">
                                <NavLink
                                    to="/videos"
                                    activeClassName="text-red"
                                >
                                    Videos
                                </NavLink>
                            </NavItem> */}
                            <NavItem className="px-4">
                                <NavLink to="/forum" activeClassName="text-red">
                                    Discussions
                                </NavLink>
                            </NavItem>
                            <NavItem className="px-4">
                                <NavLink
                                    to="/coaching"
                                    activeClassName="text-red"
                                >
                                    Coaching
                                </NavLink>
                            </NavItem>
                            <NavItem className="px-4">
                                <NavLink to="/shop" activeClassName="text-red">
                                    Ox Shop
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                    <NavItem className="d-flex align-items-center">
                        {auth?.authenticated ? (
                            <>
                                <NavLink
                                    to="/cart"
                                    className="d-flex align-items-center px-2"
                                >
                                    <span className="d-none d-sm-inline">
                                        MY CART
                                    </span>{' '}
                                    <div className="ml-2 position-relative cart-icon-container">
                                        <AiOutlineShoppingCart size={24} />
                                        <div className="position-absolute cart-counter">
                                            <span className="text-oswald text-500 text-12 text-white">
                                                {carts.length}
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="/dashboard"
                                    className="d-flex align-items-center px-2 ml-sm-3"
                                >
                                    <span className="d-none d-sm-inline text-uppercase">
                                        {auth.authUser?.name}
                                    </span>{' '}
                                    <div className="nav-avatar ml-2">
                                        {auth.authUser?.profile_pic ? (
                                            <img
                                                src={auth.authUser.profile_pic}
                                                width={24}
                                                height={24}
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: '50%'
                                                }}
                                                alt={auth.authUser?.slug}
                                            />
                                        ) : (
                                            <FiUser size={24} />
                                        )}
                                    </div>
                                </NavLink>
                            </>
                        ) : (
                            <NavLink to="/login" activeClassName="text-red">
                                LOGIN
                            </NavLink>
                        )}

                        {/* <div
                            className="px-2 ml-sm-3 text-hover-red cursor-pointer"
                            onClick={handleShowLoginModal}
                        >
                            <span className="text-oswald text-14">LOGIN</span>
                        </div> */}
                        <div
                            className="d-flex align-items-center px-2 ml-3 cursor-pointer"
                            onClick={handleToggle}
                        >
                            <span className="text-oswald text-14 text-dark-blue mr-2">
                                MENU
                            </span>

                            <FiMenu size={24} className="text-gray" />
                        </div>
                    </NavItem>
                </Container>
            </Navbar>
            {/* <Modal
                show={showLoginModal}
                onHide={handleCloseLoginModal}
                className="login-modal"
            >
                <Modal.Body>
                    <LoginModal />
                </Modal.Body>
            </Modal> */}
        </>
    );
};

export default NavBar;
