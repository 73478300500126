import { useEffect, useState } from 'react';
import { Dropdown, Row, Col, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getFeaturedTemplates } from '../../../actions/templateActions';
import { getUserOrders } from '../../../actions/userActions';
import { DeliveryStatusType } from '../../../interfaces/User';
import { StateType } from '../../../reducers';
import CustomToggle from '../../components/CustomToggle';
import DashboardLayout from '../../layouts/DashboardLayout';
import SuggestedPackages from './SuggestedPackages';
import OrderTable from './OrderTable';

interface IProps {}

const UserOrders = (props: IProps) => {
    const dispatch = useDispatch();

    const [selectedStatus, setSelectedStatus] = useState<DeliveryStatusType>();

    const [activePage, setActivePage] = useState(1);

    const resultsPerPage = '5';

    const { orders } = useSelector((state: StateType) => state.user);

    const deliveryStatus: DeliveryStatusType[] = [
        'active',
        'pending',
        'delivered',
        'cancelled'
    ];

    useEffect(() => {
        dispatch(
            getUserOrders({
                status: selectedStatus,
                sort: 'latest',
                page: activePage.toString(),
                resultsPerPage
            })
        );
    }, [dispatch, selectedStatus, activePage]);

    useEffect(() => {
        dispatch(getFeaturedTemplates({}));
    });

    const handleFilterChange = (status?: string | null) => {
        if (status) {
            setSelectedStatus(
                status === 'all' ? undefined : (status as DeliveryStatusType)
            );
            setActivePage(1);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setActivePage(pageNumber);
    };

    return (
        <DashboardLayout>
            <div className="t-search-container">
                <Row>
                    <Col className="mb-5" lg={9}>
                        <div className="d-flex flex-wrap align-items-center">
                            <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3 mr-3 flex-grow-1">
                                MY ORDERS
                            </h2>

                            <Dropdown
                                className="mb-3"
                                onSelect={(e) => handleFilterChange(e)}
                            >
                                <Dropdown.Toggle as={CustomToggle}>
                                    FILTER
                                </Dropdown.Toggle>
                                <Dropdown.Menu alignRight>
                                    <Dropdown.Item
                                        eventKey="all"
                                        active={!selectedStatus}
                                    >
                                        All
                                    </Dropdown.Item>

                                    {deliveryStatus.map((status, index) => (
                                        <Dropdown.Item
                                            className="text-capitalize"
                                            eventKey={status}
                                            key={index}
                                            active={status === selectedStatus}
                                        >
                                            {status}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div>
                            <OrderTable />

                            <div className="d-flex justify-content-end">
                                <Pagination className="mt-3">
                                    {[
                                        ...Array(
                                            orders?.orders?.length
                                                ? orders?.totalPage
                                                : 0
                                        )
                                    ].map((_, index) => {
                                        return (
                                            <Pagination.Item
                                                onClick={() =>
                                                    handlePageChange(index + 1)
                                                }
                                                key={index + 1}
                                                active={
                                                    index + 1 === activePage
                                                }
                                            >
                                                {index + 1}
                                            </Pagination.Item>
                                        );
                                    })}
                                </Pagination>
                            </div>
                        </div>
                    </Col>
                    <Col className="mb-5 mt-lg-5" lg={3}>
                        <SuggestedPackages />
                    </Col>
                </Row>
            </div>
        </DashboardLayout>
    );
};

export default UserOrders;
