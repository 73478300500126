import { Row, Col, Form } from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import AppButton from '../../components/AppButton';
import ContactDetail from './ContactDetail';
import AppInput from '../../components/AppInput';
import { useDispatch, useSelector } from 'react-redux';
import { saveContact } from '../../../actions/contactActions';
import { ISaveContactBody } from '../../../interfaces/Contact';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { StateType } from '../../../reducers';

interface IProps {}

const Contact = (props: IProps) => {
    const dispatch = useDispatch();
    const { saveContactLoading, saveContactSuccess } = useSelector(
        (state: StateType) => state.contact
    );

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Contact Us',
            link: '/contact',
            active: true
        }
    ];

    const initialValues: ISaveContactBody = {
        name: '',
        message: '',
        subject: '',
        email: ''
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required')
    });

    return (
        <MainLayout>
            <div className="container-xxl container-xl checkout-container">
                <AppBreadcrumbs items={breadcrumbItems} />
                <div className="mb-3 pt-3">
                    <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                        CONTACT US
                    </h2>
                    <p className="text-gray text-14 mb-0">
                        Get in touch with us
                    </p>
                </div>
                <Row className="mb-5">
                    <Col className="mb-5">
                        <div className="payment-container px-4 pt-4">
                            <div className="pb-5">
                                <h2 className="text-18 text-600 text-dark-blue text-uppercase mb-4">
                                    Get in touch with us
                                </h2>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    enableReinitialize={saveContactSuccess}
                                    onSubmit={(values, { resetForm }) => {
                                        dispatch(
                                            saveContact(values, resetForm)
                                        );
                                    }}
                                    isInitialValid={false}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        isValid,
                                        errors,
                                        resetForm
                                    }) => {
                                        return (
                                            <Form onSubmit={handleSubmit}>
                                                <AppInput
                                                    type="text"
                                                    label="Full Name"
                                                    name="name"
                                                    required
                                                    placeholder="Your Name"
                                                    fill
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    isInvalid={!!errors.name}
                                                    error={errors.name}
                                                />
                                                <AppInput
                                                    type="email"
                                                    label="Email"
                                                    name="email"
                                                    required
                                                    placeholder="Email address"
                                                    fill
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    isInvalid={!!errors.email}
                                                    error={errors.email}
                                                />
                                                <AppInput
                                                    type="text"
                                                    label="Reason for contact?"
                                                    name="subject"
                                                    placeholder="Reasons for contact"
                                                    required
                                                    fill
                                                    onChange={handleChange}
                                                    value={values.subject}
                                                    isInvalid={!!errors.subject}
                                                    error={errors.subject}
                                                />
                                                <AppInput
                                                    type="text"
                                                    as="textarea"
                                                    label="Message"
                                                    name="message"
                                                    required
                                                    placeholder="Your message"
                                                    fill
                                                    rows={3}
                                                    className="mb-4"
                                                    onChange={handleChange}
                                                    value={values.message}
                                                    isInvalid={!!errors.message}
                                                    error={errors.message}
                                                />
                                                <AppButton
                                                    variant="primary w-100"
                                                    loading={saveContactLoading}
                                                    disabled={
                                                        saveContactLoading ||
                                                        !isValid
                                                    }
                                                    type="submit"
                                                >
                                                    SEND MESSAGE
                                                </AppButton>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} className="mb-5">
                        <ContactDetail />
                    </Col>
                </Row>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default Contact;
