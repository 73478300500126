import TrainerProfileLayout from '../../layouts/TrainerProfileLayout';
import { Photo } from '../../../assets/images/icons';

const TrainerVideos = () => {
    // var settings = {
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     nextArrow: <ArrowNext fill="#ed2b30" />,
    //     responsive: [
    //         {
    //             breakpoint: 1200,
    //             settings: {
    //                 slidesToShow: 3
    //             }
    //         },
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 slidesToShow: 2
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1
    //             }
    //         },
    //         {
    //             breakpoint: 568,
    //             settings: {
    //                 slidesToShow: 2
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1
    //             }
    //         },
    //         {
    //             breakpoint: 320,
    //             settings: {
    //                 slidesToShow: 1
    //             }
    //         }
    //     ]
    // };

    return (
        <TrainerProfileLayout>
            {/* <div className="py-3 mb-3">
                <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3">
                    Crossfit challenges
                </h2>
                <Slider {...settings} className="video-list-slider">
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                </Slider>
            </div>
            <div className="py-3 mb-3">
                <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3">
                    Crossfit challenges
                </h2>
                <Slider {...settings} className="video-list-slider">
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                    <AppVideoCard
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        title="Daily crossfit challenge Day1"
                        img="https://static.toiimg.com/thumb/msid-78118340,imgsize-896783,width-800,height-600,resizemode-75/78118340.jpg"
                    />
                </Slider>
            </div> */}

            <div className="py-5 bg-light-gray-1">
                <div className="d-flex align-items-center flex-column py-5">
                    <Photo />
                    <p className="text-inter text-gray-2">
                        No Videos found from trainer. Please check back again.
                    </p>
                </div>
            </div>
        </TrainerProfileLayout>
    );
};

export default TrainerVideos;
