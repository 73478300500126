import Skeleton from 'react-loading-skeleton';

interface IAppTemplateCardSkeleton {
    className?: string;
    squareImage?: boolean;
}

const AppTemplateCardSkeleton = ({
    className,
    squareImage
}: IAppTemplateCardSkeleton) => {
    return (
        <div
            className={
                't-skeleton-container py-3 ' +
                (squareImage
                    ? 't-skeleton-img-square '
                    : 't-skeleton-img-rect ') +
                (className ? className : '')
            }
        >
            <div className="t-skeleton-img-wrapper">
                <div className="t-skeleton-img">
                    <Skeleton height="100%" />
                </div>
                <div className="t-skeleton-logo">
                    <Skeleton height={40} />
                </div>

                <div className="t-skeleton-img-desc">
                    <p className={`mb-1`}>
                        <Skeleton />
                    </p>

                    <p>
                        <Skeleton />
                    </p>
                </div>
            </div>

            <div className="px-1">
                <p className={`mb-1`}>
                    <Skeleton />
                </p>
                <p>
                    <Skeleton />
                </p>
            </div>
        </div>
    );
};

interface IAppProductCardSkeleton {
    className?: string;
}

const AppProductCardSkeleton = ({ className }: IAppProductCardSkeleton) => {
    return (
        <div className={'p-skeleton-container ' + (className ? className : '')}>
            <div className="p-skeleton-img-wrapper">
                <div className="p-skeleton-img">
                    <Skeleton height="100%" />
                </div>
            </div>
            <div className="p-3">
                <p className={`mb-1`}>
                    <Skeleton />
                </p>
                <p className={`mb-0`}>
                    <Skeleton />
                </p>
            </div>
        </div>
    );
};

const AppForumCardSkeleton = () => {
    return (
        <div className="f-detail-main-container p-3">
            <div className="d-flex flex-grow-1 mb-3">
                <Skeleton
                    width={40}
                    height={40}
                    style={{ borderRadius: 20 }}
                    className="mr-2"
                />

                <div>
                    <p className="text-600 text-14 mb-0 text-dark-blue">
                        <Skeleton width={100} />
                    </p>
                    <p className="text-gray text-14 mb-0">
                        <Skeleton width={120} />
                    </p>
                </div>
            </div>

            <div>
                <p className={`mb-1`}>
                    <Skeleton />
                </p>
                <p className={`mb-0`}>
                    <Skeleton />
                </p>
            </div>
        </div>
    );
};

export {
    AppTemplateCardSkeleton,
    AppProductCardSkeleton,
    AppForumCardSkeleton
};
