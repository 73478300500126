import {
    GET_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCT_REVIEWS,
    RESET_PRODUCT_STATE,
    GET_SIMILAR_PRODUCTS,
    RESET_PRODUCT_REVIEWS,
    GET_APPARELS,
    GET_GYM_GEARS,
    GET_SUPPLIMENTS,
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IProduct, Product } from '../interfaces/Product';
import { ITemplateReview } from '../interfaces/Template';

export interface IProductState {
    product: Product;
    productLoading: boolean;
    products: IProduct;
    productsLoading: boolean;
    reviews: ITemplateReview;
    reviewsLoading: boolean;
    similarProducts: IProduct;
    apparels: IProduct;
    suppliments: IProduct;
    gymGears: IProduct;
}

const initialState: IProductState = {
    product: {},
    productLoading: false,
    products: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        products: [],
    },
    productsLoading: false,
    reviews: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        reviews: [],
    },
    reviewsLoading: false,
    similarProducts: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        products: [],
    },
    apparels: { currentPage: 1, totalPage: 1, totalDocuments: 0, products: [] },
    suppliments: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        products: [],
    },
    gymGears: { currentPage: 1, totalPage: 1, totalDocuments: 0, products: [] },
};

const productReducer = (
    state = initialState,
    { payload, type }: ActionType
) => {
    switch (type) {
        case GET_PRODUCTS:
            return {
                ...state,
                productsLoading: payload?.productsLoading,
                products: {
                    ...state.products,
                    ...payload.products,
                    products: [
                        ...state.products.products,
                        ...(payload?.products?.products ?? []),
                    ],
                },
            };
        case GET_PRODUCT:
            return {
                ...state,
                ...payload,
            };
        case GET_PRODUCT_REVIEWS:
            return {
                ...state,
                reviewsLoading: payload?.reviewsLoading,
                reviews: {
                    ...state.reviews,
                    ...payload.reviews,
                    reviews: [
                        ...state.reviews.reviews,
                        ...(payload?.reviews?.reviews ?? []),
                    ],
                },
            };

        case GET_SIMILAR_PRODUCTS:
            return {
                ...state,
                similarProducts: payload,
            };

        case GET_APPARELS:
            return {
                ...state,
                apparels: payload,
            };

        case GET_GYM_GEARS:
            return {
                ...state,

                gymGears: payload,
            };

        case GET_SUPPLIMENTS:
            return {
                ...state,

                suppliments: payload,
            };

        case RESET_PRODUCT_STATE:
            return initialState;

        case RESET_PRODUCT_REVIEWS:
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    reviews: [],
                },
            };

        default:
            return state;
    }
};

export default productReducer;
