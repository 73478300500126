import { NotFound } from '../../assets/images/icons';
import { ModelType } from '../../interfaces/Cart';

interface IProps {
    type: ModelType;
}

const AppNotFound = ({ type }: IProps) => {
    return (
        <div className={`bg-light-gray-1 py-5`}>
            <div
                className={`d-flex flex-column align-items-center px-2 py-5 mx-auto text-center`}
                style={{ maxWidth: '32rem' }}
            >
                <NotFound className="mb-3" />
                <h2 className="mb-2 text-inter text-20 text-dark-blue">
                    {type} not available
                </h2>
                <p className="text-inter text-16 text-gray-2">
                    Currently we can not find the right {type.toLowerCase()} you
                    are looking for. Please change the filter or searh query
                </p>
            </div>
        </div>
    );
};

export default AppNotFound;
