import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import TruncateMarkup from 'react-truncate-markup';
import { defaultImage } from '../../../helpers/constants';
import { IForum } from '../../../interfaces/Forum';
import { StateType } from '../../../reducers';

export interface IProps {
    forums: IForum[];
}

const FeaturedTopics = (props: IProps) => {
    const { authenticated, authUser } = useSelector(
        (state: StateType) => state.auth
    );

    const { user_type } = useSelector((state: StateType) => state.user.user);

    const history = useHistory();

    const location = useLocation();

    const goToLogin = () => {
        history.push('/login', {
            from: location
        });
    };

    return (
        <div className="feat-topic-container py-2">
            <h2 className="text-700 text-24 text-dark-blue mb-3">
                FEATURED TOPICS
            </h2>
            <Row xs={2} sm={4} noGutters className="mx-n2">
                {props.forums.map((forum, index) => (
                    <div
                        onClick={() => {
                            authenticated
                                ? user_type !== 'Client' ||
                                  authUser.is_active_member === true
                                    ? history.push(`/forum/${forum._id}`)
                                    : toast.error(
                                          'You need to be an active user. Please purchase an OxStrength package to view this discussion.',
                                          {
                                              position:
                                                  toast.POSITION.TOP_CENTER
                                          }
                                      )
                                : goToLogin();
                        }}
                        className="px-2 cursor-pointer"
                        key={index}
                    >
                        <div
                            className="feat-topic-wrapper"
                            style={{
                                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 100)), url(${
                                    !!forum.cover_image
                                        ? forum.cover_image
                                        : defaultImage
                                })`
                            }}
                        >
                            <div className="feat-topic-desc p-2">
                                <TruncateMarkup lines={2}>
                                    <p className="text-white mb-0">
                                        {forum.topic}
                                    </p>
                                </TruncateMarkup>
                                <span className="text-14 text-neutrif text-gray">
                                    Posted by: {forum.author?.name}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </Row>
        </div>
    );
};

export default FeaturedTopics;
