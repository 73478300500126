import { Row, Col } from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppButton from '../../components/AppButton';
import { Link, Redirect } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import GetOffers from '../templateSearch/GetOffers';
import { Form } from 'react-bootstrap';
import AppInput from '../../components/AppInput';
import SocialButton from '../../components/SocialButton';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    facebookLogin,
    googleLogin,
    register,
    resetAuthState
} from '../../../actions/authActions';
import { StateType } from '../../../reducers';
import { ISignUp } from '../../../interfaces/User';
import { useEffect } from 'react';
import {
    $FIXME,
    FACEBOOK_APPID,
    GOOGLE_CLIENTID,
    phoneRegExp
} from '../../../helpers/constants';
import GoogleLogin from 'react-google-login';
import FacebookLogin, {
    ReactFacebookLoginInfo
} from 'react-facebook-login/dist/facebook-login-render-props';
import { setErrors } from '../../../actions/errorActions';
import { getAllOffers } from '../../../actions/offerActions';

interface IProps {}

const Register = (props: IProps) => {
    const dispatch = useDispatch();

    const auth = useSelector((state: StateType) => state.auth);

    useEffect(() => {
        dispatch(resetAuthState());
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Search',
            link: '/template'
        },
        {
            label: 'Authentication',
            link: '/login',
            active: true
        }
    ];

    const initialValues: ISignUp = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        mobile_num: ''
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters long'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        mobile_num: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
        )
    });

    const responseFacebook = (response: ReactFacebookLoginInfo) => {
        dispatch(facebookLogin(response.accessToken));
    };

    if (auth.authenticated) {
        return <Redirect to="/" />;
    } else if (auth.redirectLogin) {
        return <Redirect to="/register/verify-account" />;
    }

    return (
        <MainLayout>
            <div className="container-xxl container-xl login-container">
                <AppBreadcrumbs items={breadcrumbItems} />
                <div className="mb-5 pt-3">
                    <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                        Register
                    </h2>
                    <p className="text-gray text-14 mb-0">
                        Search and find the best templates in town
                    </p>
                </div>
                <Row>
                    <Col md={{ span: 6, order: 1 }} className="mb-5">
                        <div className="login-form auth-wrapper-bg p-4">
                            <h2 className="text-18 text-600 text-uppercase">
                                Register as a User
                            </h2>
                            <p className="text-14 text-dark-3">
                                Create an account and start getting services
                            </p>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values) => {
                                    dispatch(register(values));
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    isValid,
                                    errors
                                }) => {
                                    return (
                                        <Form
                                            className="d-flex flex-column"
                                            noValidate
                                            onSubmit={handleSubmit}
                                        >
                                            <AppInput
                                                type="text"
                                                label="Full name"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                                error={errors.name}
                                                required
                                                placeholder="Full Name"
                                                fill
                                            />
                                            <AppInput
                                                type="email"
                                                label="Email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                error={errors.email}
                                                required
                                                placeholder="Email Address"
                                                fill
                                            />
                                            <AppInput
                                                type="password"
                                                label="Password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                isInvalid={!!errors.password}
                                                error={errors.password}
                                                required
                                                placeholder="Password here"
                                                fill
                                            />
                                            <AppInput
                                                type="password"
                                                label="Confirm Password"
                                                name="password_confirmation"
                                                value={
                                                    values.password_confirmation
                                                }
                                                onChange={handleChange}
                                                isInvalid={
                                                    !!errors.password_confirmation
                                                }
                                                error={
                                                    errors.password_confirmation
                                                }
                                                required
                                                placeholder="Password here"
                                                fill
                                            />
                                            <AppInput
                                                type="text"
                                                label="Phone Number"
                                                name="mobile_num"
                                                value={values.mobile_num}
                                                onChange={handleChange}
                                                isInvalid={!!errors.mobile_num}
                                                error={errors.mobile_num}
                                                required
                                                placeholder="Phone Number"
                                                fill
                                            />

                                            {auth.registerError ? (
                                                <span className="text-12 text-red mb-3">
                                                    {auth.registerError}
                                                </span>
                                            ) : (
                                                ''
                                            )}

                                            <Link
                                                to="/forgot-password"
                                                className="mb-4 text-inter text-14"
                                            >
                                                Forgot Your Password?
                                            </Link>
                                            <AppButton
                                                variant="primary"
                                                type="submit"
                                                disabled={
                                                    auth.registerLoading ||
                                                    !isValid
                                                }
                                            >
                                                Sign Up
                                            </AppButton>
                                        </Form>
                                    );
                                }}
                            </Formik>

                            <div className="d-flex align-items-center py-4">
                                <hr className="flex-grow-1" />
                                <span className="px-3 text-14">
                                    Or Sign in With
                                </span>
                                <hr className="flex-grow-1" />
                            </div>
                            <Row
                                className="social-btn-container mx-n2"
                                noGutters
                            >
                                <Col lg={6} className="mb-3 px-2">
                                    <GoogleLogin
                                        clientId={GOOGLE_CLIENTID}
                                        render={(renderProps) => (
                                            <SocialButton
                                                variant="Google"
                                                className="w-100"
                                                onClick={renderProps.onClick}
                                            />
                                        )}
                                        onSuccess={(response: $FIXME) => {
                                            dispatch(
                                                googleLogin(response.tokenId)
                                            );
                                        }}
                                        onFailure={(response) => {
                                            dispatch(
                                                setErrors(
                                                    response ?? 'Login Failed'
                                                )
                                            );
                                        }}
                                    />
                                </Col>
                                <Col lg={6} className="mb-3 px-2">
                                    <FacebookLogin
                                        appId={FACEBOOK_APPID}
                                        callback={responseFacebook}
                                        render={(renderProps) => {
                                            return (
                                                <SocialButton
                                                    variant="Facebook"
                                                    className="w-100"
                                                    onClick={() =>
                                                        renderProps.onClick()
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="mb-5">
                        <div className="auth-wrapper-bg p-4">
                            <h2 className="text-18 text-600 text-uppercase">
                                Registered Customers
                            </h2>
                            <p className="text-14 text-dark-3">
                                If you have an account, sign in with your email
                                address.
                            </p>
                            <p className="text-14 text-dark-3">
                                Creating an account has many benefits:
                            </p>
                            <ul className="mb-4 text-14 text-dark-3 pl-4">
                                <li>Check out faster</li>
                                <li>Buy more than one templates</li>
                                <li>Track orders and your progress as well</li>
                            </ul>
                            <Link to="/login">
                                <AppButton variant="secondary" className="mb-5">
                                    SIGN IN AS A USER
                                </AppButton>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
            <GetOffers offerType="all" />
            <GetNotified />
        </MainLayout>
    );
};

export default Register;
