import { MouseEventHandler } from 'react';
import { CSSProperties, FunctionComponent } from 'react';
import Logo from '../../assets/images/logo-invert.png';

type CardVariant = 'dark' | 'light';
type CardSize = 'small' | 'large';

interface IProps {
    img: string;
    title: string;
    subtitle: string | JSX.Element;
    variant?: CardVariant;
    size?: CardSize;
    className?: string;
    imgWrapperStyle?: CSSProperties;
    squareImage?: boolean;
    hideImageText?: boolean;
    hideText?: boolean;
    imgTitle?: string;
    imgSubtitle?: string;
    hoverOverlay?: React.ReactElement;
    onClick?: MouseEventHandler<HTMLDivElement>;
    ribbonText?: string;
}

const AppTemplateCard: FunctionComponent<IProps> = (props) => {
    return (
        <div
            className={
                't-card-container my-3 position-relative ' +
                (props.squareImage
                    ? 't-card-img-square '
                    : 't-card-img-rect ') +
                props.className
            }
            onClick={props.onClick}
        >
            {props.ribbonText ? (
                <div className="position-absolute ribbon-container">
                    <div className="ribbon">
                        <span className="text-white text-12 text-500 text-uppercase">
                            {props.ribbonText}
                        </span>
                    </div>
                </div>
            ) : (
                ''
            )}

            <div className="t-card-img-wrapper" style={props.imgWrapperStyle}>
                <img src={props.img} className="t-card-img" alt={props.title} />
                <img src={Logo} className="t-card-logo" alt={props.title} />
                {!props.hideImageText ? (
                    <div className="text-uppercase text-light-gray t-card-img-desc">
                        {props.imgTitle ? (
                            <p
                                className={`text-inter text-500 mb-1 ${
                                    props.size === 'large'
                                        ? 'text-14'
                                        : 'text-12'
                                }`}
                            >
                                {props.imgTitle}
                            </p>
                        ) : (
                            ''
                        )}

                        {props.imgSubtitle ? (
                            <p
                                className={`text-oswald text-700 ${
                                    props.size === 'large'
                                        ? 'text-20'
                                        : 'text-16'
                                }`}
                            >
                                {props.imgSubtitle}
                            </p>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                )}
                {props.hoverOverlay ? (
                    <div className="position-absolute template-hover-overlay">
                        {props.hoverOverlay}
                    </div>
                ) : (
                    ''
                )}
            </div>
            {!props.hideText ? (
                <div className="px-1">
                    <p
                        className={`text-uppercase text-oswald text-700 mb-1 text-hover-red ${
                            props.variant === 'dark'
                                ? 'text-light-gray'
                                : 'text-dark-blue'
                        } ${props.size === 'large' ? 'text-24' : 'text-20'}`}
                    >
                        <span>{props.title}</span>
                    </p>
                    <p
                        className={`text-inter text-500 ${
                            props.variant === 'dark'
                                ? 'text-light-gray'
                                : 'text-gray'
                        } ${props.size === 'large' ? 'text-14' : 'text-14'}`}
                    >
                        {props.subtitle}
                    </p>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

AppTemplateCard.defaultProps = {
    variant: 'light',
    size: 'small',
    className: ''
};

export default AppTemplateCard;
