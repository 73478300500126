import dayjs from 'dayjs';
import { Spinner, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { EmptyCart } from '../../../assets/images/icons';
import { StateType } from '../../../reducers';
import { formatNumber } from '../../../utils/functions';
import AppButton from '../../components/AppButton';
import StatusChip from '../../components/StatusChip';

const OrderTable = () => {
    const { orders, ordersLoading } = useSelector(
        (state: StateType) => state.user
    );

    const history = useHistory();

    return (
        <>
            <Table responsive borderless className="order-table">
                <thead>
                    <tr>
                        <th className="text-neutrif text-600 text-14 text-dark-2">
                            Date
                        </th>

                        <th className="text-neutrif text-600 text-14 text-dark-2">
                            Products
                        </th>

                        <th className="text-neutrif text-600 text-14 text-dark-2">
                            Sub Total
                        </th>

                        <th className="text-neutrif text-600 text-14 text-dark-2">
                            Delivery Address
                        </th>

                        <th className="text-neutrif text-600 text-14 text-dark-2">
                            Total Amount
                        </th>

                        <th className="text-neutrif text-600 text-14 text-dark-2">
                            Status
                        </th>

                        <th className="text-neutrif text-600 text-14 text-dark-2">
                            Remarks
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {orders?.orders.length ? (
                        orders?.orders.map((order, index) => (
                            <tr
                                className="cursor-pointer order-row"
                                key={index}
                                onClick={() => {
                                    history.push(
                                        `/dashboard/orders/${order._id}`
                                    );
                                }}
                            >
                                <td style={{ minWidth: 110 }}>
                                    <p className="text-500 text-14 text-dark-2 mb-0">
                                        {dayjs(order.createdAt).format(
                                            'YYYY-MM-DD'
                                        )}
                                    </p>
                                </td>

                                <td>
                                    <p className="text-500 text-14 text-dark-2 mb-0">
                                        {order.carts?.length}
                                    </p>
                                </td>

                                <td style={{ minWidth: 120 }}>
                                    <p className="text-600 text-dark-2 mb-1">
                                        Rs. {formatNumber(order?.sub_total)}
                                    </p>
                                </td>

                                <td style={{ minWidth: 180 }}>
                                    {order?.delivery_address ? (
                                        <div>
                                            <p className="text-700 text-14 mb-0 text-dark-blue">
                                                {order?.delivery_address?.name}
                                            </p>
                                            <p className="text-500 text-14 mb-0 text-dark-blue">
                                                {order?.delivery_address?.email}
                                            </p>
                                            <p className="text-14 text-gray mb-0">
                                                {`${order.delivery_address?.label} - ${order.delivery_address?.address?.address_line1}, ${order.delivery_address?.address?.area}, ${order.delivery_address?.address?.district}, ${order.delivery_address?.address?.province}, ${order.delivery_address?.address?.country}`}
                                            </p>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className="text-400 text-14 mb-0 text-dark-blue">
                                                No address
                                            </p>
                                        </div>
                                    )}
                                </td>

                                <td style={{ minWidth: 120 }}>
                                    <p className="text-600 text-dark-2 mb-1">
                                        Rs. {formatNumber(order?.total_amount)}
                                    </p>
                                </td>

                                <td>
                                    <div
                                        style={{
                                            width: 90
                                        }}
                                    >
                                        <StatusChip
                                            label={order?.status ?? ''}
                                            type={
                                                order?.status === 'Active' ||
                                                order?.status === 'delivered'
                                                    ? 'success'
                                                    : order?.status ===
                                                          'Initiated' ||
                                                      order?.status ===
                                                          'Pending'
                                                    ? 'warning'
                                                    : 'danger'
                                            }
                                        />
                                    </div>
                                </td>

                                <td style={{ minWidth: 200 }}>
                                    <p className="text-500 text-14 text-dark-2 mb-0">
                                        {order.remarks}
                                    </p>
                                </td>
                            </tr>
                        ))
                    ) : ordersLoading ? (
                        <tr>
                            <td colSpan={7}>
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: 200 }}
                                >
                                    <Spinner animation="border" />
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className="py-5">
                                    <div className="d-flex align-items-center flex-column py-5">
                                        <EmptyCart />
                                        <p className="text-inter text-gray-2">
                                            Empty Orders. Explore and find the
                                            best training templates and
                                            products.
                                        </p>
                                        <Link to="/">
                                            <AppButton variant="outline-secondary">
                                                Explore templates & products
                                            </AppButton>
                                        </Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

export default OrderTable;
