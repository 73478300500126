import AppProductCard from '../../components/AppProductCard';
import TextShadow from '../../components/TextShadow';
import { ReactComponent as ArrowPrev } from '../../../assets/images/icons/arrow-prev.svg';
import { ReactComponent as ArrowNext } from '../../../assets/images/icons/arrow-next.svg';
import Slider from 'react-slick';
import AppButton from '../../components/AppButton';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { formatNumber } from '../../../utils/functions';
import { IAddCartBody, Rate } from '../../../interfaces/Product';
import { addToCart } from '../../../actions/cartActions';
import { defaultImage } from '../../../helpers/constants';

interface IProps {}

const SimilarProductList = (props: IProps) => {
    const { authenticated } = useSelector((state: StateType) => state.auth);

    const products = useSelector(
        (state: StateType) => state.product.similarProducts
    );

    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();

    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <ArrowPrev />,
        nextArrow: <ArrowNext fill="#ed2b30" />,
        centerPadding: '20px',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    arrows: false
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            }
        ]
    };

    const handleAddToCart = (id: string, rate?: Rate) => {
        if (authenticated) {
            const body: IAddCartBody = {
                model_id: id,
                onModel: 'Product',
                unit: 1,
                self_order: true,
                product_size: rate?.size
            };

            dispatch(addToCart(body));
        } else {
            history.push('/login', {
                from: location
            });
        }
    };

    return products.products.length ? (
        <div className="px-md-5 position-relative py-5">
            <div className="container-xxl py-5 position-relative">
                <div className="pb-4 d-flex justify-content-space align-items-end">
                    <div className="flex-grow-1">
                        <TextShadow
                            shadowText="SIMILAR PRODUCTS"
                            alignment="left"
                            variant="light"
                        >
                            SIMILAR PRODUCTS
                        </TextShadow>
                    </div>
                    <Link to="/shop/search">
                        <AppButton variant="outline-secondary">
                            View all Products
                        </AppButton>
                    </Link>
                </div>

                <Slider {...settings} className="mx-n2">
                    {products.products.map((product, index) => {
                        let rate = !!product?.rates?.length
                            ? product.rates?.find(
                                  (rate) => (rate.stock ?? 0) > 0
                              ) ?? product.rates[0]
                            : undefined;

                        return (
                            <div className="p-2" key={index}>
                                <Link to={`/shop/product/${product.slug}`}>
                                    <AppProductCard
                                        img={
                                            product.images &&
                                            product.images.length > 0
                                                ? product.images[0]
                                                : defaultImage
                                        }
                                        title={product.title ?? 'N/A'}
                                        subtitle={
                                            product.discount?.active ? (
                                                <>
                                                    <s>
                                                        Rs.{' '}
                                                        {formatNumber(
                                                            rate?.price
                                                        )}
                                                    </s>
                                                    <br />
                                                    {'Rs. ' +
                                                        formatNumber(
                                                            rate?.discounted_price
                                                        )}
                                                </>
                                            ) : (
                                                'Rs. ' +
                                                formatNumber(
                                                    rate?.discounted_price
                                                )
                                            )
                                        }
                                        key={index}
                                        onAddCart={() =>
                                            product._id
                                                ? handleAddToCart(
                                                      product._id,
                                                      product.rates?.length
                                                          ? product.rates[0]
                                                          : undefined
                                                  )
                                                : undefined
                                        }
                                        outOfStock={
                                            !!product.rates?.length &&
                                            (product.rates[0].stock ?? 0) === 0
                                        }
                                        ribbonText={
                                            !!product.rates?.length &&
                                            (product.rates[0].stock ?? 0) === 0
                                                ? 'Out of stock'
                                                : product.discount?.active
                                                ? product.discount.title
                                                : undefined
                                        }
                                    />
                                </Link>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default SimilarProductList;
