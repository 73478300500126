import {
    GET_CONTACTINFO,
    SAVE_CONTACT_FAILURE,
    SAVE_CONTACT_REQUEST,
    SAVE_CONTACT_SUCCESS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IContactInfo } from '../interfaces/Contact';

export interface IContactState {
    contact: IContactInfo;
    saveContactLoading: boolean;
    saveContactSuccess: boolean;
}

const initialState: IContactState = {
    contact: {},
    saveContactLoading: false,
    saveContactSuccess: false
};

const contactReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_CONTACTINFO:
            return {
                ...state,
                contact: action.payload
            };

        case SAVE_CONTACT_REQUEST:
            return {
                ...state,
                saveContactLoading: true,
                saveContactSuccess: false
            };

        case SAVE_CONTACT_SUCCESS:
            return {
                ...state,
                saveContactLoading: false,
                saveContactSuccess: true
            };

        case SAVE_CONTACT_FAILURE:
            return {
                ...state,
                saveContactLoading: false,
                saveContactSuccess: false
            };

        default:
            return state;
    }
};

export default contactReducer;
