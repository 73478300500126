import {
    INITIATE_ORDER_FAILURE,
    INITIATE_ORDER_REQUEST,
    INITIATE_ORDER_SUCCESS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IOrderResponse } from '../interfaces/Payment';

export interface IPaymentState {
    order: IOrderResponse;
    initiateOrderLoading: boolean;
    initiateOrderSuccess: boolean;
}

const initialState: IPaymentState = {
    order: {},
    initiateOrderLoading: false,
    initiateOrderSuccess: false
};

const paymentReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case INITIATE_ORDER_REQUEST:
            return {
                ...state,
                initiateOrderLoading: true,
                initiateOrderSuccess: false
            };

        case INITIATE_ORDER_SUCCESS:
            return {
                ...state,
                initiateOrderLoading: false,
                initiateOrderSuccess: true,
                order: action.payload
            };

        case INITIATE_ORDER_FAILURE:
            return {
                ...state,
                initiateOrderLoading: false,
                initiateOrderSuccess: false
            };

        default:
            return state;
    }
};

export default paymentReducer;
