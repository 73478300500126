import { Dispatch } from 'redux';
import { TOGGLE_CONSULTAION_MODAL, TOGGLE_DRAWER } from '../helpers/constants';
import { IConsultaionModalState } from '../reducers/uiReducer';

export const toggleDrawer = (open: boolean) => {
    return { type: TOGGLE_DRAWER, payload: open };
};

export const toggleConsultationModal =
    (props: IConsultaionModalState) => async (dispatch: Dispatch) => {
        dispatch({ type: TOGGLE_CONSULTAION_MODAL, payload: props });
    };
