import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import addressReducer, { IAddressState } from './addressReducers';
import authReducer, { AuthState } from './authReducers';
import cartReducer, { ICartState } from './cartReducers';
import categoryReducer, { ICategoryState } from './categoryReducers';
import coachingReducer, { ICoachingState } from './coachingReducers';
import consultationReducer, {
    IConsultationState
} from './consultationReducers';
import contactReducer, { IContactState } from './contactReducers';
import dashboardReducer, { IDashboardState } from './dashboardReducers';
import deliveryAddressReducer, {
    IDeliveryAddressState
} from './deliveryAddressReducers';
import errorReducer, { IErrorState } from './errorReducer';
import forumReducer, { IForumState } from './forumReducers';
import giftReducer, { IGiftState } from './giftReducers';
import multimediaReducer, { IMultimediaState } from './multimediaReducers';
import newsfeedReducer, { INewsfeedState } from './newsfeedReducers';
import offerReducer, { IOfferState } from './offerReducers';
import paymentReducer, { IPaymentState } from './paymentReducers';
import productReducer, { IProductState } from './productReducers';
import templateReducer, { ITemplateState } from './templateReducers';
import testimonialReducer, { ITestimonialState } from './testimonialReducers';
import trainerReducer, { ITrainerState } from './trainerReducers';
import uiReducer, { IUIState } from './uiReducer';
import userReducer, { IUserState } from './userReducer';

export interface StateType {
    ui: IUIState;
    auth: AuthState;
    errors: IErrorState;
    user: IUserState;
    template: ITemplateState;
    category: ICategoryState;
    product: IProductState;
    cart: ICartState;
    trainer: ITrainerState;
    testimonial: ITestimonialState;
    newsfeed: INewsfeedState;
    media: IMultimediaState;
    contact: IContactState;
    deliveryAddress: IDeliveryAddressState;
    payment: IPaymentState;
    address: IAddressState;
    consultation: IConsultationState;
    coaching: ICoachingState;
    dashboard: IDashboardState;
    gift: IGiftState;
    offer: IOfferState;
    forum: IForumState;
}

export default combineReducers({
    ui: uiReducer,
    auth: authReducer,
    errors: errorReducer,
    user: userReducer,
    template: templateReducer,
    category: categoryReducer,
    product: productReducer,
    cart: cartReducer,
    trainer: trainerReducer,
    testimonial: testimonialReducer,
    newsfeed: newsfeedReducer,
    media: multimediaReducer,
    contact: contactReducer,
    deliveryAddress: deliveryAddressReducer,
    payment: paymentReducer,
    address: addressReducer,
    consultation: consultationReducer,
    coaching: coachingReducer,
    dashboard: dashboardReducer,
    gift: giftReducer,
    offer: offerReducer,
    forum: forumReducer
});

export type AppDispatch = ThunkDispatch<StateType, any, AnyAction>;
