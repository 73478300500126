import { FunctionComponent, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ITemplateCategory } from '../../interfaces/Template';
import queryString from 'query-string';
import { useEffect } from 'react';

interface IProps {
    className?: string;
    items: Array<ITemplateCategory>;
    defaultSelectedIndex?: number;
}

const AppAccordion: FunctionComponent<IProps> = (props) => {
    const [selectedEventKey, setSelectedEventKey] = useState<
        number | undefined
    >();

    const location = useLocation();

    const query = queryString.parse(location.search);

    const history = useHistory();

    const handleAccordionChange = (e: any) => {
        setSelectedEventKey(parseInt(e));
    };

    useEffect(() => {
        setSelectedEventKey(props.defaultSelectedIndex);
    }, [props.defaultSelectedIndex]);

    return (
        <Accordion
            onSelect={handleAccordionChange}
            className="app-accordion"
            activeKey={selectedEventKey?.toString()}
        >
            {props.items.map((item, index) => (
                <div key={index} className="mb-3">
                    <Accordion.Toggle
                        eventKey={index.toString()}
                        className="app-accordion-toggle"
                        onClick={() => {
                            history.push({
                                pathname: `/template`,
                                search: new URLSearchParams({
                                    ...query,
                                    category: item._id ?? ''
                                }).toString()
                            });
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center mb-2 pr-3">
                            <div
                                className={
                                    !item.subCategories?.length
                                        ? 'text-hover-red'
                                        : undefined
                                }
                            >
                                <span
                                    className={`text-neutrif text-18 ${
                                        query.category === item._id
                                            ? 'text-red'
                                            : 'text-dark-blue'
                                    }`}
                                >
                                    {item.name}
                                </span>
                            </div>

                            {item.subCategories?.length ? (
                                selectedEventKey === index ? (
                                    <FiChevronDown className="text-red" />
                                ) : (
                                    <FiChevronRight className="text-dark-blue" />
                                )
                            ) : (
                                ''
                            )}
                        </div>
                    </Accordion.Toggle>

                    {item.subCategories?.length ? (
                        <Accordion.Collapse
                            eventKey={index.toString()}
                            className="app-accordion-collapse"
                        >
                            <ul>
                                {item.subCategories?.map(
                                    (subItem, subIndex) => (
                                        <li key={subIndex}>
                                            <Link
                                                to={{
                                                    pathname: `/template`,
                                                    search: new URLSearchParams(
                                                        {
                                                            ...query,
                                                            category:
                                                                subItem._id ??
                                                                ''
                                                        }
                                                    ).toString()
                                                }}
                                                className={
                                                    `text-14 text-neutrif text-hover-red ` +
                                                    (query.category ===
                                                    subItem._id
                                                        ? 'text-red'
                                                        : 'text-dark-blue')
                                                }
                                            >
                                                <span>{subItem.name}</span>
                                            </Link>
                                        </li>
                                    )
                                )}
                            </ul>
                        </Accordion.Collapse>
                    ) : (
                        ''
                    )}
                </div>
            ))}
        </Accordion>
    );
};

AppAccordion.defaultProps = {
    className: ''
};

export default AppAccordion;
