import { Row, Col, Form } from 'react-bootstrap';
import SettingsNav from './SettingsNav';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { changePassword } from '../../../actions/userActions';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import { IChangePasswordBody } from '../../../interfaces/User';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useState } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';

interface IProps {}

const ChangePassword = (props: IProps) => {
    const changePasswordLoading = useSelector(
        (state: StateType) => state.user.changePasswordLoading
    );

    const [changePasswordError, setChangePasswordError] = useState<string>();

    const dispatch = useDispatch<AppDispatch>();

    const initialValues: IChangePasswordBody = {
        old_password: '',
        password: '',
        password_confirmation: ''
    };

    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required('Old password is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters long'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required')
    });

    return (
        <DashboardLayout>
            <div className="dashboard-container">
                <Row>
                    <Col xl={3} lg={4} className="mb-5">
                        <SettingsNav />
                    </Col>
                    <Col xl={9} lg={8} className="mb-5">
                        <div className="bg-2 p-3">
                            <h2 className="text-600 text-18 text-dark-blue mb-4">
                                CHANGE PASSWORD
                            </h2>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values, { resetForm }) => {
                                    dispatch(changePassword(values))
                                        .then(() => {
                                            setChangePasswordError(undefined);
                                            resetForm();
                                        })
                                        .catch((err) =>
                                            setChangePasswordError(err)
                                        );
                                }}
                                isInitialValid={false}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    isValid,
                                    errors
                                }) => {
                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="mb-4">
                                                <Col xs={9}>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <AppInput
                                                                name="old_password"
                                                                required
                                                                type="password"
                                                                label="Current Password"
                                                                placeholder="Current Password"
                                                                fill
                                                                value={
                                                                    values.old_password
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                isInvalid={
                                                                    !!errors.old_password
                                                                }
                                                                error={
                                                                    errors.old_password
                                                                }
                                                            />
                                                        </Col>
                                                        <Col xs={12}>
                                                            <AppInput
                                                                name="password"
                                                                required
                                                                type="password"
                                                                label="New Password"
                                                                placeholder="New Password"
                                                                fill
                                                                value={
                                                                    values.password
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                isInvalid={
                                                                    !!errors.password
                                                                }
                                                                error={
                                                                    errors.password
                                                                }
                                                            />
                                                        </Col>
                                                        <Col xs={12}>
                                                            <AppInput
                                                                name="password_confirmation"
                                                                required
                                                                type="password"
                                                                label="Confirm New Password"
                                                                placeholder="Confirm New Password"
                                                                fill
                                                                value={
                                                                    values.password_confirmation
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                isInvalid={
                                                                    !!errors.password_confirmation
                                                                }
                                                                error={
                                                                    errors.password_confirmation
                                                                }
                                                            />
                                                        </Col>

                                                        {changePasswordError ? (
                                                            <Col xs={12}>
                                                                <span className="text-12 text-red mb-3">
                                                                    {
                                                                        changePasswordError
                                                                    }
                                                                </span>
                                                            </Col>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Row>
                                                </Col>

                                                <Col xs={3}>
                                                    <div
                                                        className="bg-white p-3"
                                                        style={{
                                                            marginTop: 30
                                                        }}
                                                    >
                                                        <p className="text-neutrif text-12 text-gray-1">
                                                            New password must be
                                                            different than your
                                                            older password
                                                        </p>
                                                        <p className="text-neutrif text-12 text-gray-1">
                                                            Password must be at
                                                            least 6 characters
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex mb-5">
                                                <AppButton
                                                    variant="primary"
                                                    type="submit"
                                                    loading={
                                                        changePasswordLoading
                                                    }
                                                    disabled={
                                                        !isValid ||
                                                        changePasswordLoading
                                                    }
                                                >
                                                    Change Password
                                                </AppButton>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </Col>
                </Row>
            </div>
        </DashboardLayout>
    );
};

export default ChangePassword;
