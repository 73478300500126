import { useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    getForum,
    getForumComments,
    resetForums
} from '../../../actions/forumActions';
import { getAllOffers } from '../../../actions/offerActions';
import { $FIXME } from '../../../helpers/constants';
import { AppDispatch, StateType } from '../../../reducers';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import Offers from '../home/Offers';
import GetOffers from '../templateSearch/GetOffers';
import ForumDetailGroup from './ForumDetailGroup';
import ForumFilter from './ForumFilter';

interface IProps {}

const ForumDetails = (props: IProps) => {
    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Forum',
            link: '/forum',
            active: true
        }
    ];

    const { forum, forumLoading } = useSelector(
        (state: StateType) => state.forum
    );

    const { user_type } = useSelector((state: StateType) => state.user.user);

    const { is_active_member } = useSelector(
        (state: StateType) => state.auth.authUser
    );

    const dispatch = useDispatch<AppDispatch>();

    const { id }: $FIXME = useParams();

    useEffect(() => {
        dispatch(resetForums());

        if (id) {
            dispatch(getForum(id)).then((count) => {
                if (count > 0)
                    dispatch(getForumComments(id, { resultsPerPage: 5 }));
            });
        }
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    if (user_type === 'Client' && is_active_member === false) {
        toast.error(
            'You need to be an active user. Please purchase an OxStrength package to view this discussion.',
            {
                position: toast.POSITION.TOP_CENTER
            }
        );

        return <Redirect to="/forum" />;
    }

    return (
        <MainLayout backgroundColor="#F4F6FC" navBackgroundColor="#F4F6FC">
            <div className="container-xxxl shop-search-container">
                <div className="px-lg-3">
                    <AppBreadcrumbs items={breadcrumbItems} />

                    <Row className="pb-5 mt-3 mb-5">
                        <Col lg={3} md={12}>
                            <ForumFilter />
                        </Col>

                        <Col className="shop-search-main pb-3">
                            {forumLoading ? (
                                <div
                                    className="d-flex align-items-center justify-content-center w-100"
                                    style={{ height: 200 }}
                                >
                                    <Spinner animation="border" />
                                </div>
                            ) : (
                                forum && <ForumDetailGroup forum={forum} />
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
            <GetOffers offerType="all" />
            <Offers />
            <GetNotified />
        </MainLayout>
    );
};

export default ForumDetails;
