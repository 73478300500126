import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getAppMultimedia } from '../../../actions/multimediaActions';
import { getTrainers } from '../../../actions/trainerActions';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import Offers from '../home/Offers';
import TrainerList from '../home/TrainerList';
import Activities from './Activities';

const About = () => {
    const dispatch = useDispatch();

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'About',
            link: '/about',
            active: true
        }
    ];

    useEffect(() => {
        dispatch(getTrainers());
        dispatch(getAppMultimedia());
    }, [dispatch]);

    return (
        <MainLayout>
            <div className="container-xxxl">
                <div className="px-lg-3">
                    <AppBreadcrumbs items={breadcrumbItems} />
                    <div>
                        <h2 className="text-700 text-dark-blue text-uppercase">
                            ABOUT US
                        </h2>
                        <p className="text-gray text-14">
                            Search and find the best templates in town
                        </p>
                    </div>
                </div>
            </div>
            <div className="about-banner-container py-lg-5">
                <div className="container-xxxl py-5">
                    <div className="px-lg-3">
                        <h4 className="text-neutrif text-500 text-white about-heading mx-auto text-center mb-4">
                            OX strength is an all in one online platform for
                            your coaching, supplementation and clothing needs.
                        </h4>

                        <Row className="pt-3 pt-lg-5">
                            <Col sm={4} className="mb-3">
                                <h2 className="text-600 text-20 text-white text-uppercase ">
                                    Our mission
                                </h2>
                                <p className="text-500 text-light-gray">
                                    Provide and deliver evidence based training
                                    practices in simple, affordable, easy to follow
                                    format. We focus on giving our clients the right
                                    information and accelerate their fitness journey
                                    with proper technique, programming and nutrition
                                    from the get-go.
                                </p>
                            </Col>
                            <Col sm={4} className="mb-3">
                                <h2 className="text-600 text-20 text-white text-uppercase">
                                    Our Vision
                                </h2>
                                <p className="text-500 text-light-gray">
                                    To create a community, and a country at large,
                                    passionate, eager to learn, grow and find a 
                                    career in fitness while inspiring a generation
                                    to live a healthier, more balanced life.
                                </p>
                            </Col>
                            <Col sm={4} className="mb-3">
                                <h2 className="text-600 text-20 text-white text-uppercase">
                                    Our Values
                                </h2>
                                <p className="text-500 text-light-gray">
                                    The fitness industry is filled with over
                                    promises and under delivery. Our core value
                                    is to provide sustainable and realistic
                                    expectations and help clients make permanent
                                    lifestyle changes with proper guidance. We want
                                    to guide clients the right way towards their
                                    fitness journey and competitive aspirations in
                                    Powerlifting, Bodybuilding.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <TrainerList />
            <Activities />
            <Offers />
            <GetNotified />
        </MainLayout>
    );
};

export default About;
