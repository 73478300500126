import request from '../helpers/request';
import { Dispatch } from 'redux';
import { GET_MULTIMEDIAS, GET_TRAINER_MEDIA } from '../helpers/constants';
import { AxiosResponse } from 'axios';

export const getAppMultimedia = () => async (dispatch: Dispatch) => {
    request.getAppMultimedia().then((res: AxiosResponse) => {
        dispatch({ type: GET_MULTIMEDIAS, payload: res.data });
    });
};

export const getTrainerMedia = (id: string) => async (dispatch: Dispatch) => {
    request.getTrainerMedia(id).then((res: AxiosResponse) => {
        dispatch({ type: GET_TRAINER_MEDIA, payload: res.data });
    });
};
