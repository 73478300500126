import { GET_CARTS } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { ICart } from '../interfaces/Cart';

export interface ICartState {
    carts: ICart;
}

const initialState: ICartState = {
    carts: {
        carts: []
    }
};

const cartReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_CARTS:
            return {
                ...state,
                carts: action.payload
            };
        default:
            return state;
    }
};

export default cartReducer;
