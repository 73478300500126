import { AxiosResponse, AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { $FIXME, GET_CARTS } from '../helpers/constants';
import request from '../helpers/request';
import { IUpdateCartBody } from '../interfaces/Cart';
import { IAddCartBody } from '../interfaces/Product';
import { setErrors } from '../actions/errorActions';

export const getCarts = () => async (dispatch: Dispatch) => {
    return request.getCartList().then((res: AxiosResponse) => {
        dispatch({
            type: GET_CARTS,
            payload: res.data
        });
    });
};

export const addToCart = (body: IAddCartBody) => async (dispatch: $FIXME) => {
    return request
        .addToCart(body)
        .then((res: AxiosResponse) => {
            if (body.onModel === 'Template') {
                toast.success('Template added to cart', {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            if (body.onModel === 'Product') {
                toast.success('Product added to cart', {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            if (body.onModel === 'Consultation') {
                toast.success('Consultation added to cart', {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            if (body.onModel === 'Coaching') {
                toast.success('Coaching added to cart', {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            dispatch(getCarts());

            return Promise.resolve();
        })
        .catch((err: AxiosError) => {
            dispatch(
                setErrors(err.response?.data?.message ?? 'Something went wrong')
            );

            return Promise.reject();
        });
};

export const updateCartUnit =
    (id: string, body: IUpdateCartBody) => async (dispatch: $FIXME) => {
        return request.updateCart(id, body).then((res: AxiosResponse) => {
            dispatch(getCarts());

            return Promise.resolve();
        });
    };

export const deleteCart = (id: string) => async (dispatch: $FIXME) => {
    return request
        .deleteCart(id)
        .then((res: AxiosResponse) => {
            dispatch(getCarts());

            return Promise.resolve();
        })
        .catch((err) => {
            return Promise.reject();
        });
};

export const resetCart = () => async (dispatch: $FIXME) => {
    request.resetCart().then((res: AxiosResponse) => {
        dispatch(getCarts());
    });
};
