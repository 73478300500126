import { Table } from 'react-bootstrap';
import { BiLockAlt } from 'react-icons/bi';

interface ITableProps {
    children: React.ReactNode;
    hidden?: boolean;
}

const TableHead = ({ children }: ITableProps) => {
    return (
        <th className="text-neutrif text-600 text-14 text-dark-2 text-nowrap">
            {children}
        </th>
    );
};

const TableData = ({ children }: ITableProps) => {
    return (
        <td className="text-neutrif text-400 text-14 text-dark-gray-1">
            {children}
        </td>
    );
};

const TrainingLocked = () => {
    return (
        <div className="position-relative">
            <Table
                responsive
                borderless
                className="training-details-table-blur m-0"
            >
                <tbody>
                    <tr>
                        <TableData>Pause Squat</TableData>
                        <TableData>57.5%</TableData>
                        <TableData>-</TableData>
                        <TableData>-</TableData>
                        <TableData>6</TableData>
                        <TableData>4</TableData>
                        <TableData>2-2-1</TableData>
                        <TableData>90 Sec</TableData>
                        <TableData>Add Note</TableData>
                    </tr>
                    <tr>
                        <TableData>Comp Bench Press</TableData>
                        <TableData>57.5%</TableData>
                        <TableData>-</TableData>
                        <TableData>-</TableData>
                        <TableData>6</TableData>
                        <TableData>4</TableData>
                        <TableData>2-2-1</TableData>
                        <TableData>90 Sec</TableData>
                        <TableData>Add Note</TableData>
                    </tr>
                    <tr>
                        <TableData>Conventional Deadlift</TableData>
                        <TableData>57.5%</TableData>
                        <TableData>-</TableData>
                        <TableData>-</TableData>
                        <TableData>6</TableData>
                        <TableData>4</TableData>
                        <TableData>2-2-1</TableData>
                        <TableData>90 Sec</TableData>
                        <TableData>Add Note</TableData>
                    </tr>
                    <tr>
                        <TableData>Squat Weakness</TableData>
                        <TableData>57.5%</TableData>
                        <TableData>-</TableData>
                        <TableData>-</TableData>
                        <TableData>6</TableData>
                        <TableData>4</TableData>
                        <TableData>2-2-1</TableData>
                        <TableData>90 Sec</TableData>
                        <TableData>Add Note</TableData>
                    </tr>
                    <tr>
                        <TableData>Shoulder Option</TableData>
                        <TableData>57.5%</TableData>
                        <TableData>-</TableData>
                        <TableData>-</TableData>
                        <TableData>6</TableData>
                        <TableData>4</TableData>
                        <TableData>2-2-1</TableData>
                        <TableData>90 Sec</TableData>
                        <TableData>Add Note</TableData>
                    </tr>
                    <tr>
                        <TableData>Bicep Option</TableData>
                        <TableData>57.5%</TableData>
                        <TableData>-</TableData>
                        <TableData>-</TableData>
                        <TableData>6</TableData>
                        <TableData>4</TableData>
                        <TableData>2-2-1</TableData>
                        <TableData>90 Sec</TableData>
                        <TableData>Add Note</TableData>
                    </tr>
                </tbody>
            </Table>
            <div className="position-absolute table-lock">
                <BiLockAlt size={26} className="text-red" />
            </div>
        </div>
    );
};

export default TrainingLocked;

export { TableData, TableHead };
