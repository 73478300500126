import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    GET_PRODUCT_BRANDS,
    GET_PRODUCT_CATEGORIES,
    GET_TEMPLATE_CATEGORIES,
    GET_TRAINING_CATEGORIES
} from '../helpers/constants';
import request from '../helpers/request';

export const getTrainingCategories = () => async (dispatch: Dispatch) => {
    request.getTrainingCategories().then((res: AxiosResponse) => {
        dispatch({
            type: GET_TRAINING_CATEGORIES,
            payload: res.data
        });
    });
};

export const getTemplateCategories = () => async (dispatch: Dispatch) => {
    request.getTemplateCategories().then((res: AxiosResponse) => {
        dispatch({
            type: GET_TEMPLATE_CATEGORIES,
            payload: res.data
        });
    });
};

export const getProductCategories = () => async (dispatch: Dispatch) => {
    request.getProductCategories().then((res: AxiosResponse) => {
        dispatch({
            type: GET_PRODUCT_CATEGORIES,
            payload: res.data
        });
    });
};

export const getProductBrands =
    (product_category?: string) => async (dispatch: Dispatch) => {
        request
            .getProductBrands(product_category)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_PRODUCT_BRANDS,
                    payload: res.data
                });
            });
    };
