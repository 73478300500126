import AppBreadcrumbs, { IBreadCrumbItem } from '../components/AppBreadcrumbs';
import MainLayout from './MainLayout';
import GetNotified from '../pages/home/GetNotified';
import { Row, Col } from 'react-bootstrap';
import AppButton from '../components/AppButton';
import { Link, NavLink, useParams } from 'react-router-dom';
import {
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaCheckCircle
} from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { $FIXME, defaultImage } from '../../helpers/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getTrainerBySlug,
    resetTrainerState
} from '../../actions/trainerActions';
import { StateType } from '../../reducers';
import { getTrainerMedia } from '../../actions/multimediaActions';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

interface IProps {
    children: React.ReactNode;
}

const TrainerProfileLayout = (props: IProps) => {
    const [didImageLoad, setImageLoad] = useState(false);

    const trainer = useSelector((state: StateType) => state.trainer.trainer);

    const { slug }: $FIXME = useParams();

    const dispatch = useDispatch();

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'About',
            link: '/about'
        },
        {
            label: 'Trainer Profile',
            link: `/trainer/${slug}/profile`,
            active: true
        }
    ];

    useEffect(() => {
        dispatch(getTrainerBySlug(slug));

        return () => {
            dispatch(resetTrainerState());
        };
    }, [dispatch, slug]);

    useEffect(() => {
        if (trainer._id) dispatch(getTrainerMedia(trainer._id));
    }, [dispatch, trainer._id]);

    return (
        <MainLayout backgroundColor="#f4f6fc">
            <div className="trainer-profile-container">
                <div className="trainer-cover-image">
                    <div className="container-xxxl">
                        <AppBreadcrumbs
                            items={breadcrumbItems}
                            theme="dark"
                            className="px-lg-3"
                        />
                    </div>
                </div>
                <div className="container-xxxl">
                    <div className="px-lg-3">
                        <Row className="mb-5">
                            <Col sm={6} md={5} lg={4} xl={3} className="mb-5">
                                <div className="trainer-profile-card">
                                    <Skeleton
                                        className="trainer-profile-img"
                                        style={
                                            didImageLoad
                                                ? { display: 'none' }
                                                : {}
                                        }
                                    />
                                    <img
                                        src={
                                            !!trainer.profile_pic
                                                ? trainer.profile_pic
                                                : defaultImage
                                        }
                                        alt={trainer.name}
                                        style={
                                            didImageLoad
                                                ? {}
                                                : { display: 'none' }
                                        }
                                        onLoad={() => setImageLoad(true)}
                                        className="trainer-profile-img"
                                    />
                                    <div className="trainer-profile-desc p-3">
                                        <p className="text-oswald text-700 text-20 text-white mb-0">
                                            {trainer.name}
                                            {trainer.is_verified ? (
                                                <FaCheckCircle className="text-red ml-2 mb-4" />
                                            ) : (
                                                ''
                                            )}
                                        </p>

                                        <p className="text-600 text-14 text-light-gray mb-2">
                                            {trainer.training_category?.name}
                                        </p>

                                        <p className="text-14 text-gray mb-2">
                                            {trainer.address?.area}
                                            {', '}
                                            {trainer.address?.district}
                                            {', '}
                                            {trainer.address?.country}
                                        </p>

                                        <div className="d-flex">
                                            {trainer.social?.facebook && (
                                                <a
                                                    href={
                                                        trainer.social.facebook
                                                    }
                                                    target="#"
                                                    className="mx-2 mb-4"
                                                >
                                                    <FaFacebookF
                                                        size={14}
                                                        className="text-white"
                                                    />
                                                </a>
                                            )}

                                            {trainer.social?.twitter && (
                                                <a
                                                    href={
                                                        trainer.social.twitter
                                                    }
                                                    target="#"
                                                    className="mx-2 mb-4"
                                                >
                                                    <FaTwitter
                                                        size={14}
                                                        className="text-white"
                                                    />
                                                </a>
                                            )}

                                            {trainer.social?.instagram && (
                                                <a
                                                    href={
                                                        trainer.social.instagram
                                                    }
                                                    target="#"
                                                    className="mx-2 mb-4"
                                                >
                                                    <FaInstagram
                                                        size={14}
                                                        className="text-white"
                                                    />
                                                </a>
                                            )}

                                            {trainer.social?.tiktok && (
                                                <a
                                                    href={trainer.social.tiktok}
                                                    target="#"
                                                    className="mx-2 mb-4"
                                                >
                                                    <SiTiktok
                                                        size={14}
                                                        className="text-white"
                                                    />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="px-3 py-5 mt-3 d-flex flex-column justify-content-center"
                                    style={{
                                        aspectRatio: '7/8',
                                        backgroundImage: `url('https://st3.depositphotos.com/11866110/14504/i/450/depositphotos_145041537-stock-photo-female-fitness-model-exercising.jpg')`
                                    }}
                                >
                                    <h2 className="text-oswald text-700 text-20 text-white text-uppercase mb-4">
                                        Boost your immune
                                        <br />
                                        system today
                                    </h2>

                                    <Link to="/shop/search">
                                        <AppButton
                                            variant="secondary"
                                            className="w-100"
                                        >
                                            Shop now
                                        </AppButton>
                                    </Link>
                                </div>
                            </Col>
                            <Col sm={6} md={7} lg={8} xl={9} className="mb-5">
                                <div className="trainer-detail-container">
                                    <div className="d-flex flex-wrap py-3 px-3 trainer-links">
                                        <NavLink
                                            to={`/trainer/${slug}/profile`}
                                            className="text-neutrif py-2 text-16 mr-5 position-relative trainer-nav-link"
                                            activeClassName="nav-link-active"
                                        >
                                            About Trainer
                                        </NavLink>
                                        <NavLink
                                            to={`/trainer/${slug}/videos`}
                                            className="text-neutrif py-2 text-16 mr-5 position-relative trainer-nav-link"
                                            activeClassName="nav-link-active"
                                        >
                                            Training Videos
                                        </NavLink>
                                        <NavLink
                                            to={`/trainer/${slug}/packages`}
                                            className="text-neutrif py-2 text-16 mr-5 position-relative trainer-nav-link"
                                            activeClassName="nav-link-active"
                                        >
                                            Packages
                                        </NavLink>
                                        <NavLink
                                            to={`/trainer/${slug}/images`}
                                            className="text-neutrif py-2 text-16 mr-5 position-relative trainer-nav-link"
                                            activeClassName="nav-link-active"
                                        >
                                            Images
                                        </NavLink>
                                        <NavLink
                                            to={`/trainer/${slug}/reviews`}
                                            className="text-neutrif py-2 text-16 mr-5 position-relative trainer-nav-link"
                                            activeClassName="nav-link-active"
                                        >
                                            Reviews/Testimonials
                                        </NavLink>
                                    </div>
                                    <div className="my-3">{props.children}</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <GetNotified />
        </MainLayout>
    );
};

export default TrainerProfileLayout;
