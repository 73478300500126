import { FunctionComponent, useRef } from 'react';

interface IProps {
    className?: string;
    placeholder?: string;
    defaultValue?: string;
    value?: string;
    name?: string;
    onChange?: React.ChangeEventHandler<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    type?: string;
    min?: number;
    max?: number;
    disabled?: boolean;
}

const AppTableInput: FunctionComponent<IProps> = ({
    placeholder,
    className,
    defaultValue,
    value,
    name,
    onChange,
    onBlur,
    type,
    min,
    max,
    disabled
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className={'d-flex app-table-input ' + className}>
            <input
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                name={name}
                onBlur={onBlur}
                ref={inputRef}
                disabled={disabled}
                autoComplete="off"
                type={type}
                min={min}
                max={max}
            />
        </div>
    );
};

AppTableInput.defaultProps = {
    className: ''
};

export default AppTableInput;
