import { GET_MULTIMEDIAS, GET_TRAINER_MEDIA } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IMultimedia } from '../interfaces/Multimedia';

export interface IMultimediaState {
    medias: Array<IMultimedia>;
    trainer: Array<IMultimedia>;
}

const initialState: IMultimediaState = {
    medias: [],
    trainer: []
};

const multimediaReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_MULTIMEDIAS:
            return {
                ...state,
                medias: action.payload
            };

        case GET_TRAINER_MEDIA:
            return {
                ...state,
                trainer: action.payload
            };

        default:
            return state;
    }
};

export default multimediaReducer;
