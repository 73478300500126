import { Table, Image, Spinner, Pagination, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
    getCoachingConsultations,
    getSubscribedConsultations,
    getTemplateConsultations
} from '../../../actions/consultationActions';
import { AppDispatch, StateType } from '../../../reducers';
import dayjs from 'dayjs';
import {
    ConsultationFilterType,
    ConsultationStatus,
    ConsultationType
} from '../../../interfaces/Consultation';
import { defaultImage, URL_REGEX } from '../../../helpers/constants';
import StatusChip from '../../components/StatusChip';
import AppButton from '../../components/AppButton';
import { GymBag } from '../../../assets/images/icons';
import { toggleConsultationModal } from '../../../actions/uiActions';
import CustomToggle from '../../components/CustomToggle';

interface IProps {}

const Consultations = (props: IProps) => {
    const {
        subscribedConsultations: consultation,
        templateConsultations,
        templateConsultationsLoading,
        subscribedConsultationsLoading,
        coachingConsultations,
        coachingConsultationsLoading
    } = useSelector((state: StateType) => state.consultation);

    const resultsPerPage = '5';

    const [activePage, setActivePage] = useState(1);

    const [type, setType] = useState<ConsultationType>('Paid');
    const [filter, setFilter] = useState<ConsultationFilterType>('All');

    const consulatationTypes: Array<ConsultationType> = [
        'Paid',
        'Template',
        'Coaching'
    ];

    const consultationFilter: Array<ConsultationFilterType> = [
        'All',
        'Pending',
        'Previous',
        'Upcoming'
    ];

    const dispatch = useDispatch<AppDispatch>();

    const getStatusByFilter = (
        value: ConsultationFilterType
    ): ConsultationStatus | undefined => {
        switch (value) {
            case 'Upcoming':
                return 'requested';

            case 'Previous':
                return 'completed';

            case 'Pending':
                return 'not_requested';

            default:
                return 'all';
        }
    };

    const getConsultationByType = useCallback(
        (consultationType: ConsultationType) => {
            switch (consultationType) {
                case 'Template':
                    dispatch(
                        getTemplateConsultations({
                            sort: 'latest',
                            resultsPerPage,
                            page: activePage.toString(),
                            consultation_status: getStatusByFilter(filter)
                        })
                    );
                    break;

                case 'Paid':
                    dispatch(
                        getSubscribedConsultations({
                            sort: 'latest',
                            resultsPerPage,
                            page: activePage.toString(),
                            consultation_status: getStatusByFilter(filter)
                        })
                    );
                    break;

                case 'Coaching':
                    dispatch(
                        getCoachingConsultations({
                            consultation_sort: 'latest',
                            consultation_status: getStatusByFilter(filter),
                            resultsPerPage,
                            page: activePage.toString()
                        })
                    );
                    break;
                default:
            }
        },
        [activePage, filter, dispatch]
    );

    useEffect(() => {
        if (['Coaching', 'Paid', 'Template'].includes(type)) {
            getConsultationByType(type);
        }
    }, [type, getConsultationByType]);

    const renderText = (txt: string) =>
        txt.split(' ').map((part) =>
            URL_REGEX.test(part) ? (
                <a
                    className="text-neutrif text-600 text-16 text-link"
                    href={part}
                    target="_blank"
                    rel="noreferrer"
                >
                    {part}{' '}
                </a>
            ) : (
                part + ' '
            )
        );

    const handleFilterDropdown = (e: string | null) => {
        let filter = e as ConsultationFilterType;

        setActivePage(1);

        setFilter(filter ?? 'All');
    };

    const handleTypeChange = (type: ConsultationType) => {
        setActivePage(1);

        setFilter('All');

        setType(type);
    };

    const handlePageChange = (pageNumber: number) => {
        setActivePage(pageNumber);
    };

    interface INoTemplateProps {
        label: string;
        type: ConsultationType;
    }

    const NoTemplate = ({ label, type }: INoTemplateProps) => {
        return (
            <div className="d-flex align-items-center flex-column my-5">
                <GymBag />
                <p className="text-inter text-gray-2">{label}</p>
                {(() => {
                    if (type === 'Paid') {
                        return (
                            <Link to="/consultation">
                                <AppButton variant="outline-secondary">
                                    Explore Consultations
                                </AppButton>
                            </Link>
                        );
                    }

                    if (type === 'Template') {
                        return (
                            <Link to="/template">
                                <AppButton variant="outline-secondary">
                                    Explore Templates
                                </AppButton>
                            </Link>
                        );
                    }

                    if (type === 'Coaching') {
                        return (
                            <Link to="/coaching">
                                <AppButton variant="outline-secondary">
                                    Explore Coachings
                                </AppButton>
                            </Link>
                        );
                    }
                })()}
            </div>
        );
    };

    return (
        <>
            <div className="d-flex flex-wrap align-items-center">
                <h2 className="text-20 flex-grow-1 mb-3">MY CONSULTATIONS</h2>
                <div className="mb-3">
                    {consulatationTypes.map((item, index) => (
                        <span
                            className={
                                'text-600 mx-2 text-hover-red cursor-pointer text-uppercase ' +
                                (item === type ? 'text-red' : 'text-gray-1')
                            }
                            onClick={() => handleTypeChange(item)}
                            key={index}
                        >
                            <span>{item}</span>
                        </span>
                    ))}
                </div>

                <Dropdown className="mb-3 ml-3">
                    <Dropdown.Toggle as={CustomToggle}>FILTER</Dropdown.Toggle>
                    <Dropdown.Menu alignRight>
                        {consultationFilter.map((item, index) => (
                            <Dropdown.Item
                                eventKey={item}
                                key={index}
                                onSelect={handleFilterDropdown}
                                active={item === filter}
                            >
                                {item}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {type === 'Paid' ? (
                <Table responsive borderless className="consultation-table">
                    <thead>
                        <tr>
                            {[
                                'Trainer Detail',
                                'Title',
                                'Action',
                                'Remarks',
                                'Status',
                                'Consultation Date'
                            ].map((title, index) => (
                                <th
                                    className="text-neutrif text-600 text-14 text-dark-2 text-nowrap"
                                    key={index}
                                >
                                    {title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {consultation.consultations.length ? (
                            consultation.consultations.map(
                                (
                                    {
                                        consultation_date,
                                        consultation,
                                        consultation_trainer,
                                        remarks,
                                        completed,
                                        _id
                                    },
                                    index
                                ) => (
                                    <tr key={index}>
                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <Link
                                                to={`/trainer/${consultation_trainer?.slug}/profile`}
                                                className="d-inline-flex cursor-pointer text-hover-red"
                                            >
                                                <div className="mr-3">
                                                    <Image
                                                        src={
                                                            !!consultation_trainer?.profile_pic
                                                                ? consultation_trainer?.profile_pic
                                                                : defaultImage
                                                        }
                                                        roundedCircle
                                                        height={42}
                                                        width={42}
                                                        className="profile-avatar object-cover"
                                                    />
                                                </div>
                                                <div className="text-white">
                                                    <p className="text-500 text-dark-2 mb-0 text-nowrap">
                                                        {
                                                            consultation_trainer?.name
                                                        }
                                                    </p>
                                                    <p className="text-500 text-12 text-gray-2 mb-0">
                                                        Fitness Trainer
                                                    </p>
                                                </div>
                                            </Link>
                                        </td>

                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <p className="mb-0">
                                                {consultation?.title}
                                            </p>
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <div>
                                                <Link
                                                    to={`/consultation/${consultation?.slug}`}
                                                    className="text-red text-neutrif text-14 text-500"
                                                >
                                                    Detail
                                                </Link>
                                            </div>
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <div>
                                                <p className="mb-0">
                                                    {remarks}
                                                </p>
                                            </div>
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 90
                                                }}
                                            >
                                                {completed ? (
                                                    <StatusChip
                                                        type="warning"
                                                        label="Previous"
                                                    />
                                                ) : consultation_date ? (
                                                    <StatusChip
                                                        type="success"
                                                        label="Upcoming"
                                                    />
                                                ) : (
                                                    <StatusChip
                                                        type="danger"
                                                        label="Pending"
                                                    />
                                                )}
                                            </div>
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            {consultation_date ? (
                                                <p className="text-500 text-14 text-dark-2 mb-0">
                                                    {dayjs(
                                                        consultation_date
                                                    ).format('YYYY-MM-DD')}
                                                </p>
                                            ) : (
                                                <AppButton
                                                    variant="secondary"
                                                    className="px-2"
                                                    style={{
                                                        height: 44
                                                    }}
                                                    onClick={() => {
                                                        dispatch(
                                                            toggleConsultationModal(
                                                                {
                                                                    open: true,
                                                                    consultationType:
                                                                        'Paid',
                                                                    consultationId:
                                                                        _id
                                                                }
                                                            )
                                                        );
                                                    }}
                                                >
                                                    Set Consultation Date
                                                </AppButton>
                                            )}
                                        </td>
                                    </tr>
                                )
                            )
                        ) : !subscribedConsultationsLoading ? (
                            <tr>
                                <td colSpan={9}>
                                    <NoTemplate
                                        type="Paid"
                                        label={`No consultation found. Explore and book consultation of your
                    choice`}
                                    />
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan={9}>
                                    <div className="d-flex align-items-center flex-column my-3">
                                        <Spinner animation="border" />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            ) : (
                <> </>
            )}

            {type === 'Template' ? (
                <Table responsive borderless className="consultation-table">
                    <thead>
                        <tr>
                            {[
                                'Title',
                                'Action',
                                'Remarks',
                                'Status',
                                'Consultation Date'
                            ].map((title, index) => (
                                <th
                                    className="text-neutrif text-600 text-14 text-dark-2 text-nowrap"
                                    key={index}
                                >
                                    {title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {templateConsultations.templates.length ? (
                            templateConsultations.templates.map(
                                (
                                    {
                                        consultation_date,
                                        template,
                                        remarks,
                                        status,
                                        _id
                                    },
                                    index
                                ) =>
                                    template ? (
                                        <tr key={index}>
                                            <td
                                                style={{
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                <p className="mb-0">
                                                    {template?.title}
                                                </p>
                                            </td>
                                            <td
                                                style={{
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                <div>
                                                    <Link
                                                        to={`/template/${template?.slug}`}
                                                        className="text-red text-neutrif text-14 text-500"
                                                    >
                                                        Detail
                                                    </Link>
                                                </div>
                                            </td>
                                            <td
                                                style={{
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                <div>
                                                    <p className="mb-0">
                                                        {renderText(
                                                            remarks ?? ''
                                                        )}
                                                    </p>
                                                </div>
                                            </td>

                                            <td
                                                style={{
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: 90
                                                    }}
                                                >
                                                    {consultation_date ? (
                                                        status === 'Active' ||
                                                        status === 'Inactive' ||
                                                        status ===
                                                            'Completed' ||
                                                        status ===
                                                            'Consultation Completed' ? (
                                                            <StatusChip
                                                                type="warning"
                                                                label="Previous"
                                                            />
                                                        ) : (
                                                            <StatusChip
                                                                type="success"
                                                                label="Upcoming"
                                                            />
                                                        )
                                                    ) : (
                                                        <StatusChip
                                                            type="danger"
                                                            label="Pending"
                                                        />
                                                    )}
                                                </div>
                                            </td>

                                            <td
                                                style={{
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                {consultation_date ? (
                                                    <p className="text-500 text-14 text-dark-2 mb-0">
                                                        {dayjs(
                                                            consultation_date
                                                        ).format('YYYY-MM-DD')}
                                                    </p>
                                                ) : (
                                                    <AppButton
                                                        variant="secondary"
                                                        className="px-2"
                                                        style={{
                                                            height: 44,
                                                            minWidth: 178
                                                        }}
                                                        onClick={() => {
                                                            dispatch(
                                                                toggleConsultationModal(
                                                                    {
                                                                        open: true,
                                                                        consultationType:
                                                                            'Template',
                                                                        consultationId:
                                                                            _id
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        Set Consultation Date
                                                    </AppButton>
                                                )}
                                            </td>
                                        </tr>
                                    ) : (
                                        <> </>
                                    )
                            )
                        ) : !templateConsultationsLoading ? (
                            <tr>
                                <td colSpan={9}>
                                    <NoTemplate
                                        type="Template"
                                        label={`No consultation found. Explore and book consultation of your
                    choice`}
                                    />
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan={9}>
                                    <div className="d-flex align-items-center flex-column my-3">
                                        <Spinner animation="border" />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            ) : (
                <> </>
            )}

            {type === 'Coaching' ? (
                <Table responsive borderless className="consultation-table">
                    <thead>
                        <tr>
                            {[
                                'Trainer Detail',
                                'Title',
                                'Action',
                                'Remarks',
                                'Status',
                                'Consultation Date'
                            ].map((title, index) => (
                                <th
                                    className="text-neutrif text-600 text-14 text-dark-2 text-nowrap"
                                    key={index}
                                >
                                    {title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {coachingConsultations.coachings.length ? (
                            coachingConsultations.coachings.map(
                                (
                                    {
                                        first_consultation_completed,
                                        latest_consultation,
                                        coaching,
                                        coaching_trainer,

                                        _id
                                    },
                                    index
                                ) => (
                                    <tr key={index}>
                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <Link
                                                to={`/trainer/${
                                                    coaching_trainer?.slug ?? ''
                                                }/profile`}
                                                className="d-inline-flex cursor-pointer text-hover-red"
                                            >
                                                <div className="mr-3">
                                                    <Image
                                                        src={
                                                            !!coaching_trainer?.profile_pic
                                                                ? coaching_trainer.profile_pic
                                                                : defaultImage
                                                        }
                                                        roundedCircle
                                                        height={42}
                                                        width={42}
                                                        className="profile-avatar object-cover"
                                                    />
                                                </div>
                                                <div className="text-white">
                                                    <p className="text-500 text-dark-2 mb-0 text-nowrap">
                                                        {coaching_trainer?.name}
                                                    </p>
                                                    <p className="text-500 text-12 text-gray-2 mb-0">
                                                        Fitness Trainer
                                                    </p>
                                                </div>
                                            </Link>
                                        </td>

                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <p className="mb-0">
                                                {coaching?.title}
                                            </p>
                                        </td>

                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <div>
                                                <Link
                                                    to={`/coaching/${coaching?.slug}`}
                                                    className="text-red text-neutrif text-14 text-500"
                                                >
                                                    Detail
                                                </Link>
                                            </div>
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <div>
                                                <p className="mb-0">
                                                    {renderText(
                                                        latest_consultation?.remarks ??
                                                            ''
                                                    )}
                                                </p>
                                            </div>
                                        </td>

                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 90
                                                }}
                                            >
                                                {first_consultation_completed ||
                                                latest_consultation?.completed ? (
                                                    <StatusChip
                                                        type="warning"
                                                        label="Previous"
                                                    />
                                                ) : !first_consultation_completed &&
                                                  !latest_consultation?.consultation_date ? (
                                                    <StatusChip
                                                        type="danger"
                                                        label="Pending"
                                                    />
                                                ) : (
                                                    <StatusChip
                                                        type="success"
                                                        label="Upcoming"
                                                    />
                                                )}
                                            </div>
                                        </td>

                                        <td
                                            style={{
                                                verticalAlign: 'middle'
                                            }}
                                        >
                                            {latest_consultation?.consultation_date ? (
                                                <p className="text-500 text-14 text-dark-2 mb-0">
                                                    {dayjs(
                                                        latest_consultation.consultation_date
                                                    ).format('YYYY-MM-DD')}
                                                </p>
                                            ) : !first_consultation_completed ? (
                                                <AppButton
                                                    variant="secondary"
                                                    className="px-2"
                                                    style={{
                                                        height: 44,
                                                        minWidth: 178
                                                    }}
                                                    onClick={() => {
                                                        dispatch(
                                                            toggleConsultationModal(
                                                                {
                                                                    open: true,
                                                                    consultationType:
                                                                        'Coaching',
                                                                    consultationId:
                                                                        _id
                                                                }
                                                            )
                                                        );
                                                    }}
                                                >
                                                    Set Consultation Date
                                                </AppButton>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )
                            )
                        ) : !coachingConsultationsLoading ? (
                            <tr>
                                <td colSpan={9}>
                                    <NoTemplate
                                        type="Coaching"
                                        label={`No consultation found. Explore and book consultation of your
                    choice`}
                                    />
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan={9}>
                                    <div className="d-flex align-items-center flex-column my-3">
                                        <Spinner animation="border" />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            ) : (
                <> </>
            )}

            <div className="d-flex justify-content-end">
                <Pagination className="mt-3 flex-wrap">
                    {[
                        ...Array(
                            (() => {
                                if (type === 'Paid') {
                                    return consultation.consultations.length
                                        ? consultation.totalPage
                                        : 0;
                                } else if (type === 'Template') {
                                    return templateConsultations.templates
                                        .length
                                        ? templateConsultations.totalPage
                                        : 0;
                                } else if (type === 'Coaching') {
                                    return coachingConsultations.coachings
                                        .length
                                        ? coachingConsultations.totalPage
                                        : 0;
                                }
                            })()
                        )
                    ].map((_, index) => {
                        return (
                            <Pagination.Item
                                onClick={() => handlePageChange(index + 1)}
                                key={index + 1}
                                active={index + 1 === activePage}
                            >
                                {index + 1}
                            </Pagination.Item>
                        );
                    })}
                </Pagination>
            </div>
        </>
    );
};

export default Consultations;
