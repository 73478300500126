import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    GET_TRAINER,
    GET_TRAINERS,
    GET_TRAINER_COACHING_ACHIEVEMENTS,
    GET_TRAINER_PACKAGES,
    GET_TRAINER_PERSONAL_ACHIEVEMENTS,
    GET_TRAINER_REVIEW,
    RESET_TRAINER_STATE
} from '../helpers/constants';
import { AxiosResponse } from 'axios';

export const getTrainers = () => async (dispatch: Dispatch) => {
    request.getTrainers().then((res: AxiosResponse) => {
        dispatch({ type: GET_TRAINERS, payload: res.data });
    });
};

export const getTrainerBySlug =
    (slug: string) => async (dispatch: Dispatch) => {
        request.getUserBySlug(slug).then((res: AxiosResponse) => {
            dispatch({ type: GET_TRAINER, payload: res.data });
        });
    };

export const getTrainersByCategory =
    (id: string) => async (dispatch: Dispatch) => {
        request.getTrainersByCategory(id).then((res: AxiosResponse) => {
            dispatch({ type: GET_TRAINERS, payload: res.data });
        });
    };

export const resetTrainerState = () => async (dispatch: Dispatch) => {
    dispatch({ type: RESET_TRAINER_STATE });
};

export const getTrainerReview = (id: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_TRAINER_REVIEW,
        payload: { reviewsLoading: true }
    });

    request
        .getTrainerReview(id)
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_TRAINER_REVIEW,
                payload: { reviews: res.data, reviewsLoading: false }
            });
        })
        .catch(() => {
            dispatch({
                type: GET_TRAINER_REVIEW,
                payload: { reviewsLoading: false }
            });
        });
};

export const getTrainerCoachingAchievements =
    (id: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_TRAINER_COACHING_ACHIEVEMENTS,
            payload: { coachingAchievementsLoading: true }
        });

        request
            .getTrainerCoachingAchievements(id)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_TRAINER_COACHING_ACHIEVEMENTS,
                    payload: {
                        coachingAchievements: res.data,
                        coachingAchievementsLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_TRAINER_COACHING_ACHIEVEMENTS,
                    payload: { coachingAchievementsLoading: false }
                });
            });
    };

export const getTrainerPersonalAchievements =
    (id: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_TRAINER_PERSONAL_ACHIEVEMENTS,
            payload: { personalAcievementsLoading: true }
        });

        request
            .getTrainerPersonalAchievements(id)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_TRAINER_PERSONAL_ACHIEVEMENTS,
                    payload: {
                        personalAcievements: res.data,
                        personalAcievementsLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_TRAINER_PERSONAL_ACHIEVEMENTS,
                    payload: { personalAcievementsLoading: false }
                });
            });
    };

export const getTrainerPackages =
    (id: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_TRAINER_PACKAGES,
            payload: { packagesLoading: true }
        });

        request
            .getTemplates({ trainer: id, resultsPerPage: '100' })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_TRAINER_PACKAGES,
                    payload: {
                        packages: res.data,
                        packagesLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_TRAINER_PACKAGES,
                    payload: { packagesLoading: false }
                });
            });
    };
