import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import {
    $FIXME,
    apparelId,
    GET_APPARELS,
    GET_GYM_GEARS,
    GET_PRODUCT,
    GET_PRODUCTS,
    GET_PRODUCT_REVIEWS,
    GET_SIMILAR_PRODUCTS,
    GET_SUPPLIMENTS,
    gymGearId,
    RESET_PRODUCT_REVIEWS,
    RESET_PRODUCT_STATE,
    supplimentsId,
} from '../helpers/constants';
import request from '../helpers/request';
import {
    ICreateProductReviewBody,
    IProductParams,
} from '../interfaces/Product';
import { ITemplateReviewParams } from '../interfaces/Template';
import { setErrors } from './errorActions';

export const getProducts =
    (params: IProductParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_PRODUCTS,
            payload: { productsLoading: true },
        });
        request.getProducts(params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_PRODUCTS,
                payload: { products: res.data, productsLoading: false },
            });
        });
    };

export const getProductBySlug =
    (slug: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_PRODUCT,
            payload: { productLoading: true },
        });

        return request
            .getProductBySlug(slug)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_PRODUCT,
                    payload: { product: res.data, productLoading: false },
                });

                return Promise.resolve(res.data?._id);
            })
            .catch(() => {
                dispatch({
                    type: GET_PRODUCT,
                    payload: { productLoading: false },
                });

                return Promise.reject();
            });
    };

export const getProductReviews =
    (id: string, params: ITemplateReviewParams) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: GET_PRODUCT_REVIEWS,
            payload: { reviewsLoading: true },
        });

        request.getProductReviews(id, params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_PRODUCT_REVIEWS,
                payload: { reviews: res.data, reviewsLoading: false },
            });
        });
    };

export const resetProductReviews = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_PRODUCT_REVIEWS,
    });
};

export const createProductReview =
    (body: ICreateProductReviewBody) => async (dispatch: $FIXME) => {
        return request
            .createProductReview(body)
            .then((res: AxiosResponse) => {
                toast.success('Your review has been posted.', {
                    position: toast.POSITION.TOP_CENTER,
                });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch(setErrors(err.response?.data.message));

                return Promise.reject(err.response?.data.message);
            });
    };

export const getSimilarProducts =
    (id: string) => async (dispatch: Dispatch) => {
        request.getSimilarProducts(id).then((res: AxiosResponse) => {
            dispatch({
                type: GET_SIMILAR_PRODUCTS,
                payload: res.data,
            });
        });
    };

export const resetProductState = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_PRODUCT_STATE,
    });
};

export const getApparels =
    (params: IProductParams) => async (dispatch: Dispatch) => {
        request
            .getProducts({
                ...params,
                resultsPerPage: 4,
                parentCategory: apparelId,
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_APPARELS,
                    payload: res.data,
                });
            });
    };

export const getGymGears =
    (params: IProductParams) => async (dispatch: Dispatch) => {
        request
            .getProducts({
                ...params,
                resultsPerPage: 4,
                parentCategory: gymGearId,
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_GYM_GEARS,
                    payload: res.data,
                });
            });
    };

export const getSuppliments =
    (params: IProductParams) => async (dispatch: Dispatch) => {
        request
            .getProducts({
                ...params,
                resultsPerPage: 4,
                parentCategory: supplimentsId,
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_SUPPLIMENTS,
                    payload: res.data,
                });
            });
    };
