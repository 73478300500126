import AppButton from '../../components/AppButton';
import { Redirect, useHistory } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import GetOffers from '../templateSearch/GetOffers';
import { Form } from 'react-bootstrap';
import AppInput from '../../components/AppInput';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { resetAuthState } from '../../../actions/authActions';
import { StateType } from '../../../reducers';
import { useEffect } from 'react';
import request from '../../../helpers/request';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Error } from '../../../assets/images/icons';
import { getAllOffers } from '../../../actions/offerActions';

interface IProps {}

const ForgotPassword = (props: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector((state: StateType) => state.auth);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();

    useEffect(() => {
        dispatch(resetAuthState());
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    });

    if (auth.authenticated) {
        return <Redirect to="/" />;
    }

    return (
        <MainLayout>
            <div className="container-xl py-5 px-sm-5">
                <div className="order-confirm-container py-5">
                    {error ? (
                        <div
                            className="d-flex flex-column align-items-center mx-auto"
                            style={{ maxWidth: '32rem' }}
                        >
                            <Error className="mb-5" fontSize={86} />
                            <h2>OOPS PROBLEM OCCURED</h2>
                            <p className="text-center text-14 text-gray">
                                This email {error} is not associated with any of
                                our registered emails. Please try again.
                            </p>
                            <AppButton
                                variant="primary"
                                onClick={() => setError(undefined)}
                                className="w-100"
                            >
                                try again
                            </AppButton>
                        </div>
                    ) : (
                        <div
                            style={{ maxWidth: '32rem' }}
                            className="d-flex flex-column align-items-center mx-auto"
                        >
                            <h2>FORGOT PASSWORD?</h2>
                            <p className="text-center text-14 text-gray">
                                Please provide an email associated with
                                OxStrength and we will send you the instruction
                                to recover your password
                            </p>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values) => {
                                    setLoading(true);
                                    request
                                        .forgotPassword(values.email)
                                        .then((res: AxiosResponse) => {
                                            toast.success(res.data.message, {
                                                position:
                                                    toast.POSITION.TOP_CENTER
                                            });

                                            setLoading(false);

                                            history.push('/login');
                                        })
                                        .catch((err: AxiosError) => {
                                            if (
                                                err.response?.data?.message ===
                                                'User not found'
                                            ) {
                                                setError(values.email);
                                            } else {
                                                toast.error(
                                                    err.response?.data
                                                        ?.message ??
                                                        err.message,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER
                                                    }
                                                );
                                            }

                                            setLoading(false);
                                        });
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    isValid,
                                    errors
                                }) => {
                                    return (
                                        <Form
                                            className="d-flex flex-column w-100"
                                            noValidate
                                            onSubmit={handleSubmit}
                                        >
                                            <AppInput
                                                type="email"
                                                label="Email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                error={errors.email}
                                                required
                                                placeholder="Email Address"
                                                fill
                                                className="mb-4"
                                            />

                                            <AppButton
                                                variant="primary"
                                                type="submit"
                                                loading={loading}
                                                disabled={loading || !isValid}
                                            >
                                                SEND INSTRUCTIONS
                                            </AppButton>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    )}
                </div>
            </div>
            <GetOffers offerType="all" />
            <GetNotified />
        </MainLayout>
    );
};

export default ForgotPassword;
