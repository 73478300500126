import NavBar from './NavBar';
import Footer from './Footer';
import Sidebar from 'react-sidebar';
import SideBarContent from './SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../actions/uiActions';

interface IProps {
    children: React.ReactNode;
    backgroundColor?: string;
    hideNavBorder?: boolean;
    navBackgroundColor?: string;
}

const MainLayout = (props: IProps) => {
    const toggleState = useSelector((state: any) => state.ui.drawer);
    const dispatch = useDispatch();

    const handleSidebarChange = (open: boolean) => {
        dispatch(toggleDrawer(open));
    };

    return (
        <Sidebar
            sidebar={<SideBarContent />}
            pullRight
            open={toggleState}
            onSetOpen={handleSidebarChange}
            styles={{ sidebar: { zIndex: '200' } }}
        >
            <NavBar
                hideNavBorder={props.hideNavBorder}
                navBackgroundColor={props.navBackgroundColor}
            />
            <main style={{ backgroundColor: props.backgroundColor }}>
                {props.children}
            </main>
            <Footer />
        </Sidebar>
    );
};

export default MainLayout;
