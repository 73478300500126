import AppTemplateCard from '../../components/AppTemplateCard';
import TextShadow from '../../components/TextShadow';
import { ReactComponent as ArrowLeft } from '../../../assets/images/ox-arrow-left.svg';
import { ReactComponent as ArrowPrev } from '../../../assets/images/icons/arrow-prev.svg';
import { ReactComponent as ArrowNext } from '../../../assets/images/icons/arrow-next.svg';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { formatNumber } from '../../../utils/functions';
import { defaultImage } from '../../../helpers/constants';

interface IProps {}

const FeaturedTemplates = (props: IProps) => {
    const templates = useSelector(
        (state: StateType) => state.template.featuredTemplates
    );

    const settings = {
        infinite: false,
        centerMode: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <ArrowPrev />,
        nextArrow: <ArrowNext fill="#ed2b30" />,
        responsive: [
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    arrows: false
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    };

    return templates.templates.length ? (
        <div className="featured-container px-md-5 position-relative py-5">
            <div
                className="container-xxl py-5 position-relative"
                style={{ zIndex: 10 }}
            >
                <div className="pb-4">
                    <TextShadow
                        shadowText="Featured Templates"
                        className="mb-2"
                    >
                        Featured <span className="text-red"> Templates </span>
                    </TextShadow>
                    <p className="text-gray text-center">
                        {/* You’ll look at graphs and charts in Task One, how to
                        approach the task and the language needed for a
                        successful answer. */}
                    </p>
                </div>

                <div className="px-xxl-5">
                    <Slider {...settings} className="mx-n3">
                        {templates.templates.map((template, index) => (
                            <Link
                                to={`/template/${template.slug}`}
                                key={index}
                                className="px-3"
                            >
                                <AppTemplateCard
                                    variant="dark"
                                    size="large"
                                    title={template.title ?? 'N/A'}
                                    subtitle={
                                        template.discount?.active &&
                                        !!template.discounted_price &&
                                        !!template.weeks ? (
                                            <>
                                                <s className="text-strike">
                                                    {`Rs. ${formatNumber(
                                                        template.price
                                                    )}`}
                                                </s>{' '}
                                                <br />
                                                Rs.{' '}
                                                {formatNumber(
                                                    template.discounted_price
                                                )}{' '}
                                                / {template.weeks} Weeks
                                            </>
                                        ) : (
                                            `Rs. ${formatNumber(
                                                template.discounted_price
                                            )} / ${template.weeks} Weeks`
                                        )
                                    }
                                    img={
                                        !!template.cover
                                            ? template.cover
                                            : defaultImage
                                    }
                                    key={template._id}
                                    imgTitle={template?.training_category?.name}
                                    imgSubtitle={
                                        template?.template_category?.name
                                    }
                                    ribbonText={
                                        template.discount?.active
                                            ? template.discount.title
                                            : ''
                                    }
                                />
                            </Link>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className="bg-arrow-left">
                <ArrowLeft />
            </div>
        </div>
    ) : (
        <> </>
    );
};

export default FeaturedTemplates;
