import React from 'react';
import {
    FiActivity,
    FiClipboard,
    FiCompass,
    FiCreditCard
} from 'react-icons/fi';

interface IProps {}

const AppJourney = React.forwardRef(
    (props: IProps, ref: React.ForwardedRef<HTMLDivElement>) => {
        return (
            <div className="app-journey-container position-relative" ref={ref}>
                <div className="container-xxl py-5 d-flex justify-content-center flex-wrap">
                    <div className="px-md-3 journey-col mb-2">
                        <FiCompass className="mb-3 text-red" size={24} />
                        <h2 className="text-16 text-light-gray text-700 text-uppercase">
                            Browse Templates
                        </h2>
                    </div>
                    <div className="border-dot d-none d-xl-block px-3">
                        <div />
                    </div>
                    <div className="px-md-3 journey-col mb-2">
                        <FiClipboard className="mb-3 text-red" size={24} />
                        <h2 className="text-16 text-light-gray text-700 text-uppercase">
                            MAKE YOUR CHOICE LIST
                        </h2>
                    </div>
                    <div className="border-dot d-none d-xl-block px-3">
                        <div />
                    </div>
                    <div className="px-md-3 journey-col mb-2">
                        <FiCreditCard className="mb-3 text-red" size={24} />
                        <h2 className="text-16 text-light-gray text-700 text-uppercase">
                            PURCHASE TEMPLATE
                        </h2>
                    </div>
                    <div className="border-dot d-none d-xl-block px-3">
                        <div />
                    </div>
                    <div className="px-md-3 journey-col mb-2">
                        <FiActivity className="mb-3 text-red" size={24} />
                        <h2 className="text-16 text-light-gray text-700 text-uppercase">
                            TRACK PROGRESS
                        </h2>
                    </div>
                </div>
            </div>
        );
    }
);

export default AppJourney;
