import { GET_TESTIMONIALS } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { ITestimonial } from '../interfaces/Testimonial';

export interface ITestimonialState {
    testimonials: Array<ITestimonial>;
}

const initialState: ITestimonialState = {
    testimonials: []
};

const testimonialReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_TESTIMONIALS:
            return {
                ...state,
                testimonials: action.payload
            };

        default:
            return state;
    }
};

export default testimonialReducer;
