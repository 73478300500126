import TextShadow from '../../components/TextShadow';
import { ReactComponent as ArrowLeft } from '../../../assets/images/ox-arrow-left.svg';

interface IProps {}

const About = (props: IProps) => {
    return (
        <div className="about-container px-md-5 position-relative py-5">
            <div
                className="container-xxl my-3 px-xxl-5 position-relative"
                style={{ zIndex: 10 }}
            >
                <div className="pb-4 mb-4">
                    <TextShadow shadowText="About OxStrength" className="mb-4">
                        About <span className="text-red"> OxStrength</span>
                    </TextShadow>
                    <p className="text-gray text-center mb-0">
                        OX strength is an all in one online platform for your
                        coaching needs. We provide a Free consultation call upon
                        signing up to a training program . Unlimited access to
                        an ever growing library of multiple training programs
                        catered for all fitness goals. Whether you’re brand new
                        to lifting with no experience, we have an entire library
                        of exercise tutorials, a training program that fits you,
                        a forum where you can ask questions and get a prompt
                        reply. Occasional online Live webinar where you can ask
                        any question OR if you are an advanced athlete we have a
                        training program for you and all of the above also
                        lectures on periodization , nutrition, and advanced
                        training techniques so you can take your training to the
                        next level all for a very affordable price.
                    </p>
                </div>
            </div>
            <div className="bg-arrow-left">
                <ArrowLeft />
            </div>
        </div>
    );
};

export default About;
