import {
    Row,
    Col,
    Dropdown,
    DropdownButton,
    Button,
    Spinner
} from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppButton from '../../components/AppButton';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import AppTemplateCard from '../../components/AppTemplateCard';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import Offers from '../home/Offers';
import GetOffers from '../templateSearch/GetOffers';
import { BsArrowLeft } from 'react-icons/bs';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import AppInput from '../../components/AppInput';
import React, { useState } from 'react';
import AppRatingCard from '../../components/AppRatingCard';
import Rating from 'react-rating';
import {
    $FIXME,
    defaultImage,
    phoneRegExp,
    relation,
    sortReviewFilter
} from '../../../helpers/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { IAddCartBody, ITemplateOrderBody } from '../../../interfaces/Product';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CoachSelect from './components/CoachSelect';
import CoachingPlanSelect from './components/CoachingPlanSelect';
import TemplateOrderForm from '../templateDetails/TemplateOrderForm';
import AppRadio from '../../components/AppRadio';
import { CoachingPlan, IExpressCheckoutParams } from '../../../interfaces/Cart';
import { addToCart } from '../../../actions/cartActions';
import { ICreateConsultationReviewBody } from '../../../interfaces/Consultation';
import { formatNumber } from '../../../utils/functions';
import {
    createCoachingReivew,
    getCoachingBySlug,
    getCoachingReviews,
    resetCoachingReviews
} from '../../../actions/coachingActions';
import { getCoachingOffers } from '../../../actions/offerActions';
import qs from 'query-string';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Lightbox, { Plugin } from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';

interface IProps {}

const CoachingDetails = (props: IProps) => {
    const [selectedRadio, setSelectedRadio] = useState<string>('yes');

    const [coach, setCoach] = useState<string>();

    const [addReviewOpen, setAddReviewOpen] = useState(false);

    const [coachPlan, setCoachPlan] = useState<CoachingPlan>();

    const [myReview, setMyReview] = useState<ICreateConsultationReviewBody>({});

    const [reviewSort, setReviewSort] = useState(sortReviewFilter[0].option);

    const [isClamped, setIsClamped] = useState(false);

    const [readMore, setReadMore] = useState(false);

    const [openLightBox, setOpenLightBox] = useState(false);

    const {
        coaching,
        coachingLoading,
        coachingReviews: reviews,
        coachingReviewsLoading
    } = useSelector((state: StateType) => state.coaching);

    const auth = useSelector((state: StateType) => state.auth);

    const { carts } = useSelector((state: StateType) => state.cart.carts);

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const location = useLocation();

    const { slug }: $FIXME = useParams();

    const reviewResultsPerPage = 2;

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Search',
            link: '/coaching'
        },
        {
            label: 'Coaching Details',
            link: `/coaching/${slug}`,
            active: true
        }
    ];

    useEffect(() => {
        dispatch(getCoachingBySlug(slug));
        dispatch(getCoachingOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch, slug]);

    useEffect(() => {
        if (coaching._id)
            dispatch(
                getCoachingReviews(coaching._id, {
                    sort: reviewSort,
                    resultsPerPage: reviewResultsPerPage.toString()
                })
            );
    }, [coaching._id, reviewSort, dispatch]);

    const isReviewValid = () => {
        if (myReview.review && myReview.stars) {
            return true;
        }

        return false;
    };

    const handleRating = (value: number) => {
        setMyReview({
            ...myReview,
            stars: value.toString()
        });
    };

    const handleTemplateRadioChange = (value: string) => {
        setSelectedRadio(value);
    };

    const handleReviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMyReview({
            ...myReview,
            review: e.target.value
        });
    };

    const handleReviewSubmit = () => {
        dispatch(
            createCoachingReivew({
                ...myReview,
                coaching: coaching._id
            })
        ).then(() => {
            if (coaching._id) {
                dispatch(resetCoachingReviews());
                dispatch(
                    getCoachingReviews(coaching._id, {
                        sort: reviewSort,
                        resultsPerPage: reviewResultsPerPage.toString()
                    })
                );
                setMyReview({ stars: undefined, review: '' });
            }
        });
    };

    const handleLoadMoreReviews = () => {
        if (reviews.currentPage !== reviews.totalPage)
            if (coaching._id)
                dispatch(
                    getCoachingReviews(coaching._id, {
                        sort: reviewSort,
                        page: (reviews.currentPage + 1).toString(),
                        resultsPerPage: reviewResultsPerPage.toString()
                    })
                );
    };

    const handleAddToCart = (id: string, values: ITemplateOrderBody) => {
        if (auth.authenticated) {
            if (selectedRadio === 'yes') {
                const body: IAddCartBody = {
                    model_id: id,
                    onModel: 'Coaching',
                    coaching_plan: coachPlan,
                    coaching_trainer: coach,
                    unit: 1,
                    self_order: true
                };

                dispatch(addToCart(body));
            } else if (selectedRadio === 'no') {
                const body: IAddCartBody = {
                    model_id: id,
                    onModel: 'Coaching',
                    coaching_plan: coachPlan,
                    coaching_trainer: coach,
                    unit: 1,
                    self_order: false,
                    ...values
                };
                dispatch(addToCart(body));
            }
        } else {
            history.push('/login', {
                from: location
            });
        }
    };

    const handleBuyNow = (values: ITemplateOrderBody) => {
        if (coaching._id) {
            if (selectedRadio === 'yes') {
                const body: IExpressCheckoutParams = {
                    model_id: coaching._id,
                    on_model: 'Coaching',
                    plan: coachPlan,
                    trainer: coach
                };

                history.push({
                    pathname: '/express/checkout/cart',
                    search: qs.stringify(body)
                });
            } else if (selectedRadio === 'no') {
                const body: IExpressCheckoutParams = {
                    model_id: coaching._id,
                    on_model: 'Coaching',
                    self_order: false,
                    plan: coachPlan,
                    trainer: coach,
                    ...values
                };
                history.push({
                    pathname: '/express/checkout/cart',
                    search: qs.stringify(body)
                });
            }
        }
    };

    const initialValues: ITemplateOrderBody = {
        name: '',
        mobile_num: '',
        email: '',
        relation: relation[0].label
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        mobile_num: Yup.string()
            .required('Mobile number is required')
            .matches(phoneRegExp, 'Phone number is not valid')
    });

    const handleDropdown = (e: $FIXME) => {
        dispatch(resetCoachingReviews());

        setReviewSort(e);
    };

    return (
        <MainLayout>
            {coaching.slug === slug || !coachingLoading ? (
                <>
                    <div className="container-xxl container-xl t-details-container">
                        <AppBreadcrumbs items={breadcrumbItems} />
                        <div className="mb">
                            <Link to="/coaching">
                                <BsArrowLeft size={24} />
                                <span className="text-oswald text-500 text-14 text-gray">
                                    BACK
                                </span>
                            </Link>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                if (coaching._id && coach && coachPlan)
                                    handleAddToCart(coaching._id, values);
                            }}
                            isInitialValid={false}
                        >
                            {({
                                handleChange,
                                values,
                                isValid,
                                errors,
                                submitForm,
                                setFieldValue
                            }) => (
                                <Row className="mb-5">
                                    <Col md={5} lg={4} xl={3} className="mb-5">
                                        <div style={{ maxWidth: 320 }}>
                                            <AppTemplateCard
                                                title={coaching.title ?? 'N/A'}
                                                subtitle={(() => {
                                                    let isDiscountActive =
                                                        coaching.discount
                                                            ?.active;
                                                    let rate =
                                                        coaching.rates?.find(
                                                            ({ trainer }) =>
                                                                trainer ===
                                                                coach
                                                        );

                                                    switch (coachPlan) {
                                                        case '4 Weeks Plan':
                                                            return isDiscountActive ? (
                                                                <>
                                                                    <s>{`Rs. ${formatNumber(
                                                                        rate?.w4_rate
                                                                    )}`}</s>
                                                                    <br />
                                                                    {`Rs. ${formatNumber(
                                                                        rate?.discounted_w4_rate
                                                                    )} / 4 weeks`}
                                                                </>
                                                            ) : (
                                                                `Rs. ${formatNumber(
                                                                    rate?.w4_rate
                                                                )} / 4 weeks`
                                                            );
                                                        case '12 Weeks Plan':
                                                            return isDiscountActive ? (
                                                                <>
                                                                    <s>{`Rs. ${formatNumber(
                                                                        rate?.w12_rate
                                                                    )}`}</s>
                                                                    <br />
                                                                    {`Rs. ${formatNumber(
                                                                        rate?.discounted_w12_rate
                                                                    )} / 12 weeks`}
                                                                </>
                                                            ) : (
                                                                `Rs. ${formatNumber(
                                                                    rate?.w12_rate
                                                                )} / 4 weeks`
                                                            );
                                                        case '24 Weeks Plan':
                                                            return isDiscountActive ? (
                                                                <>
                                                                    <s>{`Rs. ${formatNumber(
                                                                        rate?.w24_rate
                                                                    )}`}</s>
                                                                    <br />
                                                                    {`Rs. ${formatNumber(
                                                                        rate?.discounted_w24_rate
                                                                    )} / 24 weeks`}
                                                                </>
                                                            ) : (
                                                                `Rs. ${formatNumber(
                                                                    rate?.w24_rate
                                                                )} / 24 weeks`
                                                            );
                                                        default:
                                                            return '';
                                                    }
                                                })()}
                                                img={
                                                    !!coaching.cover
                                                        ? coaching.cover
                                                        : defaultImage
                                                }
                                                ribbonText={
                                                    coaching.discount?.active
                                                        ? coaching.discount
                                                              .title
                                                        : ''
                                                }
                                                onClick={() => setOpenLightBox(true)}
                                            />

                                            <div className="d-flex mx-n1">
                                                <div className="w-100 mx-1 mb-2">
                                                    <AppButton
                                                        variant="outline-secondary"
                                                        className="template-btn "
                                                        disabled={
                                                            (selectedRadio ===
                                                                'no' &&
                                                                !isValid) ||
                                                            !coach ||
                                                            !coachPlan
                                                        }
                                                        onClick={() =>
                                                            handleBuyNow(values)
                                                        }
                                                    >
                                                        Buy Now
                                                    </AppButton>
                                                </div>
                                                <div className="w-100 mx-1 mb-2">
                                                    <AppButton
                                                        variant="primary"
                                                        className="template-btn "
                                                        disabled={
                                                            (selectedRadio ===
                                                                'no' &&
                                                                !isValid) ||
                                                            !coachPlan ||
                                                            !coach
                                                        }
                                                        onClick={
                                                            selectedRadio ===
                                                            'yes'
                                                                ? () => {
                                                                      if (
                                                                          coaching._id &&
                                                                          coach &&
                                                                          coachPlan
                                                                      )
                                                                          handleAddToCart(
                                                                              coaching._id,
                                                                              values
                                                                          );
                                                                  }
                                                                : submitForm
                                                        }
                                                    >
                                                        {carts.filter(
                                                            (cart) =>
                                                                cart.model_id ===
                                                                coaching._id
                                                        ).length > 0
                                                            ? 'Added To Cart'
                                                            : 'Add To Cart'}
                                                    </AppButton>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="template-details-main pl-xxl-5">
                                        <div className="mb-5 pt-3 ">
                                            <Row className="d-flex">
                                                <Col className="mb-3">
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                                                            Coaching Details
                                                        </h2>
                                                        <div className="mb-2 d-flex align-items-end flex-wrap">
                                                            <Rating
                                                                emptySymbol={
                                                                    <AiOutlineStar
                                                                        size={
                                                                            18
                                                                        }
                                                                        className="text-yellow"
                                                                    />
                                                                }
                                                                fullSymbol={
                                                                    <AiFillStar
                                                                        size={
                                                                            18
                                                                        }
                                                                        className="text-yellow"
                                                                    />
                                                                }
                                                                fractions={2}
                                                                readonly
                                                                placeholderRating={
                                                                    0
                                                                }
                                                                initialRating={
                                                                    coaching.average_rating
                                                                }
                                                                className="mr-2"
                                                            />
                                                            <span
                                                                className="text-neutrif text-14 text-gray-1"
                                                                style={{
                                                                    lineHeight: 1.2
                                                                }}
                                                            >
                                                                (
                                                                {
                                                                    coaching.average_rating
                                                                }{' '}
                                                                Stars -{' '}
                                                                {reviews.totalDocuments ??
                                                                    0}{' '}
                                                                Reviews)
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className="text-gray text-14 mb-0">
                                                        Search and find the best
                                                        coach in town
                                                    </p>
                                                </Col>
                                            </Row>

                                            {!readMore ? (
                                                <HTMLEllipsis
                                                    unsafeHTML={
                                                        coaching.description
                                                    }
                                                    maxLine="5"
                                                    ellipsisHTML='...<span class="text-neutrif text-dark-blue text-underline text-600 cursor-pointer ml-2">Read More</span>'
                                                    basedOn="letters"
                                                    onReflow={(state) =>
                                                        setIsClamped(
                                                            state.clamped
                                                        )
                                                    }
                                                    onClick={() =>
                                                        isClamped &&
                                                        setReadMore(true)
                                                    }
                                                />
                                            ) : (
                                                <div
                                                    onClick={() =>
                                                        setReadMore(false)
                                                    }
                                                >
                                                    <article
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                coaching.description ??
                                                                ''
                                                        }}
                                                    />
                                                    <span className="text-neutrif text-dark-blue text-600 text-underline cursor-pointer">
                                                        Read Less
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-5">
                                            <h3 className="text-700 text-20 text-dark-blue text-uppercase mb-3">
                                                SELECT YOUR COACH
                                            </h3>
                                            <Row
                                                noGutters
                                                className="mr-n3"
                                                xs={1}
                                                sm={2}
                                                md={1}
                                                lg={2}
                                                xl={3}
                                            >
                                                {coaching?.trainers?.map(
                                                    (trainer, index) => (
                                                        <div className="pr-3" key={index}>
                                                            <CoachSelect
                                                                coach={trainer}
                                                                value={
                                                                    trainer._id
                                                                }
                                                                selected={coach}
                                                                onClick={(
                                                                    value
                                                                ) => {
                                                                    setCoach(
                                                                        value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </Row>
                                        </div>

                                        {coach ? (
                                            <div className="mb-5">
                                                <h3 className="text-700 text-20 text-dark-blue text-uppercase mb-3">
                                                    SELECT YOUR PLAN
                                                </h3>
                                                <Row
                                                    noGutters
                                                    className="mr-n3"
                                                    md={3}
                                                >
                                                    {coaching.rates?.find(
                                                        (rate) =>
                                                            rate.trainer ===
                                                            coach
                                                    )?.discounted_w4_rate ? (
                                                        <div className="pr-3">
                                                            <CoachingPlanSelect
                                                                title={`Pay upfront for 4 weeks`}
                                                                subtitle={
                                                                    'Rs. ' +
                                                                    formatNumber(
                                                                        coaching.rates?.find(
                                                                            (
                                                                                rate
                                                                            ) =>
                                                                                rate.trainer ===
                                                                                coach
                                                                        )
                                                                            ?.discounted_w4_rate
                                                                    )
                                                                }
                                                                value="4 Weeks Plan"
                                                                selected={
                                                                    coachPlan
                                                                }
                                                                onClick={(
                                                                    value
                                                                ) => {
                                                                    setCoachPlan(
                                                                        value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {coaching.rates?.find(
                                                        (rate) =>
                                                            rate.trainer ===
                                                            coach
                                                    )?.discounted_w12_rate ? (
                                                        <div className="pr-3">
                                                            <CoachingPlanSelect
                                                                title={`Pay upfront for 12 weeks`}
                                                                subtitle={
                                                                    'Rs. ' +
                                                                    formatNumber(
                                                                        coaching.rates?.find(
                                                                            (
                                                                                rate
                                                                            ) =>
                                                                                rate.trainer ===
                                                                                coach
                                                                        )
                                                                            ?.discounted_w12_rate
                                                                    )
                                                                }
                                                                value="12 Weeks Plan"
                                                                selected={
                                                                    coachPlan
                                                                }
                                                                onClick={(
                                                                    value
                                                                ) => {
                                                                    setCoachPlan(
                                                                        value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {coaching.rates?.find(
                                                        (rate) =>
                                                            rate.trainer ===
                                                            coach
                                                    )?.discounted_w24_rate ? (
                                                        <div className="pr-3">
                                                            <CoachingPlanSelect
                                                                title={`Pay upfront for 24 weeks`}
                                                                subtitle={
                                                                    'Rs. ' +
                                                                    formatNumber(
                                                                        coaching.rates?.find(
                                                                            (
                                                                                rate
                                                                            ) =>
                                                                                rate.trainer ===
                                                                                coach
                                                                        )
                                                                            ?.discounted_w24_rate
                                                                    )
                                                                }
                                                                value="24 Weeks Plan"
                                                                selected={
                                                                    coachPlan
                                                                }
                                                                onClick={(
                                                                    value
                                                                ) => {
                                                                    setCoachPlan(
                                                                        value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Row>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <div className="mb-5">
                                            <h3 className="text-700 text-20 text-dark-blue text-uppercase mb-3">
                                                Buying for yourself?
                                            </h3>
                                            <div className="d-flex">
                                                <AppRadio
                                                    value="yes"
                                                    selected={selectedRadio}
                                                    onChange={
                                                        handleTemplateRadioChange
                                                    }
                                                    text="Yes! For Myself"
                                                    labelClassName="text-neutrif text-400 text-14 text-dark-blue"
                                                    className="mr-5"
                                                />
                                                <AppRadio
                                                    value="no"
                                                    selected={selectedRadio}
                                                    onChange={
                                                        handleTemplateRadioChange
                                                    }
                                                    text="For Other"
                                                    labelClassName="text-neutrif text-400 text-14 text-dark-blue"
                                                />
                                            </div>
                                            {selectedRadio === 'no' ? (
                                                <TemplateOrderForm
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            <div className="d-flex mb-3 flex-wrap">
                                                <h3 className="text-700 text-20 text-dark-blue text-uppercase mb-0 mr-3">
                                                    User's Reviews
                                                </h3>
                                                <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                                                    <p className="text-neutrif text-14 text-gray-1 mb-0 mr-2">
                                                        {reviews.totalDocuments ??
                                                            0}{' '}
                                                        reviews
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-3 text-neutrif text-14 text-gray-1">
                                                            Sort By
                                                        </span>
                                                        <DropdownButton
                                                            menuAlign="right"
                                                            title={
                                                                sortReviewFilter.find(
                                                                    (sort) =>
                                                                        sort.option ===
                                                                        reviewSort
                                                                )?.label ??
                                                                sortReviewFilter[0]
                                                                    .label
                                                            }
                                                            variant="outline-secondary"
                                                            size="sm"
                                                            className="template-review-dropdown"
                                                            onSelect={
                                                                handleDropdown
                                                            }
                                                        >
                                                            {sortReviewFilter.map(
                                                                (
                                                                    filter,
                                                                    index
                                                                ) => (
                                                                    <Dropdown.Item
                                                                        eventKey={
                                                                            filter.option
                                                                        }
                                                                        active={
                                                                            filter.option ===
                                                                            reviewSort
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            filter.label
                                                                        }
                                                                    </Dropdown.Item>
                                                                )
                                                            )}
                                                        </DropdownButton>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="template-contents-container p-3">
                                                <div className="mb-3">
                                                    {reviews.reviews.map(
                                                        (review, index) => (
                                                            <AppRatingCard
                                                                img={
                                                                    review.user
                                                                        ?.profile_pic ??
                                                                    'https://www.irishrsa.ie/wp-content/uploads/2017/03/default-avatar.png'
                                                                }
                                                                name={
                                                                    review.user
                                                                        ?.name ??
                                                                    ''
                                                                }
                                                                rating={
                                                                    review.stars ??
                                                                    0
                                                                }
                                                                username={
                                                                    review.user
                                                                        ?.email ??
                                                                    ''
                                                                }
                                                                review={
                                                                    review.review ??
                                                                    ''
                                                                }
                                                                key={index}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    {auth.authenticated ? (
                                                        <Button
                                                            variant="link"
                                                            className="text-oswald text-700 text-16 text-red px-0"
                                                            onClick={() =>
                                                                setAddReviewOpen(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            ADD YOUR REVIEW
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="link"
                                                            className="text-oswald text-700 text-16 text-red px-0"
                                                            onClick={() => {
                                                                history.push(
                                                                    '/login',
                                                                    {
                                                                        from: location
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            LOGIN TO ADD REVIEW
                                                        </Button>
                                                    )}

                                                    {reviews.currentPage ===
                                                        reviews.totalPage ||
                                                    reviews.totalDocuments ===
                                                        0 ? (
                                                        ''
                                                    ) : (
                                                        <Button
                                                            variant="link"
                                                            className="text-neutrif text-600 text-16 text-dark-blue text-underline"
                                                            disabled={
                                                                coachingReviewsLoading
                                                            }
                                                            onClick={
                                                                handleLoadMoreReviews
                                                            }
                                                        >
                                                            Read more reviews
                                                        </Button>
                                                    )}
                                                </div>

                                                {auth.authenticated &&
                                                addReviewOpen ? (
                                                    <div className="mt-3">
                                                        <p className="text-14 text-dark-blue text-600">
                                                            Review text here
                                                        </p>
                                                        <AppInput
                                                            type="textarea"
                                                            placeholder="Your Review Here"
                                                            fill
                                                            as="textarea"
                                                            rows={5}
                                                            value={
                                                                myReview.review
                                                            }
                                                            onChange={
                                                                handleReviewChange
                                                            }
                                                        />
                                                        <div className="d-flex flex-wrap">
                                                            <div className="d-flex align-items-center flex-grow-1 mb-3 flex-wrap">
                                                                <p className="mb-0 text-600 text-14 mr-4">
                                                                    Rate this
                                                                    template
                                                                </p>
                                                                <Rating
                                                                    emptySymbol={
                                                                        <AiOutlineStar
                                                                            size={
                                                                                18
                                                                            }
                                                                            className="text-yellow"
                                                                        />
                                                                    }
                                                                    fullSymbol={
                                                                        <AiFillStar
                                                                            size={
                                                                                18
                                                                            }
                                                                            className="text-yellow"
                                                                        />
                                                                    }
                                                                    fractions={
                                                                        2
                                                                    }
                                                                    initialRating={parseFloat(
                                                                        myReview?.stars ??
                                                                            '0'
                                                                    )}
                                                                    className="mb-1 mr-2"
                                                                    onChange={
                                                                        handleRating
                                                                    }
                                                                />
                                                                {myReview.stars ? (
                                                                    <span className="text-14 text-black">
                                                                        You
                                                                        rated{' '}
                                                                        {
                                                                            myReview.stars
                                                                        }{' '}
                                                                        star out
                                                                        5 star
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                            <div className="mb-3">
                                                                <AppButton
                                                                    variant="primary"
                                                                    onClick={
                                                                        handleReviewSubmit
                                                                    }
                                                                    disabled={
                                                                        !isReviewValid()
                                                                    }
                                                                >
                                                                    Save Your
                                                                    Review
                                                                </AppButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Formik>
                    </div>
                </>
            ) : (
                <div
                    style={{ height: 'calc(100vh - 87px)' }}
                    className="d-flex align-items-center justify-content-center"
                >
                    <Spinner animation="border" />
                </div>
            )}
            <GetOffers offerType="coaching" />
            <Offers />
            <GetNotified />
            <Lightbox
                open={openLightBox}
                close={() => setOpenLightBox(false)}
                slides={ [{src: coaching.cover ?? defaultImage}] }
                carousel={{
                    finite: true,
                }}
                plugins={[Thumbnails as Plugin, Zoom as Plugin]}
            />
        </MainLayout>
    );
};

export default CoachingDetails;
