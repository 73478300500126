import { ActionType } from '../interfaces/ActionType';
import {
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    GET_USER,
    GET_USER_ORDERS,
    UPDATE_PROFILE_FAILURE,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS
} from '../helpers/constants';
import { IUser } from '../interfaces/User';
import { IOrders } from '../interfaces/Cart';

export interface IUserState {
    userLoading: boolean;
    user: IUser;
    orders: IOrders;
    ordersLoading: boolean;
    userUpdateLoading: boolean;
    changePasswordLoading: boolean;
}

const initState: IUserState = {
    userLoading: true,
    user: {},
    orders: { currentPage: 1, totalPage: 1, totalDocuments: 0, orders: [] },
    ordersLoading: false,
    userUpdateLoading: false,
    changePasswordLoading: false
};

const userReducer = (state = initState, action: ActionType) => {
    switch (action.type) {
        case GET_USER:
            return {
                userLoading: false,
                user: action.payload
            };
        case GET_USER_ORDERS:
            return {
                ...state,
                ...action.payload
            };
        case UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                userUpdateLoading: true
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                userUpdateLoading: false
            };
        case UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                userUpdateLoading: false
            };
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changePasswordLoading: true
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordLoading: false
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordLoading: false
            };
        default:
            return state;
    }
};

export default userReducer;
