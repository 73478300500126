import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { GET_PROFILE_COUNT } from '../helpers/constants';
import request from '../helpers/request';

export const getProfileSubscriptionCount = () => async (dispatch: Dispatch) => {
    request.getProfileSubscriptionCount().then((res: AxiosResponse) => {
        dispatch({
            type: GET_PROFILE_COUNT,
            payload: res.data
        });
    });
};
