import { FaCheckCircle } from 'react-icons/fa';
import { $FIXME } from '../../../../helpers/constants';
import { CoachingPlan, ConsultationPlan } from '../../../../interfaces/Cart';

interface IProps {
    value?: CoachingPlan | ConsultationPlan;
    onClick?: (value?: $FIXME) => void;
    selected?: string;
    title?: string;
    subtitle?: string;
}

const CoachingPlanSelect = ({
    value,
    onClick,
    selected,
    title,
    subtitle
}: IProps) => {
    return (
        <div
            className={
                'p-3 d-flex align-items-center cursor-pointer mb-3 w-100 ' +
                (value && selected && selected === value
                    ? 'coach-active'
                    : 'coach-inactive')
            }
            onClick={() => onClick && onClick(value)}
        >
            <div className="mr-3 flex-grow-1">
                <p className="text-inter text-500 text-gray-2 text-16 mb-0">
                    {title}
                </p>
                <p className="text-inter text-500 text-gray-2 text-16 mb-0">
                    {subtitle}
                </p>
            </div>
            <FaCheckCircle size={24} />
        </div>
    );
};

export default CoachingPlanSelect;
