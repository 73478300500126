import { useState, useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import request from '../../../helpers/request';
import { AxiosResponse } from 'axios';

const RefundPolicy = () => {
    const [page, setPage] = useState<string>();

    useEffect(() => {
        request
            .getPageDetail('refund-policy')
            .then(({ data }: AxiosResponse) => {
                setPage(data.description);
            });
    });

    return (
        <MainLayout>
            <div className="container-xl py-5 mb-5">
                <h2 className="mb-5 text-uppercase">Refund Policy</h2>

                <div dangerouslySetInnerHTML={{ __html: page ?? '' }} />
            </div>
        </MainLayout>
    );
};

export default RefundPolicy;
