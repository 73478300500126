import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import AppButton from '../../components/AppButton';
import { Link, Redirect } from 'react-router-dom';
import { Motivation } from '../../../assets/images/icons';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';

const EmailVerified = () => {
    const auth = useSelector((state: StateType) => state.auth);

    if (auth.authenticated) {
        return <Redirect to="/" />;
    }

    return (
        <MainLayout>
            <div className="container-xl py-5 px-sm-5">
                <div className="order-confirm-container py-5">
                    <div
                        className="d-flex flex-column align-items-center mx-auto"
                        style={{ maxWidth: '32rem' }}
                    >
                        <Motivation className="mb-5" fontSize={86} />
                        <h2>Email Verified</h2>
                        <p className="text-center text-14 text-gray-2">
                            Congratulations!! we have received your information,
                            to access the application and all its feature,
                            please browse templates or login to the app, Enjoy -
                            Get Fit Everyday.
                        </p>
                        <Link to="/login" className="w-100">
                            <AppButton variant="primary" className="w-100">
                                Proceed to login
                            </AppButton>
                        </Link>
                    </div>
                </div>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default EmailVerified;
