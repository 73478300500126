import {
    GET_TRAINING_CATEGORIES,
    GET_TEMPLATE_CATEGORIES,
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_BRANDS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IProductBrand, IProductCategory } from '../interfaces/Product';
import { ITemplateCategory, ITrainingCategory } from '../interfaces/Template';

export interface ICategoryState {
    trainingCategories: Array<ITrainingCategory>;
    templateCategories: Array<ITemplateCategory>;
    productBrands: Array<IProductBrand>;
    productCategories: Array<IProductCategory>;
}

const initialState: ICategoryState = {
    trainingCategories: [],
    templateCategories: [],
    productBrands: [],
    productCategories: []
};

const categoryReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_TRAINING_CATEGORIES:
            return {
                ...state,
                trainingCategories: action.payload
            };
        case GET_TEMPLATE_CATEGORIES:
            return {
                ...state,
                templateCategories: action.payload
            };

        case GET_PRODUCT_CATEGORIES:
            return {
                ...state,
                productCategories: action.payload
            };

        case GET_PRODUCT_BRANDS:
            return {
                ...state,
                productBrands: action.payload
            };

        default:
            return state;
    }
};

export default categoryReducer;
