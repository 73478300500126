import { GET_PROFILE_COUNT } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IProfileCount } from '../interfaces/Dashboard';

export interface IDashboardState {
    count: IProfileCount;
}

const initialState: IDashboardState = {
    count: {}
};

const dashboardReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_PROFILE_COUNT:
            return {
                count: action.payload
            };
        default:
            return state;
    }
};

export default dashboardReducer;
