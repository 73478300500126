import { CgMenuBoxed } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { StateType } from '../../../reducers';
import queryString from 'query-string';
import { FiEye, FiMessageCircle, FiMessageSquare } from 'react-icons/fi';
import { BiCustomize } from 'react-icons/bi';
import { BsPeople } from 'react-icons/bs';
import { Image } from 'react-bootstrap';
import { defaultImage } from '../../../helpers/constants';
import { useEffect } from 'react';
import { getTrainingCategories } from '../../../actions/categoryActions';
import { getTopAuthor } from '../../../actions/forumActions';

const ForumFilter = () => {
    const { authors } = useSelector((state: StateType) => state.forum);

    const { authUser } = useSelector((state: StateType) => state.auth);

    const { trainingCategories } = useSelector(
        (state: StateType) => state.category
    );

    const history = useHistory();

    const dispatch = useDispatch();

    const location = useLocation();

    const query = queryString.parse(location.search);

    useEffect(() => {
        dispatch(getTrainingCategories());
        dispatch(getTopAuthor());
    }, [dispatch]);

    const menus = [
        {
            label: 'All Discussion',
            icon: <FiMessageSquare className="mr-2 text-dark-blue" size={24} />,
            value: 'latest'
        },
        {
            label: 'Most Viewed',
            icon: <FiEye className="mr-2 text-dark-blue" size={24} />,
            value: 'popular'
        },
        {
            label: 'Most Commented',
            icon: <FiMessageCircle className="mr-2 text-dark-blue" size={24} />,
            value: 'most_comments'
        },

        ...(authUser.id && authUser.is_active_member === true
            ? [
                  {
                      label: 'My Discussions',
                      icon: (
                          <FiMessageCircle
                              className="mr-2 text-dark-blue"
                              size={24}
                          />
                      ),
                      value: 'my_discussions'
                  }
              ]
            : [])
    ];

    return (
        <>
            <div className="shop-filter-container mb-3">
                <div className="shop-filter-head p-3 d-flex justify-content-between">
                    <h2 className="text-600 text-18 text-uppercase mb-0">
                        Menus
                    </h2>
                    <CgMenuBoxed size={18} className="text-gray" />
                </div>

                <div className="py-3 px-3">
                    {menus.map((menu, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                if (menu.value === 'my_discussions') {
                                    delete query['author'];
                                }

                                history.push({
                                    pathname: `/forum`,
                                    search: new URLSearchParams({
                                        ...query,
                                        sort: menu.value
                                    }).toString()
                                });
                            }}
                            className="text-hover-red cursor-pointer"
                        >
                            <div
                                className={`py-2 mb-2 ${
                                    query.sort === menu.value
                                        ? 'pl-2 shop-filter-active'
                                        : ''
                                }`}
                            >
                                {menu.icon}
                                <span className="text-neutrif text-16 text-gray">
                                    {menu.label}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {!!trainingCategories.length && (
                <div className="shop-filter-container mb-3">
                    <div className="shop-filter-head p-3 d-flex justify-content-between">
                        <h2 className="text-600 text-18 text-uppercase mb-0">
                            Categories
                        </h2>
                        <BiCustomize size={18} className="text-gray" />
                    </div>
                    <div className="py-3">
                        {trainingCategories.map((category, index) => (
                            <Link
                                key={index}
                                to={
                                    category._id
                                        ? {
                                              pathname: `/forum`,
                                              search: new URLSearchParams({
                                                  ...query,
                                                  category: category._id
                                              }).toString()
                                          }
                                        : '/forum'
                                }
                                className="d-flex justify-content-between px-3 py-2 text-neutrif text-400 text-gray text-hover-red"
                            >
                                <span
                                    className={`text-16 ${
                                        query.category === category._id
                                            ? 'text-red'
                                            : ''
                                    }`}
                                >
                                    {category.name}
                                </span>
                            </Link>
                        ))}
                    </div>
                </div>
            )}

            {!!authors.length && (
                <div className="shop-filter-container mb-3">
                    <div className="shop-filter-head p-3 d-flex justify-content-between">
                        <h2 className="text-600 text-18 text-uppercase mb-0">
                            Top Users
                        </h2>
                        <BsPeople size={18} className="text-gray" />
                    </div>
                    <div className="py-3">
                        {authors.map((author, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    if (query.sort === 'my_discussions') {
                                        delete query['sort'];
                                    }

                                    if (author._id) {
                                        history.push({
                                            pathname: `/forum`,
                                            search: new URLSearchParams({
                                                ...query,
                                                author: author._id
                                            }).toString()
                                        });
                                    }
                                }}
                                className="d-flex align-items-center px-3 py-2 text-neutrif text-400 text-14 text-gray text-hover-red cursor-pointer"
                            >
                                <Image
                                    width={32}
                                    height={32}
                                    roundedCircle
                                    src={
                                        !!author.profile_pic
                                            ? author.profile_pic
                                            : defaultImage
                                    }
                                    className="mr-3 object-cover"
                                />
                                <span
                                    className={`text-16 ${
                                        query.author === author._id
                                            ? 'text-red'
                                            : ''
                                    }`}
                                >
                                    {author.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ForumFilter;
