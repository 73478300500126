import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import {
    $FIXME,
    GET_COACHING_CONSULTATIONS,
    GET_CONSULTAION_REVIEWS,
    GET_CONSULTATION,
    GET_CONSULTATIONS,
    GET_SUBSCRIBED_CONSULTATIONS,
    GET_TEMPLATE_CONSULTATIONS,
    GET_USER_CONSULTATIONS,
    RESET_CONSULTATION_REVIEWS,
    RESET_CONSULTATION_STATE,
    UPDATE_SUBSCRIBED_CONSULTATION
} from '../helpers/constants';
import request from '../helpers/request';
import { ISubscribedCoachingsParams } from '../interfaces/Coaching';
import {
    IConsultataionReviewParams,
    IConsultationParams,
    ICreateConsultationReviewBody,
    ISubscribedConsultationParams,
    ITemplateConsultationParams,
    ISubscribedConsultation
} from '../interfaces/Consultation';
import { setErrors } from './errorActions';

export const getConsultations =
    (params: IConsultationParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_CONSULTATIONS,
            payload: { consultationsLoading: true }
        });

        request.getConsultations(params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_CONSULTATIONS,
                payload: {
                    consultations: res.data,
                    consultationsLoading: false
                }
            });
        });
    };

export const getConsultationBySlug =
    (slug: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_CONSULTATION,
            payload: { consultationLoading: true }
        });

        return request
            .getConsultaionBySlug(slug)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_CONSULTATION,
                    payload: {
                        consultation: res.data,
                        consultationLoading: false
                    }
                });

                return Promise.resolve(res.data?._id);
            })
            .catch(() => {
                dispatch({
                    type: GET_CONSULTATION,
                    payload: { consultationLoading: false }
                });

                return Promise.reject();
            });
    };

export const getConsultaionReviews =
    (id: string, params: IConsultataionReviewParams) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: GET_CONSULTAION_REVIEWS,
            payload: { consultationReviewsLoading: true }
        });

        request
            .getConsultationReviews(id, params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_CONSULTAION_REVIEWS,
                    payload: {
                        consultationReviews: res.data,
                        consultationReviewsLoading: false
                    }
                });
            });
    };

export const resetConsultationReviews = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_CONSULTATION_REVIEWS
    });
};

export const createConsultaionReivew =
    (body: ICreateConsultationReviewBody) => async (dispatch: $FIXME) => {
        return request
            .createConsultationReview(body)
            .then((res: AxiosResponse) => {
                toast.success('Your review has been posted.', {
                    position: toast.POSITION.TOP_CENTER
                });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch(setErrors(err.response?.data.message));

                return Promise.reject(err.response?.data.message);
            });
    };

export const getSubscribedConsultations =
    (params: ISubscribedConsultationParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_SUBSCRIBED_CONSULTATIONS,
            payload: { subscribedConsultationsLoading: true }
        });

        request
            .getSubscribedConsultations(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_SUBSCRIBED_CONSULTATIONS,
                    payload: {
                        subscribedConsultations: res.data,
                        subscribedConsultationsLoading: false
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: GET_SUBSCRIBED_CONSULTATIONS,
                    payload: { subscribedConsultationsLoading: false }
                });
            });
    };

export const getTemplateConsultations =
    (params: ITemplateConsultationParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_TEMPLATE_CONSULTATIONS,
            payload: { templateConsultationsLoading: true }
        });

        request
            .getTemplateConsultations(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_TEMPLATE_CONSULTATIONS,
                    payload: {
                        templateConsultations: res.data,
                        templateConsultationsLoading: false
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: GET_TEMPLATE_CONSULTATIONS,
                    payload: { templateConsultationsLoading: false }
                });
            });
    };

export const getCoachingConsultations =
    (params: ISubscribedCoachingsParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_COACHING_CONSULTATIONS,
            payload: { coachingConsultationsLoading: true }
        });

        request
            .getSubscribedCoachings(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_COACHING_CONSULTATIONS,
                    payload: {
                        coachingConsultations: res.data,
                        coachingConsultationsLoading: false
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: GET_COACHING_CONSULTATIONS,
                    payload: { coachingConsultationsLoading: false }
                });
            });
    };

export const getUserConsultations =
    (params: ISubscribedConsultationParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_USER_CONSULTATIONS,
            payload: { userConsultationsLoading: true }
        });

        request
            .getSubscribedConsultations(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_USER_CONSULTATIONS,
                    payload: {
                        userConsultations: res.data,
                        userConsultationsLoading: false
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: GET_USER_CONSULTATIONS,
                    payload: { userConsultationsLoading: false }
                });
            });
    };

export const resetConsultationState = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_CONSULTATION_STATE
    });
};

export const updateSubscribedConsultation =
    (body: ISubscribedConsultation) => async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_SUBSCRIBED_CONSULTATION,
            payload: body
        });
    };
