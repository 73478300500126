import { Col, Form, Row } from 'react-bootstrap';
import { ITemplateOrderBody } from '../../../interfaces/Product';
import AppInput from '../../components/AppInput';
import { FormikErrors } from 'formik';
import { $FIXME, relation } from '../../../helpers/constants';
import AppSelect from '../../components/AppSelect';

interface IProps {
    values: ITemplateOrderBody;
    onChange?: React.ChangeEventHandler<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >;
    errors: FormikErrors<ITemplateOrderBody>;
    setFieldValue: (
        field: string,
        value: $FIXME,
        shouldValidate?: boolean
    ) => void;
}

const TemplateOrderForm = ({
    values,
    onChange,
    errors,
    setFieldValue
}: IProps) => {
    return (
        <Form className="pt-4">
            <Row>
                <Col md={6}>
                    <AppInput
                        name="name"
                        type="text"
                        label="Full Name"
                        placeholder="Full Name"
                        required
                        onChange={onChange}
                        value={values.name}
                        isInvalid={!!errors.name}
                        error={errors.name}
                    />
                </Col>

                <Col md={6}>
                    <AppInput
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Email Address"
                        required
                        value={values.email}
                        onChange={onChange}
                        isInvalid={!!errors.email}
                        error={errors.email}
                    />
                </Col>
                <Col md={6}>
                    <AppInput
                        type="text"
                        label="Phone Number"
                        placeholder="Phone Number"
                        required
                        name="mobile_num"
                        value={values.mobile_num}
                        onChange={onChange}
                        isInvalid={!!errors.mobile_num}
                        error={errors.mobile_num}
                    />
                </Col>
                <Col md={6}>
                    <AppSelect
                        label="Relation"
                        placeholder="Relation"
                        required
                        name="relation"
                        selectOptions={relation.map(({ label }) => ({
                            label,
                            value: label
                        }))}
                        value={{
                            label: values.relation,
                            value: values.relation
                        }}
                        error={errors.relation}
                        onChange={(field, value) => {
                            if (value?.value) {
                                setFieldValue(field, value.value);
                            }
                        }}
                    />
                </Col>
                {/* <Col>
                    <AppInput
                        type="text"
                        label="Why you want to buy for them?"
                        placeholder="Why do you want to buy this for other, please explain"
                        as="textarea"
                        rows={5}
                    />
                </Col> */}
            </Row>
        </Form>
    );
};

export default TemplateOrderForm;
