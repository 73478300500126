import { GET_DISTRICTS, GET_PROVINCES } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IDistrict } from '../interfaces/Address';

export interface IAddressState {
    provinces: Array<string>;
    districts: Array<IDistrict>;
}

const initialState: IAddressState = {
    provinces: [],
    districts: []
};

const addressReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_PROVINCES:
            return {
                ...state,
                provinces: action.payload
            };

        case GET_DISTRICTS:
            return {
                ...state,
                districts: action.payload
            };

        default:
            return state;
    }
};

export default addressReducer;
