import {
    GET_DELIVERY_ADDRESS,
    SAVE_DELIVERY_FAILURE,
    SAVE_DELIVERY_REQUEST,
    SAVE_DELIVERY_SUCCESS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IDeliveryAddress } from '../interfaces/DeliveryAddress';

export interface IDeliveryAddressState {
    address: Array<IDeliveryAddress>;
    saveDeliveryLoading: boolean;
    saveDeliverySuccess: boolean;
}

const initialState: IDeliveryAddressState = {
    address: [],
    saveDeliveryLoading: false,
    saveDeliverySuccess: false
};

const deliveryAddressReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_DELIVERY_ADDRESS:
            return {
                ...state,
                address: action.payload
            };

        case SAVE_DELIVERY_REQUEST:
            return {
                ...state,
                saveDeliveryLoading: true,
                saveDeliverySuccess: false
            };

        case SAVE_DELIVERY_SUCCESS:
            return {
                ...state,
                saveDeliveryLoading: false,
                saveDeliverySuccess: true
            };

        case SAVE_DELIVERY_FAILURE:
            return {
                ...state,
                saveDeliveryLoading: false,
                saveDeliverySuccess: false
            };

        default:
            return state;
    }
};

export default deliveryAddressReducer;
