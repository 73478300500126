import { Navbar, Nav, NavItem, Modal } from 'react-bootstrap';
import { CgCloseO } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toggleConsultationModal } from '../../actions/uiActions';
import { StateType } from '../../reducers';
import ConsultationDateModal from '../pages/dashboard/ConsultationDateModal';

const DashboardNav = () => {
    const showConsultationModal = useSelector(
        (state: StateType) => state.ui.consultationModal.open
    );

    const dispatch = useDispatch();

    const handleCloseConsultationModal = () =>
        dispatch(toggleConsultationModal({ open: false }));

    return (
        <>
            <div className="container-xxxl mb-5">
                <div className="px-lg-3">
                    <Navbar expand="md" className="p-0 dashboard-nav">
                        <Nav>
                            <NavItem className="p-2 mr-4">
                                <NavLink
                                    exact
                                    to="/dashboard"
                                    activeClassName="dashboard-nav-link-active text-600"
                                    className="text-neutrif text-14 dashboard-nav-link"
                                >
                                    Dashboard
                                </NavLink>
                            </NavItem>
                            <NavItem className="p-2 mr-4">
                                <NavLink
                                    to="/dashboard/packages"
                                    activeClassName="dashboard-nav-link-active text-600"
                                    className="text-neutrif text-14 dashboard-nav-link"
                                >
                                    My Packages
                                </NavLink>
                            </NavItem>
                            <NavItem className="p-2 mr-4">
                                <NavLink
                                    to="/dashboard/trainers"
                                    activeClassName="dashboard-nav-link-active text-600"
                                    className="text-neutrif text-14 dashboard-nav-link"
                                >
                                    My Trainers
                                </NavLink>
                            </NavItem>
                            <NavItem className="p-2 mr-4">
                                <NavLink
                                    to="/dashboard/orders"
                                    activeClassName="dashboard-nav-link-active text-600"
                                    className="text-neutrif text-14 dashboard-nav-link"
                                >
                                    My Orders
                                </NavLink>
                            </NavItem>
                            <NavItem className="p-2 mr-4">
                                <NavLink
                                    to="/dashboard/gifts"
                                    activeClassName="dashboard-nav-link-active text-600"
                                    className="text-neutrif text-14 dashboard-nav-link"
                                >
                                    My Gifts
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <NavItem className="p-2 ml-auto">
                            <NavLink
                                to="/dashboard/settings"
                                activeClassName="dashboard-nav-link-active text-600"
                                className="text-neutrif text-14 dashboard-nav-link"
                            >
                                Settings
                            </NavLink>
                        </NavItem>
                    </Navbar>
                </div>
            </div>
            <Modal
                show={showConsultationModal}
                onHide={handleCloseConsultationModal}
                size="lg"
                contentClassName="rounded"
            >
                <Modal.Header className="px-0 mx-3">
                    <span className="text-600 text-gray-3 text-20">
                        Schedule a Counseling Session
                    </span>
                    <CgCloseO
                        size={28}
                        className="text-gray text-hover-red cursor-pointer"
                        onClick={handleCloseConsultationModal}
                    />
                </Modal.Header>
                <Modal.Body>
                    <ConsultationDateModal />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DashboardNav;
