import TrainerProfileLayout from '../../layouts/TrainerProfileLayout';
import Masonry from 'react-masonry-css';
import { Image, Spinner } from 'react-bootstrap';
import TruncateMarkup from 'react-truncate-markup';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTrainerReview } from '../../../actions/trainerActions';
import { StateType } from '../../../reducers';
// import { RiDeviceRecoverFill } from 'react-icons/ri';

const TrainerReviews = () => {
    const [readMoreState, setReadMoreState] = useState<Array<number>>([]);

    const { trainer, reviews, reviewsLoading } = useSelector(
        (state: StateType) => state.trainer
    );

    const dispatch = useDispatch();

    const breakpointColumnsObj = {
        default: 2,
        700: 1
    };

    useEffect(() => {
        if (trainer._id) dispatch(getTrainerReview(trainer._id));
    }, [dispatch, trainer._id]);

    return (
        <TrainerProfileLayout>
            <div className="bg-white">
                {!reviewsLoading && !reviews.length ? (
                    <p className="p-3">No reviews available</p>
                ) : (
                    <div className="trainer-review py-4 px-lg-3">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="review-masonry-grid"
                            columnClassName="review-masonry-grid_column"
                        >
                            {reviews.map((review, index) => (
                                <div className="trainer-review-card">
                                    {!!review.profile_pic ? (
                                        <img
                                            className="trainer-review-img"
                                            src={review.profile_pic}
                                            alt={review._id}
                                        />
                                    ) : (
                                        ''
                                    )}

                                    <div className="p-3">
                                        <div className="mb-4 trainer-reviews">
                                            {readMoreState.some(
                                                (state) => state === index
                                            ) ? (
                                                <div>
                                                    <p className="white-space-pre-line">
                                                        {review.review}
                                                    </p>

                                                    <span
                                                        onClick={() =>
                                                            setReadMoreState(
                                                                readMoreState.filter(
                                                                    (state) =>
                                                                        state !==
                                                                        index
                                                                )
                                                            )
                                                        }
                                                        className="text-red text-700 text-underline cursor-pointer"
                                                    >
                                                        Show Less
                                                    </span>
                                                </div>
                                            ) : (
                                                <TruncateMarkup
                                                    lines={8}
                                                    ellipsis={
                                                        <span>
                                                            ...
                                                            <span
                                                                onClick={() =>
                                                                    setReadMoreState(
                                                                        [
                                                                            ...readMoreState,
                                                                            index
                                                                        ]
                                                                    )
                                                                }
                                                                className="text-red text-700 text-underline cursor-pointer"
                                                            >
                                                                Read More
                                                            </span>
                                                        </span>
                                                    }
                                                >
                                                    <p className="white-space-pre-line">
                                                        {review.review}
                                                    </p>
                                                </TruncateMarkup>
                                            )}
                                        </div>
                                        <div className="trainer-review-user d-flex flex-row">
                                            <Image
                                                roundedCircle
                                                width={50}
                                                height={50}
                                                className="mr-3"
                                                src={
                                                    !!review.profile_pic
                                                        ? review.profile_pic
                                                        : 'https://icon-library.com/images/default-user-icon/default-user-icon-4.jpg'
                                                }
                                            />
                                            <div className="d-flex flex-column">
                                                <p className="text-gray-2 text-600 text-18 mb-0">
                                                    {review.name}
                                                </p>
                                                <p className="text-gray-1 text-14 mb-0">
                                                    {review.designation}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </div>
                )}

                {reviewsLoading ? (
                    <div
                        className="d-flex justify-content-center"
                        style={{ height: 200 }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </TrainerProfileLayout>
    );
};

export default TrainerReviews;
