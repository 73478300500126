import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    $FIXME,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    GET_USER,
    GET_USER_ORDERS,
    LOGIN_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS
} from '../helpers/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { IAuthUser } from '../reducers/authReducers';
import Cookies from 'universal-cookie';
import {
    IChangePasswordBody,
    IEditUserBody,
    IUserOrderParams
} from '../interfaces/User';
import { setErrors } from './errorActions';
import { toast } from 'react-toastify';

const cookies = new Cookies();

export const getUserProfile = () => async (dispatch: Dispatch) => {
    return request.getUserProfile().then((res: AxiosResponse) => {
        const authData: IAuthUser = {
            name: res.data.name,
            slug: res.data.slug,
            email: res.data.email,
            id: res.data._id,
            profile_pic: res.data.profile_pic,
            address: res.data.address,
            mobile_num: res.data.mobile_num,
            is_active_member: res.data.is_active_member
        };
        cookies.set('authUser', authData);
        dispatch({ type: LOGIN_SUCCESS, payload: authData });
        dispatch({
            type: GET_USER,
            payload: res.data
        });
    });
};

export const editUserProfile =
    (body: IEditUserBody) => async (dispatch: $FIXME) => {
        dispatch({ type: UPDATE_PROFILE_REQUEST });

        return request
            .editUser(body)
            .then(async (res: AxiosResponse) => {
                await dispatch(getUserProfile());

                toast.success('Profile Updated', {
                    position: toast.POSITION.TOP_CENTER
                });

                dispatch({ type: UPDATE_PROFILE_SUCCESS });
            })
            .catch((err: AxiosError) => {
                dispatch({ type: UPDATE_PROFILE_FAILURE });
                dispatch(setErrors(err.message));
            });
    };

export const changePassword =
    (body: IChangePasswordBody) => async (dispatch: $FIXME) => {
        dispatch({ type: CHANGE_PASSWORD_REQUEST });

        return request
            .changePassword(body)
            .then(async (res: AxiosResponse) => {
                await dispatch(getUserProfile());

                toast.success('Your password has been changed', {
                    position: toast.POSITION.TOP_CENTER
                });

                dispatch({ type: CHANGE_PASSWORD_SUCCESS });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch({ type: CHANGE_PASSWORD_FAILURE });
                if (err.response?.status !== 403)
                    dispatch(setErrors(err.message));
                return Promise.reject(err.response?.data?.message);
            });
    };

export const getUserOrders =
    (params: IUserOrderParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_USER_ORDERS,
            payload: {
                ordersLoading: true
            }
        });

        request
            .getUserOrders(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_USER_ORDERS,
                    payload: {
                        orders: res.data,
                        ordersLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_USER_ORDERS,
                    payload: {
                        ordersLoading: false
                    }
                });
            });
    };
