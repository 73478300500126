import { useCallback, useEffect, useState } from 'react';
import request from '../../../../helpers/request';
import { IComments } from '../../../../interfaces/Comment';

interface IProps {
    _id?: string;
    comments_count?: number;
}

const useComments = ({ _id, comments_count }: IProps) => {
    const [comments, setComments] = useState<IComments>();
    const [commentsLoading, setCommentsLoading] = useState(false);

    const resultsPerPage = 2;

    const getSubComments = useCallback(
        (page: number) => {
            if (_id) {
                setCommentsLoading(true);

                request
                    .getSubComments(_id, { resultsPerPage, page })
                    .then((res) => {
                        const data: IComments = res.data;

                        setComments((prev) => ({
                            ...data,
                            comments: [
                                ...(prev?.comments ?? []),
                                ...(data.comments ?? [])
                            ]
                        }));

                        setCommentsLoading(false);
                    })
                    .catch((err) => {
                        setCommentsLoading(false);
                    });
            }
        },
        [_id]
    );

    const like = (id: string) => {
        setComments((prev) => ({
            ...prev,
            comments:
                prev?.comments.map((comment) =>
                    comment._id === id
                        ? {
                              ...comment,
                              has_liked:
                                  comment.has_liked === true ? null : true,
                              dislikes:
                                  comment.has_liked === false
                                      ? (comment.dislikes ?? 1) - 1
                                      : comment.dislikes,
                              likes:
                                  comment.has_liked === true
                                      ? (comment.likes ?? 1) - 1
                                      : (comment.likes ?? 0) + 1
                          }
                        : comment
                ) ?? []
        }));

        request.likeComment(id);
    };

    const dislike = (id: string) => {
        setComments((prev) => ({
            ...prev,
            comments:
                prev?.comments.map((comment) =>
                    comment._id === id
                        ? {
                              ...comment,
                              has_liked:
                                  comment.has_liked === false ? null : false,
                              likes:
                                  comment.has_liked === true
                                      ? (comment.likes ?? 1) - 1
                                      : comment.likes,
                              dislikes:
                                  comment.has_liked === false
                                      ? (comment.dislikes ?? 1) - 1
                                      : (comment.dislikes ?? 0) + 1
                          }
                        : comment
                ) ?? []
        }));

        request.dislikeComment(id);
    };

    const refresh = () => {
        setComments(undefined);

        getSubComments(1);
    };

    useEffect(() => {
        setComments(undefined);
    }, []);

    useEffect(() => {
        getSubComments(1);
    }, [getSubComments]);

    return {
        like,
        dislike,
        commentsLoading,
        getSubComments,
        refresh,
        comments
    };
};

export default useComments;
