import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import Logo from '../../assets/images/logo-invert.png';
// import { getTemplateCategories } from '../../actions/categoryActions';
import { useSelector } from 'react-redux';
import { StateType } from '../../reducers';

interface IProps {}

const Footer = (props: IProps) => {
    const { social } = useSelector((state: StateType) => state.contact.contact);

    return (
        <footer className="app-footer">
            <Container fluid>
                <Row className="mx-md-5 pt-5">
                    <Col md={4} className="brand-container">
                        <img src={Logo} height={72} alt="OxStrength" />
                        <p className="pt-4 brand-desc">
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. */}
                        </p>
                    </Col>
                    <Col>
                        <Row className="pl-md-5">
                            <Col className="d-none d-md-flex">
                                {/* <ul className="foo-nav-links">
                                    <li>
                                        <Link to="/template">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Our Story</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Benefits</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Team</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Careers</Link>
                                    </li>
                                </ul> */}
                            </Col>

                            <Col>
                                <ul className="foo-nav-links">
                                    <li>
                                        <Link to="/template">Templates</Link>
                                    </li>

                                    <li>
                                        <Link to="/coaching">Coachings</Link>
                                    </li>

                                    <li>
                                        <Link to="/consultation">
                                            Consultations
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/shop">Ox Shop</Link>
                                    </li>
                                </ul>
                            </Col>

                            <Col>
                                <ul className="foo-nav-links">
                                    <li>
                                        <Link to="/about">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/our-story">
                                            Our Story & Benefits
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact Us</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mx-md-5 py-3 policy-container">
                    <Col
                        md={6}
                        className="policy-links d-flex align-items-center"
                    >
                        <Link to="/terms-of-service" className="mr-5">
                            Terms of Service
                        </Link>

                        <Link to="/privacy-policy" className="mr-5">
                            Privacy Policy
                        </Link>

                        <Link to="/refund-policy">Refund Policy</Link>
                    </Col>
                    <Col className="d-flex justify-content-md-end justify-content-center py-3">
                        {!!social?.facebook ? (
                            <a
                                href={social.facebook}
                                target="#"
                                className="mx-4"
                            >
                                <FaFacebookF size={24} />
                            </a>
                        ) : (
                            ''
                        )}

                        {!!social?.twitter ? (
                            <a
                                href={social.twitter}
                                target="#"
                                className="mx-4"
                            >
                                <FaTwitter size={24} />
                            </a>
                        ) : (
                            ''
                        )}

                        {!!social?.instagram ? (
                            <a
                                href={social.instagram}
                                target="#"
                                className="mx-4"
                            >
                                <FaInstagram size={24} />
                            </a>
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
                <div className="mx-md-5 d-flex justify-content-center foo-trademark">
                    <p className="py-4 m-0">
                        Copyright © 2021. OxStrength. All rights reserved.
                    </p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
