import { Row, Col, Image, Button, Form, Spinner } from 'react-bootstrap';
import SettingsNav from './SettingsNav';
import { storage } from '../../../configs/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { setErrors } from '../../../actions/errorActions';
import { editUserProfile, getUserProfile } from '../../../actions/userActions';
import { AiFillCamera } from 'react-icons/ai';
import { useRef, useState } from 'react';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import { IEditUserBody } from '../../../interfaces/User';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { phoneRegExp } from '../../../helpers/constants';
import AppSelect from '../../components/AppSelect';
import {
    getDistrictByProvince,
    getProvinces
} from '../../../actions/addressActions';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import AppDatePicker from '../../components/AddDatePicker';
import DashboardLayout from '../../layouts/DashboardLayout';

interface IProps {}

const ProfileSettings = (props: IProps) => {
    const auth = useSelector((state: StateType) => state.auth);

    const [imageUploading, setImageUploading] = useState(false);

    const updateProfileLoading = useSelector(
        (state: StateType) => state.user.userUpdateLoading
    );

    const { name, mobile_num, gender, address, social, birth_date, about } =
        useSelector((state: StateType) => state.user.user);

    const { provinces, districts } = useSelector(
        (state: StateType) => state.address
    );

    const inputRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    const handleFireBaseUpload = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setImageUploading(true);
        // if (auth.authUser?.profile_pic) {
        //     const imageRef = storage.refFromURL(auth.authUser.profile_pic);

        //     if (imageRef) await imageRef.delete();
        // }
        const uploadTask = storage
            .ref(`/images/${auth.authUser.slug}`)
            .put(e.target.files![0]);

        uploadTask.on(
            'state_changed',
            (snapShot) => {},
            (err) => {
                dispatch(setErrors('Image upload failed'));
                setImageUploading(false);
            },
            () => {
                storage
                    .ref('images')
                    .child(auth.authUser.slug!)
                    .getDownloadURL()
                    .then((fireBaseUrl) => {
                        dispatch(editUserProfile({ profile_pic: fireBaseUrl }));
                        setImageUploading(false);
                    })
                    .catch((err) => {
                        dispatch(setErrors('Image upload failed'));
                        setImageUploading(false);
                    });
            }
        );
    };

    const initialValues: IEditUserBody = {
        name: name ?? '',
        mobile_num: mobile_num?.toString() ?? '',
        about: about ?? '',
        gender: gender ?? '',
        birth_date: birth_date,
        address: {
            country:
                address?.country || address?.country !== ''
                    ? address?.country
                    : 'Nepal',
            province: address?.province ?? '',
            district: address?.district ?? '',
            area: address?.area ?? '',
            address_line1: address?.address_line1 ?? ''
        },
        social: {
            facebook: social?.facebook ?? '',
            twitter: social?.twitter ?? '',
            instagram: social?.instagram ?? '',
            linkedid: social?.linkedid ?? '',
            tiktok: social?.tiktok ?? ''
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        mobile_num: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Phone number is required'),
        gender: Yup.string().required('Gender is required'),
        birth_date: Yup.date().required('Birth date is required'),
        address: Yup.object().shape({
            province: Yup.string().required('Region is required'),
            district: Yup.string().required('City is required'),
            area: Yup.string().required('Area is required'),
            address_line1: Yup.string().required('Address is required')
        })
    });

    useEffect(() => {
        if (auth.authenticated) {
            dispatch(getUserProfile());
        }

        dispatch(getProvinces());
    }, [dispatch, auth.authenticated]);

    return (
        <DashboardLayout>
            <div className="dashboard-container">
                <Row>
                    <Col xl={3} lg={4} className="mb-5">
                        <SettingsNav />
                    </Col>
                    <Col xl={9} lg={8} className="mb-5">
                        <div className="bg-2 p-3">
                            <p className="text-700 text-18 text-dark-blue">
                                Personal Details
                            </p>
                            <div className="mb-4">
                                <div className="mr-3 position-relative profile-avatar-container">
                                    <Image
                                        src={
                                            auth.authUser?.profile_pic ??
                                            'https://www.irishrsa.ie/wp-content/uploads/2017/03/default-avatar.png'
                                        }
                                        roundedCircle
                                        width={88}
                                        height={88}
                                        className="profile-avatar"
                                    />

                                    {!imageUploading ? (
                                        <input
                                            ref={inputRef}
                                            type="file"
                                            className="image-upload-input"
                                            accept="image/*"
                                            onChange={handleFireBaseUpload}
                                        />
                                    ) : (
                                        ''
                                    )}

                                    {!imageUploading ? (
                                        <div className="image-overlay image-upload-overlay d-flex align-items-center justify-content-center">
                                            <AiFillCamera size={24} />
                                        </div>
                                    ) : (
                                        <div className="image-overlay d-flex align-items-center justify-content-center opacity-75">
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                className="text-white"
                                            />
                                        </div>
                                    )}
                                </div>
                                <Button
                                    className="bg-white text-oswald text-600 text-14 border-0"
                                    onClick={() =>
                                        !imageUploading
                                            ? inputRef.current?.click()
                                            : undefined
                                    }
                                    disabled={imageUploading}
                                >
                                    <span className="text-red">
                                        CHANGE PICTURE
                                    </span>
                                </Button>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    dispatch(editUserProfile(values));
                                }}
                                isInitialValid={false}
                                enableReinitialize
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    isValid,
                                    errors,
                                    setFieldValue
                                }) => {
                                    const error = errors as IEditUserBody;
                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="mb-4">
                                                <Col md={6} xs={12}>
                                                    <AppInput
                                                        name="name"
                                                        type="text"
                                                        label="Full Name"
                                                        required
                                                        fill
                                                        placeholder="Full name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.name
                                                        }
                                                        error={errors.name}
                                                    />
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <AppInput
                                                        name="mobile_num"
                                                        type="text"
                                                        label="Phone Number"
                                                        required
                                                        fill
                                                        placeholder="Phone number"
                                                        value={
                                                            values.mobile_num
                                                        }
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.mobile_num
                                                        }
                                                        error={
                                                            errors.mobile_num
                                                        }
                                                    />
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <AppSelect
                                                        label="Region"
                                                        required
                                                        placeholder="Select region"
                                                        name="address.province"
                                                        value={{
                                                            label: values
                                                                .address
                                                                ?.province,
                                                            value: values
                                                                .address
                                                                ?.province
                                                        }}
                                                        selectOptions={provinces.map(
                                                            (province) => ({
                                                                label: province,
                                                                value: province
                                                            })
                                                        )}
                                                        onChange={(
                                                            field,
                                                            value
                                                        ) => {
                                                            if (value?.value) {
                                                                dispatch(
                                                                    getDistrictByProvince(
                                                                        value.value
                                                                    )
                                                                );
                                                                setFieldValue(
                                                                    field,
                                                                    value.value
                                                                );
                                                            }
                                                        }}
                                                        disabled={
                                                            !provinces.length
                                                        }
                                                        error={
                                                            error.address
                                                                ?.province
                                                        }
                                                    />
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <AppSelect
                                                        label="City"
                                                        placeholder="Select city"
                                                        required
                                                        name="address.district"
                                                        value={{
                                                            label: values
                                                                .address
                                                                ?.district,
                                                            value: values
                                                                .address
                                                                ?.district
                                                        }}
                                                        selectOptions={districts.map(
                                                            (district) => ({
                                                                label: district.name,
                                                                value: district.name
                                                            })
                                                        )}
                                                        onChange={(
                                                            field,
                                                            value
                                                        ) => {
                                                            setFieldValue(
                                                                field,
                                                                value?.value
                                                            );
                                                        }}
                                                        disabled={
                                                            !districts.length
                                                        }
                                                        error={
                                                            error.address
                                                                ?.district
                                                        }
                                                    />
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <AppInput
                                                        name="address.area"
                                                        type="text"
                                                        label="Area"
                                                        required
                                                        fill
                                                        placeholder="Area"
                                                        value={
                                                            values?.address
                                                                ?.area
                                                        }
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!error.address
                                                                ?.area
                                                        }
                                                        error={
                                                            error.address?.area
                                                        }
                                                    />
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <AppInput
                                                        name="address.address_line1"
                                                        type="text"
                                                        label="Address Line"
                                                        required
                                                        fill
                                                        placeholder="Address Line"
                                                        value={
                                                            values.address
                                                                ?.address_line1
                                                        }
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!error.address
                                                                ?.address_line1
                                                        }
                                                        error={
                                                            error.address
                                                                ?.address_line1
                                                        }
                                                    />
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <AppSelect
                                                        label="Gender"
                                                        placeholder="Select gender"
                                                        required
                                                        name="gender"
                                                        value={{
                                                            label: values.gender,

                                                            value: values.gender
                                                        }}
                                                        selectOptions={[
                                                            {
                                                                label: 'Male',
                                                                value: 'Male'
                                                            },
                                                            {
                                                                label: 'Female',
                                                                value: 'Female'
                                                            },
                                                            {
                                                                label: 'Other',
                                                                value: 'Other'
                                                            }
                                                        ]}
                                                        onChange={(
                                                            field,
                                                            value
                                                        ) => {
                                                            setFieldValue(
                                                                field,
                                                                value?.value
                                                            );
                                                        }}
                                                        error={errors.gender}
                                                    />
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <AppDatePicker
                                                        name="birth_date"
                                                        label="DOB"
                                                        required
                                                        placeholder="Date of birth"
                                                        selected={
                                                            values.birth_date
                                                                ? dayjs(
                                                                      values.birth_date
                                                                  ).toDate()
                                                                : undefined
                                                        }
                                                        value={
                                                            values.birth_date
                                                                ? dayjs(
                                                                      values.birth_date
                                                                  ).format(
                                                                      'MM-DD-YYYY'
                                                                  )
                                                                : undefined
                                                        }
                                                        onChange={(date) => {
                                                            setFieldValue(
                                                                'birth_date',
                                                                dayjs(
                                                                    date
                                                                ).format(
                                                                    'MM-DD-YYYY'
                                                                )
                                                            );
                                                        }}
                                                        error={
                                                            errors.birth_date
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={12} className="mb-4">
                                                    <AppInput
                                                        name="about"
                                                        type="text"
                                                        as="textarea"
                                                        label="About Me (Bio)"
                                                        rows={4}
                                                        fill
                                                        placeholder="Bio"
                                                        onChange={handleChange}
                                                        value={values.about}
                                                    />
                                                </Col>
                                                <Col>
                                                    <p className="text-700 text-18 text-dark-blue">
                                                        Social Information
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="d-flex align-items-center">
                                                        <AppInput
                                                            name="social.facebook"
                                                            type="text"
                                                            label="Facebook"
                                                            fill
                                                            value={
                                                                values.social
                                                                    ?.facebook
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span
                                                            className="text-700 text-16 text-dark-blue text-hover-red cursor-pointer mt-3 ml-4"
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    'social.facebook',
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <span>unlink</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="d-flex align-items-center">
                                                        <AppInput
                                                            name="social.twitter"
                                                            type="text"
                                                            label="Twitter"
                                                            fill
                                                            value={
                                                                values.social
                                                                    ?.twitter
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span
                                                            className="text-700 text-16 text-dark-blue text-hover-red cursor-pointer mt-3 ml-4"
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    'social.twitter',
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <span>unlink</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="d-flex align-items-center">
                                                        <AppInput
                                                            name="social.instagram"
                                                            type="text"
                                                            label="Instagram"
                                                            fill
                                                            value={
                                                                values.social
                                                                    ?.instagram
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span
                                                            className="text-700 text-16 text-dark-blue text-hover-red cursor-pointer mt-3 ml-4"
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    'social.instagram',
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <span>unlink</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="d-flex align-items-center">
                                                        <AppInput
                                                            name="social.linkedid"
                                                            type="text"
                                                            label="LinkedIn"
                                                            fill
                                                            value={
                                                                values.social
                                                                    ?.linkedid
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span
                                                            className="text-700 text-16 text-dark-blue text-hover-red cursor-pointer mt-3 ml-4"
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    'social.linkedid',
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <span>unlink</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="d-flex align-items-center">
                                                        <AppInput
                                                            name="social.tiktok"
                                                            type="text"
                                                            label="TikTok"
                                                            fill
                                                            value={
                                                                values.social
                                                                    ?.tiktok
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span
                                                            className="text-700 text-16 text-dark-blue text-hover-red cursor-pointer mt-3 ml-4"
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    'social.tiktok',
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <span>unlink</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-end mb-5">
                                                <AppButton
                                                    variant="primary"
                                                    type="submit"
                                                    loading={
                                                        updateProfileLoading
                                                    }
                                                    disabled={
                                                        !isValid ||
                                                        updateProfileLoading
                                                    }
                                                >
                                                    Update Information
                                                </AppButton>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </Col>
                </Row>
            </div>
        </DashboardLayout>
    );
};

export default ProfileSettings;
