import request from '../helpers/request';
import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import {
    GIFTED_COACHINGS_FAILURE,
    GIFTED_COACHINGS_REQUEST,
    GIFTED_COACHINGS_SUCCESS,
    GIFTED_CONSULTATIONS_REQUEST,
    GIFTED_CONSULTATIONS_SUCCESS,
    GIFTED_TEMPLATES_FAILURE,
    GIFTED_TEMPLATES_REQUEST,
    GIFTED_TEMPLATES_SUCCESS,
    RECEIVED_COACHINGS_FAILURE,
    RECEIVED_COACHINGS_REQUEST,
    RECEIVED_COACHINGS_SUCCESS,
    RECEIVED_CONSULTATIONS_FAILURE,
    RECEIVED_CONSULTATIONS_REQUEST,
    RECEIVED_CONSULTATIONS_SUCCESS,
    RECEIVED_TEMPLATES_FAILURE,
    RECEIVED_TEMPLATES_REQUEST,
    RECEIVED_TEMPLATES_SUCCESS
} from '../helpers/constants';

export const getGiftedTemplates = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GIFTED_TEMPLATES_REQUEST
    });

    request
        .getGiftedTemplates()
        .then((res: AxiosResponse) => {
            dispatch({
                type: GIFTED_TEMPLATES_SUCCESS,
                payload: res.data
            });
        })
        .catch((err: AxiosError) => {
            dispatch({
                type: GIFTED_TEMPLATES_FAILURE
            });
        });
};

export const getReceivedTemplates = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RECEIVED_TEMPLATES_REQUEST
    });

    request
        .getReceivedTemplates()
        .then((res: AxiosResponse) => {
            dispatch({
                type: RECEIVED_TEMPLATES_SUCCESS,
                payload: res.data
            });
        })
        .catch((err: AxiosError) => {
            dispatch({
                type: RECEIVED_TEMPLATES_FAILURE
            });
        });
};

export const getGiftedCoachings = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GIFTED_COACHINGS_REQUEST
    });

    request
        .getGiftedCoachings()
        .then((res: AxiosResponse) => {
            dispatch({
                type: GIFTED_COACHINGS_SUCCESS,
                payload: res.data
            });
        })
        .catch((err: AxiosError) => {
            dispatch({
                type: GIFTED_COACHINGS_FAILURE
            });
        });
};

export const getReceivedCoachings = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RECEIVED_COACHINGS_REQUEST
    });

    request
        .getReceivedCoachings()
        .then((res: AxiosResponse) => {
            dispatch({
                type: RECEIVED_COACHINGS_SUCCESS,
                payload: res.data
            });
        })
        .catch((err: AxiosError) => {
            dispatch({
                type: RECEIVED_COACHINGS_FAILURE
            });
        });
};

export const getGiftedConsultations = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GIFTED_CONSULTATIONS_REQUEST
    });

    request
        .getGiftedConsultations()
        .then((res: AxiosResponse) => {
            dispatch({
                type: GIFTED_CONSULTATIONS_SUCCESS,
                payload: res.data
            });
        })
        .catch((err: AxiosError) => {
            dispatch({
                type: GIFTED_COACHINGS_FAILURE
            });
        });
};

export const getReceivedConsultations = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RECEIVED_CONSULTATIONS_REQUEST
    });

    request
        .getReceivedConsultations()
        .then((res: AxiosResponse) => {
            dispatch({
                type: RECEIVED_CONSULTATIONS_SUCCESS,
                payload: res.data
            });
        })
        .catch((err: AxiosError) => {
            dispatch({
                type: RECEIVED_CONSULTATIONS_FAILURE
            });
        });
};
