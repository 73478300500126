import request from '../helpers/request';
import { Dispatch } from 'redux';
import { GET_TESTIMONIALS } from '../helpers/constants';
import { AxiosResponse } from 'axios';

export const getTestimonials = () => async (dispatch: Dispatch) => {
    request.getTestimonials().then((res: AxiosResponse) => {
        dispatch({ type: GET_TESTIMONIALS, payload: res.data });
    });
};
