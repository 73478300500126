import {
    Row,
    Col,
    Dropdown,
    DropdownButton,
    Button,
    Spinner
} from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppButton from '../../components/AppButton';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import AppTemplateCard from '../../components/AppTemplateCard';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import Offers from '../home/Offers';
import GetOffers from '../templateSearch/GetOffers';
import { BsArrowLeft } from 'react-icons/bs';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import AppInput from '../../components/AppInput';
import { useState } from 'react';
import AppRatingCard from '../../components/AppRatingCard';
import Rating from 'react-rating';
import SimilarProductList from './SimilarProductList';
import {
    $FIXME,
    defaultImage,
    sortReviewFilter
} from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    createProductReview,
    getProductBySlug,
    getProductReviews,
    getSimilarProducts,
    resetProductReviews
} from '../../../actions/productActions';
import { AppDispatch, StateType } from '../../../reducers';
import { ICreateTemplateReviewBody } from '../../../interfaces/Template';
import { IAddCartBody, Rate } from '../../../interfaces/Product';
import { addToCart } from '../../../actions/cartActions';
import { formatNumber } from '../../../utils/functions';
import AppSelect from '../../components/AppSelect';
import { getProductOffers } from '../../../actions/offerActions';
import ProductCounter from '../../components/ProductCounter';
import qs from 'query-string';
import { IExpressCheckoutParams } from '../../../interfaces/Cart';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Lightbox, { Plugin } from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';

interface IProps {}

const ShopDetails = (props: IProps) => {
    const [myReview, setMyReview] = useState<ICreateTemplateReviewBody>({});

    const [reviewSort, setReviewSort] = useState(sortReviewFilter[0].option);

    const [addReviewOpen, setAddReviewOpen] = useState(false);

    const [isClamped, setIsClamped] = useState(false);

    const [readMore, setReadMore] = useState(false);

    const [selectedQuantity, setSelectedQuantity] = useState(1);

    const { product, productLoading, reviews, reviewsLoading } = useSelector(
        (state: StateType) => state.product
    );

    const [selectedRate, setSelectedRate] = useState<Rate>();

    const auth = useSelector((state: StateType) => state.auth);

    const { carts } = useSelector((state: StateType) => state.cart.carts);

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const location = useLocation();

    const { slug }: $FIXME = useParams();

    const reviewResultsPerPage = 2;

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Search',
            link: '/shop/search'
        },
        {
            label: 'Product',
            link: `/shop/product/${slug}`,
            active: true
        }
    ];

    const [openLightBox, setOpenLightBox] = useState(false);

    useEffect(() => {
        dispatch(getProductBySlug(slug)).then((id) => {
            dispatch(getSimilarProducts(id));
        });
        dispatch(getProductOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch, slug]);

    useEffect(() => {
        if (product._id)
            dispatch(
                getProductReviews(product._id, {
                    sort: reviewSort,
                    resultsPerPage: reviewResultsPerPage.toString()
                })
            );
    }, [product._id, dispatch, reviewSort]);

    useEffect(() => {
        if (product.rates?.length) setSelectedRate(product.rates[0]);
    }, [product.rates]);

    const isReviewValid = () => {
        if (myReview.review && myReview.stars) {
            return true;
        }

        return false;
    };

    const handleRating = (value: number) => {
        setMyReview({
            ...myReview,
            stars: value.toString()
        });
    };

    const handleReviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMyReview({
            ...myReview,
            review: e.target.value
        });
    };

    const handleReviewSubmit = () => {
        dispatch(
            createProductReview({ ...myReview, product_id: product._id })
        ).then(() => {
            if (product._id) {
                dispatch(resetProductReviews());
                dispatch(
                    getProductReviews(product._id, {
                        sort: reviewSort,
                        resultsPerPage: reviewResultsPerPage.toString()
                    })
                );

                setMyReview({ stars: undefined, review: '' });
            }
        });
    };

    const handleLoadMoreReviews = () => {
        if (reviews.currentPage !== reviews.totalPage)
            if (product._id)
                dispatch(
                    getProductReviews(product._id, {
                        sort: reviewSort,
                        page: (reviews.currentPage + 1).toString(),
                        resultsPerPage: reviewResultsPerPage.toString()
                    })
                );
    };

    const handleAddToCart = (id: string) => {
        const body: IAddCartBody = {
            model_id: id,
            onModel: 'Product',
            unit: selectedQuantity,
            self_order: true,
            product_size: selectedRate?.size
        };

        dispatch(addToCart(body)).then(() => {
            setSelectedQuantity(1);
        });
    };

    const handleBuyNow = () => {
        if (product._id) {
            const body: IExpressCheckoutParams = {
                model_id: product._id,
                on_model: 'Product',
                unit: selectedQuantity,
                product_size: selectedRate?.size
            };

            history.push({
                pathname: '/express/checkout/cart',
                search: qs.stringify(body)
            });
        }
    };

    const handleDropdown = (e: $FIXME) => {
        dispatch(resetProductReviews());

        setReviewSort(e);
    };

    const handleRateChange = (rate?: Rate) => {
        setSelectedRate(rate);
    };

    useEffect(() => {
        setSelectedQuantity(1);
    }, [selectedRate]);

    return (
        <MainLayout>
            {product.slug === slug || !productLoading ? (
                <>
                    <div className="container-xxl container-xl t-details-container">
                        <AppBreadcrumbs items={breadcrumbItems} />
                        <div className="mb">
                            <Link to="/shop/search">
                                <BsArrowLeft size={24} />
                                <span className="text-oswald text-500 text-14 text-gray">
                                    BACK
                                </span>
                            </Link>
                        </div>
                        <Row className="mb-5">
                            <Col md={5} lg={4} xl={3} className="mb-5">
                                <div style={{ maxWidth: 320 }}>
                                    <AppTemplateCard
                                        title={product.title ?? 'N/A'}
                                        subtitle={
                                            product.discount?.active ? (
                                                <>
                                                    <s>
                                                        Rs.{' '}
                                                        {formatNumber(
                                                            selectedRate?.price
                                                        )}
                                                    </s>
                                                    <br />
                                                    {'Rs. ' +
                                                        formatNumber(
                                                            selectedRate?.discounted_price
                                                        )}
                                                </>
                                            ) : (
                                                'Rs. ' +
                                                formatNumber(
                                                    selectedRate?.discounted_price
                                                )
                                            )
                                        }
                                        img={
                                            product.images &&
                                            product.images.length > 0
                                                ? product.images[0]
                                                : defaultImage
                                        }
                                        imgWrapperStyle={{ maxWidth: 320 }}
                                        hideImageText
                                        ribbonText={
                                            selectedRate?.stock === 0
                                                ? 'Out of Stock'
                                                : product.discount?.active
                                                ? product.discount.title
                                                : undefined
                                        }
                                        onClick={() => setOpenLightBox(true)}
                                    />
                                    <div className="d-flex mx-n1">
                                        <div className="w-100 mx-1 mb-2">
                                            <AppButton
                                                variant="outline-secondary"
                                                className="template-btn "
                                                disabled={
                                                    !selectedRate?.size ||
                                                    selectedRate.stock === 0
                                                }
                                                onClick={() => handleBuyNow()}
                                            >
                                                Buy Now
                                            </AppButton>
                                        </div>
                                        <div className="w-100 mx-1 mb-2">
                                            <AppButton
                                                variant="primary"
                                                className="template-btn "
                                                disabled={
                                                    !selectedRate ||
                                                    selectedRate.stock === 0
                                                }
                                                onClick={() => {
                                                    if (auth.authenticated) {
                                                        if (product._id)
                                                            handleAddToCart(
                                                                product._id
                                                            );
                                                    } else {
                                                        history.push('/login', {
                                                            from: location
                                                        });
                                                    }
                                                }}
                                            >
                                                {!!product.rates?.length &&
                                                selectedRate?.stock === 0
                                                    ? 'Out of Stock'
                                                    : carts.filter(
                                                          (cart) =>
                                                              cart.model_id ===
                                                              product._id
                                                      ).length > 0
                                                    ? 'Added To Cart'
                                                    : 'Add To Cart'}
                                            </AppButton>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="template-details-main">
                                <div className="mb-5 pt-3 ">
                                    <Row className="d-flex">
                                        <Col lg={8} className="mb-3">
                                            <div className="d-flex align-items-center flex-wrap">
                                                <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                                                    {product.title}
                                                </h2>
                                                <div className="mb-2 d-flex align-items-end flex-wrap">
                                                    <Rating
                                                        emptySymbol={
                                                            <AiOutlineStar
                                                                size={18}
                                                                className="text-yellow"
                                                            />
                                                        }
                                                        fullSymbol={
                                                            <AiFillStar
                                                                size={18}
                                                                className="text-yellow"
                                                            />
                                                        }
                                                        fractions={2}
                                                        readonly
                                                        initialRating={
                                                            product.average_rating
                                                        }
                                                        className="mr-2"
                                                    />
                                                    <span
                                                        className="text-neutrif text-14 text-gray-1"
                                                        style={{
                                                            lineHeight: 1.2
                                                        }}
                                                    >
                                                        (
                                                        {product.average_rating}{' '}
                                                        Stars -{' '}
                                                        {reviews.totalDocuments ??
                                                            0}{' '}
                                                        Reviews)
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="text-gray text-14 mb-0">
                                                {product.product_brand?.name}
                                            </p>
                                        </Col>
                                        <Col className="mb-3">
                                            <div className="d-flex flex-column align-items-lg-end justify-content-lg-end">
                                                {product.discount
                                                    ?.discount_percentage &&
                                                product.discount.active ? (
                                                    <span className="text-neutrif text-14 text-dark-blue">
                                                        {
                                                            product.discount
                                                                .discount_percentage
                                                        }
                                                        % Off
                                                    </span>
                                                ) : (
                                                    ''
                                                )}

                                                <span className="text-neutrif text-14 text-dark-blue text-600">
                                                    {selectedRate?.size}
                                                </span>

                                                <span className="text-neutrif text-24 text-dark-blue">
                                                    Rs.{' '}
                                                    {formatNumber(
                                                        selectedRate?.discounted_price
                                                    )}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    {!readMore ? (
                                        <HTMLEllipsis
                                            unsafeHTML={product.short_info}
                                            maxLine="5"
                                            ellipsisHTML='...<span class="text-neutrif text-dark-blue text-underline text-600 cursor-pointer ml-2">Read More</span>'
                                            basedOn="letters"
                                            onReflow={(state) =>
                                                setIsClamped(state.clamped)
                                            }
                                            onClick={() =>
                                                isClamped && setReadMore(true)
                                            }
                                        />
                                    ) : (
                                        <div onClick={() => setReadMore(false)}>
                                            <article
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        product.short_info ?? ''
                                                }}
                                            />
                                            <span className="text-neutrif text-dark-blue text-600 text-underline cursor-pointer">
                                                Read Less
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <div className="mb-5">
                                    <div
                                        className="mb-3 d-flex flex-wrap justify-content-between mr-n5"
                                        style={{ maxWidth: 482 }}
                                    >
                                        <div
                                            className="d-flex flex-column flex-grow-1 mr-5"
                                            style={{ maxWidth: 190 }}
                                        >
                                            <h2 className="text-20">SIZE</h2>
                                            <AppSelect
                                                label="Size"
                                                required
                                                value={{
                                                    label: selectedRate?.size,
                                                    value: selectedRate?.size
                                                }}
                                                selectOptions={product.rates?.map(
                                                    (rate) => ({
                                                        value: rate.size,
                                                        label: rate.size
                                                    })
                                                )}
                                                onChange={(field, option) =>
                                                    handleRateChange(
                                                        product.rates?.find(
                                                            (item) =>
                                                                item.size ===
                                                                option?.value
                                                        )
                                                    )
                                                }
                                            />
                                        </div>

                                        {!!selectedRate?.servings && (
                                            <div
                                                className="d-flex flex-column flex-grow-1 mr-5"
                                                style={{ maxWidth: 190 }}
                                            >
                                                <h2 className="text-20">
                                                    SERVINGS
                                                </h2>
                                                <AppSelect
                                                    label="Servings"
                                                    required
                                                    value={{
                                                        label: selectedRate?.servings,
                                                        value: selectedRate?.servings
                                                    }}
                                                    selectOptions={product.rates?.map(
                                                        (rate) => ({
                                                            value: rate.servings,
                                                            label: rate.servings
                                                        })
                                                    )}
                                                    onChange={(field, option) =>
                                                        handleRateChange(
                                                            product.rates?.find(
                                                                (item) =>
                                                                    item.servings ===
                                                                    option?.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {!!selectedRate?.stock && (
                                        <div>
                                            <h2 className="text-20">
                                                QUANTITY
                                            </h2>
                                            <ProductCounter
                                                value={
                                                    !selectedRate?.stock
                                                        ? 0
                                                        : selectedQuantity
                                                }
                                                max={selectedRate?.stock}
                                                disabled={
                                                    !!selectedRate &&
                                                    selectedRate?.stock === 0
                                                }
                                                onChange={(value) => {
                                                    setSelectedQuantity(value);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <div className="d-flex mb-3 flex-wrap">
                                        <h3 className="text-700 text-20 text-dark-blue text-uppercase mb-0 mr-3">
                                            User's Reviews
                                        </h3>
                                        <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                                            <p className="text-neutrif text-14 text-gray-1 mb-0 mr-2">
                                                {reviews.totalDocuments ?? 0}{' '}
                                                reviews
                                            </p>
                                            <div className="d-flex align-items-center">
                                                <span className="mr-3 text-neutrif text-14 text-gray-1">
                                                    Sort By
                                                </span>
                                                <DropdownButton
                                                    menuAlign="right"
                                                    title={
                                                        sortReviewFilter.find(
                                                            (sort) =>
                                                                sort.option ===
                                                                reviewSort
                                                        )?.label ??
                                                        sortReviewFilter[0]
                                                            .label
                                                    }
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    className="template-review-dropdown"
                                                    onSelect={handleDropdown}
                                                >
                                                    {sortReviewFilter.map(
                                                        (filter, index) => (
                                                            <Dropdown.Item
                                                                key={index}
                                                                eventKey={
                                                                    filter.option
                                                                }
                                                                active={
                                                                    filter.option ===
                                                                    reviewSort
                                                                }
                                                            >
                                                                {filter.label}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </DropdownButton>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="template-contents-container p-3">
                                        <div className="mb-3">
                                            {reviews.reviews.map(
                                                (review, index) => (
                                                    <AppRatingCard
                                                        img={
                                                            review.user
                                                                ?.profile_pic ??
                                                            'https://www.irishrsa.ie/wp-content/uploads/2017/03/default-avatar.png'
                                                        }
                                                        name={
                                                            review.user?.name ??
                                                            ''
                                                        }
                                                        rating={
                                                            review.stars ?? 0
                                                        }
                                                        username={
                                                            review.user
                                                                ?.email ?? ''
                                                        }
                                                        review={
                                                            review.review ?? ''
                                                        }
                                                        key={index}
                                                    />
                                                )
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-between flex-wrap">
                                            {auth.authenticated ? (
                                                <Button
                                                    variant="link"
                                                    className="text-oswald text-700 text-16 text-red px-0"
                                                    onClick={() =>
                                                        setAddReviewOpen(true)
                                                    }
                                                >
                                                    ADD YOUR REVIEW
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="link"
                                                    className="text-oswald text-700 text-16 text-red px-0"
                                                    onClick={() => {
                                                        history.push('/login', {
                                                            from: location
                                                        });
                                                    }}
                                                >
                                                    LOGIN TO ADD REVIEW
                                                </Button>
                                            )}

                                            {reviews.currentPage ===
                                                reviews.totalPage ||
                                            reviews.totalDocuments === 0 ? (
                                                ''
                                            ) : (
                                                <Button
                                                    variant="link"
                                                    className="text-neutrif text-600 text-16 text-dark-blue text-underline"
                                                    disabled={reviewsLoading}
                                                    onClick={
                                                        handleLoadMoreReviews
                                                    }
                                                >
                                                    Read more reviews
                                                </Button>
                                            )}
                                        </div>
                                        {auth.authenticated && addReviewOpen ? (
                                            <div className="mt-3">
                                                <p className="text-14 text-dark-blue text-600">
                                                    Review text here
                                                </p>
                                                <AppInput
                                                    type="textarea"
                                                    placeholder="Your Review Here"
                                                    fill
                                                    as="textarea"
                                                    rows={5}
                                                    value={myReview.review}
                                                    onChange={
                                                        handleReviewChange
                                                    }
                                                />
                                                <div className="d-flex flex-wrap">
                                                    <div className="d-flex align-items-center flex-grow-1 mb-3 flex-wrap">
                                                        <p className="mb-0 text-600 text-14 mr-4">
                                                            Rate this template
                                                        </p>
                                                        <Rating
                                                            emptySymbol={
                                                                <AiOutlineStar
                                                                    size={18}
                                                                    className="text-yellow"
                                                                />
                                                            }
                                                            fullSymbol={
                                                                <AiFillStar
                                                                    size={18}
                                                                    className="text-yellow"
                                                                />
                                                            }
                                                            fractions={2}
                                                            initialRating={parseFloat(
                                                                myReview?.stars ??
                                                                    '0'
                                                            )}
                                                            className="mb-1 mr-2"
                                                            onChange={
                                                                handleRating
                                                            }
                                                        />
                                                        {myReview.stars ? (
                                                            <span className="text-14 text-black">
                                                                You rated{' '}
                                                                {myReview.stars}{' '}
                                                                star out 5 star
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <AppButton
                                                            variant="primary"
                                                            onClick={
                                                                handleReviewSubmit
                                                            }
                                                            disabled={
                                                                !isReviewValid()
                                                            }
                                                        >
                                                            Save Your Review
                                                        </AppButton>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <SimilarProductList />
                </>
            ) : (
                <div
                    style={{ height: 'calc(100vh - 87px)' }}
                    className="d-flex align-items-center justify-content-center"
                >
                    <Spinner animation="border" />
                </div>
            )}
            <GetOffers offerType="product" />
            <Offers />
            <GetNotified />
            <Lightbox
                open={openLightBox}
                close={() => setOpenLightBox(false)}
                slides={ product.images?.map(url=> { return {src: url} }) }
                carousel={{
                    finite: true,
                }}
                plugins={[Thumbnails as Plugin, Zoom as Plugin]}
            />
        </MainLayout>
    );
};

export default ShopDetails;
