import { ChangeEvent } from 'react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { AiOutlineComment } from 'react-icons/ai';
import { BiDislike, BiLike } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { defaultImage } from '../../../../helpers/constants';
import request from '../../../../helpers/request';
import { IComment } from '../../../../interfaces/Comment';
import { StateType } from '../../../../reducers';
import AppButton from '../../../components/AppButton';
import AppInput from '../../../components/AppInput';
import useComments from './useComments';
import { setErrors } from '../../../../actions/errorActions';
import { AxiosError } from 'axios';
import {
    addCommentCount,
    addReplyCount,
    dislikeComment,
    likeComment
} from '../../../../actions/forumActions';

interface ISubCommentProps {
    comment: IComment;
}

const Comments = (props: ISubCommentProps) => {
    const {
        author,
        content,
        createdAt,
        likes,
        dislikes,
        _id,
        forum,
        comments_count,
        has_liked
    } = props.comment;

    const [comment, setComment] = useState<string>();

    const [saveCommentLoading, setSaveCommentLoading] = useState(false);

    const {
        getSubComments,
        refresh,
        commentsLoading,
        comments,
        like,
        dislike
    } = useComments({
        _id,
        comments_count
    });

    const { profile_pic } = useSelector(
        (state: StateType) => state.auth.authUser
    );

    const dispatch = useDispatch();

    const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    const onComment = () => {
        if (_id && forum && !!comment) {
            setSaveCommentLoading(true);

            request
                .saveComment({
                    forum: forum,
                    comment: _id,
                    content: comment
                })
                .then(() => {
                    refresh();

                    dispatch(addReplyCount(_id));

                    dispatch(addCommentCount(forum));

                    setComment('');

                    setSaveCommentLoading(false);
                })
                .catch((err: AxiosError) => {
                    setSaveCommentLoading(false);

                    dispatch(
                        setErrors(
                            err.response?.data?.message ??
                                'Something went wrong'
                        )
                    );
                });
        }
    };

    return (
        <>
            <hr className="my-0" />

            <div className="pt-4">
                <div className="d-flex mb-3">
                    <div className="d-flex flex-grow-1">
                        <Image
                            roundedCircle
                            width={33}
                            height={33}
                            style={{
                                minHeight: 33,
                                minWidth: 33
                            }}
                            src={
                                !!author?.profile_pic
                                    ? author?.profile_pic
                                    : defaultImage
                            }
                            className="mr-2 object-cover"
                        />
                        <div>
                            <p className="text-600 text-14 mb-0 text-dark-blue">
                                {author?.name}
                            </p>
                            <p className="text-dark text-14 mb-0">{content}</p>
                        </div>
                    </div>

                    <span className="ml-2 text-neutrif text-14 text-gray text-nowrap">
                        {dayjs(createdAt).fromNow()}
                    </span>
                </div>

                <div className="d-flex justify-content-between mb-4">
                    <div className="d-flex align-items-center">
                        <div
                            className={`d-flex align-items-center text-hover-red cursor-pointer mr-3 ${
                                has_liked ? 'text-red' : 'text-gray'
                            }`}
                        >
                            <BiLike
                                size={18}
                                className="mr-1"
                                onClick={() => {
                                    if (_id) dispatch(likeComment(_id));
                                }}
                            />
                            <span className="text-14 text-neutrif mt-1">
                                {likes}
                            </span>
                        </div>
                        <div
                            className={`d-flex align-items-center text-hover-red cursor-pointer ${
                                has_liked === false ? 'text-red' : 'text-gray'
                            }`}
                        >
                            <BiDislike
                                size={18}
                                className="mr-1"
                                onClick={() => {
                                    if (_id) dispatch(dislikeComment(_id));
                                }}
                            />
                            <span className="text-14 text-neutrif mt-1">
                                {dislikes}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex align-items-center text-gray">
                        <AiOutlineComment size={18} className="mr-1" />
                        <span className="text-14 text-neutrif mt-1">
                            {comments_count} Replies
                        </span>
                    </div>
                </div>

                <hr className="my-0" />
            </div>

            <div className="pl-5">
                <div className="d-flex py-4">
                    <Image
                        src={!!profile_pic ? profile_pic : defaultImage}
                        height={28}
                        width={28}
                        style={{ minHeight: 28, minWidth: 28 }}
                        className="rounded-circle object-cover mr-3 mt-2"
                    />

                    <AppInput
                        placeholder="Write a reply"
                        onChange={handleCommentChange}
                        value={comment}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') onComment();
                        }}
                    />

                    {!!comment?.length && (
                        <AppButton
                            variant="primary"
                            className="ml-3"
                            onClick={() => onComment()}
                            loading={saveCommentLoading}
                            disabled={saveCommentLoading || !comment?.length}
                        >
                            Reply
                        </AppButton>
                    )}
                </div>

                {comments?.comments?.map((subComment) => (
                    <div key={subComment._id} className="pb-4">
                        <hr className="pt-4 my-0" />

                        <div className="d-flex mb-3">
                            <div className="d-flex flex-grow-1">
                                <Image
                                    roundedCircle
                                    width={28}
                                    height={28}
                                    style={{
                                        minHeight: 28,
                                        minWidth: 28
                                    }}
                                    src={
                                        !!subComment?.author?.profile_pic
                                            ? subComment.author.profile_pic
                                            : defaultImage
                                    }
                                    className="mr-2 object-cover"
                                />
                                <div>
                                    <p className="text-600 text-14 mb-0 text-dark-blue">
                                        {subComment?.author?.name}
                                    </p>
                                    <p className="text-dark text-14 mb-0">
                                        {subComment?.content}
                                    </p>
                                </div>
                            </div>

                            <span className="ml-2 text-neutrif text-14 text-gray text-nowrap">
                                {dayjs(subComment.createdAt).fromNow()}
                            </span>
                        </div>

                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <div
                                    className={`d-flex align-items-center text-hover-red cursor-pointer mr-3 ${
                                        subComment.has_liked === true
                                            ? 'text-red'
                                            : 'text-gray'
                                    }`}
                                >
                                    <BiLike
                                        size={18}
                                        className="mr-1"
                                        onClick={() => {
                                            if (subComment._id)
                                                like(subComment._id);
                                        }}
                                    />

                                    <span className="text-14 text-neutrif mt-1">
                                        {subComment.likes}
                                    </span>
                                </div>

                                <div
                                    className={`d-flex align-items-center text-hover-red cursor-pointer ${
                                        subComment.has_liked === false
                                            ? 'text-red'
                                            : 'text-gray'
                                    }`}
                                >
                                    <BiDislike
                                        size={18}
                                        className="mr-1"
                                        onClick={() => {
                                            if (subComment._id)
                                                dislike(subComment._id);
                                        }}
                                    />

                                    <span className="text-14 text-neutrif mt-1">
                                        {subComment.dislikes}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {commentsLoading && (
                    <div className="d-flex justify-content-center py-3">
                        <Spinner animation="border" size="sm" />
                    </div>
                )}

                {!commentsLoading &&
                    (comments?.totalPage ?? 0) >
                        (comments?.currentPage ?? 0) && (
                        <p
                            className="text-14 text-gray cursor-pointer mt-2 text-hover-red"
                            onClick={() => {
                                getSubComments(
                                    (comments?.currentPage ?? 0) + 1
                                );
                            }}
                        >
                            <span>View more replies...</span>
                        </p>
                    )}
            </div>
        </>
    );
};

export default Comments;
