import { FunctionComponent } from 'react';
import AppButton from './AppButton';

interface IProps {
    img: string;
    title: string;
    subtitle: string;
    className?: string;
}

const AppTrainerCard: FunctionComponent<IProps> = (props) => {
    return (
        <div
            className={
                'trainer-card-container p-3 mx-auto ' + (props.className ?? '')
            }
        >
            <div className="trainer-card-img-wrapper">
                <img
                    src={props.img}
                    className="trainer-card-img"
                    alt={props.title}
                />
            </div>
            <div className="text-uppercase px-1 pt-2">
                <p
                    className={`text-oswald text-700 mb-1 text-20 text-dark-blue`}
                >
                    {props.title}
                </p>
                <p
                    className={`text-neutrif text-gray text-14`}
                    style={{ height: 21 }}
                >
                    {props.subtitle}
                </p>
            </div>
            <AppButton variant="outline-primary" className="w-100">
                VIEW PROFILE
            </AppButton>
        </div>
    );
};

export default AppTrainerCard;
