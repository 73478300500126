import React, { useEffect } from 'react';
import { Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    getTemplates,
    resetTemplateState
} from '../../../actions/templateActions';
import { StateType } from '../../../reducers';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppButton from '../../components/AppButton';
import AppTemplateCard from '../../components/AppTemplateCard';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import Offers from '../home/Offers';
import GetOffers from './GetOffers';
import TemplateFilter from './TemplateFilter';
import queryString from 'query-string';
import { $FIXME, defaultImage, sortFilter } from '../../../helpers/constants';
import { AppTemplateCardSkeleton } from '../../components/AppSkeleton';
import AppInput from '../../components/AppInput';
import { useDebouncedCallback } from 'use-debounce/lib';
import { formatNumber } from '../../../utils/functions';
import AppNotFound from '../../components/AppNotFound';
import { getTemplateOffers } from '../../../actions/offerActions';

interface IProps {}

const TemplateSearch = (props: IProps) => {
    const { templates, templatesLoading } = useSelector(
        (state: StateType) => state.template
    );

    const dispatch = useDispatch();

    const location = useLocation();

    const history = useHistory();

    const query: $FIXME = queryString.parse(location.search);

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Search',
            link: '/template',
            active: true
        }
    ];

    useEffect(() => {
        dispatch(resetTemplateState());
        dispatch(
            getTemplates({
                template_category: query.category,
                sort: query.sort ?? sortFilter[0].option,
                q: query.query,
                resultsPerPage: '8'
            })
        );
    }, [dispatch, query.category, query.sort, query.query]);

    useEffect(() => {
        dispatch(getTemplateOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    const handleSearch = useDebouncedCallback((value) => {
        history.push({
            pathname: `/template`,
            search: new URLSearchParams({
                ...query,
                query: value
            }).toString()
        });
    }, 500);

    const handleDropdown = (e: $FIXME) => {
        history.push({
            pathname: `/template`,
            search: new URLSearchParams({
                ...query,
                sort: e
            }).toString()
        });
    };

    const handleLoadMore = () => {
        if (templates.currentPage !== templates.totalPage)
            dispatch(
                getTemplates({
                    template_category: query.category,
                    sort: query.sort,
                    page: (templates.currentPage + 1).toString(),
                    q: query.query,
                    resultsPerPage: '8'
                })
            );
    };

    return (
        <MainLayout>
            <div className="container-xxxl t-search-container">
                <div className="px-lg-3">
                    <AppBreadcrumbs items={breadcrumbItems} />
                    <Row className="mb-3 mb-lg-5">
                        <Col>
                            <h2 className="text-700 text-dark-blue text-uppercase">
                                Training Templates
                            </h2>
                            <p className="text-gray text-14">
                                Search and find the best templates in town
                            </p>
                        </Col>
                        <Col lg={4}>
                            <AppInput
                                placeholder="Search training templates"
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => handleSearch(e.target.value)}
                                defaultValue={query.query}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col lg={3} md={12}>
                            <TemplateFilter />
                        </Col>
                        <Col className="template-search-main">
                            <div className="d-flex mb-3 flex-wrap">
                                <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-2 mr-3">
                                    Basic & Advance Training
                                </h2>
                                <div className="d-flex flex-grow-1 justify-content-between align-items-center mb-2">
                                    <p className="text-inter text-500 text-14 text-gray mb-0 mr-2">
                                        Found{' '}
                                        <span className="text-dark-blue text-700">
                                            {templates.totalDocuments}
                                        </span>{' '}
                                        Results
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-3 text-inter text-500 text-14 text-gray">
                                            Sort By
                                        </span>
                                        <DropdownButton
                                            menuAlign="right"
                                            title={
                                                sortFilter.find(
                                                    (sort) =>
                                                        sort.option ===
                                                        query.sort
                                                )?.label ?? sortFilter[0].label
                                            }
                                            variant="outline-secondary"
                                            size="sm"
                                            className="template-filter-dropdown"
                                            onSelect={handleDropdown}
                                        >
                                            {sortFilter.map((filter, index) => (
                                                <Dropdown.Item
                                                    eventKey={filter.option}
                                                    active={
                                                        filter.option ===
                                                        query.sort
                                                    }
                                                >
                                                    {filter.label}
                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>

                            {!templatesLoading &&
                            templates.templates.length === 0 ? (
                                <AppNotFound type="Template" />
                            ) : (
                                <Row lg={4} sm={3} xs={2} className="pr-3">
                                    {templates.templates.map(
                                        (template, index) => (
                                            <Link
                                                to={
                                                    '/template/' + template.slug
                                                }
                                            >
                                                <AppTemplateCard
                                                    title={
                                                        template.title ?? 'N/A'
                                                    }
                                                    subtitle={
                                                        template.discount
                                                            ?.active &&
                                                        !!template.discounted_price &&
                                                        !!template.weeks ? (
                                                            <>
                                                                <s className="text-strike">
                                                                    {`Rs. ${formatNumber(
                                                                        template.price
                                                                    )}`}
                                                                </s>{' '}
                                                                <br />
                                                                Rs.{' '}
                                                                {formatNumber(
                                                                    template.discounted_price
                                                                )}{' '}
                                                                /{' '}
                                                                {template.weeks}{' '}
                                                                Weeks
                                                            </>
                                                        ) : (
                                                            `Rs. ${formatNumber(
                                                                template.discounted_price
                                                            )} / ${
                                                                template.weeks
                                                            } Weeks`
                                                        )
                                                    }
                                                    img={
                                                        !!template.cover
                                                            ? template.cover
                                                            : defaultImage
                                                    }
                                                    key={template._id}
                                                    className="pl-3"
                                                    imgTitle={
                                                        template
                                                            ?.training_category
                                                            ?.name
                                                    }
                                                    imgSubtitle={
                                                        template
                                                            ?.template_category
                                                            ?.name
                                                    }
                                                    ribbonText={
                                                        template.discount
                                                            ?.active
                                                            ? template.discount
                                                                  .title
                                                            : ''
                                                    }
                                                />
                                            </Link>
                                        )
                                    )}
                                </Row>
                            )}

                            {templatesLoading ? (
                                <Row lg={4} sm={3} xs={2} className="pr-3">
                                    {[...Array(8)].map((item, i) => (
                                        <AppTemplateCardSkeleton
                                            key={i}
                                            className="pl-3"
                                        />
                                    ))}
                                </Row>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                    {templates.currentPage === templates.totalPage ||
                    templates.totalDocuments === 0 ? (
                        ''
                    ) : (
                        <div className="mb-5 d-flex justify-content-center justify-content-lg-end">
                            <AppButton
                                variant="outline-primary"
                                onClick={handleLoadMore}
                                loading={templatesLoading}
                            >
                                Load More Templates
                            </AppButton>
                        </div>
                    )}
                </div>
            </div>
            <GetOffers offerType="template" />
            <Offers />
            <GetNotified />
        </MainLayout>
    );
};

export default TemplateSearch;
