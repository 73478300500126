import { SET_ERRORS, CLEAR_ERRORS } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';

export interface IErrorState {
    message?: string;
}

const initialState: IErrorState = {};

const errorReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case SET_ERRORS:
            return {
                message: action.payload
            };
        case CLEAR_ERRORS:
            return {
                message: null
            };

        default:
            return state;
    }
};

export default errorReducer;
