import { Row, Col } from 'react-bootstrap';
import AppButton from '../../components/AppButton';
import DumbbellLeft from '../../../assets/images/dumbbell-left.png';
import DumbbellRight from '../../../assets/images/dumbbell-right.png';
import { Link } from 'react-router-dom';

interface IProps {}

const Offers = (props: IProps) => {
    return (
        <div className="offers-container position-relative">
            <div className="container-xxl py-5">
                <Row>
                    <Col md={6}>
                        <div className="offer-container d-flex flex-column align-items-center text-center py-3 mx-auto">
                            <h2 className="text-uppercase text-center">
                                Purchase Templates
                            </h2>
                            <p className="text-center">
                                {/* You’ll look at graphs and charts in Task One,
                                how to approach the task and the language needed
                                for a successful answer. */}
                            </p>
                            <Link to="/template">
                                <AppButton variant="secondary">
                                    Get Templates
                                </AppButton>
                            </Link>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="offer-container d-flex flex-column align-items-center text-center py-3 mx-auto">
                            <h2 className="text-uppercase">
                                Training Consultation
                            </h2>
                            <p>
                                {/* You’ll look at graphs and charts in Task One,
                                how to approach the task and the language needed
                                for a successful answer. */}
                            </p>
                            <Link to="/trainers">
                                <AppButton variant="outline-primary">
                                    Find Trainers
                                </AppButton>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
            <img
                src={DumbbellLeft}
                className="dumb-left position-absolute"
                alt=""
            />
            <img
                src={DumbbellRight}
                className="dumb-right position-absolute"
                alt=""
            />
        </div>
    );
};

export default Offers;
