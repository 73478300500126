import { Col, Row } from 'react-bootstrap';
import { OfferType } from '../../../interfaces/Offer';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';

interface IProps {
    offerType?: OfferType;
}

const GetOffers = ({ offerType }: IProps) => {
    const offers = useSelector((state: StateType) => {
        switch (offerType) {
            case 'product':
                return state.offer.productOffers;

            case 'template':
                return state.offer.templateOffers;

            case 'coaching':
                return state.offer.coachingOffers;

            case 'consultation':
                return state.offer.consultationOffers;

            default:
                return state.offer.allOffers;
        }
    });

    return offers.offers?.length ? (
        <div className="container-xxl get-offer-container">
            <Row className="py-5">
                {offers.offers.map((offer) => (
                    <Col
                        md={offers.offers?.length === 1 ? 12 : 6}
                        className="mb-3"
                    >
                        <div
                            className="get-offer-card-container d-flex h-100"
                            style={{
                                backgroundImage: `url("${offer.cover}")`
                            }}
                        >
                            <div className="get-offer-desc h-100 d-flex flex-column justify-content-end px-3 px-md-4 px-lg-5 py-3">
                                <p className="text-inter text-500 text-light-gray mb-1">
                                    {offer.title}
                                </p>
                                <p className="text-oswald text-700 text-white">
                                    {offer.info}
                                </p>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    ) : (
        <> </>
    );
};

export default GetOffers;
