import { Formik } from 'formik';
import React, { useState } from 'react';
import { AiFillCamera } from 'react-icons/ai';
import { IForumBody } from '../../../interfaces/Forum';
import AppButton from '../../components/AppButton';
import AppInput from '../../components/AppInput';
import * as Yup from 'yup';
import { Form, Spinner } from 'react-bootstrap';
import { storage } from '../../../configs/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setErrors } from '../../../actions/errorActions';
import { getExtension } from '../../../utils/functions';
import { saveForum } from '../../../actions/forumActions';
import { AppDispatch, StateType } from '../../../reducers';
import AppSelect from '../../components/AppSelect';
import { toast } from 'react-toastify';

interface IProps {
    closeModal: VoidFunction;
    onPostSuccess: () => void;
}

const QuestionModal = (props: IProps) => {
    const [characters, setCharacter] = useState(0);

    const [imageUploading, setImageUploading] = useState(false);

    const [selectedImage, setSelectedImage] = useState<string>();

    const { trainingCategories } = useSelector(
        (state: StateType) => state.category
    );

    const { savingForum } = useSelector((state: StateType) => state.forum);

    const dispatch = useDispatch<AppDispatch>();

    const handleTitleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        setCharacter(e.target.value.length);
    };

    const initialValues: IForumBody = {
        topic: '',
        description: '',
        cover_image: '',
        training_category: ''
    };

    const validationSchema = Yup.object().shape({
        topic: Yup.string().required('Topic is required'),
        description: Yup.string().required('Description is required')
    });

    const handleFireBaseUpload = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files?.length && e.target.files[0].name) {
            let image = e.target.files[0];

            setImageUploading(true);

            const imageName =
                Date.now().toString() + '.' + getExtension(image.name);

            const newImage = new File([image], imageName, { type: image.type });

            const ref = storage.ref(`/forum/${imageName}`);

            const uploadTask = ref.put(newImage);

            uploadTask.on(
                'state_changed',
                (snapShot) => {},
                (err) => {
                    dispatch(setErrors('Image upload failed'));
                    setImageUploading(false);
                },
                () => {
                    storage
                        .ref('/forum')
                        .child(imageName)
                        .getDownloadURL()
                        .then((fireBaseUrl) => {
                            setSelectedImage(fireBaseUrl);
                            setImageUploading(false);
                        })
                        .catch((err) => {
                            dispatch(setErrors('Image upload failed'));
                            setImageUploading(false);
                        });
                }
            );
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                dispatch(
                    saveForum({ ...values, cover_image: selectedImage })
                ).then(() => {
                    toast.success('Your question has been posted', {
                        position: toast.POSITION.TOP_CENTER
                    });

                    props.closeModal();

                    props.onPostSuccess();
                });
            }}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                isValid,
                errors,
                setFieldValue
            }) => (
                <Form onSubmit={handleSubmit}>
                    <AppSelect
                        placeholder="Select training category"
                        name="training_category"
                        selectOptions={trainingCategories.map((category) => ({
                            label: category.name,
                            value: category._id
                        }))}
                        error={errors.training_category}
                        onChange={(field, value) => {
                            if (value?.value) {
                                setFieldValue(field, value.value);
                            }
                        }}
                        disabled={!trainingCategories.length}
                    />

                    <AppInput
                        name="topic"
                        placeholder="Title"
                        onChange={(e) => {
                            if (e.target.value.length <= 300) {
                                handleTitleChange(e);
                                handleChange(e);
                            }
                        }}
                        suffix={
                            <span className="text-gray text-500 text-16 text-neutrif">
                                {characters}/300
                            </span>
                        }
                        value={values.topic}
                        error={errors.topic}
                        isInvalid={!!errors.topic}
                    />

                    <AppInput
                        placeholder="Description"
                        as="textarea"
                        rows={8}
                        name="description"
                        error={errors.description}
                        isInvalid={!!errors.description}
                        onChange={handleChange}
                    />

                    <div className="d-flex align-items-center">
                        {selectedImage ? (
                            <img
                                src={selectedImage}
                                height={90}
                                width={120}
                                className="object-cover"
                                alt="forum"
                            />
                        ) : imageUploading ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            <div className="text-hover-dark mr-3 position-relative">
                                <AiFillCamera
                                    size={24}
                                    className="text-black cursor-pointer"
                                />

                                <input
                                    type="file"
                                    className="image-upload-input"
                                    accept="image/*"
                                    onChange={handleFireBaseUpload}
                                    disabled={imageUploading || !!selectedImage}
                                />
                            </div>
                        )}

                        <div className="flex-grow-1 d-flex justify-content-end">
                            <AppButton
                                type="submit"
                                variant="primary"
                                disabled={
                                    !isValid || imageUploading || savingForum
                                }
                                loading={savingForum}
                            >
                                Post
                            </AppButton>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default QuestionModal;
