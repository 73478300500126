import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import queryString from 'query-string';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { $FIXME } from '../../../helpers/constants';
import { useEffect, useState } from 'react';
import { IEmailVerifyParams } from '../../../interfaces/User';
import request from '../../../helpers/request';
import { Spinner } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { RiErrorWarningLine, RiCheckboxCircleFill } from 'react-icons/ri';
import AppButton from '../../components/AppButton';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';

const EmailVerify = () => {
    const auth = useSelector((state: StateType) => state.auth);

    const [error, setError] = useState(false);
    const [message, setMessage] = useState<string>();
    const [alreadyVerified, setAlreadyVerified] = useState(false);

    const location = useLocation();

    const history = useHistory();

    const query: $FIXME = queryString.parse(location.search);

    useEffect(() => {
        const params: IEmailVerifyParams = {
            email: query.email,
            verification_code: query.verification_code
        };

        if (params.email && params.verification_code) {
            request
                .verifyEmail(params)
                .then((res) => {
                    if (res.data?.already_verified) {
                        setAlreadyVerified(true);
                    } else {
                        history.push('/email-verified');
                    }
                })
                .catch((err: AxiosError) => {
                    setError(true);
                    setMessage(
                        err.response?.data?.message ?? 'Something went wrong.'
                    );
                });
        } else {
            setError(true);
            setMessage('No email or token was found');
        }
    }, [history, query.email, query.verification_code]);

    if (auth.authenticated) {
        return <Redirect to="/" />;
    }

    return (
        <MainLayout>
            <div className="container-xl py-5 px-sm-5">
                <div className="order-confirm-container py-5">
                    <div
                        className="d-flex flex-column align-items-center mx-auto"
                        style={{ maxWidth: '32rem' }}
                    >
                        {error && (
                            <RiErrorWarningLine
                                className="text-danger mb-3"
                                size={86}
                            />
                        )}

                        {alreadyVerified && (
                            <RiCheckboxCircleFill
                                className="text-success mb-3"
                                size={86}
                            />
                        )}

                        {!error && !alreadyVerified && (
                            <h2>Verifying your email......</h2>
                        )}

                        {error || alreadyVerified ? (
                            error ? (
                                <p className="text-center text-dark-blue">
                                    There is problem while verifying your
                                    account, Reason:{' '}
                                    <span className="text-danger text-500">
                                        {message}
                                    </span>
                                </p>
                            ) : alreadyVerified ? (
                                <p className="text-center text-dark-blue">
                                    Email is already verified.
                                </p>
                            ) : (
                                ''
                            )
                        ) : (
                            <Spinner animation="border" />
                        )}

                        {(error || alreadyVerified) && (
                            <AppButton
                                variant="primary"
                                type="submit"
                                onClick={() => {
                                    history.push('/');
                                }}
                            >
                                Go To Homepage
                            </AppButton>
                        )}
                    </div>
                </div>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default EmailVerify;
