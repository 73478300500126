import { FunctionComponent } from 'react';
import { Image } from 'react-bootstrap';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import Rating from 'react-rating';

interface IProps {
    name: string;
    rating: number;
    username: string;
    img: string;
    review: string;
}

const AppRatingCard: FunctionComponent<IProps> = (props) => {
    return (
        <div className={'rating-card-container py-3 d-flex'}>
            <div>
                <Image
                    src={props.img}
                    roundedCircle
                    width={62}
                    height={62}
                    className="mr-3 rating-avatar"
                />
            </div>
            <div className="rating-card-main p-3 flex-grow-1">
                <div className="d-flex flex-wrap">
                    <div className="flex-grow-1">
                        <p className="text-neutrif text-600 text-18 text-dark-blue mb-1">
                            {props.name}
                        </p>
                        <p className="text-neutrif text-14 text-gray mb-1">
                            {props.name} | {props.username}
                        </p>
                    </div>
                    <div>
                        <Rating
                            emptySymbol={
                                <AiOutlineStar
                                    size={18}
                                    className="text-yellow"
                                />
                            }
                            fullSymbol={
                                <AiFillStar size={18} className="text-yellow" />
                            }
                            fractions={2}
                            readonly
                            initialRating={props.rating}
                            className="mb-1"
                        />
                    </div>
                </div>
                <p className="text-neutrif text-gray-1 mt-1">{props.review}</p>
            </div>
        </div>
    );
};

AppRatingCard.defaultProps = {};

export default AppRatingCard;
