import { Row, Col } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import DashboardLayout from '../../layouts/DashboardLayout';
import SuggestedPackages from './SuggestedPackages';
import OrderDetailTable from './OrderDetailTable';

interface IProps {}

const OrderDetail = (props: IProps) => {
    return (
        <DashboardLayout>
            <div className="t-search-container">
                <div className="mt-n4 mb-4">
                    <Link to="/dashboard/orders">
                        <BsArrowLeft size={24} />
                        <span className="text-oswald text-500 text-14 text-gray">
                            BACK
                        </span>
                    </Link>
                </div>

                <Row>
                    <Col className="mb-5" lg={9}>
                        <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3 mr-3 flex-grow-1">
                            Order Detail
                        </h2>

                        <div>
                            <OrderDetailTable />
                        </div>
                    </Col>
                    <Col className="mb-5 mt-lg-5" lg={3}>
                        <SuggestedPackages />
                    </Col>
                </Row>
            </div>
        </DashboardLayout>
    );
};

export default OrderDetail;
