import firebase from 'firebase';
import 'firebase/storage';

var firebaseConfig = {
    apiKey: 'AIzaSyD3QfNuO0-HdX2kj6ygQFXSQnD15X5QSAw',
    authDomain: 'oxstrength-4d9bf.firebaseapp.com',
    projectId: 'oxstrength-4d9bf',
    storageBucket: 'oxstrength-4d9bf.appspot.com',
    messagingSenderId: '920815447434',
    appId: '1:920815447434:web:76dc20e5d1b735a8a2ac73',
    measurementId: 'G-27L73V5JS5'
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

firebase.analytics();

export { storage, firebase as default };
