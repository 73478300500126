import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    INITIATE_ORDER_FAILURE,
    INITIATE_ORDER_REQUEST,
    INITIATE_ORDER_SUCCESS
} from '../helpers/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { setErrors } from './errorActions';
import { IInitiateOrderBody } from '../interfaces/Payment';

export const initiateOrder =
    (body: IInitiateOrderBody) => async (dispatch: Dispatch) => {
        dispatch({ type: INITIATE_ORDER_REQUEST });

        return request
            .initiateOrder(body)
            .then((res: AxiosResponse) => {
                dispatch({ type: INITIATE_ORDER_SUCCESS, payload: res.data });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch({ type: INITIATE_ORDER_FAILURE });
                dispatch(
                    setErrors(
                        err.response?.data.message ?? 'Something went wrong'
                    )
                );

                return Promise.reject();
            });
    };
