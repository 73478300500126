import { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import TrainerProfileLayout from '../../layouts/TrainerProfileLayout';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { Photo } from '../../../assets/images/icons';

const TrainerImages = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const medias = useSelector((state: StateType) => state.media.trainer);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <TrainerProfileLayout>
            {medias.length ? (
                <div className="trainer-gallery-wrapper py-3">
                    <Gallery
                        photos={medias
                            .flatMap((item) => item.media)
                            .map((media, index) => ({
                                src: media.url ?? '',
                                height: index % 2 === 0 ? 4 : 3,
                                width: index % 3 === 0 ? 5 : 4
                            }))}
                        onClick={openLightbox}
                        margin={5}
                    />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={medias
                                        .flatMap((item) => item.media)
                                        .map((x) => ({
                                            source: x.url ?? ''
                                        }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            ) : (
                <div className="py-5 bg-light-gray-1">
                    <div className="d-flex align-items-center flex-column py-5">
                        <Photo />
                        <p className="text-inter text-gray-2">
                            No Images found from trainer. Please check back
                            again.
                        </p>
                    </div>
                </div>
            )}
        </TrainerProfileLayout>
    );
};

export default TrainerImages;
