import { Form } from 'react-bootstrap';
import Select, { StylesConfig, ValueType } from 'react-select';

export interface ISelectOptions {
    label?: string;
    value?: string;
}

interface IProps {
    label?: string;
    placeholder?: string;
    onChange?: (field: string, option: ISelectOptions | null) => void;
    className?: string;
    required?: boolean;
    selectOptions?: Array<ISelectOptions>;
    value?: ValueType<ISelectOptions, false>;
    error?: string;
    name?: string;
    style?: React.CSSProperties;
    defaultValue?: ValueType<ISelectOptions, false>;
    disabled?: boolean;
}

const AppSelect = (props: IProps) => {
    const selectStyles: StylesConfig<ISelectOptions, false> = {
        control: (provided, state) => ({
            ...provided,
            height: 50,
            borderColor: state.isFocused ? '#ed2b30' : '#a4a6a8',
            boxShadow: state.isFocused
                ? '0 0 0 0.2rem rgb(237 43 48 / 25%)'
                : undefined,
            borderRadius: 0,
            '&:hover': {
                borderColor: '#ed2b30',
                transition:
                    'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '2px 12px'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#495057'
        })
    };

    return (
        <Form.Group className={props.className} style={props.style}>
            {props.label ? (
                <Form.Label className="text-neutrif text-600 text-14 text-black">
                    {props.label}
                    {props.required ? (
                        <span className="text-red text-14"> *</span>
                    ) : (
                        ''
                    )}
                </Form.Label>
            ) : (
                ''
            )}
            <Select
                options={props.selectOptions}
                isDisabled={props.disabled}
                placeholder={props.placeholder}
                name={props.name}
                value={props.value}
                defaultValue={props.defaultValue}
                components={{
                    IndicatorSeparator: () => null
                }}
                onChange={(value) => {
                    props.onChange && props.onChange(props.name ?? '', value);
                }}
                styles={selectStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary50: '#e0e0e0',
                        primary25: '#e9ecef',
                        primary: '#1c2026'
                    }
                })}
            />
            <div
                className={'invalid-feedback' + (props.error ? ' d-block' : '')}
            >
                {props.error}
            </div>
        </Form.Group>
    );
};

export default AppSelect;
