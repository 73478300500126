import AppTrainerCard from '../../components/AppTrainerCard';
import TextShadow from '../../components/TextShadow';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { defaultImage } from '../../../helpers/constants';
import { Row } from 'react-bootstrap';

interface IProps {}

const TrainerList = (props: IProps) => {
    const trainers = useSelector((state: StateType) => state.trainer.trainers);

    return (
        <div className="trainer-list-container px-md-5 py-5">
            <div className="container-xxl py-5" style={{ zIndex: 10 }}>
                <div className="pb-4">
                    <TextShadow
                        shadowText="Our Trainers"
                        className="mb-2"
                        variant="light"
                    >
                        Our <span className="text-red"> Trainers</span>
                    </TextShadow>
                </div>

                <Row
                    xs={1}
                    sm={2}
                    md={3}
                    lg={4}
                    xl={5}
                    className="justify-content-around"
                >
                    {trainers.map((trainer) => (
                        <Link
                            to={`trainer/${trainer.slug}/profile`}
                            key={trainer._id}
                        >
                            <AppTrainerCard
                                title={trainer.name ?? ''}
                                subtitle={trainer.training_category?.name ?? ''}
                                img={
                                    !!trainer.profile_pic
                                        ? trainer.profile_pic
                                        : defaultImage
                                }
                            />
                        </Link>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default TrainerList;
