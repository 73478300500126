import AppButton from '../../components/AppButton';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import { Form } from 'react-bootstrap';
import AppInput from '../../components/AppInput';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { resetAuthState } from '../../../actions/authActions';
import { StateType } from '../../../reducers';
import { useEffect } from 'react';
import request from '../../../helpers/request';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { $FIXME } from '../../../helpers/constants';
import queryString from 'query-string';
import { Motivation } from '../../../assets/images/icons';

interface IProps {}

const ResetPassword = (props: IProps) => {
    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();

    const { email, reset_token }: $FIXME = queryString.parse(location.search);

    const auth = useSelector((state: StateType) => state.auth);
    const [loading, setLoading] = useState(false);

    const [resetSuccess, setResetSuccess] = useState(false);

    useEffect(() => {
        dispatch(resetAuthState());
    }, [dispatch]);

    const initialValues = {
        password: '',
        password_confirmation: ''
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required')
    });

    if (auth.authenticated || !email || !reset_token) {
        return <Redirect to="/" />;
    }

    return (
        <MainLayout>
            <div className="container-xl py-5 px-sm-5">
                <div className="order-confirm-container py-5">
                    {resetSuccess ? (
                        <div
                            className="d-flex flex-column align-items-center mx-auto"
                            style={{ maxWidth: '32rem' }}
                        >
                            <Motivation className="mb-5" fontSize={86} />
                            <h2>SUCCESS</h2>
                            <p className="text-center text-14 text-gray">
                                Your new password has been successfully saved.
                                Use this password for login from now on.
                            </p>
                            <AppButton
                                variant="primary"
                                onClick={() => history.push('/login')}
                                className="w-100"
                            >
                                Login
                            </AppButton>
                        </div>
                    ) : (
                        <div
                            style={{ maxWidth: '32rem' }}
                            className="d-flex flex-column align-items-center mx-auto"
                        >
                            <h2>RECOVER PASSWORD</h2>
                            <p className="text-center text-14 text-gray">
                                Create a new password that needs to be different
                                than the previously used and must contain at
                                least 8 characters
                            </p>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={({
                                    password,
                                    password_confirmation
                                }) => {
                                    setLoading(true);
                                    request
                                        .resetPassword({
                                            password,
                                            password_confirmation,
                                            reset_token,
                                            email
                                        })
                                        .then((res: AxiosResponse) => {
                                            setResetSuccess(true);
                                        })
                                        .catch((err: AxiosError) => {
                                            toast.error(
                                                err.response?.data?.message ??
                                                    err.message,
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER
                                                }
                                            );

                                            setLoading(false);
                                        });
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    isValid,
                                    errors
                                }) => {
                                    return (
                                        <Form
                                            className="d-flex flex-column w-100"
                                            noValidate
                                            onSubmit={handleSubmit}
                                        >
                                            <AppInput
                                                type="password"
                                                label="New Password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                isInvalid={!!errors.password}
                                                error={errors.password}
                                                required
                                                placeholder="New Password"
                                                fill
                                            />
                                            <AppInput
                                                type="password"
                                                label="Confirm New Password"
                                                name="password_confirmation"
                                                value={
                                                    values.password_confirmation
                                                }
                                                onChange={handleChange}
                                                isInvalid={
                                                    !!errors.password_confirmation
                                                }
                                                error={
                                                    errors.password_confirmation
                                                }
                                                required
                                                placeholder="Confirm New Password"
                                                fill
                                                className="mb-4"
                                            />

                                            <AppButton
                                                variant="primary"
                                                type="submit"
                                                loading={loading}
                                                disabled={loading || !isValid}
                                            >
                                                CHANGE PASSWORD
                                            </AppButton>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    )}
                </div>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default ResetPassword;
