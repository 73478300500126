import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import AppButton from '../../components/AppButton';
import { Link } from 'react-router-dom';
import { AiFillCloseCircle } from 'react-icons/ai';

const OrderFailed = () => {
    return (
        <MainLayout>
            <div className="container-xl py-5 px-sm-5">
                <div className="order-confirm-container d-flex flex-column align-items-center py-5">
                    <AiFillCloseCircle
                        size={120}
                        className="mb-3 text-danger"
                    />

                    <h2>ORDER FAILED!!</h2>
                    <p
                        className="text-center text-14 text-gray mb-4"
                        style={{ maxWidth: '23rem' }}
                    >
                        There seems to be a problem while placing your order.
                        Please try again.
                    </p>
                    <Link to="/cart">
                        <AppButton variant="secondary">
                            Go to Checkout
                        </AppButton>
                    </Link>
                </div>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default OrderFailed;
