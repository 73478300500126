import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import { Email } from '../../../assets/images/icons';

const RegistrationConfirmation = () => {
    return (
        <MainLayout>
            <div className="container-xl py-5 px-sm-5">
                <div className="order-confirm-container py-5">
                    <div
                        className="d-flex flex-column align-items-center mx-auto"
                        style={{ maxWidth: '32rem' }}
                    >
                        <Email className="mb-5" fontSize={86} />
                        <h2>VERIFY YOUR ACCOUNT</h2>
                        <p className="text-center text-14 text-gray">
                            Congratulations!! we have received your information,
                            to access the application and all its feature,
                            please verify your account and login to app, Enjoy -
                            Get Fit Everyday.
                        </p>
                    </div>
                </div>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default RegistrationConfirmation;
