import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import {
    $FIXME,
    GET_COACHING,
    GET_COACHINGS,
    GET_COACHING_REVIEWS,
    GET_SUBSCRIBED_COACHING,
    GET_SUBSCRIBED_COACHINGS,
    RESET_COACHING_REVIEWS,
    RESET_COACHING_STATE,
    UPDATE_SUBSCRIBED_COACHING
} from '../helpers/constants';
import request from '../helpers/request';
import {
    ICoachingParams,
    ICoachingReviewParams,
    ICreateCoachingReviewBody,
    ISaveUserCoachingProgressBody,
    ISubscribedCoaching,
    ISubscribedCoachingsParams
} from '../interfaces/Coaching';
import { setErrors } from './errorActions';

export const getCoachings =
    (params: ICoachingParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_COACHINGS,
            payload: { coachingsLoading: true }
        });

        request
            .getCoachings(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_COACHINGS,
                    payload: {
                        coachings: res.data,
                        coachingsLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_COACHINGS,
                    payload: { coachingsLoading: false }
                });
            });
    };

export const getCoachingBySlug =
    (slug: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_COACHING,
            payload: { coachingLoading: true }
        });

        return request
            .getCoachingBySlug(slug)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_COACHING,
                    payload: { coaching: res.data, coachingLoading: false }
                });

                return Promise.resolve(res.data?._id);
            })
            .catch(() => {
                dispatch({
                    type: GET_COACHING,
                    payload: { coachingLoading: false }
                });

                return Promise.reject();
            });
    };

export const getCoachingReviews =
    (id: string, params: ICoachingReviewParams) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: GET_COACHING_REVIEWS,
            payload: { coachingReviewsLoading: true }
        });

        request.getCoachingReviews(id, params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_COACHING_REVIEWS,
                payload: {
                    coachingReviews: res.data,
                    coachingReviewsLoading: false
                }
            });
        });
    };

export const resetCoachingReviews = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_COACHING_REVIEWS
    });
};

export const createCoachingReivew =
    (body: ICreateCoachingReviewBody) => async (dispatch: $FIXME) => {
        return request
            .createCoachingReview(body)
            .then((res: AxiosResponse) => {
                toast.success('Your review has been posted.', {
                    position: toast.POSITION.TOP_CENTER
                });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch(setErrors(err.response?.data.message));

                return Promise.reject(err.response?.data.message);
            });
    };

export const getSubscribedCoachings =
    (params: ISubscribedCoachingsParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_SUBSCRIBED_COACHINGS,
            payload: { subscribedCoachingsLoading: true }
        });

        request
            .getSubscribedCoachings(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_SUBSCRIBED_COACHINGS,
                    payload: {
                        subscribedCoachings: res.data,
                        subscribedCoachingsLoading: false
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: GET_SUBSCRIBED_COACHINGS,
                    payload: { subscribedCoachingsLoading: false }
                });
            });
    };

export const getSubscribedCoaching =
    (slug: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_SUBSCRIBED_COACHING,
            payload: {
                subscribedCoachingLoading: true
            }
        });

        return request
            .getSubscribedCoaching(slug)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_SUBSCRIBED_COACHING,
                    payload: {
                        subscribedCoaching: res.data,
                        subscribedCoachingLoading: false
                    }
                });

                return Promise.resolve();
            })
            .catch(() => {
                dispatch({
                    type: GET_SUBSCRIBED_COACHING,
                    payload: {
                        subscribedCoachingLoading: false
                    }
                });

                return Promise.reject();
            });
    };

export const saveUserCoachingProgress =
    (body: ISaveUserCoachingProgressBody, id: string) =>
    async (dispatch: $FIXME) => {
        return request
            .saveUserCoachingProgress(id, body)
            .then((res: AxiosResponse) => {
                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch(setErrors(err.response?.data.message));
                return Promise.reject(err.response?.data.message);
            });
    };

export const resetCoachingState = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_COACHING_STATE
    });
};

export const updateSubscribedCoaching =
    (body: ISubscribedCoaching) => async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_SUBSCRIBED_COACHING,
            payload: body
        });
    };
