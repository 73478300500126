import { useState } from 'react';
import { IoMdEye } from 'react-icons/io';
import {
    DayActivity as IDayActivity,
    WeekProgress
} from '../../../../../interfaces/Template';
import AppTableInput from '../../../../components/AppTableInput';
import { TableData } from '../../TrainingLocked';
import { useParams } from 'react-router-dom';
import { $FIXME } from '../../../../../helpers/constants';
import DayActivityModal from './DayActivityModal';

interface IProps {
    dayActivity: IDayActivity;
    onChange: (value: WeekProgress) => void;
    value?: WeekProgress;
    show: boolean;
}

const DayActivity = ({ dayActivity, value, onChange, show }: IProps) => {
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const { _id, movement, intensity, note, reps, rest, sets, tempo } =
        dayActivity;

    const { id }: $FIXME = useParams();

    const handleOnChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        if (e.target.name === 'weight_used') {
            onChange({ ...value, weight_used: e.target.value });
        }

        if (e.target.name === 'actual_rpe') {
            const tempRPE = parseInt(e.target.value);

            onChange({
                ...value,
                actual_rpe:
                    tempRPE > 10
                        ? value?.actual_rpe
                        : tempRPE >= 0
                        ? tempRPE
                        : undefined
            });
        }
    };

    return (
        <>
            <tr
                className="bg-light-gray-2"
                style={{ display: show ? 'table-row' : 'none' }}
            >
                <TableData>{movement}</TableData>
                <TableData>{intensity}</TableData>
                <TableData>
                    <AppTableInput
                        placeholder="Enter weight"
                        name="weight_used"
                        value={value?.weight_used}
                        onChange={handleOnChange}
                        onBlur={(e) => {}}
                    />
                </TableData>
                <TableData>
                    <AppTableInput
                        placeholder="Enter RPE"
                        name="actual_rpe"
                        value={value?.actual_rpe?.toString() ?? ''}
                        onChange={handleOnChange}
                        onBlur={(e) => {}}
                    />
                </TableData>
                <TableData>{sets}</TableData>
                <TableData>{reps}</TableData>
                <TableData>{tempo}</TableData>
                <TableData>{rest}</TableData>

                <TableData>
                    <div
                        className="text-truncate"
                        style={{
                            width: 60
                        }}
                    >
                        {!!note ? note : '-'}
                    </div>
                </TableData>

                <TableData>
                    <div
                        className="cursor-pointer text-hover-red text-nowrap"
                        onClick={() => {
                            if (_id) setShowUpdateModal(true);
                        }}
                    >
                        <span>View </span>

                        <IoMdEye className="mb-1" size={18} />
                    </div>
                </TableData>
            </tr>

            <DayActivityModal
                show={showUpdateModal}
                onClose={() => {
                    setShowUpdateModal(false);
                }}
                onUpdate={(feedback) =>
                    onChange({
                        ...value,
                        feedback
                    })
                }
                templateId={id}
                dayActivity={dayActivity}
            />
        </>
    );
};

export default DayActivity;
