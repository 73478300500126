import request from '../helpers/request';
import { Dispatch } from 'redux';
import { GET_DISTRICTS, GET_PROVINCES } from '../helpers/constants';
import { AxiosResponse } from 'axios';

export const getProvinces = () => async (dispatch: Dispatch) => {
    request.getProvinces().then((res: AxiosResponse) => {
        dispatch({ type: GET_PROVINCES, payload: res.data });
    });
};

export const getDistrictByProvince =
    (province: string) => async (dispatch: Dispatch) => {
        request.getDistricts(province).then((res: AxiosResponse) => {
            dispatch({ type: GET_DISTRICTS, payload: res.data });
        });
    };
