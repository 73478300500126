import { CSSProperties, FunctionComponent } from 'react';
import { CartAdd } from '../../assets/images/icons';

interface IProps {
    img: string;
    title: string;
    subtitle: string | JSX.Element;
    className?: string;
    imgWrapperStyle?: CSSProperties;
    onAddCart?: () => void;
    outOfStock?: boolean;
    ribbonText?: string;
}

const AppProductCard: FunctionComponent<IProps> = (props) => {
    return (
        <div
            className={'position-relative p-card-container ' + props.className}
        >
            {props.ribbonText ? (
                <div className="position-absolute ribbon-container">
                    <div className="ribbon">
                        <span className="text-white text-12 text-500 text-uppercase">
                            {props.ribbonText}
                        </span>
                    </div>
                </div>
            ) : (
                ''
            )}

            <div className="p-card-img-wrapper" style={props.imgWrapperStyle}>
                <img src={props.img} className="p-card-img" alt={props.title} />
            </div>
            <div className="p-card-detail-wrapper p-3 position-relative">
                <p
                    className={`text-oswald text-700 mb-1 text-dark-blue text-20 text-truncate text-uppercase`}
                >
                    {props.title}
                </p>

                <p className={`text-inter text-500 text-gray text-14 mb-0`}>
                    {props.subtitle}
                </p>

                {!props.outOfStock ? (
                    <CartAdd
                        className="position-absolute p-cart-add"
                        onClick={(e) => {
                            e.preventDefault();

                            if (props.onAddCart) props.onAddCart();
                        }}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

AppProductCard.defaultProps = {
    className: ''
};

export default AppProductCard;
