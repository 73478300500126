import { TOGGLE_CONSULTAION_MODAL, TOGGLE_DRAWER } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { ConsultationModalType } from '../interfaces/Consultation';

export interface IConsultaionModalState {
    open: boolean;
    consultationId?: string;
    consultationType?: ConsultationModalType;
}

export interface IUIState {
    drawer: boolean;
    consultationModal: IConsultaionModalState;
}

const initState: IUIState = {
    drawer: false,
    consultationModal: {
        open: false
    }
};

const uiReducer = (state = initState, action: ActionType) => {
    let { type, payload } = action;

    switch (type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                drawer: payload
            };
        case TOGGLE_CONSULTAION_MODAL:
            return {
                ...state,
                consultationModal: payload
            };
        default:
            return state;
    }
};

export default uiReducer;
