import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import MainLayout from '../../layouts/MainLayout';
import { ReactComponent as Page404 } from '../../../assets/images/404.svg';

const PageNotFound = () => {
    return (
        <MainLayout>
            <div className="container-xl py-5">
                <Row>
                    <Col>
                        <div className="h-100 d-flex align-items-center pb-5">
                            <div className="position-relative">
                                <h1 className="text-48 text-blue">404</h1>
                                <p className="text-600 text-32 text-dark-2">
                                    Oh No ! Page Not Found ! OxStresgth is
                                    running but can not find the Link you are
                                    looking for
                                </p>
                                <p>
                                    Sorry we can not find the page you are
                                    looking for, Please check the link once
                                    again and try. or you can go to home page.
                                </p>
                                <Link to="/" className="mt-3">
                                    <AppButton variant="primary">
                                        Go to HomePage
                                    </AppButton>
                                </Link>
                                <h1
                                    className="position-absolute text-light-gray-1"
                                    style={{
                                        top: 0,
                                        left: 0,
                                        opacity: 0.52,
                                        fontSize: '10rem',
                                        zIndex: -1
                                    }}
                                >
                                    404
                                </h1>
                            </div>
                        </div>
                    </Col>
                    <Col xs={5} className="d-none d-md-flex">
                        <Page404 width="100%" height="auto" />
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
};

export default PageNotFound;
