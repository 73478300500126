import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-next.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/images/ox-arrow-left.svg';
import Hero1 from '../../../assets/images/hero/hero-1.png';
import Hero2 from '../../../assets/images/hero/hero-2.png';
import Hero3 from '../../../assets/images/hero/hero-3.png';
import Hero4 from '../../../assets/images/hero/hero-4.png';
// import { AiOutlineRight } from 'react-icons/ai';
import AppButton from '../../components/AppButton';
// import ReactPlayer from 'react-player';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    onScrollClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Hero = (props: IProps) => {
    const heroItem = [
        {
            label: (
                <>
                    Start your
                    <br /> Fitness journey <br /> with OX Strength
                </>
            ),
            image: Hero1
        },
        {
            label: (
                <>
                    Take your
                    <br /> training to the <br /> next level
                </>
            ),
            image: Hero2
        },
        {
            label: (
                <>
                    Zero to infinity. <br />
                    Strong like
                    <br /> an OX
                </>
            ),
            image: Hero3
        },
        {
            label: (
                <>
                    Get fit.
                    <br /> Work hard. <br /> Be determined.
                </>
            ),
            image: Hero4
        }
    ];

    const [activeHeroIndex, setActiveHeroIndex] = useState<number>(0);

    const handleIndicator = (index: number) => {
        setActiveHeroIndex(index);
    };

    const delay = 5000;

    const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setActiveHeroIndex((prevIndex) =>
                    prevIndex === heroItem.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [activeHeroIndex, heroItem.length]);

    return (
        <div className="hero-container position-relative d-flex">
            <div className="hero-sidebar flex-column py-5 d-none d-lg-flex">
                <div className="dot-container d-flex flex-column align-items-center flex-grow-1 justify-content-center">
                    {heroItem.map((item, index: number) => (
                        <div
                            className="dot-outer-circle p-2 my-2"
                            onClick={() => handleIndicator(index)}
                            key={index}
                        >
                            <div
                                className={
                                    'dot-circle ' +
                                    (activeHeroIndex === index
                                        ? 'dot-active'
                                        : '')
                                }
                            />
                        </div>
                    ))}
                </div>
                <div
                    className="scroll-label d-flex flex-column align-items-center animated pulse cursor-pointer"
                    onClick={props.onScrollClick}
                >
                    <span className="text-uppercase text-oswald text-14 text-gray py-2">
                        SCROLL
                    </span>
                    <Arrow fontSize={16} fill="#ed2b30" />
                </div>
            </div>
            <div className="px-3 px-md-5 py-4 py-md-5 hero-main position-relative">
                <div className="d-flex align-items-start">
                    <span className="text-oswald text-red text-700 text-36">
                        {activeHeroIndex + 1}
                    </span>
                    <span className="text-oswald text-dark-blue text-700 text-14">
                        /{heroItem.length}
                    </span>
                </div>

                {heroItem.map((item, index) =>
                    index === activeHeroIndex ? (
                        <p
                            className="text-oswald text-700 text-48 text-black text-uppercase mb-5 hero-text fade-in"
                            style={{ lineHeight: '1.0267' }}
                        >
                            {heroItem[index].label}
                        </p>
                    ) : (
                        ''
                    )
                )}

                <div className="mb-5">
                    <Link to="/template">
                        <AppButton variant="secondary" className="mr-3 mb-3">
                            Templates
                        </AppButton>
                    </Link>
                    <Link to="/trainers">
                        <AppButton
                            variant="outline-primary"
                            className="mr-3 mb-3"
                        >
                            Trainers
                        </AppButton>
                    </Link>
                </div>
                {/* <div className="d-flex flex-wrap">
                    <div className="mr-4 mb-2">
                        <div className="d-flex align-items-center mb-2">
                            <p className="text-uppercase text-oswald text-700 text-dark-blue mr-2 mb-0">
                                DAILY WORKOUT CLASSES
                            </p>
                            <AiOutlineRight className="text-dark-blue" />
                        </div>
                        <div className="player-wrapper">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                className="react-player"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                    <div className="mr-4 mb-2">
                        <div className="d-flex align-items-center mb-2">
                            <p className="text-uppercase text-oswald text-700 text-dark-blue mr-2 mb-0">
                                DAILY WORKOUT CLASSES
                            </p>
                            <AiOutlineRight className="text-dark-blue" />
                        </div>
                        <div className="player-wrapper">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                className="react-player"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </div> */}
                <div className="bg-arrow-left">
                    <ArrowLeft />
                </div>

                {heroItem.map((item, index) =>
                    index === activeHeroIndex ? (
                        <div className="bg-image">
                            <img
                                src={heroItem[index].image}
                                alt="ox-hero"
                                className="fade-in"
                            />
                        </div>
                    ) : (
                        ''
                    )
                )}
            </div>
        </div>
    );
};

export default Hero;
