import { BiDislike, BiLike } from 'react-icons/bi';
import { AiOutlineComment, AiOutlineEye } from 'react-icons/ai';
import { Image, Spinner } from 'react-bootstrap';
import { IForum } from '../../../interfaces/Forum';
import { defaultImage } from '../../../helpers/constants';
import dayjs from 'dayjs';
import relative from 'dayjs/plugin/relativeTime';
import { ChangeEvent, useCallback, useState } from 'react';
import { ModalGateway, Modal } from 'react-images';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import request from '../../../helpers/request';
import { setErrors } from '../../../actions/errorActions';
import { AxiosError } from 'axios';
import {
    addCommentCount,
    dislikeForumDetail,
    getForumComments,
    likeForumDetail,
    resetForumComments
} from '../../../actions/forumActions';
import Comments from './components/Comments';

interface IProps {
    forum: IForum;
}

const ForumDetailGroup = ({ forum }: IProps) => {
    const { profile_pic } = useSelector(
        (state: StateType) => state.auth.authUser
    );

    const { comments, commentsLoading } = useSelector(
        (state: StateType) => state.forum
    );

    const resultsPerPage = 5;

    dayjs.extend(relative);

    const [comment, setComoment] = useState<string>();

    const [saveCommentLoading, setSaveCommentLoading] = useState(false);

    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
        setComoment(e.target.value);
    };

    const onComment = () => {
        if (comment && comment?.length && forum._id) {
            setSaveCommentLoading(true);

            request
                .saveComment({ forum: forum._id, content: comment })
                .then(() => {
                    setSaveCommentLoading(false);

                    setComoment('');

                    if (forum._id) {
                        dispatch(resetForumComments());
                        dispatch(addCommentCount(forum._id));
                        dispatch(
                            getForumComments(forum._id, { resultsPerPage })
                        );
                    }
                })
                .catch((err: AxiosError) => {
                    setSaveCommentLoading(false);

                    dispatch(
                        setErrors(err.response?.data?.message ?? 'Failed')
                    );
                });
        }
    };

    const openLightbox = useCallback(() => {
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setViewerIsOpen(false);
    };

    return (
        <div className="px-4 pt-4 bg-white">
            <div>
                <div className="d-flex mb-3">
                    <div className="d-flex flex-grow-1">
                        <Image
                            roundedCircle
                            width={40}
                            height={40}
                            src={
                                !!forum?.author?.profile_pic
                                    ? forum.author.profile_pic
                                    : defaultImage
                            }
                            className="mr-2 object-cover"
                        />
                        <div>
                            <p className="text-600 text-14 mb-0 text-dark-blue">
                                {forum?.author?.name}
                            </p>
                            <p className="text-gray text-14 mb-0">
                                {forum?.author?.email}
                            </p>
                        </div>
                    </div>
                    <div>
                        <span className="ml-2 text-neutrif text-14 text-gray">
                            {dayjs(forum?.createdAt).fromNow()}
                        </span>
                    </div>
                </div>

                <div className="mb-3">
                    <p className={`mb-1 text-dark-blue text-600 text-20`}>
                        {forum?.topic}
                    </p>
                    <p className={`text-neutrif text-gray text-14 mb-0`}>
                        {forum?.description}
                    </p>
                </div>

                {!!forum.cover_image ? (
                    <img
                        src={forum.cover_image}
                        className="w-100 object-cover mb-3 cursor-pointer"
                        style={{ maxHeight: 240 }}
                        onClick={openLightbox}
                        alt={forum.topic}
                    />
                ) : (
                    ''
                )}

                <div className="d-flex justify-content-between mb-4">
                    <div className="d-flex align-items-center">
                        <div
                            className={`d-flex align-items-center text-hover-red cursor-pointer mr-3 ${
                                forum.has_liked === true
                                    ? 'text-red'
                                    : 'text-gray'
                            }`}
                        >
                            <BiLike
                                size={18}
                                className="mr-1"
                                onClick={(e) => {
                                    if (forum._id)
                                        dispatch(likeForumDetail(forum._id));
                                }}
                            />
                            <span className="text-14 text-neutrif mt-1">
                                {forum.likes}
                            </span>
                        </div>
                        <div
                            className={`d-flex align-items-center text-hover-red cursor-pointer ${
                                forum.has_liked === false
                                    ? 'text-red'
                                    : 'text-gray'
                            }`}
                        >
                            <BiDislike
                                size={18}
                                className="mr-1"
                                onClick={(e) => {
                                    if (forum._id)
                                        dispatch(dislikeForumDetail(forum._id));
                                }}
                            />
                            <span className="text-14 text-neutrif mt-1">
                                {forum.dislikes}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="d-flex align-items-center text-gray mr-3">
                            <AiOutlineComment size={18} className="mr-1" />
                            <span className="text-14 text-neutrif mt-1">
                                {forum.comments_count} Answers
                            </span>
                        </div>
                        <div className="d-flex align-items-center text-gray">
                            <AiOutlineEye size={18} className="mr-1" />
                            <span className="text-14 text-neutrif mt-1">
                                {forum.views} views
                            </span>
                        </div>
                    </div>
                </div>

                <hr className="my-0 ml-n2" />
            </div>

            <div className="pr-3 pl-4">
                <div className="d-flex py-4">
                    <Image
                        src={!!profile_pic ? profile_pic : defaultImage}
                        height={33}
                        width={33}
                        style={{ minHeight: 33, minWidth: 33 }}
                        className="rounded-circle object-cover mr-3 mt-2"
                    />

                    <AppInput
                        placeholder="Write a comment"
                        onChange={handleCommentChange}
                        value={comment}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') onComment();
                        }}
                    />

                    {!!comment?.length && (
                        <AppButton
                            className="ml-3"
                            variant="primary"
                            onClick={() => onComment()}
                            loading={saveCommentLoading}
                            disabled={saveCommentLoading || !comment?.length}
                        >
                            Comment
                        </AppButton>
                    )}
                </div>

                {comments.comments.map(
                    (cmmnt) =>
                        cmmnt && <Comments comment={cmmnt} key={cmmnt._id} />
                )}

                {commentsLoading && (
                    <div className="d-flex justify-content-center py-3">
                        <Spinner animation="border" size="sm" />
                    </div>
                )}

                {!commentsLoading &&
                    (comments?.totalPage ?? 0) >
                        (comments?.currentPage ?? 0) && (
                        <p
                            className="text-14 text-gray cursor-pointer mt-2 pb-3 text-hover-red"
                            onClick={() => {
                                if (forum._id)
                                    dispatch(
                                        getForumComments(forum._id, {
                                            resultsPerPage,
                                            page:
                                                (comments?.currentPage ?? 0) + 1
                                        })
                                    );
                            }}
                        >
                            <span>View more replies...</span>
                        </p>
                    )}
            </div>

            {!!forum.cover_image && (
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox} closeOnBackdropClick>
                            <img
                                src={forum.cover_image}
                                alt={forum.topic}
                                style={{ maxHeight: '100vh' }}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            )}
        </div>
    );
};

export default ForumDetailGroup;
