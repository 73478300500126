import { IExpressCheckoutParams, IUpdateCartBody } from '../interfaces/Cart';
import {
    ICoachingConsultationParams,
    ICoachingParams,
    ICoachingReviewParams,
    ICreateCoachingReviewBody,
    ISaveUserCoachingProgressBody,
    ISubscribedCoachingsParams
} from '../interfaces/Coaching';
import { IForumCommentParams } from '../interfaces/Comment';
import {
    IConsultataionReviewParams,
    IConsultationParams,
    IConsultationRequestParams,
    ICreateConsultationReviewBody,
    ISubscribedConsultationParams,
    ITemplateConsultationParams
} from '../interfaces/Consultation';
import { ISaveContactBody } from '../interfaces/Contact';
import { IDeliveryAddressBody } from '../interfaces/DeliveryAddress';
import {
    IForumBody,
    IForumCommentBody,
    IForumParams
} from '../interfaces/Forum';
import { ISubscribeNewsfeedBody } from '../interfaces/NewsFeed';
import { IOfferParams } from '../interfaces/Offer';
import {
    ICellPayTransactionBody,
    IInitiateOrderBody,
    IStripeIntentParams,
    QRGenerateParams
} from '../interfaces/Payment';
import {
    IAddCartBody,
    ICreateProductReviewBody,
    IProductParams
} from '../interfaces/Product';
import {
    ITemplateConsultationRequestParams,
    ICreateTemplateReviewBody,
    ISaveUserProgressBody,
    ITemplateParams,
    IWeekCompleteionRequestParams,
    ISubscribedTemplatesParams,
    ITemplateReviewParams,
    ISaveUserProgressBulkBody
} from '../interfaces/Template';
import {
    IChangePasswordBody,
    IEditUserBody,
    IEmailVerifyParams,
    ILogin,
    IResetPasswordBody,
    ISignUp,
    IUserOrderParams
} from '../interfaces/User';
import { api, cellPayApi } from './api';

let auth = {
    userLogin: (body: ILogin) => api.post('/auth/login', body),
    userRegister: (body: ISignUp) => api.post('/auth/register', body),
    getRefreshToken: (authToken: string) =>
        api.post('/auth/refresh-token', { token: authToken }),
    forgotPassword: (email: string) =>
        api.post('/user/forgot/password', { email }),
    resetPassword: (body: IResetPasswordBody) =>
        api.post('/user/reset/password', body),
    googleLogin: (accessToken: string) =>
        api.post('/auth/social/google/check/token', { accessToken }),
    facebookLogin: (accessToken: string) =>
        api.post('/auth/social/fb/check/token', { accessToken }),
    verifyEmail: (params: IEmailVerifyParams) =>
        api.get(`/user/validate/email`, { params })
};

let user = {
    getUserProfile: () => api.get('/auth/profile'),
    getUserBySlug: (slug: string) => api.get(`/user/profile/${slug}`),
    editUser: (body: IEditUserBody) => api.put('/auth/profile', body),
    getUserOrders: (params: IUserOrderParams) =>
        api.get('/user/orders', { params }),
    changePassword: (body: IChangePasswordBody) =>
        api.put('/auth/update/password', body)
};

let template = {
    getTrainingCategories: () => api.get('/training/categories'),
    getTemplateCategories: () => api.get('/template/categories'),
    getTemplates: (params: ITemplateParams) =>
        api.get('/templates', { params }),
    getTemplateBySlug: (slug: string) => api.get(`/template/${slug}`),
    getTemplatesByTrainingCategory: (id: string) =>
        api.get(`/templates/training/category/${id}`),
    getTemplatesByCategory: (id: string) =>
        api.get(`/templates/category/${id}`),
    getTemplateReviews: (id: string, params: ITemplateReviewParams) =>
        api.get(`/template/reviews/${id}`, { params }),
    createTemplateReview: (body: ICreateTemplateReviewBody) =>
        api.post('/template/review', body),
    getSimilarTemplates: (id: string) => api.get(`/similar/templates/${id}`),
    getSubscribedTemplates: (params: ISubscribedTemplatesParams) =>
        api.get('/subscribed/templates', { params }),
    getSubscribedTemplate: (id: string) =>
        api.get(`/subscribed/template/${id}`),
    saveUserTemplateProgress: (id: string, body: ISaveUserProgressBody) =>
        api.post(`/user/template/progress/${id}`, body),
    templateConsultationRequest: (body: ITemplateConsultationRequestParams) =>
        api.post('/subscribed/template/consultation/request', body),
    templateWeekCompletionRequest: (body: IWeekCompleteionRequestParams) =>
        api.post('/subscribed/template/week/completion', body),
    bulkUpdateProgress: (body: ISaveUserProgressBulkBody) =>
        api.put('/user/template/progress/bulk', body)
};

let product = {
    getProductBrands: (product_category?: string) =>
        api.get('/product/brands', { params: { product_category } }),
    getProductCategories: () => api.get('/product/categories'),
    getProductReviews: (id: string, params: ITemplateReviewParams) =>
        api.get(`/product/reviews/${id}`, { params }),
    createProductReview: (body: ICreateProductReviewBody) =>
        api.post('/product/review', body),
    getProducts: (params: IProductParams) => api.get('/products', { params }),
    getProductBySlug: (slug: string) => api.get(`/product/${slug}`),
    getProductByBrand: (id: string) => api.get(`/products/brand/${id}`),
    getProductByCategory: (id: string) => api.get(`/products/category/${id}`),
    getSimilarProducts: (id: string) => api.get(`/similar/products/${id}`)
};

let cart = {
    getCartList: () => api.get('/user/carts'),
    addToCart: (body: IAddCartBody) => api.post('/user/cart', body),
    updateCart: (id: string, body: IUpdateCartBody) =>
        api.put(`/user/cart/${id}`, body),
    deleteCart: (id: string) => api.delete(`/user/cart/${id}`),
    resetCart: () => api.delete('/user/cart/reset'),
    expressCheckout: (body: IExpressCheckoutParams) =>
        api.post('/user/cart/buynow', body)
};

let trainer = {
    getTrainers: () => api.get('/user/trainers'),
    getTrainersByCategory: (id: string) =>
        api.get(`/user/trainers/category/${id}`),
    getTrainerReview: (id: string) => api.get(`/trainer/reviews/${id}`),
    getTrainerCoachingAchievements: (id: string) =>
        api.get(`/trainer/achievements/coaching/${id}`),
    getTrainerPersonalAchievements: (id: string) =>
        api.get(`/trainer/achievements/personal/${id}`)
};

let testimonial = {
    getTestimonials: () => api.get('/testimonials')
};

let newsfeed = {
    subscribeNewsfeed: (body: ISubscribeNewsfeedBody) =>
        api.post('/newsfeed/subscribe', body),
    unsubscribeNewsfeed: (id: string) =>
        api.patch(`/newsfeed/unsubscribe/${id}`),
    resubscribeNewsfeed: (id: string) =>
        api.patch(`/newsfeed/resubscribe/${id}`),
    getSubscriberDetail: (id: string) => api.get(`/newsfeed/subscriber/${id}`)
};

let multimedia = {
    getAppMultimedia: () => api.get('/multimedias/app'),
    getTrainerMedia: (id: string) => api.get(`/multimedias/trainer/${id}`)
};

let contact = {
    getContactInfo: () => api.get('/contact/information'),
    saveContact: (body: ISaveContactBody) => api.post('/contact/query', body)
};

let deliverAddress = {
    getDeliveryAddress: () => api.get('/user/delivery/addresses'),
    saveDeliveryAddress: (body: IDeliveryAddressBody) =>
        api.post('/user/delivery/address', body),
    deleteAddress: (id: string) => api.delete(`/user/delivery/address/${id}`),
    updateAddress: (id: string, body: IDeliveryAddressBody) =>
        api.put(`/user/delivery/address/${id}`, body)
};

let payment = {
    initiateOrder: (body: IInitiateOrderBody) =>
        api.post('/order/initiate', body),
    cellPayTransaction: (body: ICellPayTransactionBody) =>
        cellPayApi.post('/test_merchant_api/', body),
    getStripeIntent: ({ user_id, order_id }: IStripeIntentParams) =>
        api.get(
            '/payment/transaction/stripe/intent?' +
                (user_id ? `user_id=${user_id}&` : '') +
                (order_id ? `order_id=${order_id}&` : '')
        ),
    generateQRCode: (body: QRGenerateParams) =>
        api.post('/payment/transaction/fonepay/qr/generate', body),
    qrStatus: (order_id: string) =>
        api.post('/payment/transaction/fonepay/qr/status', { order_id })
};

let address = {
    getProvinces: () => api.get('/provinces'),
    getDistricts: (province: string) =>
        api.get(`/province/district/${province}`)
};

let consultation = {
    getConsultations: (params: IConsultationParams) =>
        api.get('/consultations', { params }),
    getConsultaionBySlug: (slug: string) => api.get(`/consultation/${slug}`),
    getConsultationReviews: (id: string, params: IConsultataionReviewParams) =>
        api.get(`/consultation/reviews/${id}`, { params }),
    createConsultationReview: (body: ICreateConsultationReviewBody) =>
        api.post('/consultation/review', body),
    getSubscribedConsultations: (params: ISubscribedConsultationParams) =>
        api.get('/subscribed/consultations', { params }),
    getTemplateConsultations: (params: ITemplateConsultationParams) =>
        api.get('/subscribed/templates', { params }),
    getCoachingConsultations: (params: ICoachingConsultationParams) =>
        api.get('/subscribed/coaching/consultations', { params }),
    consultationRequest: (body: IConsultationRequestParams) =>
        api.post('/subscribed/consultation/request', body)
};

let coaching = {
    getCoachings: (params: ICoachingParams) =>
        api.get('/coachings', { params }),
    getCoachingBySlug: (slug: string) => api.get(`/coaching/${slug}`),
    getCoachingReviews: (id: string, params: ICoachingReviewParams) =>
        api.get(`/coaching/reviews/${id}`, { params }),
    createCoachingReview: (body: ICreateCoachingReviewBody) =>
        api.post('/coaching/review', body),
    getSubscribedCoachings: (params: ISubscribedCoachingsParams) =>
        api.get('/subscribed/coachings', { params }),
    getSubscribedCoaching: (id: string) =>
        api.get(`/subscribed/coaching/detail/${id}`),
    saveUserCoachingProgress: (
        id: string,
        body: ISaveUserCoachingProgressBody
    ) => api.put(`/subscribed/coaching/user/progress/${id}`, body),
    coachingConsultationRequest: (body: IConsultationRequestParams) =>
        api.post('/subscribed/coaching/consultation/request', body),
    updateCoachingConsultationRequest: (body: IConsultationRequestParams) =>
        api.put(`/subscribed/coaching/consultation/${body.subscription}`, {
            consultation_date: body.consultation_date
        }),
    coachingWeekCompletionRequest: (body: IWeekCompleteionRequestParams) =>
        api.post('/subscribed/coaching/week/completion', body),
    bulkUpdateCoachingProgress: (body: ISaveUserProgressBulkBody) =>
        api.put('/subscribed/coaching/user/progress/bulk', body)
};

let dashboard = {
    getProfileSubscriptionCount: () => api.get('/analytics/profile/dashboard')
};

let coupon = {
    checkCouponValidity: (code: string) =>
        api.get(`/coupon/check/validity/${code}`),
    getCouponByCode: (code: string) => api.get(`/coupon/detail/${code}`)
};

let gifts = {
    getGiftedTemplates: () => api.get(`/gifted/templates`),
    getReceivedTemplates: () => api.get(`/received/gift/templates`),
    claimGiftedTemplate: (id: string) =>
        api.patch(`/claim/gift/template/${id}`),
    getGiftedCoachings: () => api.get(`/gifted/coachings`),
    getReceivedCoachings: () => api.get(`/received/gift/coachings`),
    claimGiftedCoaching: (id: string) =>
        api.patch(`/claim/gift/coaching/${id}`),
    getGiftedConsultations: () => api.get(`/gifted/consultations`),
    getReceivedConsultations: () => api.get(`/received/gift/consultations`),
    claimGiftedConsultation: (id: string) =>
        api.patch(`/claim/gift/consultation/${id}`)
};

let offers = {
    getOffers: (params: IOfferParams) => api.get(`/offers`, { params })
};

let forum = {
    getAllForums: (params: IForumParams) => api.get('/forums', { params }),
    getForum: (id: string) => api.get(`/forum/${id}`),
    saveForum: (body: IForumBody) => api.post('/forum', body),
    deleteForum: (id: string) => api.delete(`/forum/${id}`),
    getTopAuthor: () => api.get('/forum/top/authors'),
    likeForum: (id: string) => api.post('/forum/like', { forum: id }),
    dislikeForum: (id: string) => api.post('/forum/dislike', { forum: id }),
    getComments: (id: string, params: IForumCommentParams) =>
        api.get(`/forum/comments/${id}`, { params }),
    saveComment: (body: IForumCommentBody) => api.post('/forum/comment', body),
    getSubComments: (id: string, params: IForumCommentParams) =>
        api.get(`/forum/comment/subcomments/${id}`, { params }),
    likeComment: (id: string) =>
        api.post('/forum/comment/like', { comment: id }),
    dislikeComment: (id: string) =>
        api.post('/forum/comment/dislike', { comment: id })
};

let pages = {
    getPageDetail: (slug: string) => api.get(`/page/detail/${slug}`)
};

const request = {
    ...auth,
    ...user,
    ...template,
    ...product,
    ...cart,
    ...trainer,
    ...testimonial,
    ...newsfeed,
    ...multimedia,
    ...contact,
    ...deliverAddress,
    ...payment,
    ...address,
    ...consultation,
    ...coaching,
    ...dashboard,
    ...coupon,
    ...gifts,
    ...offers,
    ...forum,
    ...pages
};

export default request;
