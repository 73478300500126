import { Row, Col, Table } from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import GetOffers from '../templateSearch/GetOffers';
import CheckoutSummary from './CheckoutSummary';
import AppButton from '../../components/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import {
    getCarts,
    resetCart,
    updateCartUnit
} from '../../../actions/cartActions';
import { Link, useHistory } from 'react-router-dom';
import { getDeliveryAddress } from '../../../actions/deliveryAddressActions';
import { useEffect } from 'react';
import { EmptyCart } from '../../../assets/images/icons';
import { getAllOffers } from '../../../actions/offerActions';
import CartTableRow from './CartTableRow';

interface IProps {}

const Cart = (props: IProps) => {
    const cart = useSelector((state: StateType) => state.cart.carts);

    const carts = cart.carts;

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Template',
            link: '/template'
        },
        {
            label: 'Cart',
            link: '/cart',
            active: true
        }
    ];

    useEffect(() => {
        dispatch(getCarts());
        dispatch(getDeliveryAddress());
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    const handleClearCart = () => {
        dispatch(resetCart());
    };

    return (
        <MainLayout>
            <div className="container-xxl container-xl cart-container">
                <AppBreadcrumbs items={breadcrumbItems} />
                <div className="mb-3 pt-3">
                    <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                        My Cart
                    </h2>
                    <p className="text-gray text-14 mb-0">
                        Check cart and proceed to payment
                    </p>
                </div>
                <Row className="mb-5">
                    <Col className="mb-5">
                        <div className="cart-item-container mb-3">
                            <Table responsive borderless>
                                <thead className="border-bottom-1">
                                    <tr>
                                        <th className="pl-0 text-neutrif text-600 text-14 text-dark-2">
                                            Product/Package
                                        </th>
                                        <th className="text-neutrif text-600 text-14 text-dark-2">
                                            Price
                                        </th>
                                        <th className="text-neutrif text-600 text-14 text-dark-2">
                                            Unit
                                        </th>
                                        <th className="pr-0 text-neutrif text-600 text-14 text-dark-2">
                                            Subtotal
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {carts.length ? (
                                        carts.map((cart, index) => {
                                            return !!cart ? (
                                                <CartTableRow
                                                    cart={cart}
                                                    key={index}
                                                    onCounterChange={(
                                                        value
                                                    ) => {
                                                        if (cart._id)
                                                            dispatch(
                                                                updateCartUnit(
                                                                    cart._id,
                                                                    {
                                                                        unit: value,
                                                                        self_order:
                                                                            true,
                                                                        product_size:
                                                                            cart.product_size
                                                                    }
                                                                )
                                                            );
                                                    }}
                                                />
                                            ) : (
                                                ''
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={4} className="px-0">
                                                <div className="py-5 bg-light-gray-1">
                                                    <div className="d-flex align-items-center flex-column py-5">
                                                        <EmptyCart />
                                                        <p className="text-inter text-gray-2">
                                                            Empty Cart. Explore
                                                            and find the best
                                                            training templates
                                                            and products.
                                                        </p>
                                                        <Link to="/">
                                                            <AppButton variant="outline-secondary">
                                                                Explore
                                                                templates &
                                                                products
                                                            </AppButton>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>

                        {carts.length ? (
                            <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <AppButton
                                        variant="outline-secondary"
                                        className="mr-3 mb-3"
                                        onClick={() => {
                                            history.push('/');
                                        }}
                                    >
                                        Continue Shopping
                                    </AppButton>

                                    <AppButton
                                        variant="outline-secondary"
                                        className="mr-3 mb-3"
                                        onClick={handleClearCart}
                                    >
                                        Clear Cart
                                    </AppButton>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </Col>

                    {carts.length > 0 ? (
                        <Col lg={4} className="mb-5">
                            <CheckoutSummary cart={cart} />
                        </Col>
                    ) : (
                        ''
                    )}
                </Row>
            </div>
            <GetOffers offerType="all" />
            <GetNotified />
        </MainLayout>
    );
};

export default Cart;
