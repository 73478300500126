import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import { useHistory, useParams } from 'react-router-dom';
import { $FIXME } from '../../../helpers/constants';
import { useEffect, useState } from 'react';
import request from '../../../helpers/request';
import { AxiosError, AxiosResponse } from 'axios';
import { RiNewspaperLine } from 'react-icons/ri';
import AppButton from '../../components/AppButton';
import { Spinner } from 'react-bootstrap';

const UnsubscribeNewsfeed = () => {
    const { id }: $FIXME = useParams();

    const [unsubscribed, setUnsubscribed] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState<string>();
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setLoading(true);

        if (id) {
            request
                .getSubscriberDetail(id)
                .then((res: AxiosResponse) => {
                    if (res.data?.emailBlacklisted) {
                        setUnsubscribed(true);
                    } else {
                        setUnsubscribed(false);
                    }

                    setLoading(false);
                })
                .catch((err: AxiosError) => {
                    setError(true);

                    if ((err.response?.status ?? 0) > 500) {
                        setMessage('Something went wrong.');
                    } else {
                        setMessage(
                            err.response?.data?.message ??
                                'Something went wrong.'
                        );
                    }

                    setLoading(false);
                });
        } else {
            setError(true);
            setMessage('Something went wrong.');
            setLoading(false);
        }
    }, [id, unsubscribed]);

    const handleUnsubscribe = () => {
        setLoading(true);

        if (id) {
            request
                .unsubscribeNewsfeed(id)
                .then((res: AxiosResponse) => {
                    setUnsubscribed(true);
                    setMessage(res.data?.message);
                    setError(false);
                    setLoading(false);
                })
                .catch((err: AxiosError) => {
                    setError(true);

                    if (parseInt(err?.code ?? '') > 500) {
                        setMessage('Something went wrong.');
                    } else {
                        setMessage(
                            err.response?.data?.message ??
                                'Something went wrong.'
                        );
                    }

                    setLoading(false);
                });
        } else {
            setError(true);
            setMessage('Something went wrong.');
            setLoading(false);
        }
    };

    const handleSubscribe = () => {
        setLoading(true);

        if (id) {
            request
                .resubscribeNewsfeed(id)
                .then((res: AxiosResponse) => {
                    setUnsubscribed(false);
                    setMessage(res.data?.message);
                    setError(false);
                    setLoading(false);
                })
                .catch((err: AxiosError) => {
                    setError(true);

                    if (parseInt(err?.code ?? '') > 500) {
                        setMessage('Something went wrong.');
                    } else {
                        setMessage(
                            err.response?.data?.message ??
                                'Something went wrong.'
                        );
                    }

                    setLoading(false);
                });
        } else {
            setError(true);
            setMessage('Something went wrong.');
            setLoading(false);
        }
    };

    return (
        <MainLayout>
            <div className="container-xl py-5 px-sm-5">
                <div className="order-confirm-container py-5">
                    <div
                        className="d-flex flex-column align-items-center mx-auto"
                        style={{ maxWidth: '32rem' }}
                    >
                        {!loading ? (
                            <>
                                {' '}
                                <RiNewspaperLine
                                    className="text-danger mb-3"
                                    size={86}
                                />
                                <h2 className="mb-3">
                                    {unsubscribed
                                        ? 'Subscribe to Newsfeed'
                                        : 'Unsubscribe from Newsfeed'}
                                </h2>
                                {error ? (
                                    <p className={`text-center text-danger`}>
                                        {message}
                                    </p>
                                ) : unsubscribed ? (
                                    <p className="text-center text-dark-blue mb-3">
                                        Are you sure you want to subscribe to{' '}
                                        <span className="text-600">
                                            OxStrength
                                        </span>{' '}
                                        newsfeed?
                                    </p>
                                ) : (
                                    <p className="text-center text-dark-blue mb-3">
                                        Are you sure you want to unsubscribe
                                        from{' '}
                                        <span className="text-600">
                                            OxStrength
                                        </span>{' '}
                                        newsfeed?
                                    </p>
                                )}
                                {error ? (
                                    <AppButton
                                        variant="primary"
                                        type="submit"
                                        onClick={() => {
                                            history.push('/');
                                        }}
                                    >
                                        GO TO HOMEPAGE
                                    </AppButton>
                                ) : (
                                    ''
                                )}
                                {!error && (
                                    <AppButton
                                        variant="primary"
                                        type="submit"
                                        onClick={
                                            unsubscribed
                                                ? handleSubscribe
                                                : handleUnsubscribe
                                        }
                                        loading={loading}
                                        disabled={loading}
                                    >
                                        {unsubscribed
                                            ? 'Subscribe Me'
                                            : 'Unsubscribe Me'}
                                    </AppButton>
                                )}
                            </>
                        ) : (
                            <Spinner animation="border" />
                        )}
                    </div>
                </div>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default UnsubscribeNewsfeed;
