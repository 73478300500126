import { CSSProperties, FunctionComponent } from 'react';
import ReactPlayer from 'react-player';

interface IProps {
    img: string;
    title: string;
    url: string;
    className?: string;
    imgWrapperStyle?: CSSProperties;
}

const AppVideoCard: FunctionComponent<IProps> = (props) => {
    return (
        <div className={'v-card-container ' + props.className}>
            <div className="mb-2 v-wrapper">
                <ReactPlayer
                    url={props.url}
                    className="react-player"
                    width="100%"
                    height="100%"
                    light={props.img}
                />
            </div>
            <p className="text-16 mb-1">{props.title}</p>
            <p className="text-14 text-gray">2 hours ago</p>
        </div>
    );
};

AppVideoCard.defaultProps = {
    className: ''
};

export default AppVideoCard;
