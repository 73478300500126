import { Link, useHistory, useLocation } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import ShopHero from './ShopHero';
import { Col, Row } from 'react-bootstrap';
import AppButton from '../../components/AppButton';
import AppProductCard from '../../components/AppProductCard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import {
    getApparels,
    getGymGears,
    getSuppliments
} from '../../../actions/productActions';
import { getProductCategories } from '../../../actions/categoryActions';
import { formatNumber } from '../../../utils/functions';
import {
    apparelId,
    defaultImage,
    gymGearId,
    supplimentsId
} from '../../../helpers/constants';
import Slider, { Settings } from 'react-slick';
import { ReactComponent as ArrowPrev } from '../../../assets/images/icons/arrow-prev.svg';
import { ReactComponent as ArrowNext } from '../../../assets/images/icons/arrow-next.svg';
import GetNotified from '../home/GetNotified';
import { IAddCartBody, Rate } from '../../../interfaces/Product';
import { addToCart } from '../../../actions/cartActions';
import {
    supplements,
    apparels as apparelsImg,
    gymGears as gymGearsImg
} from '../../../assets/images/shop/shopImages';

interface IProps {}

const ShopLanding = (props: IProps) => {
    const { authenticated } = useSelector((state: StateType) => state.auth);
    const { apparels, gymGears, suppliments } = useSelector(
        (state: StateType) => state.product
    );
    const categories = useSelector(
        (state: StateType) => state.category.productCategories
    );

    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();

    useEffect(() => {
        dispatch(getProductCategories());
        dispatch(getApparels({}));
        dispatch(getSuppliments({}));
        dispatch(getGymGears({}));
    }, [dispatch]);

    var settings: Settings = {
        infinite: false,
        speed: 500,
        slidesToShow: categories.length > 6 ? 6 : categories.length,
        slidesToScroll: 1,
        prevArrow: <ArrowPrev />,
        nextArrow: <ArrowNext />,
        className: 'shop-category-list-slider',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: categories.length > 5 ? 5 : categories.length
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: categories.length > 4 ? 4 : categories.length
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: categories.length > 3 ? 3 : categories.length,
                    arrows: false
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: categories.length > 2 ? 2 : categories.length,
                    arrows: false
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: categories.length > 1 ? 1 : categories.length,
                    arrows: false
                }
            }
        ]
    };

    const handleAddToCart = (id: string, rate?: Rate) => {
        if (authenticated) {
            const body: IAddCartBody = {
                model_id: id,
                onModel: 'Product',
                unit: 1,
                self_order: true,
                product_size: rate?.size
            };

            dispatch(addToCart(body));
        } else {
            history.push('/login', {
                from: location
            });
        }
    };

    return (
        <MainLayout backgroundColor="#F4F6FC" navBackgroundColor="#F4F6FC">
            <ShopHero />

            <div className="container-xxxl py-5">
                <div className="px-lg-3">
                    <div className="mb-5">
                        <h2 className="text-600 text-20 mb-4">
                            EXPLORE BY CATEGORIES
                        </h2>

                        <Slider {...settings}>
                            {categories.map((category, index) => (
                                <Link
                                    to={{
                                        pathname: '/shop/search',
                                        search: new URLSearchParams({
                                            category: category._id ?? ''
                                        }).toString()
                                    }}
                                    className="category-link mb-2 px-2"
                                    key={index}
                                >
                                    <div className="d-flex align-items-center p-2 category-container">
                                        <img
                                            src={
                                                !!category.icon
                                                    ? category.icon
                                                    : defaultImage
                                            }
                                            width={74}
                                            height={60}
                                            alt={category.name}
                                            className="mr-2"
                                        />
                                        <div
                                            className="overflow-hidden"
                                            title={category.name}
                                        >
                                            <p className="text-600 mb-0 category-title text-truncate">
                                                {category.name}
                                            </p>
                                            <p className="text-gray text-14 mb-0">
                                                {category.product} Products
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </Slider>
                    </div>

                    {!!apparels.products.length && (
                        <div className="mb-5">
                            <div className="d-flex mb-4 align-items-center flex-wrap">
                                <h2 className="text-600 text-20 flex-grow-1 mb-0 mr-3 py-2">
                                    EXPLORE OUR APPARELS
                                </h2>

                                <AppButton
                                    variant="outline-secondary text-nowrap"
                                    onClick={() => {
                                        history.push(
                                            `/shop/search?category=${apparelId}`
                                        );
                                    }}
                                >
                                    View all apparels
                                </AppButton>
                            </div>
                            <Row xs={2} md={4} className="mb-5 mx-n2" noGutters>
                                {apparels.products.map((product, index) => {
                                    let rate = !!product?.rates?.length
                                        ? product.rates?.find(
                                              (rate) => (rate.stock ?? 0) > 0
                                          ) ?? product.rates[0]
                                        : undefined;

                                    return (
                                        <div className="p-2">
                                            <Link
                                                to={`/shop/product/${product.slug}`}
                                            >
                                                <AppProductCard
                                                    img={
                                                        product.images &&
                                                        product.images.length >
                                                            0
                                                            ? product.images[0]
                                                            : defaultImage
                                                    }
                                                    title={
                                                        product.title ?? 'N/A'
                                                    }
                                                    subtitle={
                                                        product.discount
                                                            ?.active ? (
                                                            <>
                                                                <s>
                                                                    Rs.{' '}
                                                                    {formatNumber(
                                                                        rate?.price
                                                                    )}
                                                                </s>
                                                                <br />
                                                                {'Rs. ' +
                                                                    formatNumber(
                                                                        rate?.discounted_price
                                                                    )}
                                                            </>
                                                        ) : (
                                                            'Rs. ' +
                                                            formatNumber(
                                                                rate?.discounted_price
                                                            )
                                                        )
                                                    }
                                                    key={index}
                                                    onAddCart={() =>
                                                        product._id
                                                            ? handleAddToCart(
                                                                  product._id,
                                                                  product.rates
                                                                      ?.length
                                                                      ? product
                                                                            .rates[0]
                                                                      : undefined
                                                              )
                                                            : undefined
                                                    }
                                                    outOfStock={
                                                        !!product.rates
                                                            ?.length &&
                                                        (product.rates[0]
                                                            .stock ?? 0) === 0
                                                    }
                                                    ribbonText={
                                                        !!product.rates
                                                            ?.length &&
                                                        (product.rates[0]
                                                            .stock ?? 0) === 0
                                                            ? 'Out of stock'
                                                            : product.discount
                                                                  ?.active
                                                            ? product.discount
                                                                  .title
                                                            : undefined
                                                    }
                                                />
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Row>
                        </div>
                    )}

                    {!!suppliments.products.length && (
                        <div className="mb-5">
                            <div className="d-flex mb-4 align-items-center flex-wrap">
                                <h2 className="text-600 text-20 flex-grow-1 mb-0 mr-3 py-2">
                                    EXPLORE OUR SUPPLIMENTS
                                </h2>

                                <AppButton
                                    variant="outline-secondary text-nowrap"
                                    onClick={() => {
                                        history.push(
                                            `/shop/search?category=${supplimentsId}`
                                        );
                                    }}
                                >
                                    View all suppliments
                                </AppButton>
                            </div>
                            <Row xs={2} md={4} className="mb-5 mx-n2" noGutters>
                                {suppliments.products.map((product, index) => {
                                    let rate = !!product?.rates?.length
                                        ? product.rates?.find(
                                              (rate) => (rate.stock ?? 0) > 0
                                          ) ?? product.rates[0]
                                        : undefined;

                                    return (
                                        <div className="p-2">
                                            <Link
                                                to={`/shop/product/${product.slug}`}
                                            >
                                                <AppProductCard
                                                    img={
                                                        product.images &&
                                                        product.images.length >
                                                            0
                                                            ? product.images[0]
                                                            : defaultImage
                                                    }
                                                    title={
                                                        product.title ?? 'N/A'
                                                    }
                                                    subtitle={
                                                        product.discount
                                                            ?.active ? (
                                                            <>
                                                                <s>
                                                                    Rs.{' '}
                                                                    {formatNumber(
                                                                        rate?.price
                                                                    )}
                                                                </s>
                                                                <br />
                                                                {'Rs. ' +
                                                                    formatNumber(
                                                                        rate?.discounted_price
                                                                    )}
                                                            </>
                                                        ) : (
                                                            'Rs. ' +
                                                            formatNumber(
                                                                rate?.discounted_price
                                                            )
                                                        )
                                                    }
                                                    key={index}
                                                    onAddCart={() =>
                                                        product._id
                                                            ? handleAddToCart(
                                                                  product._id,
                                                                  product.rates
                                                                      ?.length
                                                                      ? product
                                                                            .rates[0]
                                                                      : undefined
                                                              )
                                                            : undefined
                                                    }
                                                    outOfStock={
                                                        !!product.rates
                                                            ?.length &&
                                                        (product.rates[0]
                                                            .stock ?? 0) === 0
                                                    }
                                                    ribbonText={
                                                        !!product.rates
                                                            ?.length &&
                                                        (product.rates[0]
                                                            .stock ?? 0) === 0
                                                            ? 'Out of stock'
                                                            : product.discount
                                                                  ?.active
                                                            ? product.discount
                                                                  .title
                                                            : undefined
                                                    }
                                                />
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Row>
                        </div>
                    )}

                    <Row className="mb-5 mx-n2" noGutters>
                        <Col
                            className="position-relative px-2 mb-3"
                            sm={7}
                            style={{ maxHeight: 500 }}
                        >
                            <img
                                src={supplements}
                                alt="supplements"
                                className="w-100 object-cover h-100"
                            />

                            <div
                                className="position-absolute cursor-pointer"
                                style={{
                                    inset: 40
                                }}
                                onClick={() => {
                                    history.push(
                                        `/shop/search?category=${supplimentsId}`
                                    );
                                }}
                            >
                                <h1
                                    className="text-white text-uppercase mb-4 promo-title-text"
                                    style={{ lineHeight: 1.35 }}
                                >
                                    Boost your <br />
                                    immune system <br /> today
                                </h1>

                                <Link to="/shop/search">
                                    <AppButton variant="primary">
                                        Shop Now
                                    </AppButton>
                                </Link>
                            </div>
                        </Col>

                        <Col
                            sm={5}
                            className="d-flex flex-column px-2 mb-3"
                            style={{ maxHeight: 500 }}
                        >
                            <img
                                src={apparelsImg}
                                alt="apparels"
                                className="w-100 object-cover cursor-pointer flex-grow-1 h-50 pb-2"
                                style={{ objectPosition: 'left top' }}
                                onClick={() => {
                                    history.push(
                                        `/shop/search?category=${apparelId}`
                                    );
                                }}
                            />

                            <img
                                src={gymGearsImg}
                                alt="gym gears"
                                className="w-100 object-cover cursor-pointer flex-grow-1 h-50 pt-2"
                                onClick={() => {
                                    history.push(
                                        `/shop/search?category=${gymGearId}`
                                    );
                                }}
                            />
                        </Col>
                    </Row>

                    {!!gymGears.products.length && (
                        <div className="mb-5">
                            <div className="d-flex mb-4 align-items-center flex-wrap">
                                <h2 className="text-600 text-20 flex-grow-1 mb-0 mr-3 py-2">
                                    EXPLORE OUR GYM GEARS
                                </h2>

                                <AppButton
                                    variant="outline-secondary text-nowrap"
                                    onClick={() => {
                                        history.push(
                                            `/shop/search?category=${gymGearId}`
                                        );
                                    }}
                                >
                                    View all gym gears
                                </AppButton>
                            </div>
                            <Row xs={2} md={4} className="mb-5 mx-n2" noGutters>
                                {gymGears.products.map((product, index) => {
                                    let rate = !!product?.rates?.length
                                        ? product.rates?.find(
                                              (rate) => (rate.stock ?? 0) > 0
                                          ) ?? product.rates[0]
                                        : undefined;

                                    return (
                                        <div className="p-2">
                                            <Link
                                                to={`/shop/product/${product.slug}`}
                                            >
                                                <AppProductCard
                                                    img={
                                                        product.images &&
                                                        product.images.length >
                                                            0
                                                            ? product.images[0]
                                                            : defaultImage
                                                    }
                                                    title={
                                                        product.title ?? 'N/A'
                                                    }
                                                    subtitle={
                                                        product.discount
                                                            ?.active ? (
                                                            <>
                                                                <s>
                                                                    Rs.{' '}
                                                                    {formatNumber(
                                                                        rate?.price
                                                                    )}
                                                                </s>
                                                                <br />
                                                                {'Rs. ' +
                                                                    formatNumber(
                                                                        rate?.discounted_price
                                                                    )}
                                                            </>
                                                        ) : (
                                                            'Rs. ' +
                                                            formatNumber(
                                                                rate?.discounted_price
                                                            )
                                                        )
                                                    }
                                                    key={index}
                                                    onAddCart={() =>
                                                        product._id
                                                            ? handleAddToCart(
                                                                  product._id,
                                                                  product.rates
                                                                      ?.length
                                                                      ? product
                                                                            .rates[0]
                                                                      : undefined
                                                              )
                                                            : undefined
                                                    }
                                                    outOfStock={
                                                        !!product.rates
                                                            ?.length &&
                                                        (product.rates[0]
                                                            .stock ?? 0) === 0
                                                    }
                                                    ribbonText={
                                                        !!product.rates
                                                            ?.length &&
                                                        (product.rates[0]
                                                            .stock ?? 0) === 0
                                                            ? 'Out of stock'
                                                            : product.discount
                                                                  ?.active
                                                            ? product.discount
                                                                  .title
                                                            : undefined
                                                    }
                                                />
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Row>
                        </div>
                    )}
                </div>
            </div>

            <GetNotified />
        </MainLayout>
    );
};

export default ShopLanding;
