import {
    GET_TEMPLATES,
    GET_TEMPLATE,
    GET_TEMPLATE_REVIEWS,
    RESET_TEMPLATE_STATE,
    GET_SUBSCRIBED_TEMPLATES,
    GET_SUBSCRIBED_TEMPLATE,
    GET_FEATURED_TEMPLATES,
    GET_SIMILAR_TEMPLATES,
    GET_EXPIRED_TEMPLATES,
    RESET_TEMPLATE_REVIEWS,
    UPDATE_SUBSCRIBED_TEMPLATE
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import {
    ISubscribedTemplate,
    ISubscribedTemplateDetail,
    ISubscribedTemplates,
    ITemplateReview,
    ITemplates,
    Template
} from '../interfaces/Template';

export interface ITemplateState {
    template: Template;
    templateLoading: boolean;
    templates: ITemplates;
    templatesLoading: boolean;
    reviews: ITemplateReview;
    reviewsLoading: boolean;
    subscribed: ISubscribedTemplates;
    subscribedLoading: boolean;
    subscribedTemplate: ISubscribedTemplateDetail;
    subscribedTemplateLoading: boolean;
    featuredTemplates: ITemplates;
    similarTemplates: ITemplates;
    expiredTemplates: ISubscribedTemplates;
    expiredTemplatesLoading: boolean;
}

const initialState: ITemplateState = {
    template: {},
    templateLoading: false,
    templates: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        templates: []
    },
    templatesLoading: false,
    reviews: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        reviews: []
    },
    reviewsLoading: false,
    subscribed: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        templates: []
    },
    subscribedLoading: false,
    subscribedTemplate: {},
    subscribedTemplateLoading: false,
    featuredTemplates: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        templates: []
    },
    similarTemplates: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        templates: []
    },
    expiredTemplates: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        templates: []
    },
    expiredTemplatesLoading: false
};

const templateReducer = (
    state = initialState,
    { payload, type }: ActionType
): ITemplateState => {
    switch (type) {
        case GET_TEMPLATES:
            return {
                ...state,
                templatesLoading: payload?.templatesLoading,
                templates: {
                    ...state.templates,
                    ...payload.templates,
                    templates: [
                        ...state.templates.templates,
                        ...(payload?.templates?.templates ?? [])
                    ]
                }
            };
        case GET_TEMPLATE:
            return {
                ...state,
                ...payload
            };
        case GET_TEMPLATE_REVIEWS:
            return {
                ...state,
                reviewsLoading: payload?.reviewsLoading,
                reviews: {
                    ...state.reviews,
                    ...payload.reviews,
                    reviews: [
                        ...state.reviews.reviews,
                        ...(payload?.reviews?.reviews ?? [])
                    ]
                }
            };

        case GET_SUBSCRIBED_TEMPLATES:
            return {
                ...state,
                ...payload,
                subscribed: {
                    ...state.subscribed,
                    ...payload.subscribed,
                    templates: [
                        ...state.subscribed.templates,
                        ...(payload?.subscribed?.templates ?? [])
                    ]
                }
            };
        case GET_SUBSCRIBED_TEMPLATE:
            return {
                ...state,
                ...payload
            };

        case GET_FEATURED_TEMPLATES:
            return {
                ...state,
                featuredTemplates: payload
            };

        case GET_SIMILAR_TEMPLATES:
            return {
                ...state,
                similarTemplates: payload
            };

        case GET_EXPIRED_TEMPLATES:
            return {
                ...state,
                ...payload,
                expiredTemplates: {
                    ...state.expiredTemplates,
                    ...payload.expiredTemplates,
                    templates: [
                        ...state.expiredTemplates.templates,
                        ...(payload?.expiredTemplates?.templates ?? [])
                    ]
                }
            };

        case RESET_TEMPLATE_STATE:
            return initialState;

        case RESET_TEMPLATE_REVIEWS:
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    reviews: []
                }
            };

        case UPDATE_SUBSCRIBED_TEMPLATE:
            let subscribedTemplate = payload as ISubscribedTemplate;

            return {
                ...state,
                subscribed: {
                    ...state.subscribed,
                    templates: state.subscribed.templates.map((template) =>
                        template._id === subscribedTemplate._id
                            ? {
                                  ...template,
                                  ...subscribedTemplate,
                                  template: template.template
                              }
                            : template
                    )
                }
            };

        default:
            return state;
    }
};

export default templateReducer;
