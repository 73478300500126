import MainLayout from '../../layouts/MainLayout';

const OurStory = () => {
    return (
        <MainLayout>
            <div className="container-xl py-5 mb-5">
                <h2 className="mb-4">OUR STORY AND BENEFITS</h2>

                <p className="text-dark-3 text-18" style={{ lineHeight: 1.8 }}>
                    Founded By Bramand Moktan ( Tyson) in 2020 amidst the
                    pandemic. OX strength has been in the works since 2018 when
                    Tyson started his career as a fitness instructor. He always
                    believed the traditional way of Personal training was very
                    powerful to get results the fastest way but also limited
                    because of the number of people one trainer could handle and
                    it would also be limited to a specific area. Since,
                    powerlifting is a niche and he wanted to connect to other
                    interested athletes outside of just one gym but from all
                    over Nepal and over the world. He developed a system unique
                    to himself while trying to be as hands on as possible but
                    remotely through online programming with some inspiration
                    from his coaches. This was a very successful move and Tyson
                    was able to inspire many athletes all over Nepal and even
                    internationally with his coaching method. Even though this
                    was reaching more athletes than the traditional way. It was
                    limited again by the Number of clients one coach can take
                    and for some clients financially. Then the idea of this
                    platform now “ OX strength “ was formed. To reach more
                    athletes wanting one and one coaching with a similar
                    philosophy as Tyson and also to provide a more affordable
                    training plan with the templates we provide. Template
                    culture has also been very popular all over the world but at
                    “ OX Strength” to provide a more hands on experience we
                    provide templates bundled with a free consultation call and
                    with videos that would help the understanding process much
                    easier.
                </p>
            </div>
        </MainLayout>
    );
};

export default OurStory;
