import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    ADD_COMMENT_COUNT,
    ADD_REPLY_COUNT,
    DISLIKE_COMMENT,
    DISLIKE_FORUM,
    DISLIKE_FORUM_DETAIL,
    GET_ALL_FORUMS,
    GET_FEATURED_FORUMS,
    GET_FORUM,
    GET_FORUM_COMMENTS,
    GET_TOP_AUTHOR,
    LIKE_COMMENT,
    LIKE_FORUM,
    LIKE_FORUM_DETAIL,
    RESET_FORUMS,
    RESET_FORUM_COMMENTS,
    SAVE_FORUM_FAILURE,
    SAVE_FORUM_REQUEST,
    SAVE_FORUM_SUCCESS
} from '../helpers/constants';
import request from '../helpers/request';
import { IForumCommentParams } from '../interfaces/Comment';
import { IForumBody, IForumParams } from '../interfaces/Forum';
import { setErrors } from './errorActions';

export const getAllForums =
    (params: IForumParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_ALL_FORUMS,
            payload: { forumsLoading: true }
        });

        return request.getAllForums(params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_ALL_FORUMS,
                payload: { forums: res.data, forumsLoading: false }
            });
        });
    };

export const getFeaturedForums =
    (params: IForumParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_FEATURED_FORUMS,
            payload: { featuredLoading: true }
        });

        request
            .getAllForums({ ...params, featured: true })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_FEATURED_FORUMS,
                    payload: { featured: res.data, featuredLoading: false }
                });
            });
    };

export const getForum = (id: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_FORUM,
        payload: { forumLoading: true }
    });

    return request
        .getForum(id)
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_FORUM,
                payload: { forum: res.data, forumLoading: false }
            });

            return Promise.resolve((res.data?.comments_count ?? 0) as number);
        })
        .catch(() => {
            dispatch({
                type: GET_FORUM,
                payload: { forumLoading: false }
            });

            return Promise.reject();
        });
};

export const getTopAuthor = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_TOP_AUTHOR,
        payload: { authorsLoading: true }
    });

    return request
        .getTopAuthor()
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_TOP_AUTHOR,
                payload: { authors: res.data, authorsLoading: false }
            });
        })
        .catch(() => {
            dispatch({
                type: GET_TOP_AUTHOR,
                payload: { authorsLoading: false }
            });
        });
};

export const saveForum = (body: IForumBody) => async (dispatch: Dispatch) => {
    dispatch({ type: SAVE_FORUM_REQUEST });

    return request
        .saveForum(body)
        .then((res: AxiosResponse) => {
            dispatch({ type: SAVE_FORUM_SUCCESS });

            return Promise.resolve();
        })
        .catch((err: AxiosError) => {
            dispatch({ type: SAVE_FORUM_FAILURE });
            dispatch(
                setErrors(err.response?.data.message ?? 'Something went wrong')
            );

            return Promise.reject();
        });
};

export const getForumComments =
    (id: string, params: IForumCommentParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_FORUM_COMMENTS,
            payload: { commentsLoading: true }
        });

        return request
            .getComments(id, params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_FORUM_COMMENTS,
                    payload: { comments: res.data, commentsLoading: false }
                });

                return Promise.resolve(res.data?._id);
            })
            .catch(() => {
                dispatch({
                    type: GET_FORUM_COMMENTS,
                    payload: { commentsLoading: false }
                });

                return Promise.reject();
            });
    };

export const likeForum = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: LIKE_FORUM, payload: id });

    request.likeForum(id);
};

export const likeForumDetail = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: LIKE_FORUM_DETAIL, payload: id });

    request.likeForum(id);
};

export const likeComment = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: LIKE_COMMENT, payload: id });

    request.likeComment(id);
};

export const dislikeForum = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: DISLIKE_FORUM, payload: id });

    request.dislikeForum(id);
};

export const dislikeForumDetail =
    (id: string) => async (dispatch: Dispatch) => {
        dispatch({ type: DISLIKE_FORUM_DETAIL, payload: id });

        request.dislikeForum(id);
    };

export const dislikeComment = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: DISLIKE_COMMENT, payload: id });

    request.dislikeComment(id);
};

export const addCommentCount = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: ADD_COMMENT_COUNT, payload: id });
};

export const addReplyCount = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: ADD_REPLY_COUNT, payload: id });
};

export const resetForums = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_FORUMS
    });
};

export const resetForumComments = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_FORUM_COMMENTS
    });
};
