import Login from '../views/pages/auth/Login';
import Register from '../views/pages/auth/Register';
import Cart from '../views/pages/cart/Cart';
import Dashboard from '../views/pages/dashboard/Dashboard';
import About from '../views/pages/about/About';
import Home from '../views/pages/home/Home';
import TemplateDetails from '../views/pages/templateDetails/TemplateDetails';
import TemplateSearch from '../views/pages/templateSearch/TemplateSearch';
import UserOrders from '../views/pages/userOrders/UserOrders';
import UserPackages from '../views/pages/userPackages/UserPackages';
import ShopLanding from '../views/pages/shop/ShopLanding';
import ShopListing from '../views/pages/shop/ShopListing';
import TrainerProfile from '../views/pages/trainerProfile/TrainerProfile';
import TrainerPackages from '../views/pages/trainerProfile/TrainerPackages';
import TrainerImages from '../views/pages/trainerProfile/TrainerImages';
import TrainerVideos from '../views/pages/trainerProfile/TrainerVideos';
import VideoList from '../views/pages/videos/VideoList';
import ForumListing from '../views/pages/forum/ForumList';
import ForumDetails from '../views/pages/forum/ForumDetails';
import ShopDetails from '../views/pages/shop/ShopDetails';
import Checkout from '../views/pages/cart/Checkout';
import OrderConfirmed from '../views/pages/cart/OrderConfirmed';
import PrivacyPolicy from '../views/pages/privacy/PrivacyPolicy';
import PageNotFound from '../views/pages/pageNotFound/PageNotFound';
import EmailVerified from '../views/pages/auth/EmailVerificatiton';
import ForgotPassword from '../views/pages/auth/ForgotPassword';
import ResetPassword from '../views/pages/auth/ResetPassword';
import Contact from '../views/pages/contact/Contact';
import TrainerSearch from '../views/pages/trainerSearch/TrainerSearch';
import RegistrationConfirmation from '../views/pages/auth/RegistrationConfirmation';
import TrainerReviews from '../views/pages/trainerProfile/TrainerReviews';
import TrainingDetails from '../views/pages/trainingDetails/TrainingDetail';
import ProfileSettings from '../views/pages/settings/ProfileSettings';
import AdddressSettings from '../views/pages/settings/AddressSettings';
import ConsultationList from '../views/pages/coaching/ConsultationList';
import ConsultationDetails from '../views/pages/coaching/ConsultationDetails';
import CoachingList from '../views/pages/coaching/CoachingList';
import CoachingDetails from '../views/pages/coaching/CoachingDetails';
import UserTrainers from '../views/pages/userTrainers/UserTrainers';
import SubscribedCoaching from '../views/pages/coaching/SubscribedCoaching';
import EmailVerify from '../views/pages/auth/EmailVerify';
import UnsubscribeNewsfeed from '../views/pages/newsfeed/UnsubscribeNewsfeed';
import ChangePassword from '../views/pages/settings/ChangePassword';
import UserGifts from '../views/pages/userGifts/UserGifts';
import OrderDetail from '../views/pages/userOrders/OrderDetail';
import TermsOfService from '../views/pages/termsOfService/TermsOfService';
import RefundPolicy from '../views/pages/refundPolicy/RefundPolicy';
import OurStory from '../views/pages/ourStory/OurStory';
import OrderFailed from '../views/pages/cart/OrderFailed';
import BuyNow from '../views/pages/cart/BuyNow';

const routes = [
    {
        path: '/',
        component: Home,
        exact: true
    },
    {
        path: '/template',
        component: TemplateSearch,
        exact: true
    },
    {
        path: '/template/:slug',
        component: TemplateDetails
    },
    {
        path: '/cart',
        component: Cart,
        exact: true,
        protected: true
    },
    {
        path: '/cart/checkout',
        component: Checkout,
        protected: true
    },
    {
        path: '/express/checkout/cart',
        component: BuyNow,
        protected: true
    },
    {
        path: '/checkout/confirmed',
        component: OrderConfirmed
    },
    {
        path: '/checkout/failed',
        component: OrderFailed
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/register',
        component: Register,
        exact: true
    },
    {
        path: '/register/verify-account',
        component: RegistrationConfirmation
    },
    {
        path: '/forgot-password',
        component: ForgotPassword
    },
    {
        path: '/reset-password',
        component: ResetPassword
    },
    {
        path: '/email/verification',
        component: EmailVerify
    },
    {
        path: '/newsfeed/unsubscribe/:id',
        component: UnsubscribeNewsfeed
    },
    {
        path: '/dashboard',
        component: Dashboard,
        protected: true,
        exact: true
    },
    {
        path: '/dashboard/packages',
        component: UserPackages,
        protected: true,
        exact: true
    },
    {
        path: '/dashboard/trainers',
        component: UserTrainers,
        protected: true,
        exact: true
    },
    {
        path: '/dashboard/trainers/:id',
        component: SubscribedCoaching,
        protected: true,
        exact: true
    },
    {
        path: '/dashboard/packages/:id',
        component: TrainingDetails,
        protected: true
    },
    {
        path: '/dashboard/orders',
        component: UserOrders,
        protected: true,
        exact: true
    },
    {
        path: '/dashboard/orders/:id',
        component: OrderDetail,
        protected: true,
        exact: true
    },
    {
        path: '/dashboard/gifts',
        component: UserGifts,
        protected: true
    },
    {
        path: '/dashboard/settings',
        component: ProfileSettings,
        protected: true,
        exact: true
    },
    {
        path: '/dashboard/settings/address',
        component: AdddressSettings,
        protected: true,
        exact: true
    },
    {
        path: '/dashboard/settings/change-password',
        component: ChangePassword,
        protected: true,
        exact: true
    },
    {
        path: '/about',
        component: About
    },
    {
        path: '/shop',
        component: ShopLanding,
        exact: true
    },
    {
        path: '/shop/product/:slug',
        component: ShopDetails
    },
    {
        path: '/shop/search',
        component: ShopListing
    },
    {
        path: '/trainer/:slug/profile',
        component: TrainerProfile
    },
    {
        path: '/trainer/:slug/packages',
        component: TrainerPackages
    },
    {
        path: '/trainer/:slug/images',
        component: TrainerImages
    },
    {
        path: '/trainer/:slug/videos',
        component: TrainerVideos
    },
    {
        path: '/trainer/:slug/reviews',
        component: TrainerReviews
    },
    {
        path: '/videos',
        component: VideoList
    },
    {
        path: '/forum',
        component: ForumListing,
        exact: true
    },
    {
        path: '/forum/:id',
        component: ForumDetails,
        exact: true,
        protected: true
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/terms-of-service',
        component: TermsOfService
    },
    {
        path: '/refund-policy',
        component: RefundPolicy
    },
    {
        path: '/our-story',
        component: OurStory
    },
    {
        path: '/email-verified',
        component: EmailVerified
    },
    {
        path: '/contact',
        component: Contact
    },
    {
        path: '/trainers',
        component: TrainerSearch
    },
    {
        path: '/coaching',
        component: CoachingList,
        exact: true
    },
    {
        path: '/coaching/:slug',
        component: CoachingDetails,
        exact: true
    },
    {
        path: '/consultation',
        component: ConsultationList,
        exact: true
    },
    {
        path: '/consultation/:slug',
        component: ConsultationDetails,
        exact: true
    },
    {
        path: '',
        component: PageNotFound
    }
];

export default routes;
