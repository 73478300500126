import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './helpers/store';
import 'react-calendar/dist/Calendar.css';
import './assets/styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'yet-another-react-lightbox/dist/styles.css';
import 'yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
