import React, { FunctionComponent } from 'react';
import { FaCheck } from 'react-icons/fa';

interface CheckboxProps {
    className?: string;
    disabled?: boolean;
    checked?: boolean;
    containerClassName?: string;
    label: string;
    labelClassName?: string;
    name?: string;
    onChange?: (value: string) => void;
    size?: 'sm';
}

const AppCheckbox: FunctionComponent<CheckboxProps> = (props) => {
    const {
        className,
        disabled,
        checked,
        containerClassName,
        label,
        labelClassName,
        name,
        onChange,
        size
    } = props;

    const handleLabelClick = (
        e: React.MouseEvent<HTMLLabelElement, MouseEvent>
    ) => {
        e.preventDefault();
        onChange && onChange(label);
    };

    return (
        <label
            className={
                containerClassName + ' d-flex align-items-center cursor-pointer'
            }
            onClick={(e) => (!disabled ? handleLabelClick(e) : null)}
        >
            <span>
                <div
                    className={
                        className +
                        `app-checkbox d-flex align-items-center justify-content-center ${
                            size === 'sm'
                                ? 'app-checkbox-sm'
                                : 'app-checkbox-lg'
                        } ` +
                        (disabled ? '' : 'cursor-pointer')
                    }
                >
                    {(checked && (
                        <FaCheck
                            size={size === 'sm' ? 10 : 16}
                            className="text-red"
                        />
                    )) ||
                        null}
                    <input
                        type="checkbox"
                        name={name}
                        checked={checked}
                        onChange={() => onChange && onChange(label)}
                        disabled={disabled}
                        hidden
                    />
                </div>
            </span>

            <span
                className={
                    labelClassName +
                    ' text-neutrif text-16 text-gray app-check-label'
                }
            >
                {label}
            </span>
        </label>
    );
};
AppCheckbox.defaultProps = {
    checked: false,
    disabled: false,
    name: '',
    className: '',
    labelClassName: '',
    containerClassName: ''
};

export default AppCheckbox;
