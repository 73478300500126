import Cookies from 'universal-cookie';
import { api } from '../helpers/api';
import { store } from '../helpers/store';
import { LOGIN_SUCCESS } from '../helpers/constants';

export const checkAuthentication = async () => {
    const cookies = new Cookies();
    const token = await cookies.get('token');
    const authData = await cookies.get('authUser');
    const authToken = token ? token : null;

    if (authToken) {
        const token = `jwt ${authToken}`;
        api.defaults.headers.common['Authorization'] = token;
        store.dispatch({ type: LOGIN_SUCCESS, payload: authData });
    }
};
