import { CSSProperties, FunctionComponent } from 'react';
import { Image } from 'react-bootstrap';
import { BiDislike, BiLike } from 'react-icons/bi';
import { AiOutlineComment, AiOutlineEye } from 'react-icons/ai';
import { IForum } from '../../interfaces/Forum';
import { defaultImage } from '../../helpers/constants';
import dayjs from 'dayjs';
import relative from 'dayjs/plugin/relativeTime';
import { useDispatch, useSelector } from 'react-redux';
import { dislikeForum, likeForum } from '../../actions/forumActions';
import { useHistory, useLocation } from 'react-router-dom';
import { StateType } from '../../reducers';
import TruncateMarkup from 'react-truncate-markup';
import { toast } from 'react-toastify';

interface IProps {
    forum: IForum;
    className?: string;
    imgWrapperStyle?: CSSProperties;
}

const AppForumCard: FunctionComponent<IProps> = (props) => {
    dayjs.extend(relative);

    const { authenticated, authUser } = useSelector(
        (state: StateType) => state.auth
    );

    const { user_type } = useSelector((state: StateType) => state.user.user);

    const {
        _id,
        author,
        cover_image,
        topic,
        description,
        dislikes,
        likes,
        slug,
        has_liked,
        comments_count,
        views,
        createdAt
    } = props.forum;

    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();

    const goToLogin = () => {
        history.push('/login', {
            from: location
        });
    };

    const showActiveUserToast = () => {
        toast.error(
            'You need to be an active user. Please purchase an OxStrength package to view this discussion.',
            {
                position: toast.POSITION.TOP_CENTER
            }
        );
    };

    return (
        <div
            className={
                'd-flex p-3 f-card-container cursor-pointer ' + props.className
            }
            onClick={() => {
                authenticated
                    ? user_type !== 'Client' ||
                      authUser.is_active_member === true
                        ? history.push(`/forum/${_id}`)
                        : showActiveUserToast()
                    : goToLogin();
            }}
        >
            {!!cover_image && (
                <div
                    className="f-card-img-wrapper mr-3"
                    style={props.imgWrapperStyle}
                >
                    <img src={cover_image} className="f-card-img" alt={slug} />
                </div>
            )}

            <div className="f-card-detail-wrapper flex-grow-1 d-flex flex-column">
                <div className="flex-grow-1">
                    <div className="d-flex mb-3">
                        <div className="d-flex flex-grow-1">
                            <Image
                                roundedCircle
                                width={40}
                                height={40}
                                src={
                                    !!author?.profile_pic
                                        ? author.profile_pic
                                        : defaultImage
                                }
                                className="mr-2 object-cover"
                            />
                            <div>
                                <p className="text-600 text-14 mb-0 text-dark-blue">
                                    {author?.name}
                                </p>
                                <p className="text-gray text-14 mb-0">
                                    {author?.email}
                                </p>
                            </div>
                        </div>
                        <div>
                            <span className="ml-2 text-neutrif text-14 text-gray">
                                {dayjs(createdAt).fromNow()}
                            </span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <p className={`mb-1 text-dark-blue text-600`}>
                            {topic}
                        </p>

                        <TruncateMarkup lines={3}>
                            <p
                                className={`text-neutrif text-gray text-14 mb-0`}
                            >
                                {description}
                            </p>
                        </TruncateMarkup>
                    </div>
                </div>

                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center text-gray mr-3">
                            <div className="text-hover-red">
                                <BiLike
                                    size={18}
                                    className={`mr-1 cursor-pointer ${
                                        has_liked === true &&
                                        authUser.is_active_member === true
                                            ? 'text-red'
                                            : ''
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        if (_id)
                                            authenticated
                                                ? authUser.is_active_member ===
                                                  true
                                                    ? dispatch(likeForum(_id))
                                                    : showActiveUserToast()
                                                : goToLogin();
                                    }}
                                />
                            </div>

                            <span className="text-14 text-neutrif mt-1">
                                {likes ?? 0}
                            </span>
                        </div>

                        <div className="d-flex align-items-center text-gray">
                            <div className="text-hover-red">
                                <BiDislike
                                    size={18}
                                    className={`mr-1 cursor-pointer ${
                                        has_liked === false &&
                                        authUser.is_active_member === true
                                            ? 'text-red'
                                            : ''
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        if (_id)
                                            authenticated
                                                ? authUser.is_active_member ===
                                                  true
                                                    ? dispatch(
                                                          dislikeForum(_id)
                                                      )
                                                    : showActiveUserToast()
                                                : goToLogin();
                                    }}
                                />
                            </div>

                            <span className="text-14 text-neutrif mt-1">
                                {dislikes ?? 0}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="d-flex align-items-center text-hover-red cursor-pointer text-gray mr-3">
                            <AiOutlineComment size={18} className="mr-1" />
                            <span className="text-14 text-neutrif mt-1">
                                {comments_count ?? 0} Answers
                            </span>
                        </div>
                        <div className="d-flex align-items-center text-gray">
                            <AiOutlineEye size={18} className="mr-1" />
                            <span className="text-14 text-neutrif mt-1">
                                {views ?? 0} views
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AppForumCard.defaultProps = {
    className: ''
};

export default AppForumCard;
