export type $FIXME = any;

//Keys
export const GOOGLE_CLIENTID =
    process.env.REACT_APP_GOOGLE_CLIENT_ID ??
    '1045802231043-fn2iq728iolnkvpvj6heb908fn7ak802.apps.googleusercontent.com';
export const FACEBOOK_APPID =
    process.env.REACT_APP_FACEBOOK_APP_ID ?? '1950580658287646';
export const CELLPAY_MERCHANTID =
    process.env.REACT_APP_CELLPAY_MERCHANT_ID ?? '9841980389';
export const esewaURL =
    process.env.REACT_APP_ESEWA_URL ?? 'https://uat.esewa.com.np/epay/main';
export const esewaClientId =
    process.env.REACT_APP_ESEWA_CLIENT_ID ?? 'EPAYTEST';
export const fonePayURL =
    (process.env.REACT_APP_FONEPAY_URL ?? 'https://dev-clientapi.fonepay.com') +
    '/api/merchantRequest';
export const fonepayClientId =
    process.env.REACT_APP_FONEPAY_CLIENT_ID ?? 'Sudan0123';
export const redirectUrl =
    process.env.REACT_APP_REDIRECT_URL ?? 'https://oxstrength-web.asterdio.xyz';
export const stripeKey =
    process.env.REACT_APP_STRIPE_KEY ??
    'pk_test_51K6ocoJUVzsRrpsQrqHOH1T9chqyj9brbUXLvl4kdRElc3aA8KEfS2auhr14fqNUtth4h34VkO1zqBstGvk7hybd00vbg1c4hy';

// Actions
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_CONSULTAION_MODAL = 'TOGGLE_CONSULTAION_MODAL';

//Users
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const GET_USER_ORDERS = 'GET_USER_ORDERS';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';

export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const GET_USER = 'GET_USER';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

//Templates
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_FEATURED_TEMPLATES = 'GET_FEATURED_TEMPLATES';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const GET_TEMPLATE_REVIEWS = 'GET_TEMPLATE_REVIEWS';
export const RESET_TEMPLATE_REVIEWS = 'RESET_TEMPLATE_REVIEWS';
export const GET_SUBSCRIBED_TEMPLATES = 'GET_SUBSCRIBED_TEMPLATES';
export const GET_SUBSCRIBED_TEMPLATE = 'GET_SUBSCRIBED_TEMPLATE';
export const RESET_TEMPLATE_STATE = 'RESET_TEMPLATE_STATE';
export const GET_SIMILAR_TEMPLATES = 'GET_SIMILAR_TEMPLATES';
export const GET_EXPIRED_TEMPLATES = 'GET_EXPIRED_TEMPLATES';
export const UPDATE_SUBSCRIBED_TEMPLATE = 'UPDATE_SUBSCRIBED_TEMPLATE';

//Categories
export const GET_TRAINING_CATEGORIES = 'GET_TRAINING_CATEGORIES';
export const GET_TEMPLATE_CATEGORIES = 'GET_TEMPLATE_CATEGORIES';
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_BRANDS = 'GET_PRODUCT_BRANDS';

//Products
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
export const RESET_PRODUCT_REVIEWS = 'RESET_PRODUCT_REVIEWS';
export const RESET_PRODUCT_STATE = 'RESET_PRODUCT_STATE';
export const GET_SIMILAR_PRODUCTS = 'GET_SIMILAR_PRODUCTS';
export const GET_APPARELS = 'GET_APPARELS';
export const GET_GYM_GEARS = 'GET_GYM_GEARS';
export const GET_SUPPLIMENTS = 'GET_SUPPLIMENTS';

//Carts
export const GET_CARTS = 'GET_CARTS';

//Trainers
export const GET_TRAINERS = 'GET_TRAINERS';
export const GET_TRAINER = 'GET_TRAINER';
export const RESET_TRAINER_STATE = 'RESET_TRAINER_STATE';
export const GET_TRAINER_REVIEW = 'GET_TRAINER_REVIEW';
export const GET_TRAINER_COACHING_ACHIEVEMENTS =
    'GET_TRAINER_COACHING_ACHIEVEMENTS';
export const GET_TRAINER_PERSONAL_ACHIEVEMENTS =
    'GET_TRAINER_PERSONAL_ACHIEVEMENTS';
export const GET_TRAINER_PACKAGES = 'GET_TRAINER_PACKAGES';

//Testimonials
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';

//Newsfeed
export const SUBSCRIBE_NEWSFEED_REQUEST = 'SUBSCRIBE_NEWSFEED_REQUEST';
export const SUBSCRIBE_NEWSFEED_SUCCESS = 'SUBSCRIBE_NEWSFEED_SUCCESS';
export const SUBSCRIBE_NEWSFEED_FAIL = 'SUBSCRIBE_NEWSFEED_FAIL';

//Multimedia
export const GET_MULTIMEDIAS = 'GET_MULTIMEDIAS';
export const GET_TRAINER_MEDIA = 'GET_TRAINER_MEDIA';

//Contacts
export const GET_CONTACTINFO = 'GET_CONTACTINFO';
export const SAVE_CONTACT_REQUEST = 'SAVE_CONTACT_REQUEST';
export const SAVE_CONTACT_SUCCESS = 'SAVE_CONTACT_SUCCESS';
export const SAVE_CONTACT_FAILURE = 'SAVE_CONTACT_SUCCESS';

//Delivery Address
export const GET_DELIVERY_ADDRESS = 'GET_DELIVERY_ADDRESS';
export const SAVE_DELIVERY_REQUEST = 'SAVE_DELIVERY_REQUEST';
export const SAVE_DELIVERY_SUCCESS = 'SAVE_DELIVERY_SUCCESS';
export const SAVE_DELIVERY_FAILURE = 'SAVE_DELIVERY_FAILURE';

//Payment
export const INITIATE_ORDER_REQUEST = 'INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_FAILURE = 'INITIATE_ORDER_FAILURE';

//Province & District
export const GET_PROVINCES = 'GET_PROVINCES';
export const GET_DISTRICTS = 'GET_DISTRICTS';

//Consultation
export const GET_CONSULTATIONS = 'GET_CONSULTATIONS';
export const GET_CONSULTATION = 'GET_CONSULTATION';
export const GET_CONSULTAION_REVIEWS = 'GET_CONSULTAION_REVIEWS';
export const RESET_CONSULTATION_REVIEWS = 'RESET_CONSULTATION_REVIEWS';
export const GET_SUBSCRIBED_CONSULTATIONS = 'GET_SUBSCRIBED_CONSULTATIONS';
export const GET_USER_CONSULTATIONS = 'GET_USER_CONSULTATIONS';
export const GET_TEMPLATE_CONSULTATIONS = 'GET_TEMPLATE_CONSULTATIONS';
export const GET_COACHING_CONSULTATIONS = 'GET_COACHING_CONSULTATIONS';
export const RESET_CONSULTATION_STATE = 'RESET_CONSULTATION_STATE';
export const UPDATE_SUBSCRIBED_CONSULTATION = 'UPDATE_SUBSCRIBED_CONSULTATION';

//Coaching
export const GET_COACHINGS = 'GET_COACHINGS';
export const GET_COACHING = 'GET_COACHING';
export const GET_COACHING_REVIEWS = 'GET_COACHING_REVIEWS';
export const RESET_COACHING_REVIEWS = 'RESET_COACHING_REVIEWS';
export const GET_SUBSCRIBED_COACHINGS = 'GET_SUBSCRIBED_COACHINGS';
export const GET_SUBSCRIBED_COACHING = 'GET_SUBSCRIBED_COACHING';
export const RESET_COACHING_STATE = 'RESET_COACHING_STATE';
export const UPDATE_SUBSCRIBED_COACHING = 'UPDATE_SUBSCRIBED_COACHING';

//Dashbaord
export const GET_PROFILE_COUNT = 'GET_PROFILE_COUNT';

//Gifts
export const GIFTED_TEMPLATES_REQUEST = 'GIFTED_TEMPLATES_REQUEST';
export const GIFTED_TEMPLATES_SUCCESS = 'GIFTED_TEMPLATES_SUCCESS';
export const GIFTED_TEMPLATES_FAILURE = 'GIFTED_TEMPLATES_FAILURE';
export const RECEIVED_TEMPLATES_REQUEST = 'RECEIVED_TEMPLATES_REQUEST';
export const RECEIVED_TEMPLATES_SUCCESS = 'RECEIVED_TEMPLATES_SUCCESS';
export const RECEIVED_TEMPLATES_FAILURE = 'RECEIVED_TEMPLATES_FAILURE';

export const GIFTED_COACHINGS_REQUEST = 'GIFTED_COACHINGS_REQUEST';
export const GIFTED_COACHINGS_SUCCESS = 'GIFTED_COACHINGS_SUCCESS';
export const GIFTED_COACHINGS_FAILURE = 'GIFTED_COACHINGS_FAILURE';
export const RECEIVED_COACHINGS_REQUEST = 'RECEIVED_COACHINGS_REQUEST';
export const RECEIVED_COACHINGS_SUCCESS = 'RECEIVED_COACHINGS_SUCCESS';
export const RECEIVED_COACHINGS_FAILURE = 'RECEIVED_COACHINGS_FAILURE';

export const GIFTED_CONSULTATIONS_REQUEST = 'GIFTED_CONSULTATIONS_REQUEST';
export const GIFTED_CONSULTATIONS_SUCCESS = 'GIFTED_CONSULTATIONS_SUCCESS';
export const GIFTED_CONSULTATIONS_FAILURE = 'GIFTED_CONSULTATIONS_FAILURE';
export const RECEIVED_CONSULTATIONS_REQUEST = 'RECEIVED_CONSULTATIONS_REQUEST';
export const RECEIVED_CONSULTATIONS_SUCCESS = 'RECEIVED_CONSULTATIONS_SUCCESS';
export const RECEIVED_CONSULTATIONS_FAILURE = 'RECEIVED_CONSULTATIONS_FAILURE';

//Offers
export const GET_ALL_OFFERS = 'GET_ALL_OFFERS';
export const GET_PRODUCT_OFFERS = 'GET_PRODUCT_OFFERS';
export const GET_TEMPLATE_OFFERS = 'GET_TEMPLATE_OFFERS';
export const GET_COACHING_OFFERS = 'GET_COACHING_OFFERS';
export const GET_CONSULTATION_OFFERS = 'GET_CONSULTATION_OFFERS';

//Forums
export const GET_ALL_FORUMS = 'GET_ALL_FORUMS';
export const GET_FORUM = 'GET_FORUM';
export const GET_TOP_AUTHOR = 'GET_TOP_AUTHOR';
export const GET_FEATURED_FORUMS = 'GET_FEATURED_FORUMS';
export const SAVE_FORUM_REQUEST = 'SAVE_FORUM_REQUEST';
export const SAVE_FORUM_SUCCESS = 'SAVE_FORUM_SUCCESS';
export const SAVE_FORUM_FAILURE = 'SAVE_FORUM_FAILURE';
export const RESET_FORUMS = 'RESET_FORUMS';
export const LIKE_FORUM = 'LIKE_FORUM';
export const DISLIKE_FORUM = 'DISLIKE_FORUM';
export const LIKE_FORUM_DETAIL = 'LIKE_FORUM_DETAIL';
export const DISLIKE_FORUM_DETAIL = 'DISLIKE_FORUM_DETAIL';
export const ADD_COMMENT_COUNT = 'ADD_COMMENT_COUNT';
export const ADD_REPLY_COUNT = 'ADD_REPLY_COUNT';

//Comments
export const GET_FORUM_COMMENTS = 'GET_FORUM_COMMENTS';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const DISLIKE_COMMENT = 'DISLIKE_COMMENT';
export const RESET_FORUM_COMMENTS = 'RESET_FORUM_COMMENTS';

export const sortFilter = [
    {
        option: 'popular',
        label: 'Most Popular'
    },
    {
        option: 'latest',
        label: 'Most Recent'
    },
    {
        option: 'expensive',
        label: 'Price: High to Low'
    },
    {
        option: 'cheap',
        label: 'Price: Low to High'
    }
];

export const sortReviewFilter = [
    {
        option: 'popular',
        label: 'Highest Rating'
    },
    {
        option: 'unpopular',
        label: 'Lowest Rating'
    },
    {
        option: 'latest',
        label: 'Most Recent'
    },
    {
        option: 'oldest',
        label: 'Oldest'
    }
];

export const coachingFilter = [
    {
        option: 'popular',
        label: 'Most Popular'
    },
    {
        option: 'latest',
        label: 'Most Recent'
    }
];

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const URL_REGEX =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;

export const relation = [
    { label: 'Family' },
    { label: 'Friend' },
    { label: 'Other' }
];

export const defaultImage =
    'https://firebasestorage.googleapis.com/v0/b/oxstrength-4d9bf.appspot.com/o/images%2Fdefault-image.jpg?alt=media&token=58aaa54c-1e27-4f88-a521-bc6436bd10be';

//SHOP CATGEGORY ID
export const apparelId = '61e645bc5ede3b368c5d001a';
export const gymGearId = '61e645545ede3b368c5d0014';
export const supplimentsId = '61e63cfcef0a4613e06612ee';
