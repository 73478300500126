import {
    GET_COACHING,
    GET_COACHINGS,
    GET_SUBSCRIBED_COACHINGS,
    RESET_COACHING_STATE,
    GET_COACHING_REVIEWS,
    GET_SUBSCRIBED_COACHING,
    RESET_COACHING_REVIEWS,
    UPDATE_SUBSCRIBED_COACHING
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import {
    Coaching,
    ICoaching,
    ICoachingReview,
    ISubscribedCoaching,
    ISubscribedCoachingDetail,
    ISubscribedCoachings
} from '../interfaces/Coaching';

export interface ICoachingState {
    coachings: ICoaching;
    coaching: Coaching;
    coachingLoading: boolean;
    coachingsLoading: boolean;
    coachingReviews: ICoachingReview;
    coachingReviewsLoading: boolean;
    subscribedCoachings: ISubscribedCoachings;
    subscribedCoachingsLoading: boolean;
    subscribedCoaching: ISubscribedCoachingDetail;
    subscribedCoachingLoading: boolean;
}

const initialState: ICoachingState = {
    coaching: {},
    coachingLoading: false,
    coachings: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        coachings: []
    },
    coachingsLoading: false,
    coachingReviews: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        reviews: []
    },
    coachingReviewsLoading: false,
    subscribedCoachings: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        coachings: []
    },
    subscribedCoachingsLoading: false,
    subscribedCoaching: {},
    subscribedCoachingLoading: false
};

const coachingReducer = (
    state = initialState,
    { payload, type }: ActionType
): ICoachingState => {
    switch (type) {
        case GET_COACHINGS:
            return {
                ...state,
                coachingsLoading: payload?.coachingsLoading,
                coachings: {
                    ...state.coachings,
                    ...payload.coachings,
                    coachings: [
                        ...state.coachings.coachings,
                        ...(payload?.coachings?.coachings ?? [])
                    ]
                }
            };

        case GET_COACHING:
            return {
                ...state,
                ...payload
            };

        case GET_COACHING_REVIEWS:
            return {
                ...state,
                coachingReviewsLoading: payload?.coachingReviewsLoading,
                coachingReviews: {
                    ...state.coachingReviews,
                    ...payload.coachingReviews,
                    reviews: [
                        ...state.coachingReviews.reviews,
                        ...(payload?.coachingReviews?.reviews ?? [])
                    ]
                }
            };

        case GET_SUBSCRIBED_COACHINGS:
            return {
                ...state,
                ...payload,
                subscribedCoachings: {
                    ...state.subscribedCoachings,
                    ...payload.subscribedCoachings,
                    coachings: [
                        ...state.subscribedCoachings.coachings,
                        ...(payload?.subscribedCoachings?.coachings ?? [])
                    ]
                }
            };

        case GET_SUBSCRIBED_COACHING:
            return {
                ...state,
                ...payload
            };

        case RESET_COACHING_STATE:
            return initialState;

        case RESET_COACHING_REVIEWS:
            return {
                ...state,
                coachingReviews: {
                    ...state.coachingReviews,
                    reviews: []
                }
            };

        case UPDATE_SUBSCRIBED_COACHING:
            let subscribedCoaching = payload as ISubscribedCoaching;

            return {
                ...state,
                subscribedCoachings: {
                    ...state.subscribedCoachings,
                    coachings: state.subscribedCoachings.coachings.map(
                        (coaching) =>
                            coaching._id === subscribedCoaching._id
                                ? {
                                      ...coaching,
                                      ...subscribedCoaching
                                  }
                                : coaching
                    )
                }
            };

        default:
            return state;
    }
};

export default coachingReducer;
