import AppButton from '../components/AppButton';
import Logo from '../../assets/images/logo.png';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../actions/uiActions';
import { $FIXME, GOOGLE_CLIENTID } from '../../helpers/constants';
import { LoginSource } from '../../interfaces/User';
import Cookies from 'universal-cookie';
import { GoogleLogout } from 'react-google-login';
import { StateType } from '../../reducers';

const SideBarContent = () => {
    const toggleState = useSelector((state: StateType) => state.ui.drawer);
    const cookies = new Cookies();
    const auth = useSelector((state: StateType) => state.auth);

    const dispatch = useDispatch();

    const loginSource: LoginSource = cookies.get('loginSource');

    const handleNavClick = () => {
        dispatch(toggleDrawer(!toggleState));
    };

    const handleLogout = () => {
        const cookiesNames = [
            'authUser',
            'authenticated',
            'token',
            'loginSource'
        ];
        for (const cookie in cookiesNames) {
            cookies.remove(cookiesNames[cookie], { path: '/' });
        }

        (window as $FIXME).location.reload();
    };

    return (
        <div className="sidebar-container">
            <div className="d-flex flex-column p-3 align-items-center h-100">
                <Link to="/" onClick={handleNavClick} className="mb-5">
                    <img src={Logo} width={120} alt="OxStrength" />
                </Link>

                <div className="flex-grow-1 d-flex flex-column w-100">
                    <NavLink
                        exact
                        to="/template"
                        className="w-100 text-16 py-2 text-center text-uppercase mb-2"
                        activeClassName="text-white bg-red"
                        onClick={handleNavClick}
                    >
                        Templates
                    </NavLink>

                    <NavLink
                        exact
                        to="/forum"
                        className="w-100 text-16 py-2 text-center text-uppercase mb-2"
                        activeClassName="text-white bg-red"
                        onClick={handleNavClick}
                    >
                        Discussions
                    </NavLink>

                    <NavLink
                        to="/coaching"
                        className="w-100 text-16 py-2 text-center text-uppercase mb-2"
                        activeClassName="text-white bg-red"
                        onClick={handleNavClick}
                    >
                        Coaching
                    </NavLink>

                    <NavLink
                        to="/consultation"
                        className="w-100 text-16 py-2 text-center text-uppercase mb-2"
                        activeClassName="text-white bg-red"
                        onClick={handleNavClick}
                    >
                        Consultation
                    </NavLink>

                    <NavLink
                        to="/shop"
                        className="w-100 text-16 py-2 text-center text-uppercase mb-2"
                        activeClassName="text-white bg-red"
                        onClick={handleNavClick}
                    >
                        Ox Shop
                    </NavLink>

                    <NavLink
                        to="/about"
                        className="w-100 text-16 py-2 text-center text-uppercase mb-2"
                        activeClassName="text-white bg-red"
                        onClick={handleNavClick}
                    >
                        About
                    </NavLink>
                </div>

                {auth.authenticated && loginSource ? (
                    <div className="w-100 mb-3">
                        {loginSource === 'google' ? (
                            <GoogleLogout
                                clientId={GOOGLE_CLIENTID}
                                render={(props) => (
                                    <AppButton
                                        variant="secondary"
                                        className="w-100"
                                        onClick={() => {
                                            props.onClick();
                                            handleLogout();
                                        }}
                                    >
                                        Logout
                                    </AppButton>
                                )}
                            ></GoogleLogout>
                        ) : (
                            <AppButton
                                variant="secondary"
                                className="w-100"
                                onClick={handleLogout}
                            >
                                Logout
                            </AppButton>
                        )}
                    </div>
                ) : (
                    <>
                        <Link
                            to="/login"
                            className="w-100 mb-3"
                            onClick={handleNavClick}
                        >
                            {' '}
                            <AppButton variant="primary" className="w-100">
                                Sign In
                            </AppButton>
                        </Link>

                        <Link
                            to="/register"
                            className="w-100"
                            onClick={handleNavClick}
                        >
                            {' '}
                            <AppButton variant="secondary" className="w-100">
                                Register
                            </AppButton>
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default SideBarContent;
