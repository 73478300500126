import { Row, Col } from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppButton from '../../components/AppButton';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import GetOffers from '../templateSearch/GetOffers';
import { Form } from 'react-bootstrap';
import AppInput from '../../components/AppInput';
import SocialButton from '../../components/SocialButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    facebookLogin,
    googleLogin,
    login,
    resetAuthState
} from '../../../actions/authActions';
import {
    $FIXME,
    FACEBOOK_APPID,
    GOOGLE_CLIENTID
} from '../../../helpers/constants';
import { StateType } from '../../../reducers';
import { useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import FacebookLogin, {
    ReactFacebookLoginInfo
} from 'react-facebook-login/dist/facebook-login-render-props';
import { setErrors } from '../../../actions/errorActions';
import { getAllOffers } from '../../../actions/offerActions';

interface IProps {}

const Login = (props: IProps) => {
    const dispatch = useDispatch();
    const auth = useSelector((state: StateType) => state.auth);
    const location = useLocation();
    const history = useHistory();

    const { from }: $FIXME = location.state || { from: { pathname: '/' } };

    useEffect(() => {
        dispatch(resetAuthState());
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Search',
            link: '/template'
        },
        {
            label: 'Authentication',
            link: '/login',
            active: true
        }
    ];

    const initialValues = {
        email: '',
        password: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string().required('Password is required')
    });

    const handleSubmit = (values: $FIXME) => {
        dispatch(login(values));
    };

    const responseFacebook = (response: ReactFacebookLoginInfo) => {
        dispatch(facebookLogin(response.accessToken));
    };

    if (auth.redirectToReferrer) {
        history.replace(from);
    }

    return (
        <MainLayout>
            <div className="container-xxl container-xl login-container">
                <AppBreadcrumbs items={breadcrumbItems} />
                <div className="mb-5 pt-3">
                    <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                        Login
                    </h2>
                    <p className="text-gray text-14 mb-0">
                        Search and find the best templates in town
                    </p>
                </div>
                <Row>
                    <Col md={6} className="mb-5">
                        <div className="login-form auth-wrapper-bg p-4">
                            <h2 className="text-18 text-600 text-uppercase">
                                Registered Customers
                            </h2>
                            <p className="text-14 text-dark-3">
                                If you have an account, sign in with your email
                                address.
                            </p>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    isValid,
                                    errors
                                }) => (
                                    <Form
                                        noValidate
                                        className="d-flex flex-column"
                                        onSubmit={handleSubmit}
                                    >
                                        <AppInput
                                            type="email"
                                            label="Email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                            error={errors.email}
                                            required
                                            placeholder="Email Address"
                                            fill
                                        />
                                        <AppInput
                                            type="password"
                                            label="Password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                            error={errors.password}
                                            required
                                            placeholder="Password here"
                                            fill
                                        />
                                        {auth.loginError ? (
                                            <span className="text-12 text-red mb-3">
                                                {auth.loginError}
                                            </span>
                                        ) : (
                                            ''
                                        )}

                                        <Link
                                            to="/forgot-password"
                                            className="mb-4 text-inter text-14"
                                        >
                                            Forgot Your Password?
                                        </Link>
                                        <AppButton
                                            variant="primary"
                                            type="submit"
                                            disabled={
                                                auth.loginLoading || !isValid
                                            }
                                        >
                                            Sign In
                                        </AppButton>
                                    </Form>
                                )}
                            </Formik>

                            <div className="d-flex align-items-center py-4">
                                <hr className="flex-grow-1" />
                                <span className="px-3 text-14">
                                    Or Sign in With
                                </span>
                                <hr className="flex-grow-1" />
                            </div>
                            <Row
                                className="social-btn-container mx-n2 mx-lg-5"
                                noGutters
                            >
                                <Col
                                    lg={6}
                                    className="mb-3 p-2 pl-lg-4 pl-xl-5"
                                >
                                    <GoogleLogin
                                        clientId={GOOGLE_CLIENTID}
                                        render={(renderProps) => (
                                            <SocialButton
                                                variant="Google"
                                                className="w-100"
                                                onClick={renderProps.onClick}
                                            />
                                        )}
                                        onSuccess={(response: $FIXME) => {
                                            dispatch(
                                                googleLogin(response.tokenId)
                                            );
                                        }}
                                        onFailure={(response) => {
                                            dispatch(
                                                setErrors(
                                                    response ?? 'Login Failed'
                                                )
                                            );
                                        }}
                                    />
                                </Col>
                                <Col
                                    lg={6}
                                    className="mb-3 p-2 pr-lg-4 pr-xl-5"
                                >
                                    <FacebookLogin
                                        appId={FACEBOOK_APPID}
                                        callback={responseFacebook}
                                        render={(renderProps) => {
                                            return (
                                                <SocialButton
                                                    variant="Facebook"
                                                    className="w-100"
                                                    onClick={() =>
                                                        renderProps.onClick()
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="mb-5">
                        <div className="auth-wrapper-bg p-4">
                            <h2 className="text-18 text-600 text-uppercase">
                                New Here?
                            </h2>
                            <p className="text-14 text-dark-3">
                                Creating an account has many benefits:
                            </p>
                            <ul className="mb-4 text-14 text-dark-3 pl-4">
                                <li>Check out faster</li>
                                <li>Buy more than one templates</li>
                                <li>Track orders and your progress as well</li>
                            </ul>
                            <Link to="/register">
                                <AppButton variant="secondary" className="mb-5">
                                    Create An Account
                                </AppButton>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
            <GetOffers offerType="all" />
            <GetNotified />
        </MainLayout>
    );
};

export default Login;
