import React, { useEffect } from 'react';
import { Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { StateType } from '../../../reducers';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppTemplateCard from '../../components/AppTemplateCard';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import Offers from '../home/Offers';
import queryString from 'query-string';
import {
    $FIXME,
    defaultImage,
    coachingFilter as sortFilter
} from '../../../helpers/constants';
import { AppTemplateCardSkeleton } from '../../components/AppSkeleton';
import AppInput from '../../components/AppInput';
import { useDebouncedCallback } from 'use-debounce/lib';
import CoachingFilter from './CoachingFilter';
import GetOffers from '../templateSearch/GetOffers';
import AppButton from '../../components/AppButton';
import {
    getCoachings,
    resetCoachingState
} from '../../../actions/coachingActions';
import AppNotFound from '../../components/AppNotFound';
import { getCoachingOffers } from '../../../actions/offerActions';

interface IProps {}

const CoachingList = (props: IProps) => {
    const { coachings, coachingsLoading } = useSelector(
        (state: StateType) => state.coaching
    );

    const dispatch = useDispatch();

    const location = useLocation();

    const history = useHistory();

    const query: $FIXME = queryString.parse(location.search);

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Search',
            link: '/coaching',
            active: true
        }
    ];

    const perPage = '9';

    useEffect(() => {
        dispatch(resetCoachingState());
        dispatch(
            getCoachings({
                sort: query.sort ?? sortFilter[0].option,
                q: query.query,
                resultsPerPage: perPage
            })
        );
    }, [dispatch, query.sort, query.query]);

    useEffect(() => {
        dispatch(getCoachingOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    const handleSearch = useDebouncedCallback((value) => {
        history.push({
            pathname: `/coaching`,
            search: new URLSearchParams({
                ...query,
                query: value
            }).toString()
        });
    }, 500);

    const handleDropdown = (e: $FIXME) => {
        history.push({
            pathname: `/coaching`,
            search: new URLSearchParams({
                ...query,
                sort: e
            }).toString()
        });
    };

    const handleLoadMore = () => {
        if (coachings.currentPage !== coachings.totalPage)
            dispatch(
                getCoachings({
                    sort: query.sort,
                    q: query.query,
                    resultsPerPage: perPage,
                    page: (coachings.currentPage + 1).toString()
                })
            );
    };

    return (
        <MainLayout>
            <div className="container-xxxl t-search-container">
                <div className="px-lg-3">
                    <AppBreadcrumbs items={breadcrumbItems} />
                    <Row className="mb-3 mb-lg-5">
                        <Col>
                            <h2 className="text-700 text-dark-blue text-uppercase">
                                Coaching
                            </h2>
                            <p className="text-gray text-14">
                                Search and find the best coaches in town
                            </p>
                        </Col>
                        <Col lg={4}>
                            <AppInput
                                placeholder="Search coaching packages"
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => handleSearch(e.target.value)}
                                defaultValue={query.query}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col lg={3} md={12}>
                            <CoachingFilter />
                        </Col>
                        <Col className="template-search-main">
                            <div className="d-flex mb-3 flex-wrap">
                                <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-2 mr-3">
                                    ONE-ON-ONE ONLINE TRAINING
                                </h2>
                                <div className="d-flex flex-grow-1 justify-content-between align-items-center mb-2">
                                    <p className="text-inter text-500 text-14 text-gray mb-0 mr-2">
                                        Found{' '}
                                        <span className="text-dark-blue text-700">
                                            {coachings.totalDocuments}
                                        </span>{' '}
                                        Results
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-3 text-inter text-500 text-14 text-gray">
                                            Sort By
                                        </span>
                                        <DropdownButton
                                            menuAlign="right"
                                            title={
                                                sortFilter.find(
                                                    (sort) =>
                                                        sort.option ===
                                                        query.sort
                                                )?.label ?? sortFilter[0].label
                                            }
                                            variant="outline-secondary"
                                            size="sm"
                                            className="template-filter-dropdown"
                                            onSelect={handleDropdown}
                                        >
                                            {sortFilter.map((filter, index) => (
                                                <Dropdown.Item
                                                    eventKey={filter.option}
                                                    active={
                                                        filter.option ===
                                                        query.sort
                                                    }
                                                >
                                                    {filter.label}
                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>

                            {!coachingsLoading &&
                            coachings.coachings.length === 0 ? (
                                <AppNotFound type="Coaching" />
                            ) : (
                                <Row
                                    lg={3}
                                    sm={3}
                                    xs={2}
                                    className="pl-3 mr-sm-n5"
                                >
                                    {coachings.coachings.map(
                                        (coaching, index) => (
                                            <Link
                                                to={
                                                    '/coaching/' + coaching.slug
                                                }
                                                className="pr-3 pr-sm-5"
                                                key={index}
                                            >
                                                <AppTemplateCard
                                                    title={
                                                        coaching.title ?? 'N/A'
                                                    }
                                                    subtitle=""
                                                    squareImage
                                                    img={
                                                        !!coaching.cover
                                                            ? coaching.cover
                                                            : defaultImage
                                                    }
                                                    key={coaching._id}
                                                    ribbonText={
                                                        coaching.discount
                                                            ?.active
                                                            ? coaching.discount
                                                                  .title
                                                            : ''
                                                    }
                                                />
                                            </Link>
                                        )
                                    )}
                                </Row>
                            )}

                            {coachingsLoading ? (
                                <Row lg={3} sm={3} xs={2} className="pr-3">
                                    {[...Array(8)].map((item, i) => (
                                        <AppTemplateCardSkeleton
                                            squareImage
                                            key={i}
                                            className="pl-3"
                                        />
                                    ))}
                                </Row>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                    {coachings.currentPage === coachings.totalPage ||
                    coachings.totalDocuments === 0 ? (
                        ''
                    ) : (
                        <div className="mb-5 d-flex justify-content-center justify-content-lg-end">
                            <AppButton
                                variant="outline-primary"
                                onClick={handleLoadMore}
                                loading={coachingsLoading}
                            >
                                Load More Templates
                            </AppButton>
                        </div>
                    )}
                </div>
            </div>
            <GetOffers offerType="coaching" />
            <Offers />
            <GetNotified />
        </MainLayout>
    );
};

export default CoachingList;
