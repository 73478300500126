import React from 'react';
import { BiFilter } from 'react-icons/bi';

interface CustomToggleProps {
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
}

const CustomToggle = React.forwardRef(
    (
        { children, onClick }: CustomToggleProps,
        ref: React.Ref<HTMLButtonElement>
    ) => (
        <button
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="text-oswald text-dark-blue text-16 d-flex align-items-center custom-toggle px-3 py-1"
        >
            {children}
            <BiFilter size={24} className="text-red ml-3" />
        </button>
    )
);

export default CustomToggle;
