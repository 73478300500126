import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    SUBSCRIBE_NEWSFEED_FAIL,
    SUBSCRIBE_NEWSFEED_REQUEST,
    SUBSCRIBE_NEWSFEED_SUCCESS
} from '../helpers/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { ISubscribeNewsfeedBody } from '../interfaces/NewsFeed';
import { toast } from 'react-toastify';
import { FormikState } from 'formik';

export const subscribeNewsfeed =
    (
        body: ISubscribeNewsfeedBody,
        resetForm: (
            nextState?: Partial<FormikState<ISubscribeNewsfeedBody>>
        ) => void
    ) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: SUBSCRIBE_NEWSFEED_REQUEST
        });

        request
            .subscribeNewsfeed(body)
            .then((res: AxiosResponse) => {
                resetForm();

                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch({
                    type: SUBSCRIBE_NEWSFEED_SUCCESS
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: SUBSCRIBE_NEWSFEED_FAIL
                });
            });
    };
