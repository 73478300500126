import DashboardNav from './DashboardNav';
import MainLayout from './MainLayout';

interface IProps {
    children: React.ReactNode;
}

const DashboardLayout = (props: IProps) => {
    return (
        <MainLayout hideNavBorder>
            <DashboardNav />

            <div className="container-xxxl">
                <div className="px-lg-3">{props.children}</div>
            </div>
        </MainLayout>
    );
};

export default DashboardLayout;
