import { FunctionComponent, useEffect, useState } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

interface IProps {
    min?: number;
    max?: number;
    value?: number;
    onChange?: (value: number) => void;
    disabled?: boolean;
    className?: string;
    size?: 'sm' | 'lg';
}

const ProductCounter: FunctionComponent<IProps> = (props) => {
    const [counter, setCounter] = useState<number>(props.value ?? 1);

    const handleIncrement = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        if (props.max && counter >= props.max) {
            return;
        } else {
            setCounter(counter + 1);
            props.onChange && props.onChange(counter + 1);
        }
    };

    const handleDecrement = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        if (props.min && counter <= props.min) {
            return;
        } else {
            setCounter(counter - 1);
            props.onChange && props.onChange(counter - 1);
        }
    };

    useEffect(() => {
        if (props.value) setCounter(props.value);
    }, [props.value]);

    return (
        <div
            className={`product-counter-container d-inline-flex align-items-center my-2 ${
                props.className
            } ${
                props.size === 'sm'
                    ? 'product-counter-sm'
                    : 'product-counter-lg'
            }`}
        >
            <div
                className={
                    `${
                        props.size === 'sm' ? 'px-1' : 'px-2'
                    } h-100 product-counter-icon-wrapper` +
                    (!props.disabled ? ' cursor-pointer' : ' opacity-25')
                }
                onClick={!props.disabled ? handleDecrement : undefined}
            >
                <FiMinus
                    style={{ verticalAlign: 'middle' }}
                    className="h-100"
                    size={props.size === 'sm' ? 16 : 24}
                />
            </div>

            <span
                className={`text-500 text-neutrif text-dark-4 text-center ${
                    props.size === 'sm' ? 'text-12' : 'text-16'
                }`}
                style={{ width: props.size === 'sm' ? 45 : 50 }}
            >
                {counter}
            </span>

            <div
                className={
                    `${
                        props.size === 'sm' ? 'px-1' : 'px-2'
                    } h-100 product-counter-icon-wrapper` +
                    (!props.disabled ? ' cursor-pointer' : ' opacity-25')
                }
                onClick={!props.disabled ? handleIncrement : undefined}
            >
                <FiPlus
                    style={{ verticalAlign: 'middle' }}
                    className="h-100"
                    size={props.size === 'sm' ? 16 : 24}
                />
            </div>
        </div>
    );
};

ProductCounter.defaultProps = {
    value: 1,
    className: '',
    min: 1,
};

export default ProductCounter;
