import { Row, Col, Table, Spinner } from 'react-bootstrap';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';
import GetOffers from '../templateSearch/GetOffers';
import CheckoutSummary from './CheckoutSummary';
import AppButton from '../../components/AppButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../reducers';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { getDeliveryAddress } from '../../../actions/deliveryAddressActions';
import { useEffect, useState } from 'react';
import { getAllOffers } from '../../../actions/offerActions';
import CartTableRow from './CartTableRow';
import queryString from 'query-string';
import request from '../../../helpers/request';
import { $FIXME } from '../../../helpers/constants';
import { ICart } from '../../../interfaces/Cart';
import { setErrors } from '../../../actions/errorActions';
import { AxiosError } from 'axios';
import { IExpressCheckoutParams } from '../../../interfaces/Cart';

interface IProps {}

const BuyNow = (props: IProps) => {
    const [cart, setCart] = useState<ICart>();

    const [loading, setLoading] = useState(false);

    const carts = cart?.carts;

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const location = useLocation();

    const query = queryString.parse(location.search, {
        parseBooleans: true,
        parseNumbers: true
    }) as $FIXME as IExpressCheckoutParams;

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Template',
            link: '/template'
        },
        {
            label: 'Cart',
            link: '/cart',
            active: true
        }
    ];

    useEffect(() => {
        if (query.model_id && query.on_model) {
            setLoading(true);

            request
                .expressCheckout({
                    model_id: query.model_id,
                    on_model: query.on_model,
                    trainer: query.trainer,
                    plan: query.plan,
                    product_size: query.product_size,
                    unit: query.unit,
                    self_order: query.self_order,
                    relation: query.relation,
                    name: query.name,
                    email: query.email,
                    mobile_num: query.mobile_num
                })
                .then((res) => {
                    setCart(res.data);

                    setLoading(false);
                })
                .catch((err: AxiosError) => {
                    dispatch(
                        setErrors(
                            err.response?.data?.message ??
                                'Something went wrong'
                        )
                    );

                    setLoading(false);

                    history.length === 1 ? history.push('/') : history.goBack();
                });
        }
    }, [
        dispatch,
        history,
        query.model_id,
        query.on_model,
        query.trainer,
        query.plan,
        query.product_size,
        query.unit,
        query.self_order,
        query.relation,
        query.name,
        query.email,
        query.mobile_num
    ]);

    useEffect(() => {
        dispatch(getDeliveryAddress());
        dispatch(getAllOffers({ resultsPerPage: '10', status: 'active' }));
    }, [dispatch]);

    if (!query.model_id || !query.on_model) {
        return <Redirect to="/" />;
    }

    return (
        <MainLayout>
            {!loading ? (
                <div className="container-xxl container-xl cart-container">
                    <AppBreadcrumbs items={breadcrumbItems} />
                    <div className="mb-3 pt-3">
                        <h2 className="text-700 text-dark-blue text-uppercase mb-2 mr-3">
                            Express Checkout
                        </h2>
                        <p className="text-gray text-14 mb-0">
                            Check cart and proceed to payment
                        </p>
                    </div>
                    <Row className="mb-5">
                        <Col className="mb-5">
                            <div className="cart-item-container mb-3">
                                <Table responsive borderless>
                                    <thead className="border-bottom-1">
                                        <tr>
                                            <th className="pl-0 text-neutrif text-600 text-14 text-dark-2">
                                                Product/Package
                                            </th>
                                            <th className="text-neutrif text-600 text-14 text-dark-2">
                                                Price
                                            </th>
                                            <th className="text-neutrif text-600 text-14 text-dark-2">
                                                Unit
                                            </th>
                                            <th className="pr-0 text-neutrif text-600 text-14 text-dark-2">
                                                Subtotal
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!carts &&
                                            !!carts.length &&
                                            carts.map((cart, index) => {
                                                return !!cart ? (
                                                    <CartTableRow
                                                        cart={cart}
                                                        key={index}
                                                        hideDelete
                                                        onCounterChange={(
                                                            value
                                                        ) => {
                                                            history.push({
                                                                pathname:
                                                                    '/express/checkout/cart',
                                                                search: queryString.stringify(
                                                                    {
                                                                        ...query,
                                                                        unit: value
                                                                    }
                                                                )
                                                            });
                                                            query.unit = value;
                                                        }}
                                                    />
                                                ) : (
                                                    ''
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </div>

                            {!!carts && carts.length && (
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div>
                                        <AppButton
                                            variant="outline-secondary"
                                            className="mr-3 mb-3"
                                            onClick={() => {
                                                history.push('/');
                                            }}
                                        >
                                            Continue Shopping
                                        </AppButton>
                                    </div>
                                </div>
                            )}
                        </Col>

                        {!!cart && !!carts && !!carts.length && (
                            <Col lg={4} className="mb-5">
                                <CheckoutSummary cart={cart} />
                            </Col>
                        )}
                    </Row>
                </div>
            ) : (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: 'calc(100vh - 200px)' }}
                >
                    <Spinner animation="border" />
                </div>
            )}

            <GetOffers offerType="all" />
            <GetNotified />
        </MainLayout>
    );
};

export default BuyNow;
