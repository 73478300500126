import AppTemplateCard from '../../components/AppTemplateCard';
import TextShadow from '../../components/TextShadow';
import { ReactComponent as ArrowPrev } from '../../../assets/images/icons/arrow-prev.svg';
import { ReactComponent as ArrowNext } from '../../../assets/images/icons/arrow-next.svg';
import Slider from 'react-slick';
import AppButton from '../../components/AppButton';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { formatNumber } from '../../../utils/functions';
import { defaultImage } from '../../../helpers/constants';

interface IProps {}

const TemplateList = (props: IProps) => {
    const templates = useSelector(
        (state: StateType) => state.template.templates.templates
    );

    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        prevArrow: <ArrowPrev />,
        nextArrow: <ArrowNext fill="#ed2b30" />,
        responsive: [
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    arrows: false
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    };

    return templates.length ? (
        <div className="template-list-container px-md-5 position-relative py-5">
            <div
                className="container-xxl py-5 position-relative"
                style={{ zIndex: 10 }}
            >
                <div className="pb-4 d-flex justify-content-space align-items-end">
                    <div className="flex-grow-1">
                        <TextShadow
                            shadowText="Training Templates"
                            alignment="left"
                            variant="light"
                        >
                            Training Templates
                        </TextShadow>
                    </div>
                    <Link to="/template">
                        <AppButton variant="outline-secondary">
                            View all Templates
                        </AppButton>
                    </Link>
                </div>

                <Slider {...settings} className="mx-n2">
                    {templates.map((template, index) => (
                        <Link
                            to={`/template/${template.slug}`}
                            key={index}
                            className="px-2"
                        >
                            <AppTemplateCard
                                title={template.title ?? 'N/A'}
                                subtitle={
                                    template.discount?.active &&
                                    !!template.discounted_price &&
                                    !!template.weeks ? (
                                        <>
                                            <s className="text-strike">
                                                {`Rs. ${formatNumber(
                                                    template.price
                                                )}`}
                                            </s>{' '}
                                            <br />
                                            Rs.{' '}
                                            {formatNumber(
                                                template.discounted_price
                                            )}{' '}
                                            / {template.weeks} Weeks
                                        </>
                                    ) : (
                                        `Rs. ${formatNumber(
                                            template.discounted_price
                                        )} / ${template.weeks} Weeks`
                                    )
                                }
                                img={
                                    !!template.cover
                                        ? template.cover
                                        : defaultImage
                                }
                                key={template._id}
                                imgTitle={template?.training_category?.name}
                                imgSubtitle={template?.template_category?.name}
                                ribbonText={
                                    template.discount?.active
                                        ? template.discount.title
                                        : ''
                                }
                            />
                        </Link>
                    ))}
                </Slider>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default TemplateList;
