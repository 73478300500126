import { FaCircle } from 'react-icons/fa';

type StatusType = 'success' | 'warning' | 'danger';

interface IProps {
    type: StatusType;
    label: string;
}

const StatusChip = ({ type, label }: IProps) => {
    const getTypeClass = () => {
        switch (type) {
            case 'success':
                return 'status-success';
            case 'warning':
                return 'status-warning';
            case 'danger':
                return 'status-danger';
            default:
                return 'status-success';
        }
    };

    return (
        <div
            className={`d-flex align-items-center px-2 status-container ${getTypeClass()}`}
        >
            <FaCircle size={6} />
            <span className="text-400 text-12 ml-1">{label}</span>
        </div>
    );
};

export default StatusChip;
