import { Col, Row } from 'react-bootstrap';
import { AiOutlineCalendar } from 'react-icons/ai';
import Calendar from 'react-calendar';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { ChangeEvent } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import request from '../../../helpers/request';
import { toggleConsultationModal } from '../../../actions/uiActions';
import { setErrors } from '../../../actions/errorActions';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { updateSubscribedTemplate } from '../../../actions/templateActions';
import { updateSubscribedCoaching } from '../../../actions/coachingActions';
import { updateSubscribedConsultation } from '../../../actions/consultationActions';

const ConsultationDateModal = () => {
    const [confirmDateLoading, setConfirmDateLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const { consultationId, consultationType } = useSelector(
        (state: StateType) => state.ui.consultationModal
    );

    const dispatch = useDispatch();

    const handleDateChange = (
        value: Date,
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedDate(value);
    };

    const handleConfirmDate = () => {
        if (consultationId) {
            setConfirmDateLoading(true);

            if (consultationType === 'Template') {
                request
                    .templateConsultationRequest({
                        subscription_id: consultationId,
                        consultation_date:
                            dayjs(selectedDate).format('YYYY-MM-DD')
                    })
                    .then((res) => {
                        toast.success(
                            `Your consultation request has been set for ${dayjs(
                                selectedDate
                            ).format(
                                'YYYY-MM-DD'
                            )}. Please wait until our team approves your request.`,
                            {
                                position: toast.POSITION.TOP_CENTER
                            }
                        );
                        setConfirmDateLoading(false);

                        dispatch(
                            updateSubscribedTemplate(res.data?.subscription)
                        );

                        dispatch(
                            toggleConsultationModal({
                                open: false,
                                consultationType: 'Template'
                            })
                        );
                    })
                    .catch((err: AxiosError) => {
                        setConfirmDateLoading(false);
                        dispatch(
                            setErrors(
                                err.response?.data.message ??
                                    'Something went wrong'
                            )
                        );
                    });
            }

            if (consultationType === 'Coaching Update') {
                request
                    .updateCoachingConsultationRequest({
                        subscription: consultationId,
                        consultation_date:
                            dayjs(selectedDate).format('YYYY-MM-DD')
                    })
                    .then((res) => {
                        toast.success(
                            `Your consultation request has been set for ${dayjs(
                                selectedDate
                            ).format(
                                'YYYY-MM-DD'
                            )}. Please wait until our team approves your request.`,
                            {
                                position: toast.POSITION.TOP_CENTER
                            }
                        );
                        setConfirmDateLoading(false);

                        dispatch(
                            updateSubscribedCoaching(res.data?.subscription)
                        );

                        dispatch(
                            toggleConsultationModal({
                                open: false,
                                consultationType: 'Coaching Update'
                            })
                        );
                    })
                    .catch((err: AxiosError) => {
                        setConfirmDateLoading(false);
                        dispatch(
                            setErrors(
                                err.response?.data.message ??
                                    'Something went wrong'
                            )
                        );
                    });
            }

            if (consultationType === 'Paid') {
                request
                    .consultationRequest({
                        subscription: consultationId,
                        consultation_date:
                            dayjs(selectedDate).format('YYYY-MM-DD')
                    })
                    .then((res) => {
                        toast.success(
                            `Your consultation request has been set for ${dayjs(
                                selectedDate
                            ).format(
                                'YYYY-MM-DD'
                            )}. Please wait until our team approves your request.`,
                            {
                                position: toast.POSITION.TOP_CENTER
                            }
                        );
                        setConfirmDateLoading(false);

                        dispatch(
                            updateSubscribedConsultation(res.data?.subscription)
                        );

                        dispatch(
                            toggleConsultationModal({
                                open: false,
                                consultationType: 'Paid'
                            })
                        );
                    })
                    .catch((err: AxiosError) => {
                        setConfirmDateLoading(false);
                        dispatch(
                            setErrors(
                                err.response?.data.message ??
                                    'Something went wrong'
                            )
                        );
                    });
            }

            if (consultationType === 'Coaching') {
                request
                    .coachingConsultationRequest({
                        subscription: consultationId,
                        consultation_date:
                            dayjs(selectedDate).format('YYYY-MM-DD')
                    })
                    .then((res) => {
                        toast.success(
                            `Your consultation request has been set for ${dayjs(
                                selectedDate
                            ).format(
                                'YYYY-MM-DD'
                            )}. Please wait until our team approves your request.`,
                            {
                                position: toast.POSITION.TOP_CENTER
                            }
                        );
                        setConfirmDateLoading(false);

                        dispatch(
                            updateSubscribedCoaching(res.data?.subscription)
                        );

                        dispatch(
                            toggleConsultationModal({
                                open: false,
                                consultationType: 'Coaching'
                            })
                        );
                    })
                    .catch((err: AxiosError) => {
                        setConfirmDateLoading(false);
                        dispatch(
                            setErrors(
                                err.response?.data.message ??
                                    'Something went wrong'
                            )
                        );
                    });
            }
        }
    };

    return (
        <div>
            <Row>
                <Col xs={12} lg={7} className="mb-5">
                    <div className="d-flex mb-3">
                        <AiOutlineCalendar
                            size={24}
                            className="text-dark-blue mr-2"
                        />
                        <span className="text-16 text-600 text-gray-2">
                            Confirm Date
                        </span>
                    </div>
                    <Calendar
                        className="calendar-consultation"
                        tileClassName="calendar-consultation-tile"
                        minDate={new Date()}
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                </Col>

                <Col lg={5} className="mb-5">
                    <div className="d-flex flex-column h-100">
                        <div className="d-flex mb-3 align-items-center justify-content-center">
                            <span className="text-16 text-600 text-gray-2">
                                Booking Detail
                            </span>
                        </div>
                        <div className="booking-detail flex-grow-1 py-4 px-3 d-flex flex-column">
                            <div className="mb-5 flex-grow-1">
                                <div className="d-flex mb-4">
                                    <AiOutlineCalendar
                                        size={44}
                                        className="text-gray mr-3"
                                    />
                                    <div className="d-flex flex-column">
                                        <p className="text-white mb-0">
                                            {dayjs(selectedDate).format(
                                                'D MMMM YYYY'
                                            )}
                                        </p>
                                        <p className="text-12 text-gray mb-0">
                                            Date
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <p className="text-center text-12 text-light-gray mb-5">
                                    If you have any trouble booking a session,
                                    contact us through our{' '}
                                    <Link
                                        to="/"
                                        className="text-neutrif text-12 text-700 text-underline text-light-gray"
                                    >
                                        customer support
                                    </Link>{' '}
                                    or call us at{' '}
                                    <Link
                                        to="/"
                                        className="text-neutrif text-12 text-700 text-underline text-light-gray"
                                    >
                                        +9771234567890
                                    </Link>
                                </p>
                                <div className="mx-auto">
                                    <AppButton
                                        variant="primary"
                                        onClick={handleConfirmDate}
                                        loading={confirmDateLoading}
                                        disabled={confirmDateLoading}
                                    >
                                        Confirm Date
                                    </AppButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ConsultationDateModal;
