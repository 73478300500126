import { Row, Spinner } from 'react-bootstrap';
import TrainerProfileLayout from '../../layouts/TrainerProfileLayout';
import { Link } from 'react-router-dom';
import AppTemplateCard from '../../components/AppTemplateCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { StateType } from '../../../reducers';
import { getTrainerPackages } from '../../../actions/trainerActions';
import { GymBag } from '../../../assets/images/icons';
import AppButton from '../../components/AppButton';
import { formatNumber } from '../../../utils/functions';
import { defaultImage } from '../../../helpers/constants';

const TrainerPackages = () => {
    const { trainer, packages, packagesLoading } = useSelector(
        (state: StateType) => state.trainer
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (trainer._id) dispatch(getTrainerPackages(trainer._id));
    }, [dispatch, trainer._id]);

    return (
        <TrainerProfileLayout>
            {packagesLoading ? (
                <div
                    className="d-flex justify-content-center"
                    style={{ height: 200 }}
                >
                    <Spinner animation="border" />
                </div>
            ) : !packages.templates.length ? (
                <div className="py-5 bg-light-gray-1">
                    <div className="d-flex align-items-center flex-column py-5">
                        <GymBag />
                        <p className="text-inter text-gray-2">
                            No packages from trainer. Explore and find the best
                            training templates.
                        </p>
                        <Link to="/template">
                            <AppButton variant="outline-secondary">
                                Explore Training Templates
                            </AppButton>
                        </Link>
                    </div>
                </div>
            ) : (
                <Row xl={4} lg={3} xs={2} className="mx-n2" noGutters>
                    {packages.templates.map((template, index) => (
                        <Link to={`/template/${template.slug}`} key={index}>
                            <AppTemplateCard
                                title={template.title ?? 'N/A'}
                                subtitle={
                                    template.discount?.active &&
                                    !!template.discounted_price &&
                                    !!template.weeks ? (
                                        <>
                                            <s className="text-strike">
                                                {`Rs. ${formatNumber(
                                                    template.price
                                                )}`}
                                            </s>{' '}
                                            <br />
                                            Rs.{' '}
                                            {formatNumber(
                                                template.discounted_price
                                            )}{' '}
                                            / {template.weeks} Weeks
                                        </>
                                    ) : (
                                        `Rs. ${formatNumber(
                                            template.discounted_price
                                        )} / ${template.weeks} Weeks`
                                    )
                                }
                                img={
                                    !!template.cover
                                        ? template.cover
                                        : defaultImage
                                }
                                key={template._id}
                                className="mx-2"
                                imgTitle={template?.training_category?.name}
                                imgSubtitle={template?.template_category?.name}
                                ribbonText={
                                    template.discount?.active
                                        ? template.discount.title
                                        : ''
                                }
                            />
                        </Link>
                    ))}
                </Row>
            )}
        </TrainerProfileLayout>
    );
};

export default TrainerPackages;
