import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { defaultImage } from '../../../helpers/constants';
import { StateType } from '../../../reducers';
import { formatNumber } from '../../../utils/functions';
import AppTemplateCard from '../../components/AppTemplateCard';

const SuggestedPackages = () => {
    const { featuredTemplates } = useSelector(
        (state: StateType) => state.template
    );

    var settings = {
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    vertical: false
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2,
                    vertical: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    vertical: false
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    vertical: false
                }
            }
        ]
    };

    return (
        <div>
            <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-2 mr-3 flex-grow-1">
                SUGGESTED PACKAGES
            </h2>
            <div>
                <Slider {...settings} className="mx-n2 my-n3">
                    {featuredTemplates.templates.map((template) => (
                        <Link
                            to={`/template/${template.slug}`}
                            className="px-2 py-3"
                        >
                            <AppTemplateCard
                                title={template.title ?? ''}
                                subtitle={
                                    template.discount?.active &&
                                    !!template.discounted_price &&
                                    !!template.weeks ? (
                                        <>
                                            <s className="text-strike">
                                                {`Rs. ${formatNumber(
                                                    template.price
                                                )}`}
                                            </s>{' '}
                                            <br />
                                            Rs.{' '}
                                            {formatNumber(
                                                template.discounted_price
                                            )}{' '}
                                            / {template.weeks} Weeks
                                        </>
                                    ) : (
                                        `Rs. ${formatNumber(
                                            template.discounted_price
                                        )} / ${template.weeks} Weeks`
                                    )
                                }
                                img={
                                    !!template.cover
                                        ? template.cover
                                        : defaultImage
                                }
                                squareImage
                                imgSubtitle={template?.template_category?.name}
                                ribbonText={
                                    template.discount?.active
                                        ? template.discount.title
                                        : ''
                                }
                            />
                        </Link>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default SuggestedPackages;
