import { Formik } from "formik";
import { Form, Modal } from "react-bootstrap";
import { CgCloseO } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import AppButton from "../../../../components/AppButton";
import AppInput from "../../../../components/AppInput";
import * as Yup from "yup";
import { AppDispatch } from "../../../../../reducers";
import { DayActivity } from "../../../../../interfaces/Coaching";
import {
    getSubscribedCoaching,
    saveUserCoachingProgress,
} from "../../../../../actions/coachingActions";

interface IProps {
    show: boolean;
    onClose?: () => void;
    onUpdate?: (feedback: string) => void;
    dayActivity: DayActivity;
    coachingId: string;
}

const DayActivityModal = ({
    show,
    onClose,
    dayActivity,
    coachingId,
    onUpdate,
}: IProps) => {
    const {
        _id,
        movement,
        intensity,
        note,
        reps,
        rest,
        sets,
        tempo,
        actual_rpe,
        weight_used,
        feedback,
    } = dayActivity;

    const dispatch = useDispatch<AppDispatch>();

    return (
        <Modal
            show={show}
            onHide={() => {
                onClose && onClose();
            }}
            size="lg"
            centered
            contentClassName="rounded"
        >
            <Modal.Header className="px-0 mx-3 pb-0 border-0">
                <h2 className="text-uppercase text-20 text-dark-blue text-600">
                    Day Activity
                </h2>

                <CgCloseO
                    size={28}
                    className="text-gray text-hover-red cursor-pointer"
                    onClick={() => {
                        onClose && onClose();
                    }}
                />
            </Modal.Header>

            <Modal.Body>
                <div className="mb-3">
                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Movement:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {movement ?? "-"}
                        </span>
                    </div>

                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Intensity:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {intensity ?? "-"}
                        </span>
                    </div>

                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Weight Used:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {weight_used ?? "-"}
                        </span>
                    </div>

                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Actual RPE:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {actual_rpe ?? "-"}
                        </span>
                    </div>

                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Sets:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {sets ?? "-"}
                        </span>
                    </div>

                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Reps:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {reps ?? "-"}
                        </span>
                    </div>

                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Tempo:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {tempo ?? "-"}
                        </span>
                    </div>

                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Rest:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {rest ?? "-"}
                        </span>
                    </div>

                    <div>
                        <span className="text-neutrif text-600 text-16 text-dark-2 text-nowrap">
                            Note:{" "}
                        </span>
                        <span className="text-neutrif text-400 text-16 text-dark-gray-1">
                            {!!note ? note : "-"}
                        </span>
                    </div>
                </div>

                {actual_rpe || weight_used ? (
                    <Formik
                        initialValues={{
                            feedback: feedback ?? "",
                        }}
                        validationSchema={Yup.object().shape({
                            feedback: Yup.string().required(
                                "Feedback is required"
                            ),
                        })}
                        onSubmit={(values) => {
                            if (_id)
                                dispatch(
                                    saveUserCoachingProgress(
                                        {
                                            actual_rpe: actual_rpe,
                                            weight_used: weight_used ?? "",
                                            feedback: values.feedback,
                                        },
                                        _id
                                    )
                                ).then(() => {
                                    onUpdate && onUpdate(values.feedback);

                                    dispatch(getSubscribedCoaching(coachingId));

                                    onClose && onClose();

                                    toast.success(
                                        "Thank you for your feedback.",
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                        }
                                    );
                                });
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            isValid,
                            errors,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <AppInput
                                    as="textarea"
                                    required
                                    fill
                                    label="What’s your feedback for the day training?"
                                    rows={4}
                                    placeholder="Share your experience and feedback related to this days training."
                                    name="feedback"
                                    value={values.feedback}
                                    onChange={handleChange}
                                    isInvalid={!!errors.feedback}
                                    error={errors.feedback}
                                />
                                <div className="d-flex justify-content-end">
                                    <AppButton
                                        variant="primary"
                                        disabled={!isValid}
                                        type="submit"
                                    >
                                        {!!feedback
                                            ? "Update Feedback"
                                            : "Add Feedback"}
                                    </AppButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    ""
                )}
            </Modal.Body>
        </Modal>
    );
};

export default DayActivityModal;
