import {
    GIFTED_COACHINGS_FAILURE,
    GIFTED_COACHINGS_REQUEST,
    GIFTED_COACHINGS_SUCCESS,
    GIFTED_CONSULTATIONS_FAILURE,
    GIFTED_CONSULTATIONS_REQUEST,
    GIFTED_CONSULTATIONS_SUCCESS,
    GIFTED_TEMPLATES_FAILURE,
    GIFTED_TEMPLATES_REQUEST,
    GIFTED_TEMPLATES_SUCCESS,
    RECEIVED_TEMPLATES_REQUEST,
    RECEIVED_TEMPLATES_SUCCESS,
    RECEIVED_TEMPLATES_FAILURE,
    RECEIVED_COACHINGS_REQUEST,
    RECEIVED_COACHINGS_SUCCESS,
    RECEIVED_COACHINGS_FAILURE,
    RECEIVED_CONSULTATIONS_REQUEST,
    RECEIVED_CONSULTATIONS_SUCCESS,
    RECEIVED_CONSULTATIONS_FAILURE
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import {
    IGiftedCoaching,
    IGiftedConsultation,
    IGiftedTemplate
} from '../interfaces/Gift';

export interface IGiftState {
    giftedTemplatesLoading: boolean;
    giftedTemplates: IGiftedTemplate;
    receivedTemplates: IGiftedTemplate;
    receivedTemplatesLoading: boolean;
    giftedCoachingsLoading: boolean;
    giftedCoachings: IGiftedCoaching;
    receivedCoachings: IGiftedCoaching;
    receivedCoachingsLoading: boolean;
    giftedConsultationsLoading: boolean;
    giftedConsultations: IGiftedConsultation;
    receivedConsultations: IGiftedConsultation;
    receivedConsultationsLoading: boolean;
}

const initialState: IGiftState = {
    giftedCoachingsLoading: false,
    giftedTemplates: {
        gifts: []
    },
    receivedTemplates: {
        gifts: []
    },
    receivedCoachingsLoading: false,
    giftedTemplatesLoading: false,
    giftedCoachings: {
        gifts: []
    },
    receivedCoachings: {
        gifts: []
    },
    receivedTemplatesLoading: false,
    giftedConsultations: { gifts: [] },
    giftedConsultationsLoading: false,
    receivedConsultationsLoading: false,
    receivedConsultations: { gifts: [] }
};

const giftReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GIFTED_TEMPLATES_REQUEST:
            return {
                ...state,
                giftedTemplatesLoading: true
            };

        case GIFTED_TEMPLATES_SUCCESS:
            return {
                ...state,
                giftedTemplatesLoading: false,
                giftedTemplates: action.payload
            };

        case GIFTED_TEMPLATES_FAILURE:
            return {
                ...state,
                giftedTemplatesLoading: false
            };

        case GIFTED_COACHINGS_REQUEST:
            return {
                ...state,
                giftedCoachingsLoading: true
            };

        case GIFTED_COACHINGS_SUCCESS:
            return {
                ...state,
                giftedCoachingsLoading: false,
                giftedCoachings: action.payload
            };

        case GIFTED_COACHINGS_FAILURE:
            return {
                ...state,
                giftedCoachingsLoading: false
            };

        case GIFTED_CONSULTATIONS_REQUEST:
            return {
                ...state,
                giftedConsultationsLoading: true
            };

        case GIFTED_CONSULTATIONS_SUCCESS:
            return {
                ...state,
                giftedConsultationsLoading: false,
                giftedConsultations: action.payload
            };

        case GIFTED_CONSULTATIONS_FAILURE:
            return {
                ...state,
                giftedConsultationsLoading: false
            };

        case RECEIVED_TEMPLATES_REQUEST:
            return {
                ...state,
                receivedTemplatesLoading: true
            };

        case RECEIVED_TEMPLATES_SUCCESS:
            return {
                ...state,
                receivedTemplatesLoading: false,
                receivedTemplates: action.payload
            };

        case RECEIVED_TEMPLATES_FAILURE:
            return {
                ...state,
                receivedTemplatesLoading: false
            };

        case RECEIVED_COACHINGS_REQUEST:
            return {
                ...state,
                receivedCoachingsLoading: true
            };

        case RECEIVED_COACHINGS_SUCCESS:
            return {
                ...state,
                receivedCoachingsLoading: false,
                receivedCoachings: action.payload
            };

        case RECEIVED_COACHINGS_FAILURE:
            return {
                ...state,
                receivedCoachingsLoading: false
            };

        case RECEIVED_CONSULTATIONS_REQUEST:
            return {
                ...state,
                receivedConsultationsLoading: true
            };

        case RECEIVED_CONSULTATIONS_SUCCESS:
            return {
                ...state,
                receivedConsultationsLoading: false,
                receivedConsultations: action.payload
            };

        case RECEIVED_CONSULTATIONS_FAILURE:
            return {
                ...state,
                receivedConsultationsLoading: false
            };

        default:
            return state;
    }
};

export default giftReducer;
