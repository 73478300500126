import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    GET_ALL_OFFERS,
    GET_COACHING_OFFERS,
    GET_CONSULTATION_OFFERS,
    GET_PRODUCT_OFFERS,
    GET_TEMPLATE_OFFERS
} from '../helpers/constants';
import { AxiosResponse } from 'axios';
import { IOfferParams, IOffer } from '../interfaces/Offer';

export const getAllOffers =
    (params: IOfferParams) => async (dispatch: Dispatch) => {
        request
            .getOffers({ ...params, valid_on: 'all' })
            .then((res: AxiosResponse) => {
                const offer: IOffer = res.data;

                dispatch({
                    type: GET_ALL_OFFERS,
                    payload: {
                        ...offer,
                        offers: offer?.offers
                            ?.sort(() => Math.random() - Math.random())
                            .slice(0, 2)
                    }
                });
            });
    };

export const getProductOffers =
    (params: IOfferParams) => async (dispatch: Dispatch) => {
        request
            .getOffers({ ...params, valid_on: 'product' })
            .then((res: AxiosResponse) => {
                const offer: IOffer = res.data;

                dispatch({
                    type: GET_PRODUCT_OFFERS,
                    payload: {
                        ...offer,
                        offers: offer?.offers
                            ?.sort(() => Math.random() - Math.random())
                            .slice(0, 2)
                    }
                });
            });
    };
export const getTemplateOffers =
    (params: IOfferParams) => async (dispatch: Dispatch) => {
        request
            .getOffers({ ...params, valid_on: 'template' })
            .then((res: AxiosResponse) => {
                const offer: IOffer = res.data;

                dispatch({
                    type: GET_TEMPLATE_OFFERS,
                    payload: {
                        ...offer,
                        offers: offer?.offers
                            ?.sort(() => Math.random() - Math.random())
                            .slice(0, 2)
                    }
                });
            });
    };
export const getCoachingOffers =
    (params: IOfferParams) => async (dispatch: Dispatch) => {
        request
            .getOffers({ ...params, valid_on: 'coaching' })
            .then((res: AxiosResponse) => {
                const offer: IOffer = res.data;

                dispatch({
                    type: GET_COACHING_OFFERS,
                    payload: {
                        ...offer,
                        offers: offer?.offers
                            ?.sort(() => Math.random() - Math.random())
                            .slice(0, 2)
                    }
                });
            });
    };
export const getConsultationOffers =
    (params: IOfferParams) => async (dispatch: Dispatch) => {
        request
            .getOffers({ ...params, valid_on: 'consultation' })
            .then((res: AxiosResponse) => {
                const offer: IOffer = res.data;

                dispatch({
                    type: GET_CONSULTATION_OFFERS,
                    payload: {
                        ...offer,
                        offers: offer?.offers
                            ?.sort(() => Math.random() - Math.random())
                            .slice(0, 2)
                    }
                });
            });
    };
