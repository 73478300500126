import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { deleteCart } from '../../../actions/cartActions';
import { defaultImage } from '../../../helpers/constants';
import { Cart } from '../../../interfaces/Cart';
import { AppDispatch } from '../../../reducers';
import { getPrice, formatNumber } from '../../../utils/functions';
import Counter from '../../components/Counter';

interface IProps {
    cart: Cart;
    hideDelete?: boolean;
    onCounterChange?: (value: number) => void;
}

const CartTableRow = ({ cart, hideDelete, onCounterChange }: IProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const [deleting, setDeleting] = useState(false);

    return (
        <tr className="border-bottom-1">
            <td style={{ width: 300 }} className="pl-0">
                <div className="d-flex">
                    <div className="position-relative cart-img-wrapper mr-3">
                        <img
                            src={
                                cart.onModel === 'Product'
                                    ? cart.ref?.images &&
                                      cart.ref.images.length > 0
                                        ? cart.ref.images[0]
                                        : ''
                                    : !!cart.ref?.cover
                                    ? cart.ref.cover
                                    : defaultImage
                            }
                            alt="cart-img"
                        />

                        {cart.onModel === 'Product' &&
                            cart.ref?.rates?.find(
                                (rate) => rate.size === cart.product_size
                            )?.stock === 0 && (
                                <div className="cart-img-overlay position-absolute d-flex align-items-center justify-content-center">
                                    <span className="text-white text-12 text-700 text-uppercase">
                                        Out of stock
                                    </span>
                                </div>
                            )}
                    </div>
                    <div>
                        <p className="text-700 text-14 mb-0 text-dark-blue">
                            {cart.ref?.title}
                        </p>
                        <p className="text-700 text-14 mb-2 text-dark-blue">
                            {(() => {
                                if (cart?.onModel === 'Product') {
                                    return cart?.ref?.product_brand?.name;
                                } else if (cart?.onModel === 'Template') {
                                    return cart?.ref?.training_category?.name;
                                } else if (cart?.onModel === 'Consultation') {
                                    return cart.consultation_plan;
                                } else if (cart?.onModel === 'Coaching') {
                                    return cart.coaching_plan;
                                } else {
                                    return;
                                }
                            })()}
                        </p>

                        <p className="text-14 text-gray mb-0">
                            {(() => {
                                if (cart?.onModel === 'Product') {
                                    return cart.product_size;
                                }
                            })()}
                        </p>

                        <p className="text-14 text-gray mb-0">
                            {(() => {
                                if (cart?.onModel === 'Product') {
                                    return cart?.ref?.product_category?.name;
                                } else if (cart?.onModel === 'Consultation') {
                                    return `Consultation By: ${cart?.consultation_trainer?.name}`;
                                } else if (cart?.onModel === 'Coaching') {
                                    return `Coaching By: ${cart?.coaching_trainer?.name}`;
                                } else {
                                    return;
                                }
                            })()}
                        </p>
                    </div>
                </div>
            </td>
            <td>
                {(getPrice(cart)?.discounted_price ?? 0) <
                (getPrice(cart)?.price ?? 0) ? (
                    <>
                        <p className="text-600 text-dark-2 mb-1">
                            Rs. {formatNumber(getPrice(cart)?.discounted_price)}
                        </p>
                        <p className="text-400 text-14 text-gray-1 mb-1">
                            <s>Rs. {formatNumber(getPrice(cart)?.price)}</s>
                        </p>
                    </>
                ) : (
                    <p className="text-600 text-dark-2 mb-1">
                        Rs. {formatNumber(getPrice(cart)?.discounted_price)}
                    </p>
                )}
            </td>
            <td>
                <Counter
                    min={1}
                    max={
                        cart.ref?.rates?.find(
                            (rate) => rate.size === cart.product_size
                        )?.stock
                    }
                    value={cart.unit}
                    onChange={onCounterChange}
                    disabled={
                        cart.onModel !== 'Product' ||
                        (cart.onModel === 'Product' &&
                            cart.ref?.rates?.find(
                                (rate) => rate.size === cart.product_size
                            )?.stock === 0)
                    }
                />
            </td>
            <td className="pr-0">
                <div className="d-flex">
                    <p className="text-600 flex-grow-1 text-dark-2">
                        Rs.{' '}
                        {formatNumber(
                            (cart.unit ?? 0) *
                                (getPrice(cart)?.discounted_price ?? 0)
                        )}
                    </p>

                    {!hideDelete && (
                        <div className="text-gray d-flex flex-column ml-3">
                            <div
                                className="edit-icon-container text-hover-dark mb-2"
                                onClick={() => {
                                    if (cart._id && !deleting) {
                                        setDeleting(true);

                                        dispatch(deleteCart(cart._id))
                                            .then(() => {
                                                setDeleting(false);
                                            })
                                            .catch(() => {
                                                setDeleting(false);
                                            });
                                    }
                                }}
                            >
                                {deleting ? (
                                    <Spinner
                                        className="text-red"
                                        animation="border"
                                        size="sm"
                                    />
                                ) : (
                                    <BsX />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default CartTableRow;
