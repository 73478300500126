import { Row, Col, Image } from 'react-bootstrap';
import AppButton from '../../components/AppButton';
import Consultations from './Consultations';
import Slider from 'react-slick';
import AppTemplateCard from '../../components/AppTemplateCard';
import { CgGym } from 'react-icons/cg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { getUserProfile } from '../../../actions/userActions';
import { toggleConsultationModal } from '../../../actions/uiActions';
import {
    getSubscribedTemplates,
    resetTemplateState
} from '../../../actions/templateActions';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { getProfileSubscriptionCount } from '../../../actions/dashboardActions';
import { GymBag } from '../../../assets/images/icons';
import { defaultImage } from '../../../helpers/constants';
import DashboardLayout from '../../layouts/DashboardLayout';

interface IProps {}

const Dashboard = (props: IProps) => {
    const auth = useSelector((state: StateType) => state.auth);
    const user = useSelector((state: StateType) => state.user);
    const count = useSelector(
        (state: StateType) => state.dashboard.count.result
    );

    const subscribed = useSelector(
        (state: StateType) => state.template.subscribed
    );

    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    var settings = {
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1375,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    useEffect(() => {
        if (auth.authenticated) {
            dispatch(getUserProfile());
            dispatch(getProfileSubscriptionCount());
            dispatch(getSubscribedTemplates({ resultsPerPage: 4 }));
        }

        return () => {
            dispatch(resetTemplateState());
        };
    }, [dispatch, auth.authenticated]);

    return (
        <DashboardLayout>
            <div className="dashboard-container">
                <Row>
                    <Col xl={3} lg={4} className="mb-5">
                        <div className="profile-info-container px-3 py-4">
                            <div className="d-flex align-items-center py-3 w-100">
                                <div className="mr-3 position-relative profile-avatar-container">
                                    <Image
                                        src={
                                            auth.authUser?.profile_pic ??
                                            'https://www.irishrsa.ie/wp-content/uploads/2017/03/default-avatar.png'
                                        }
                                        roundedCircle
                                        width={88}
                                        height={88}
                                        className="profile-avatar"
                                    />
                                </div>
                                <div className="text-white text-break">
                                    <p className="mb-1 text-700 text-20">
                                        {user.user.name}
                                    </p>
                                    <p className="mb-0 text-14">
                                        {user.user.email}
                                    </p>
                                </div>
                            </div>
                            <hr className="hr-gray" />

                            {user.user.about && user.user.about !== '' ? (
                                <>
                                    <p
                                        className="text-14 text-white py-3"
                                        dangerouslySetInnerHTML={{
                                            __html: !!user.user.about
                                                ? user.user.about
                                                : 'No bio available'
                                        }}
                                    />

                                    <hr className="hr-gray" />
                                </>
                            ) : (
                                ''
                            )}

                            <Link to="dashboard/settings">
                                <AppButton
                                    variant="outline-primary"
                                    className="w-100 my-3"
                                >
                                    Edit Profile
                                </AppButton>
                            </Link>
                        </div>
                    </Col>
                    <Col xl={9} lg={8} className="mb-5">
                        {/* <div className="mb-4 p-3 bg-gray-1">
                            <p className="text-600 text-dark-blue mb-1">
                                Congratulations, You have Purchased a new
                                Template!!
                            </p>
                            <p className="text-inter text-14 text-gray-2 mb-1">
                                It seems that you have purchased a template
                                recently. Now you can set{' '}
                                <span
                                    className="text-red text-underline text-700 cursor-pointer"
                                    onClick={handleshowConsultationModal}
                                >
                                    consultation date
                                </span>{' '}
                                and set a{' '}
                                <span
                                    className="text-red text-underline text-700 cursor-pointer"
                                    onClick={handleshowConsultationModal}
                                >
                                    start date
                                </span>{' '}
                                for your training.
                            </p>
                        </div> */}
                        <Row
                            xs={2}
                            sm={4}
                            noGutters
                            className="stats-container mx-n2 mb-5"
                        >
                            <div className="px-2 mb-2">
                                <div className="p-3 stats-item position-relative">
                                    <h2 className="text-red text-600 text-right">
                                        {count?.subscribed_templates ?? 0}
                                    </h2>
                                    <p className="text-600 text-center mb-0">
                                        Packages Purchased
                                    </p>
                                    <CgGym
                                        size={70}
                                        className="text-gray position-absolute"
                                        style={{ top: 0, opacity: 0.4 }}
                                    />
                                </div>
                            </div>
                            <div className="px-2 mb-2">
                                <div className="p-3 stats-item position-relative">
                                    <h2 className="text-red text-600 text-right">
                                        {count?.upcomming_consultations ?? 0}
                                    </h2>
                                    <p className="text-600 text-center mb-0">
                                        Trainer Consultation
                                    </p>
                                    <CgGym
                                        size={70}
                                        className="text-gray position-absolute"
                                        style={{ top: 0, opacity: 0.4 }}
                                    />
                                </div>
                            </div>
                            <div className="px-2 mb-2">
                                <div className="p-3 stats-item position-relative">
                                    <h2 className="text-red text-600 text-right">
                                        {count?.active_orders ?? 0}
                                    </h2>
                                    <p className="text-600 text-center mb-0">
                                        Active Orders
                                    </p>
                                    <CgGym
                                        size={70}
                                        className="text-gray position-absolute"
                                        style={{ top: 0, opacity: 0.4 }}
                                    />
                                </div>
                            </div>
                            <div className="px-2 mb-2">
                                <div className="p-3 stats-item position-relative">
                                    <h2 className="text-red text-600 text-right">
                                        {count?.subscribed_coachings ?? 0}
                                    </h2>
                                    <p className="text-600 text-center mb-0">
                                        Personal Trainers
                                    </p>
                                    <CgGym
                                        size={70}
                                        className="text-gray position-absolute"
                                        style={{ top: 0, opacity: 0.4 }}
                                    />
                                </div>
                            </div>
                        </Row>
                        <div>
                            <Consultations />

                            <div className="mb-5 mt-3">
                                <div className="d-flex mb-3 align-items-center flex-wrap">
                                    <h2 className="text-700 text-20 flex-grow-1 mb-0 mr-3 py-2 text-uppercase">
                                        My Packages
                                    </h2>

                                    <AppButton
                                        variant="outline-secondary text-nowrap"
                                        onClick={() => {
                                            history.push(`/dashboard/packages`);
                                        }}
                                    >
                                        View all
                                    </AppButton>
                                </div>

                                <div>
                                    {subscribed.templates.length ? (
                                        <Slider {...settings} className="mx-n2">
                                            {subscribed.templates.map(
                                                (
                                                    {
                                                        _id,
                                                        status,
                                                        consultation_date,
                                                        template,
                                                        createdAt
                                                    },
                                                    index
                                                ) => {
                                                    if (template) {
                                                        return (
                                                            <AppTemplateCard
                                                                key={index}
                                                                subtitle={`Purchased on: ${dayjs(
                                                                    createdAt
                                                                ).format(
                                                                    'D MMM, YYYY'
                                                                )}`}
                                                                img={
                                                                    !!template.cover
                                                                        ? template.cover
                                                                        : defaultImage
                                                                }
                                                                className="mx-2"
                                                                imgTitle={
                                                                    template
                                                                        ?.training_category
                                                                        ?.name
                                                                }
                                                                imgSubtitle={
                                                                    template
                                                                        ?.template_category
                                                                        ?.name
                                                                }
                                                                title={
                                                                    template.title ??
                                                                    ''
                                                                }
                                                                hoverOverlay={
                                                                    !(
                                                                        status ===
                                                                            'Active' ||
                                                                        status ===
                                                                            'Completed'
                                                                    ) ? (
                                                                        <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                            {status ===
                                                                            'Consultation Requested' ? (
                                                                                <>
                                                                                    <p className="text-white text-12 text-center">
                                                                                        Your
                                                                                        consultation
                                                                                        date
                                                                                        request
                                                                                        is
                                                                                        set
                                                                                        for{' '}
                                                                                        {dayjs(
                                                                                            consultation_date
                                                                                        ).format(
                                                                                            'MM/DD/YY'
                                                                                        )}

                                                                                        .
                                                                                        Please
                                                                                        wait
                                                                                        for
                                                                                        approval.
                                                                                    </p>
                                                                                    <AppButton
                                                                                        variant="secondary"
                                                                                        className="px-1 w-100"
                                                                                        style={{
                                                                                            height: 44,
                                                                                            fontSize: 12
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            dispatch(
                                                                                                toggleConsultationModal(
                                                                                                    {
                                                                                                        open: true,
                                                                                                        consultationType:
                                                                                                            'Template',
                                                                                                        consultationId:
                                                                                                            _id
                                                                                                    }
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Change
                                                                                        Date
                                                                                    </AppButton>
                                                                                </>
                                                                            ) : status ===
                                                                              'Consultation Completed' ? (
                                                                                <p className="text-white text-12 text-center">
                                                                                    Your
                                                                                    consultation
                                                                                    is
                                                                                    complete.
                                                                                    Please
                                                                                    wait
                                                                                    for
                                                                                    activation
                                                                                </p>
                                                                            ) : status ===
                                                                              'Inactive' ? (
                                                                                <p className="text-white text-12 text-center">
                                                                                    Your
                                                                                    template
                                                                                    is
                                                                                    inactive.
                                                                                    Please
                                                                                    wait
                                                                                    for
                                                                                    activation
                                                                                </p>
                                                                            ) : (
                                                                                <>
                                                                                    <p className="text-white text-12 text-center">
                                                                                        Please
                                                                                        set
                                                                                        a
                                                                                        consultation
                                                                                        date
                                                                                        for
                                                                                        your
                                                                                        newly
                                                                                        purchased
                                                                                        template.
                                                                                        You
                                                                                        can
                                                                                        select
                                                                                        the
                                                                                        date
                                                                                        as
                                                                                        per
                                                                                        your
                                                                                        convenience
                                                                                    </p>
                                                                                    <AppButton
                                                                                        variant="secondary"
                                                                                        className="px-1 w-100"
                                                                                        style={{
                                                                                            height: 44,
                                                                                            fontSize: 12
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            dispatch(
                                                                                                toggleConsultationModal(
                                                                                                    {
                                                                                                        open: true,
                                                                                                        consultationType:
                                                                                                            'Template',
                                                                                                        consultationId:
                                                                                                            _id
                                                                                                    }
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Set
                                                                                        Consultation
                                                                                        Date
                                                                                    </AppButton>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : undefined
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        status ===
                                                                        'Pending'
                                                                    ) {
                                                                        dispatch(
                                                                            toggleConsultationModal(
                                                                                {
                                                                                    open: true,
                                                                                    consultationType:
                                                                                        'Template',
                                                                                    consultationId:
                                                                                        _id
                                                                                }
                                                                            )
                                                                        );
                                                                    } else if (
                                                                        status ===
                                                                        'Active'
                                                                    ) {
                                                                        history.push(
                                                                            `/dashboard/packages/${_id}`
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        );
                                                    } else {
                                                        return '';
                                                    }
                                                }
                                            )}
                                        </Slider>
                                    ) : (
                                        <div className="d-flex align-items-center flex-column py-5 bg-light-gray-1">
                                            <GymBag />
                                            <p className="text-inter text-gray-2">
                                                Empty Packages. Explore and find
                                                the best training templates.
                                            </p>
                                            <Link to="/template">
                                                <AppButton variant="outline-secondary">
                                                    Explore Training Templates
                                                </AppButton>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </DashboardLayout>
    );
};

export default Dashboard;
