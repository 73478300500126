import { Row } from 'react-bootstrap';
import AppTemplateCard from '../../components/AppTemplateCard';
import DashboardLayout from '../../layouts/DashboardLayout';
import AppButton from '../../components/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { StateType } from '../../../reducers';
import {
    getSubscribedCoachings,
    resetCoachingState
} from '../../../actions/coachingActions';
import { toggleConsultationModal } from '../../../actions/uiActions';
import dayjs from 'dayjs';
import { defaultImage } from '../../../helpers/constants';
import {
    getUserConsultations,
    resetConsultationState
} from '../../../actions/consultationActions';
import { AppTemplateCardSkeleton } from '../../components/AppSkeleton';

interface IProps {}

const UserTrainers = (props: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { subscribedCoachings, subscribedCoachingsLoading } = useSelector(
        (state: StateType) => state.coaching
    );

    const { userConsultations, userConsultationsLoading } = useSelector(
        (state: StateType) => state.consultation
    );

    const resultsPerPage = '5';

    useEffect(() => {
        dispatch(
            getSubscribedCoachings({
                sort: 'latest',
                resultsPerPage,
                page: '1'
            })
        );

        dispatch(
            getUserConsultations({
                sort: 'latest',
                resultsPerPage,
                page: '1'
            })
        );

        return () => {
            dispatch(resetCoachingState());
            dispatch(resetConsultationState());
        };
    }, [dispatch]);

    const handleCoachingsLoadMore = () => {
        if (subscribedCoachings.currentPage !== subscribedCoachings.totalPage)
            dispatch(
                getSubscribedCoachings({
                    resultsPerPage,
                    page: (subscribedCoachings.currentPage + 1).toString()
                })
            );
    };

    const handleConsultationsLoadMore = () => {
        if (userConsultations.currentPage !== userConsultations.totalPage)
            dispatch(
                getUserConsultations({
                    resultsPerPage,
                    page: (userConsultations.currentPage + 1).toString()
                })
            );
    };

    return (
        <DashboardLayout>
            <div className="t-search-container">
                <div className="mb-5">
                    <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3 mr-3 flex-grow-1">
                        MY TRAINERS
                    </h2>

                    {subscribedCoachings.coachings.length ? (
                        <div>
                            <Row
                                noGutters
                                className="mx-n2"
                                xl={5}
                                md={4}
                                sm={3}
                                xs={2}
                            >
                                {subscribedCoachings.coachings.map(
                                    (
                                        {
                                            _id,
                                            latest_consultation,
                                            coaching_plan,
                                            coaching,
                                            coaching_trainer,
                                            status,
                                            first_consultation_completed,
                                            createdAt
                                        },
                                        index
                                    ) => {
                                        if (coaching) {
                                            return (
                                                <AppTemplateCard
                                                    key={index}
                                                    subtitle={`Purchased on: ${dayjs(
                                                        createdAt
                                                    ).format('D MMM, YYYY')}`}
                                                    className="px-2"
                                                    img={
                                                        !!coaching.cover
                                                            ? coaching.cover
                                                            : defaultImage
                                                    }
                                                    imgTitle={
                                                        coaching_trainer?.name
                                                    }
                                                    imgSubtitle={coaching_plan}
                                                    title={coaching.title ?? ''}
                                                    hoverOverlay={(() => {
                                                        if (
                                                            status ===
                                                                'Pending' ||
                                                            status === 'Active'
                                                        ) {
                                                            if (
                                                                latest_consultation &&
                                                                !latest_consultation.completed
                                                            ) {
                                                                return (
                                                                    <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                        <p className="text-white text-12 text-center">
                                                                            Your
                                                                            consulatation
                                                                            date
                                                                            request
                                                                            is
                                                                            set
                                                                            for{' '}
                                                                            {dayjs(
                                                                                latest_consultation.consultation_date
                                                                            ).format(
                                                                                'MM/DD/YY'
                                                                            )}
                                                                            .
                                                                            Please
                                                                            wait
                                                                            for
                                                                            approval.
                                                                        </p>
                                                                        <AppButton
                                                                            variant="secondary"
                                                                            className="px-1 w-100"
                                                                            style={{
                                                                                height: 44,
                                                                                fontSize: 12
                                                                            }}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                dispatch(
                                                                                    toggleConsultationModal(
                                                                                        {
                                                                                            open: true,
                                                                                            consultationType:
                                                                                                'Coaching Update',
                                                                                            consultationId:
                                                                                                latest_consultation._id
                                                                                        }
                                                                                    )
                                                                                );
                                                                            }}
                                                                        >
                                                                            Change
                                                                            Date
                                                                        </AppButton>
                                                                    </div>
                                                                );
                                                            } else if (
                                                                status !==
                                                                'Active'
                                                            ) {
                                                                return !first_consultation_completed ? (
                                                                    <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                        <p className="text-white text-12 text-center">
                                                                            Please
                                                                            set
                                                                            a
                                                                            consultation
                                                                            date
                                                                            for
                                                                            your
                                                                            newly
                                                                            purchased
                                                                            coaching.
                                                                            You
                                                                            can
                                                                            select
                                                                            the
                                                                            date
                                                                            as
                                                                            per
                                                                            your
                                                                            convenience
                                                                        </p>
                                                                        <AppButton
                                                                            variant="secondary"
                                                                            className="px-1 w-100"
                                                                            style={{
                                                                                height: 44,
                                                                                fontSize: 12
                                                                            }}
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    toggleConsultationModal(
                                                                                        {
                                                                                            open: true,
                                                                                            consultationType:
                                                                                                'Coaching',
                                                                                            consultationId:
                                                                                                _id
                                                                                        }
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            Set
                                                                            Consultation
                                                                            Date
                                                                        </AppButton>
                                                                    </div>
                                                                ) : (
                                                                    <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                        <p className="text-white text-12 text-center">
                                                                            Your
                                                                            consultation
                                                                            is
                                                                            complete.
                                                                            Please
                                                                            wait
                                                                            for
                                                                            activation
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }
                                                        } else if (
                                                            status ===
                                                            'Inactive'
                                                        ) {
                                                            return (
                                                                <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                    <p className="text-white text-12 text-center">
                                                                        Your
                                                                        coaching
                                                                        is
                                                                        Inactive
                                                                    </p>

                                                                    <Link
                                                                        to={`/express/checkout/cart?model_id=${coaching._id}&on_model=coaching&trainer=${coaching_trainer?._id}&plan=${coaching_plan}`}
                                                                    >
                                                                        <AppButton
                                                                            variant="secondary"
                                                                            className="px-1 w-100"
                                                                            style={{
                                                                                height: 44,
                                                                                fontSize: 12
                                                                            }}
                                                                        >
                                                                            View
                                                                            Coaching
                                                                        </AppButton>
                                                                    </Link>
                                                                </div>
                                                            );
                                                        } else if (
                                                            status ===
                                                            'Completed'
                                                        ) {
                                                            return (
                                                                <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                    <>
                                                                        <p className="text-white text-12 text-center">
                                                                            Your
                                                                            coaching
                                                                            is
                                                                            complete.
                                                                        </p>

                                                                        <Link
                                                                            to={`/coaching/${coaching.slug}`}
                                                                        >
                                                                            <AppButton
                                                                                variant="primary"
                                                                                className="px-1 w-100"
                                                                                style={{
                                                                                    height: 44,
                                                                                    fontSize: 12
                                                                                }}
                                                                            >
                                                                                Purchase
                                                                                Again
                                                                            </AppButton>
                                                                        </Link>
                                                                    </>
                                                                </div>
                                                            );
                                                        }

                                                        return undefined;
                                                    })()}
                                                    onClick={() => {
                                                        if (
                                                            status === 'Active'
                                                        ) {
                                                            history.push(
                                                                `/dashboard/trainers/${_id}`
                                                            );
                                                        } else if (
                                                            status === 'Pending'
                                                        ) {
                                                            if (
                                                                !first_consultation_completed &&
                                                                !latest_consultation
                                                            ) {
                                                                dispatch(
                                                                    toggleConsultationModal(
                                                                        {
                                                                            open: true,
                                                                            consultationType:
                                                                                'Coaching',
                                                                            consultationId:
                                                                                _id
                                                                        }
                                                                    )
                                                                );
                                                            }
                                                        }
                                                    }}
                                                />
                                            );
                                        } else {
                                            return '';
                                        }
                                    }
                                )}
                            </Row>

                            {subscribedCoachings.currentPage ===
                                subscribedCoachings.totalPage ||
                            subscribedCoachings.totalDocuments === 0 ? (
                                ''
                            ) : (
                                <div className="mb-5 d-flex justify-content-center justify-content-lg-end">
                                    <AppButton
                                        variant="outline-primary"
                                        onClick={handleCoachingsLoadMore}
                                        loading={subscribedCoachingsLoading}
                                    >
                                        Load More Coachings
                                    </AppButton>
                                </div>
                            )}
                        </div>
                    ) : !subscribedCoachingsLoading ? (
                        <div
                            style={{ height: 180 }}
                            className="d-flex flex-column justify-content-center align-items-center"
                        >
                            <p className="text-20">
                                You do not have any active packages...
                            </p>
                            <Link to="/template">
                                <AppButton variant="secondary">
                                    Continue Shopping
                                </AppButton>
                            </Link>
                        </div>
                    ) : (
                        <Row className="mx-n2" xl={5} md={4} sm={3} xs={2}>
                            {[...Array(parseInt(resultsPerPage))].map(
                                (item, i) => (
                                    <AppTemplateCardSkeleton
                                        key={i}
                                        className="px-2"
                                    />
                                )
                            )}
                        </Row>
                    )}
                </div>

                {userConsultations.consultations.length ? (
                    <div className="mb-5">
                        <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3 mr-3 flex-grow-1">
                            MY CONSULTATIONS
                        </h2>

                        <Row
                            noGutters
                            className="mx-n2"
                            xl={5}
                            md={4}
                            sm={3}
                            xs={2}
                        >
                            {userConsultations.consultations.map(
                                (
                                    {
                                        _id,
                                        consultation,
                                        consultation_trainer,
                                        consultation_plan,
                                        consultation_date,
                                        completed,
                                        createdAt
                                    },
                                    index
                                ) => {
                                    if (consultation) {
                                        return (
                                            <AppTemplateCard
                                                key={index}
                                                subtitle={`Purchased on: ${dayjs(
                                                    createdAt
                                                ).format('D MMM, YYYY')}`}
                                                className="px-2"
                                                img={
                                                    !!consultation.cover
                                                        ? consultation.cover
                                                        : defaultImage
                                                }
                                                imgTitle={
                                                    consultation_trainer?.name
                                                }
                                                imgSubtitle={consultation_plan}
                                                title={consultation.title ?? ''}
                                                hoverOverlay={
                                                    !completed ? (
                                                        consultation_date ? (
                                                            <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                <>
                                                                    <p className="text-white text-12 text-center">
                                                                        Your
                                                                        consulatation
                                                                        date
                                                                        request
                                                                        is set
                                                                        for{' '}
                                                                        {dayjs(
                                                                            consultation_date
                                                                        ).format(
                                                                            'MM/DD/YY'
                                                                        )}
                                                                        . Please
                                                                        wait for
                                                                        approval.
                                                                    </p>
                                                                    <AppButton
                                                                        variant="secondary"
                                                                        className="px-1 w-100"
                                                                        style={{
                                                                            height: 44,
                                                                            fontSize: 12
                                                                        }}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            dispatch(
                                                                                toggleConsultationModal(
                                                                                    {
                                                                                        open: true,
                                                                                        consultationType:
                                                                                            'Paid',
                                                                                        consultationId:
                                                                                            _id
                                                                                    }
                                                                                )
                                                                            );
                                                                        }}
                                                                    >
                                                                        Change
                                                                        Date
                                                                    </AppButton>
                                                                </>
                                                            </div>
                                                        ) : (
                                                            <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                <p className="text-white text-12 text-center">
                                                                    Please set a
                                                                    consultation
                                                                    date for
                                                                    your newly
                                                                    purchased
                                                                    consultation.
                                                                    You can
                                                                    select the
                                                                    date as per
                                                                    your
                                                                    convenience
                                                                </p>
                                                                <AppButton
                                                                    variant="secondary"
                                                                    className="px-1 w-100"
                                                                    style={{
                                                                        height: 44,
                                                                        fontSize: 12
                                                                    }}
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            toggleConsultationModal(
                                                                                {
                                                                                    open: true,
                                                                                    consultationType:
                                                                                        'Paid',
                                                                                    consultationId:
                                                                                        _id
                                                                                }
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    Set
                                                                    Consultation
                                                                    Date
                                                                </AppButton>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                            <>
                                                                <p className="text-white text-12 text-center">
                                                                    Your
                                                                    consultation
                                                                    is complete.
                                                                </p>

                                                                <Link
                                                                    to={`/express/checkout/cart?model_id=${consultation._id}&on_model=consultation&trainer=${consultation_trainer?._id}&plan=${consultation_plan}`}
                                                                >
                                                                    <AppButton
                                                                        variant="primary"
                                                                        className="px-1 w-100"
                                                                        style={{
                                                                            height: 44,
                                                                            fontSize: 12
                                                                        }}
                                                                    >
                                                                        Purchase
                                                                        Again
                                                                    </AppButton>
                                                                </Link>
                                                            </>
                                                        </div>
                                                    )
                                                }
                                                onClick={() => {
                                                    if (!consultation_date)
                                                        dispatch(
                                                            toggleConsultationModal(
                                                                {
                                                                    open: true,
                                                                    consultationType:
                                                                        'Paid',
                                                                    consultationId:
                                                                        _id
                                                                }
                                                            )
                                                        );
                                                }}
                                            />
                                        );
                                    } else {
                                        return '';
                                    }
                                }
                            )}
                        </Row>

                        {userConsultations.currentPage ===
                            userConsultations.totalPage ||
                        userConsultations.totalDocuments === 0 ? (
                            ''
                        ) : (
                            <div className="mb-5 d-flex justify-content-center justify-content-lg-end">
                                <AppButton
                                    variant="outline-primary"
                                    onClick={handleConsultationsLoadMore}
                                    loading={userConsultationsLoading}
                                >
                                    Load More Consultations
                                </AppButton>
                            </div>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </DashboardLayout>
    );
};

export default UserTrainers;
