import { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTrainers } from '../../../actions/trainerActions';
import { defaultImage } from '../../../helpers/constants';
import { StateType } from '../../../reducers';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppTrainerCard from '../../components/AppTrainerCard';
import MainLayout from '../../layouts/MainLayout';
import GetNotified from '../home/GetNotified';

const TrainerSearch = () => {
    const trainers = useSelector((state: StateType) => state.trainer.trainers);

    const dispatch = useDispatch();

    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Trainers',
            link: '/trainers',
            active: true
        }
    ];

    useEffect(() => {
        dispatch(getTrainers());
    }, [dispatch]);

    return (
        <MainLayout>
            <div className="container-xxl">
                <AppBreadcrumbs items={breadcrumbItems} />
                <div>
                    <h2 className="text-700 text-dark-blue text-uppercase">
                        TRAINERS
                    </h2>
                    <p className="text-gray text-14">
                        All the trainers associated with OX Strength
                    </p>
                </div>
            </div>
            <div className="trainer-banner-container py-lg-5">
                <div className="container-xxl py-5">
                    <h2 className="text-oswald text-700 text-white about-heading mx-auto text-center">
                        MEET OUR AWESOME{' '}
                        <span className="text-red">TRAINERS</span>
                    </h2>
                    <p
                        className="text-white text-center mx-auto mb-0 text-24"
                        style={{ maxWidth: '47rem' }}
                    ></p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <Row xs={1} sm={2} md={3} className="py-5">
                    {trainers.map((trainer) => (
                        <Link
                            to={`trainer/${trainer.slug}/profile`}
                            key={trainer._id}
                        >
                            <AppTrainerCard
                                title={trainer.name ?? ''}
                                subtitle={trainer.training_category?.name ?? ''}
                                img={
                                    !!trainer.profile_pic
                                        ? trainer.profile_pic
                                        : defaultImage
                                }
                                className="mx-auto"
                            />
                        </Link>
                    ))}
                </Row>
            </div>

            <GetNotified />
        </MainLayout>
    );
};

export default TrainerSearch;
