import {
    GET_TRAINERS,
    GET_TRAINER,
    RESET_TRAINER_STATE,
    GET_TRAINER_REVIEW,
    GET_TRAINER_COACHING_ACHIEVEMENTS,
    GET_TRAINER_PERSONAL_ACHIEVEMENTS,
    GET_TRAINER_PACKAGES
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { ITemplates } from '../interfaces/Template';
import {
    ITrainer,
    ITrainerCoachingAchievement,
    ITrainerPersonalAchievement,
    ITrainerReview
} from '../interfaces/Trainer';

export interface ITrainerState {
    trainer: ITrainer;
    trainers: Array<ITrainer>;
    reviews: Array<ITrainerReview>;
    coachingAchievements: Array<ITrainerCoachingAchievement>;
    personalAcievements: Array<ITrainerPersonalAchievement>;
    packages: ITemplates;
    reviewsLoading: boolean;
    coachingAchievementsLoading: boolean;
    personalAcievementsLoading: boolean;
    packagesLoading: boolean;
}

const initialState: ITrainerState = {
    trainer: {},
    trainers: [],
    reviews: [],
    coachingAchievements: [],
    personalAcievements: [],
    packages: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        templates: []
    },
    reviewsLoading: false,
    coachingAchievementsLoading: false,
    personalAcievementsLoading: false,
    packagesLoading: false
};

const trainerReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_TRAINERS:
            return {
                ...state,
                trainers: action.payload
            };
        case GET_TRAINER:
            return {
                ...state,
                trainer: action.payload
            };

        case GET_TRAINER_REVIEW:
            return {
                ...state,
                ...action.payload
            };

        case GET_TRAINER_COACHING_ACHIEVEMENTS:
            return {
                ...state,
                ...action.payload
            };

        case GET_TRAINER_PERSONAL_ACHIEVEMENTS:
            return {
                ...state,
                ...action.payload
            };

        case GET_TRAINER_PACKAGES:
            return {
                ...state,
                ...action.payload
            };

        case RESET_TRAINER_STATE:
            return initialState;

        default:
            return state;
    }
};

export default trainerReducer;
