import { Image } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { defaultImage } from '../../../../helpers/constants';
import { Trainer } from '../../../../interfaces/Consultation';

interface IProps {
    value?: string;
    onClick?: (value?: string) => void;
    selected?: string;
    coach?: Trainer;
}

const CoachSelect = ({ value, onClick, selected, coach }: IProps) => {
    return (
        <div
            className={
                'position-relative p-3 d-flex align-items-center cursor-pointer mb-3 w-100 ' +
                (selected === value ? 'coach-active' : 'coach-inactive')
            }
            onClick={() => onClick && onClick(value)}
        >
            <Image
                roundedCircle
                height={60}
                width={60}
                style={{ minWidth: 60 }}
                src={!!coach?.profile_pic ? coach?.profile_pic : defaultImage}
                className="object-cover mr-3"
            />
            <div className="flex-grow-1">
                <p className="text-inter text-500 text-gray-2 text-16 mb-0">
                    {coach?.name}
                </p>
                <p className="text-inter text-400 text-gray-2 text-14 mb-0 mr-4 pr-1 text-break">
                    Fitness Trainer
                </p>
                <Link
                    to={`/trainer/${coach?.slug}/profile`}
                    className="text-inter text-400 text-14 text-red text-underline"
                >
                    View Profile
                </Link>
            </div>
            <FaCheckCircle
                size={24}
                className="position-absolute"
                style={{ right: 16 }}
            />
        </div>
    );
};

export default CoachSelect;
