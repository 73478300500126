import AppTemplateCard from '../../components/AppTemplateCard';
import DashboardLayout from '../../layouts/DashboardLayout';
import Slider from 'react-slick';
import AppButton from '../../components/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { StateType } from '../../../reducers';
import { defaultImage } from '../../../helpers/constants';
import {
    getGiftedCoachings,
    getGiftedConsultations,
    getGiftedTemplates,
    getReceivedCoachings,
    getReceivedConsultations,
    getReceivedTemplates
} from '../../../actions/giftActions';
import { GymBag } from '../../../assets/images/icons';
import request from '../../../helpers/request';
import { setErrors } from '../../../actions/errorActions';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

interface IProps {}

type GiftType = 'received' | 'gifted';

interface ClaimGift {
    id: string;
    loading: boolean;
}

const UserGifts = (props: IProps) => {
    const [giftType, setGiftType] = useState<GiftType>('received');
    const [claimGift, setClaimGift] = useState<ClaimGift>();

    const dispatch = useDispatch();

    const {
        giftedCoachingsLoading,
        giftedConsultationsLoading,
        giftedTemplatesLoading,
        giftedCoachings,
        giftedConsultations,
        giftedTemplates,
        receivedCoachingsLoading,
        receivedConsultationsLoading,
        receivedTemplatesLoading,
        receivedCoachings,
        receivedTemplates,
        receivedConsultations
    } = useSelector((state: StateType) => state.gift);

    const giftTypes: GiftType[] = ['received', 'gifted'];

    var settings = {
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    useEffect(() => {
        if (giftType === 'received') {
            dispatch(getReceivedTemplates());
            dispatch(getReceivedCoachings());
            dispatch(getReceivedConsultations());
        } else {
            dispatch(getGiftedTemplates());
            dispatch(getGiftedCoachings());
            dispatch(getGiftedConsultations());
        }
    }, [dispatch, giftType]);

    return (
        <DashboardLayout>
            <div className="t-search-container">
                <div className="mb-5">
                    <div className="d-flex flex-wrap align-items-center mb-4">
                        <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-3 mr-3 flex-grow-1">
                            MY GIFTS
                        </h2>

                        <div className="mb-3">
                            {giftTypes.map((item, index) => (
                                <span
                                    className={
                                        'text-600 mx-2 text-hover-red cursor-pointer text-uppercase ' +
                                        (item === giftType
                                            ? 'text-red'
                                            : 'text-gray-1')
                                    }
                                    onClick={() => setGiftType(item)}
                                    key={index}
                                >
                                    <span>{item}</span>
                                </span>
                            ))}
                        </div>
                    </div>

                    {giftType === 'gifted' ? (
                        !giftedTemplates.gifts.length &&
                        !giftedCoachings.gifts.length &&
                        !giftedConsultations.gifts.length ? (
                            giftedTemplatesLoading ||
                            giftedConsultationsLoading ||
                            giftedCoachingsLoading ? (
                                <div
                                    className="d-flex justify-content-center"
                                    style={{ height: 200 }}
                                >
                                    <Spinner animation="border" />
                                </div>
                            ) : (
                                <div className="py-5 bg-light-gray-1">
                                    <div className="d-flex align-items-center flex-column py-5">
                                        <GymBag />
                                        <p className="text-inter text-gray-2">
                                            You do not have any sent gifts. Gift
                                            someone today.
                                        </p>
                                        <Link to="/template">
                                            <AppButton variant="outline-secondary">
                                                Explore Training Templates
                                            </AppButton>
                                        </Link>
                                    </div>
                                </div>
                            )
                        ) : (
                            <>
                                {giftedTemplates.gifts?.length ? (
                                    <div className="mb-5">
                                        <p className="text-700 text-18 text-dark-blue mb-2">
                                            Templates
                                        </p>

                                        <Slider {...settings} className="mx-n2">
                                            {giftedTemplates.gifts?.map(
                                                (
                                                    {
                                                        _id,
                                                        status,
                                                        template,
                                                        receiver_email
                                                    },
                                                    index
                                                ) => {
                                                    if (template) {
                                                        return (
                                                            <div className="px-2">
                                                                <AppTemplateCard
                                                                    key={index}
                                                                    ribbonText={
                                                                        status
                                                                    }
                                                                    img={
                                                                        !!template.cover
                                                                            ? template.cover
                                                                            : defaultImage
                                                                    }
                                                                    imgTitle={
                                                                        'RECEIVER: ' +
                                                                        receiver_email
                                                                    }
                                                                    subtitle=""
                                                                    squareImage
                                                                    title={
                                                                        template.title ??
                                                                        ''
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    } else {
                                                        return '';
                                                    }
                                                }
                                            )}
                                        </Slider>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {giftedCoachings.gifts?.length ? (
                                    <div className="mb-5">
                                        <p className="text-700 text-18 text-dark-blue mb-2">
                                            Coachings
                                        </p>
                                        <Slider {...settings} className="mx-n2">
                                            {giftedCoachings.gifts?.map(
                                                (
                                                    {
                                                        _id,
                                                        status,
                                                        coaching,
                                                        receiver_email
                                                    },
                                                    index
                                                ) => {
                                                    if (coaching) {
                                                        return (
                                                            <div className="px-2">
                                                                <AppTemplateCard
                                                                    title={
                                                                        coaching.title ??
                                                                        'N/A'
                                                                    }
                                                                    subtitle=""
                                                                    squareImage
                                                                    imgTitle={
                                                                        'RECEIVER: ' +
                                                                        receiver_email
                                                                    }
                                                                    img={
                                                                        !!coaching.cover
                                                                            ? coaching.cover
                                                                            : defaultImage
                                                                    }
                                                                    key={
                                                                        coaching._id
                                                                    }
                                                                    ribbonText={
                                                                        status
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    } else {
                                                        return '';
                                                    }
                                                }
                                            )}
                                        </Slider>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {giftedConsultations.gifts?.length ? (
                                    <div className="mb-5">
                                        <p className="text-700 text-18 text-dark-blue mb-2">
                                            Consultations
                                        </p>
                                        <Slider {...settings} className="mx-n2">
                                            {giftedConsultations.gifts?.map(
                                                (
                                                    {
                                                        _id,
                                                        status,
                                                        consultation,
                                                        receiver_email
                                                    },
                                                    index
                                                ) => {
                                                    if (consultation) {
                                                        return (
                                                            <div className="px-2">
                                                                <AppTemplateCard
                                                                    title={
                                                                        consultation.title ??
                                                                        'N/A'
                                                                    }
                                                                    subtitle=""
                                                                    imgTitle={
                                                                        'RECEIVER: ' +
                                                                        receiver_email
                                                                    }
                                                                    squareImage
                                                                    img={
                                                                        !!consultation.cover
                                                                            ? consultation.cover
                                                                            : defaultImage
                                                                    }
                                                                    key={
                                                                        consultation._id
                                                                    }
                                                                    ribbonText={
                                                                        status
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    } else {
                                                        return '';
                                                    }
                                                }
                                            )}
                                        </Slider>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        )
                    ) : !receivedCoachings.gifts.length &&
                      !receivedTemplates.gifts.length &&
                      !receivedConsultations.gifts.length ? (
                        receivedTemplatesLoading ||
                        receivedConsultationsLoading ||
                        receivedCoachingsLoading ? (
                            <div
                                className="d-flex justify-content-center"
                                style={{ height: 200 }}
                            >
                                <Spinner animation="border" />
                            </div>
                        ) : (
                            <div className="py-5 bg-light-gray-1">
                                <div className="d-flex align-items-center flex-column py-5">
                                    <GymBag />
                                    <p className="text-inter text-gray-2">
                                        You have not received any gifts
                                    </p>
                                    <Link to="/template">
                                        <AppButton variant="outline-secondary">
                                            Explore Training Templates
                                        </AppButton>
                                    </Link>
                                </div>
                            </div>
                        )
                    ) : (
                        <>
                            {receivedTemplates.gifts?.length ? (
                                <div className="mb-5">
                                    <p className="text-700 text-18 text-dark-blue mb-2">
                                        Templates
                                    </p>

                                    <Slider {...settings} className="mx-n2">
                                        {receivedTemplates.gifts?.map(
                                            (
                                                {
                                                    _id,
                                                    status,
                                                    template,
                                                    receiver_email
                                                },
                                                index
                                            ) => {
                                                if (template) {
                                                    return (
                                                        <div className="px-2">
                                                            <AppTemplateCard
                                                                key={index}
                                                                ribbonText={
                                                                    status
                                                                }
                                                                img={
                                                                    !!template.cover
                                                                        ? template.cover
                                                                        : defaultImage
                                                                }
                                                                imgTitle={
                                                                    'RECEIVER: ' +
                                                                    receiver_email
                                                                }
                                                                subtitle=""
                                                                squareImage
                                                                title={
                                                                    template.title ??
                                                                    ''
                                                                }
                                                                hoverOverlay={
                                                                    <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                        <AppButton
                                                                            variant="secondary"
                                                                            className="px-1 w-100"
                                                                            style={{
                                                                                height: 44,
                                                                                fontSize: 12
                                                                            }}
                                                                            loaderSize="sm"
                                                                            loading={
                                                                                _id ===
                                                                                    claimGift?.id &&
                                                                                claimGift?.loading
                                                                            }
                                                                            disabled={
                                                                                status ===
                                                                                'Claimed'
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    _id
                                                                                ) {
                                                                                    setClaimGift(
                                                                                        {
                                                                                            id: _id,
                                                                                            loading:
                                                                                                true
                                                                                        }
                                                                                    );

                                                                                    request
                                                                                        .claimGiftedTemplate(
                                                                                            _id
                                                                                        )
                                                                                        .then(
                                                                                            () => {
                                                                                                toast.success(
                                                                                                    'Gift claimed successfully',
                                                                                                    {
                                                                                                        position:
                                                                                                            toast
                                                                                                                .POSITION
                                                                                                                .TOP_CENTER
                                                                                                    }
                                                                                                );

                                                                                                dispatch(
                                                                                                    getReceivedTemplates()
                                                                                                );

                                                                                                setClaimGift(
                                                                                                    undefined
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        .catch(
                                                                                            (
                                                                                                err: AxiosError
                                                                                            ) => {
                                                                                                dispatch(
                                                                                                    setErrors(
                                                                                                        err
                                                                                                            .response
                                                                                                            ?.data
                                                                                                            ?.message ??
                                                                                                            'Something went wrong'
                                                                                                    )
                                                                                                );
                                                                                                setClaimGift(
                                                                                                    undefined
                                                                                                );
                                                                                            }
                                                                                        );
                                                                                }
                                                                            }}
                                                                        >
                                                                            {status ===
                                                                            'Claimed'
                                                                                ? 'Claimed'
                                                                                : 'Claim Now'}
                                                                        </AppButton>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return '';
                                                }
                                            }
                                        )}
                                    </Slider>
                                </div>
                            ) : (
                                ''
                            )}

                            {receivedCoachings.gifts?.length ? (
                                <div className="mb-5">
                                    <p className="text-700 text-18 text-dark-blue mb-2">
                                        Coachings
                                    </p>
                                    <Slider {...settings} className="mx-n2">
                                        {receivedCoachings.gifts?.map(
                                            (
                                                {
                                                    _id,
                                                    status,
                                                    coaching,
                                                    receiver_email
                                                },
                                                index
                                            ) => {
                                                if (coaching) {
                                                    return (
                                                        <div className="px-2">
                                                            <AppTemplateCard
                                                                title={
                                                                    coaching.title ??
                                                                    'N/A'
                                                                }
                                                                subtitle=""
                                                                squareImage
                                                                hoverOverlay={
                                                                    <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                        <AppButton
                                                                            variant="secondary"
                                                                            className="px-1 w-100"
                                                                            style={{
                                                                                height: 44,
                                                                                fontSize: 12
                                                                            }}
                                                                            loaderSize="sm"
                                                                            loading={
                                                                                _id ===
                                                                                    claimGift?.id &&
                                                                                claimGift?.loading
                                                                            }
                                                                            disabled={
                                                                                status ===
                                                                                'Claimed'
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    _id
                                                                                ) {
                                                                                    setClaimGift(
                                                                                        {
                                                                                            id: _id,
                                                                                            loading:
                                                                                                true
                                                                                        }
                                                                                    );

                                                                                    request
                                                                                        .claimGiftedCoaching(
                                                                                            _id
                                                                                        )
                                                                                        .then(
                                                                                            () => {
                                                                                                toast.success(
                                                                                                    'Gift claimed successfully',
                                                                                                    {
                                                                                                        position:
                                                                                                            toast
                                                                                                                .POSITION
                                                                                                                .TOP_CENTER
                                                                                                    }
                                                                                                );

                                                                                                dispatch(
                                                                                                    getReceivedCoachings()
                                                                                                );

                                                                                                setClaimGift(
                                                                                                    undefined
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        .catch(
                                                                                            (
                                                                                                err: AxiosError
                                                                                            ) => {
                                                                                                dispatch(
                                                                                                    setErrors(
                                                                                                        err
                                                                                                            .response
                                                                                                            ?.data
                                                                                                            ?.message ??
                                                                                                            'Something went wrong'
                                                                                                    )
                                                                                                );
                                                                                                setClaimGift(
                                                                                                    undefined
                                                                                                );
                                                                                            }
                                                                                        );
                                                                                }
                                                                            }}
                                                                        >
                                                                            {status ===
                                                                            'Claimed'
                                                                                ? 'Claimed'
                                                                                : 'Claim Now'}
                                                                        </AppButton>
                                                                    </div>
                                                                }
                                                                img={
                                                                    !!coaching.cover
                                                                        ? coaching.cover
                                                                        : defaultImage
                                                                }
                                                                key={
                                                                    coaching._id
                                                                }
                                                                ribbonText={
                                                                    status
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return '';
                                                }
                                            }
                                        )}
                                    </Slider>
                                </div>
                            ) : (
                                ''
                            )}

                            {receivedConsultations.gifts?.length ? (
                                <div className="mb-5">
                                    <p className="text-700 text-18 text-dark-blue mb-2">
                                        Consultations
                                    </p>
                                    <Slider {...settings} className="mx-n2">
                                        {receivedConsultations.gifts?.map(
                                            (
                                                {
                                                    _id,
                                                    status,
                                                    consultation,
                                                    receiver_email
                                                },
                                                index
                                            ) => {
                                                if (consultation) {
                                                    return (
                                                        <div className="px-2">
                                                            <AppTemplateCard
                                                                title={
                                                                    consultation.title ??
                                                                    'N/A'
                                                                }
                                                                subtitle=""
                                                                imgTitle={
                                                                    'RECEIVER: ' +
                                                                    receiver_email
                                                                }
                                                                hoverOverlay={
                                                                    <div className="p-3 d-flex flex-column justify-content-center h-100">
                                                                        <AppButton
                                                                            variant="secondary"
                                                                            className="px-1 w-100"
                                                                            style={{
                                                                                height: 44,
                                                                                fontSize: 12
                                                                            }}
                                                                            loaderSize="sm"
                                                                            loading={
                                                                                _id ===
                                                                                    claimGift?.id &&
                                                                                claimGift?.loading
                                                                            }
                                                                            disabled={
                                                                                status ===
                                                                                'Claimed'
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    _id
                                                                                ) {
                                                                                    setClaimGift(
                                                                                        {
                                                                                            id: _id,
                                                                                            loading:
                                                                                                true
                                                                                        }
                                                                                    );

                                                                                    request
                                                                                        .claimGiftedConsultation(
                                                                                            _id
                                                                                        )
                                                                                        .then(
                                                                                            () => {
                                                                                                toast.success(
                                                                                                    'Gift claimed successfully',
                                                                                                    {
                                                                                                        position:
                                                                                                            toast
                                                                                                                .POSITION
                                                                                                                .TOP_CENTER
                                                                                                    }
                                                                                                );

                                                                                                dispatch(
                                                                                                    getReceivedConsultations()
                                                                                                );

                                                                                                setClaimGift(
                                                                                                    undefined
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        .catch(
                                                                                            (
                                                                                                err: AxiosError
                                                                                            ) => {
                                                                                                dispatch(
                                                                                                    setErrors(
                                                                                                        err
                                                                                                            .response
                                                                                                            ?.data
                                                                                                            ?.message ??
                                                                                                            'Something went wrong'
                                                                                                    )
                                                                                                );
                                                                                                setClaimGift(
                                                                                                    undefined
                                                                                                );
                                                                                            }
                                                                                        );
                                                                                }
                                                                            }}
                                                                        >
                                                                            {status ===
                                                                            'Claimed'
                                                                                ? 'Claimed'
                                                                                : 'Claim Now'}
                                                                        </AppButton>
                                                                    </div>
                                                                }
                                                                squareImage
                                                                img={
                                                                    !!consultation.cover
                                                                        ? consultation.cover
                                                                        : defaultImage
                                                                }
                                                                key={
                                                                    consultation._id
                                                                }
                                                                ribbonText={
                                                                    status
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return '';
                                                }
                                            }
                                        )}
                                    </Slider>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </div>
            </div>
        </DashboardLayout>
    );
};

export default UserGifts;
