import {
    Col,
    Row,
    Table,
    ProgressBar,
    Spinner,
    Modal,
    Form
} from 'react-bootstrap';
import DashboardLayout from '../../layouts/DashboardLayout';
import { Link, useParams } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { useState } from 'react';
import StatusChip from '../../components/StatusChip';
import {
    IoIosArrowDropdownCircle,
    IoIosArrowDroprightCircle
} from 'react-icons/io';
import TrainingLocked, { TableHead } from './TrainingLocked';
import AppTemplateCard from '../../components/AppTemplateCard';
import { useDispatch, useSelector } from 'react-redux';
import { $FIXME, defaultImage } from '../../../helpers/constants';
import { getSubscribedTemplate } from '../../../actions/templateActions';
import { AppDispatch, StateType } from '../../../reducers';
import { useEffect } from 'react';
import { CgCloseO } from 'react-icons/cg';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import request from '../../../helpers/request';
import { Motivation } from '../../../assets/images/icons';
import { AxiosError } from 'axios';
import { setErrors } from '../../../actions/errorActions';
import WeekActivity from './components/WeekActivity';

interface IProps {}

interface IConfirmationModalProps {
    week?: string;
    show: boolean;
}

const TrainingDetails = (props: IProps) => {
    const { subscribedTemplate: template, subscribedTemplateLoading } =
        useSelector((state: StateType) => state.template);

    const [expanded, setExpanded] = useState<Array<number>>([]);
    const [showConfirmationModal, setShowConfirmationModal] =
        useState<IConfirmationModalProps>();
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleCloseConfirmationModal = () =>
        setShowConfirmationModal((prev) => {
            return { ...prev, show: false };
        });

    const dispatch = useDispatch<AppDispatch>();

    const { id }: $FIXME = useParams();

    useEffect(() => {
        dispatch(getSubscribedTemplate(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (template.weeks_completed && template.template?.weeks) {
            let weeks_completed = template.weeks_completed;

            setExpanded((prev) => (prev.length ? prev : [weeks_completed]));
        }
    }, [template]);

    return (
        <DashboardLayout>
            <div className="training-details t-search-container">
                <div className="mb-3">
                    <Link to="/dashboard/packages">
                        <BsArrowLeft size={24} />
                        <span className="text-oswald text-500 text-14 text-gray">
                            BACK
                        </span>
                    </Link>
                </div>
                {id === template._id ? (
                    <Row className="mb-5">
                        <Col md={5} lg={4} xl={3} className="mb-5 col-xxl-2">
                            <AppTemplateCard
                                squareImage
                                title=""
                                subtitle=""
                                imgTitle={
                                    template.template?.training_category?.name
                                }
                                imgSubtitle={template.template?.title}
                                img={
                                    !!template.template?.cover
                                        ? template.template?.cover
                                        : defaultImage
                                }
                                className="my-n1 py-1"
                            />
                            <div className="bg-light-gray-3 p-3">
                                <h2 className="text-16 text-700 mb-4">
                                    PROGRESS REPORT
                                </h2>
                                <div>
                                    <div className="d-flex mb-3">
                                        <span className="text-gray-2 tet-14 text-500">
                                            Completed
                                        </span>
                                        <span className="text-gray-2 tet-14 flex-grow-1 text-right text-500">
                                            <span className="text-red text-700">
                                                {template.weeks_completed}
                                            </span>
                                            /{template.template?.weeks} Weeks
                                        </span>
                                    </div>
                                    <ProgressBar
                                        now={
                                            ((template.weeks_completed ?? 0) /
                                                (template.template?.weeks ??
                                                    1)) *
                                            100
                                        }
                                        className="training-progress mb-3"
                                    />
                                    <p className="text-dark-3 text-center text-14 mb-4">
                                        {(() => {
                                            if (
                                                template.weeks_completed &&
                                                template.template?.weeks
                                            ) {
                                                if (
                                                    template.weeks_completed ===
                                                    template.template?.weeks
                                                ) {
                                                    return `Training Completed`;
                                                } else {
                                                    return `Currently on: Week 
                                                    ${
                                                        template.weeks_completed +
                                                        1
                                                    }`;
                                                }
                                            } else {
                                                return '';
                                            }
                                        })()}
                                    </p>

                                    <div className="d-flex mb-1">
                                        <span className="text-gray-2 tet-14 text-500">
                                            Difficulty
                                        </span>
                                        <span className="text-gray-2 tet-14 flex-grow-1 text-right text-500">
                                            {template.template?.detail
                                                ?.difficulty ?? 'N/A'}
                                        </span>
                                    </div>

                                    <div className="d-flex mb-1">
                                        <span className="text-gray-2 tet-14 text-500">
                                            Requirement
                                        </span>
                                        <span className="text-gray-2 tet-14 flex-grow-1 text-right text-500">
                                            {
                                                template.template?.detail
                                                    ?.requirement
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={7} lg={8} xl={9} className="col-xxl-10">
                            <h2 className="text-700 text-20 text-dark-blue text-uppercase mb-3">
                                WEEKLY SCHEDULE
                            </h2>
                            <Table
                                responsive
                                className="training-details-table"
                            >
                                <thead>
                                    <tr className="bg-light-gray">
                                        <TableHead>Movement</TableHead>
                                        <TableHead>Intensity</TableHead>
                                        <TableHead>Weight Used</TableHead>
                                        <TableHead>Actual RPE</TableHead>
                                        <TableHead>Sets</TableHead>
                                        <TableHead>Reps</TableHead>
                                        <TableHead>Tempo</TableHead>
                                        <TableHead>Rest</TableHead>
                                        <TableHead>Note</TableHead>
                                        <TableHead>Action</TableHead>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[
                                        ...Array(template.template?.weeks ?? 0)
                                    ].map((item, index) => {
                                        if (template.schedule) {
                                            const schedule =
                                                template?.schedule[index];

                                            if (
                                                index <=
                                                (template.weeks_completed ?? 0)
                                            ) {
                                                return (
                                                    <>
                                                        <tr
                                                            className="bg-dark-blue cursor-pointer"
                                                            onClick={() =>
                                                                expanded.some(
                                                                    (state) =>
                                                                        state ===
                                                                        index
                                                                )
                                                                    ? setExpanded(
                                                                          expanded.filter(
                                                                              (
                                                                                  state
                                                                              ) =>
                                                                                  state !==
                                                                                  index
                                                                          )
                                                                      )
                                                                    : setExpanded(
                                                                          [
                                                                              ...expanded,
                                                                              index
                                                                          ]
                                                                      )
                                                            }
                                                        >
                                                            <td colSpan={10}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1 d-flex align-items-center">
                                                                        <p className="text-oswald text-16 text-white text-700 mb-0 mr-4">
                                                                            WEEK{' '}
                                                                            {schedule?.week ??
                                                                                'N/A'}
                                                                            {/* <span className="text-neutrif text-400 text-10 ml-2">
                                                                                12
                                                                                July,
                                                                                Sunday
                                                                            </span> */}
                                                                        </p>
                                                                        <StatusChip
                                                                            type={
                                                                                index ===
                                                                                (template.weeks_completed ??
                                                                                    0)
                                                                                    ? 'warning'
                                                                                    : 'success'
                                                                            }
                                                                            label={
                                                                                index ===
                                                                                (template.weeks_completed ??
                                                                                    0)
                                                                                    ? 'In Progress'
                                                                                    : 'Completed'
                                                                            }
                                                                        />
                                                                        {index ===
                                                                        (template.weeks_completed ??
                                                                            0) ? (
                                                                            <div className="flex-grow-1 d-flex align-items-center">
                                                                                <p className="flex-grow-1 text-14 text-gray ml-3 mb-0">
                                                                                    Currently
                                                                                    you
                                                                                    are
                                                                                    on
                                                                                    Week{' '}
                                                                                    {index +
                                                                                        1}
                                                                                </p>
                                                                                {template
                                                                                    .week_completed_req
                                                                                    ?.weeks_completed ===
                                                                                index +
                                                                                    1 ? (
                                                                                    <button
                                                                                        className="bg-dark-gray-2 text-white text-neutrif text-14 py-1 border-none mx-3"
                                                                                        disabled
                                                                                    >
                                                                                        Completion
                                                                                        Requested
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        className="bg-dark-gray-2 text-white text-neutrif text-14 py-1 border-none mx-3"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation();
                                                                                            setShowConfirmationModal(
                                                                                                {
                                                                                                    week: (
                                                                                                        index +
                                                                                                        1
                                                                                                    ).toString(),
                                                                                                    show: true
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Set
                                                                                        as
                                                                                        completed
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </div>
                                                                    {expanded.some(
                                                                        (
                                                                            state
                                                                        ) =>
                                                                            state ===
                                                                            index
                                                                    ) ? (
                                                                        <IoIosArrowDropdownCircle
                                                                            size={
                                                                                24
                                                                            }
                                                                            fill="white"
                                                                            fillOpacity="0.41"
                                                                        />
                                                                    ) : (
                                                                        <IoIosArrowDroprightCircle
                                                                            size={
                                                                                24
                                                                            }
                                                                            fill="white"
                                                                            fillOpacity="0.41"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {schedule?.week_activities?.map(
                                                            (
                                                                weekActivities,
                                                                weekIndex
                                                            ) => (
                                                                <WeekActivity
                                                                    show={expanded.some(
                                                                        (
                                                                            state
                                                                        ) =>
                                                                            state ===
                                                                            index
                                                                    )}
                                                                    weekActivities={
                                                                        weekActivities
                                                                    }
                                                                    key={
                                                                        weekIndex
                                                                    }
                                                                    templateId={
                                                                        id
                                                                    }
                                                                />
                                                            )
                                                        )}

                                                        <tr
                                                            style={{
                                                                height: '1rem'
                                                            }}
                                                        />
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <tr
                                                            className="bg-dark-blue cursor-pointer"
                                                            onClick={() =>
                                                                expanded.some(
                                                                    (state) =>
                                                                        state ===
                                                                        index
                                                                )
                                                                    ? setExpanded(
                                                                          expanded.filter(
                                                                              (
                                                                                  state
                                                                              ) =>
                                                                                  state !==
                                                                                  index
                                                                          )
                                                                      )
                                                                    : setExpanded(
                                                                          [
                                                                              ...expanded,
                                                                              index
                                                                          ]
                                                                      )
                                                            }
                                                        >
                                                            <td colSpan={10}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1 d-flex align-items-center">
                                                                        <p className="text-oswald text-16 text-white text-700 mb-0 mr-4">
                                                                            WEEK{' '}
                                                                            {index +
                                                                                1}
                                                                            {/* <span className="text-neutrif text-400 text-10 ml-2">
                                                                                12
                                                                                July,
                                                                                Sunday
                                                                            </span> */}
                                                                        </p>
                                                                        <StatusChip
                                                                            type="danger"
                                                                            label="Trainning Locked"
                                                                        />
                                                                        <p className="text-14 text-gray ml-3 mb-0">
                                                                            This
                                                                            is
                                                                            currently
                                                                            locked!!
                                                                            You
                                                                            need
                                                                            to
                                                                            complete
                                                                            the
                                                                            previous
                                                                            week
                                                                            training
                                                                            to
                                                                            start
                                                                            with
                                                                            week{' '}
                                                                            {index +
                                                                                1}
                                                                        </p>
                                                                    </div>
                                                                    {expanded.some(
                                                                        (
                                                                            state
                                                                        ) =>
                                                                            state ===
                                                                            index
                                                                    ) ? (
                                                                        <IoIosArrowDropdownCircle
                                                                            size={
                                                                                24
                                                                            }
                                                                            fill="white"
                                                                            fillOpacity="0.41"
                                                                        />
                                                                    ) : (
                                                                        <IoIosArrowDroprightCircle
                                                                            size={
                                                                                24
                                                                            }
                                                                            fill="white"
                                                                            fillOpacity="0.41"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        {expanded.some(
                                                            (state) =>
                                                                state === index
                                                        ) ? (
                                                            <tr>
                                                                <td
                                                                    colSpan={10}
                                                                    className="p-0"
                                                                >
                                                                    <TrainingLocked />
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <tr
                                                            style={{
                                                                height: '1rem'
                                                            }}
                                                        />
                                                    </>
                                                );
                                            }
                                        } else {
                                            return null;
                                        }
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                ) : subscribedTemplateLoading ? (
                    <div
                        className="d-flex justify-content-center mb-5"
                        style={{ height: 400 }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : (
                    ''
                )}
            </div>
            <Modal
                show={showConfirmationModal?.show}
                onHide={handleCloseConfirmationModal}
                size="lg"
                centered
                contentClassName="rounded"
            >
                <Modal.Header className="px-0 mx-3 pb-0 border-0">
                    <span className="text-oswald text-uppercase text-600 text-dark-blue text-18">
                        Week Training completed confirmation
                    </span>
                    <CgCloseO
                        size={28}
                        className="text-gray text-hover-red cursor-pointer"
                        onClick={handleCloseConfirmationModal}
                    />
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="text-14 text-gray-2">
                            You are about to confirm the completion of{' '}
                            <span className="text-600">
                                WEEK {showConfirmationModal?.week}
                            </span>{' '}
                            Training Program. Please share your feedback
                            regarding this week’s training.
                        </p>
                        <Formik
                            initialValues={{ remarks: '' }}
                            validationSchema={Yup.object().shape({
                                remarks: Yup.string().required(
                                    'Remarks is required'
                                )
                            })}
                            onSubmit={(values) => {
                                request
                                    .templateWeekCompletionRequest({
                                        subscription: id,
                                        weeks_completed:
                                            showConfirmationModal?.week ?? '',
                                        remarks: values.remarks
                                    })
                                    .then(() => {
                                        dispatch(getSubscribedTemplate(id));
                                        handleCloseConfirmationModal();
                                        setShowSuccessModal(true);
                                    })
                                    .catch((err: AxiosError) => {
                                        dispatch(
                                            setErrors(
                                                err.response?.data.message ??
                                                    'Something went wrong'
                                            )
                                        );
                                    });
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                isValid,
                                errors
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <AppInput
                                        as="textarea"
                                        required
                                        fill
                                        label="What’s your feedback for the training format?"
                                        rows={4}
                                        placeholder="Share your experience and feedback related to this weeks training."
                                        name="remarks"
                                        value={values.remarks}
                                        onChange={handleChange}
                                        isInvalid={!!errors.remarks}
                                        error={errors.remarks}
                                    />
                                    <div className="d-flex justify-content-end">
                                        <AppButton
                                            variant="primary"
                                            disabled={!isValid}
                                            type="submit"
                                        >
                                            Complete Training
                                        </AppButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={showSuccessModal}
                onHide={() => setShowSuccessModal(false)}
                size="lg"
                centered
                contentClassName="rounded"
            >
                <Modal.Header className="px-0 mx-3 pb-0 border-0">
                    <CgCloseO
                        size={28}
                        className="text-gray text-hover-red cursor-pointer"
                        onClick={() => setShowSuccessModal(false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center">
                        <Motivation className="mb-3" />
                        <h2 className="text-uppercase text-20 text-dark-blue text-600 text-center">
                            Congratulations on Completing week{' '}
                            {showConfirmationModal?.week} training
                        </h2>
                        <p className="text-gray-2 text-center">
                            Your training completion information will be kept
                            under review and your next training will be
                            available after review process. Keep getting fit.
                        </p>
                        <Link to="/template" className="mb-3">
                            <AppButton variant="primary">
                                Explore Templates
                            </AppButton>
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
        </DashboardLayout>
    );
};

export default TrainingDetails;
