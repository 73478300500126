import {
    GET_ALL_FORUMS,
    GET_FORUM,
    GET_TOP_AUTHOR,
    GET_FEATURED_FORUMS,
    SAVE_FORUM_REQUEST,
    SAVE_FORUM_SUCCESS,
    SAVE_FORUM_FAILURE,
    RESET_FORUMS,
    GET_FORUM_COMMENTS,
    LIKE_FORUM,
    DISLIKE_FORUM,
    LIKE_FORUM_DETAIL,
    DISLIKE_FORUM_DETAIL,
    LIKE_COMMENT,
    DISLIKE_COMMENT,
    RESET_FORUM_COMMENTS,
    ADD_REPLY_COUNT,
    ADD_COMMENT_COUNT
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IComments } from '../interfaces/Comment';
import { IAuthor, IForum, IForums } from '../interfaces/Forum';

export interface IForumState {
    forum: IForum;
    forumLoading: boolean;
    forums: IForums;
    forumsLoading: boolean;
    authors: IAuthor[];
    authorsLoading: boolean;
    featured: IForums;
    featuredLoading: boolean;
    savingForum: boolean;
    comments: IComments;
    commentsLoading: boolean;
}

const initialState: IForumState = {
    forum: {},
    forumLoading: false,
    forums: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        forums: []
    },
    forumsLoading: false,
    authors: [],
    authorsLoading: false,
    featured: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        forums: []
    },
    featuredLoading: false,
    savingForum: false,
    comments: {
        currentPage: 1,
        totalPage: 1,
        comments: []
    },
    commentsLoading: false
};

const forumReducer = (state = initialState, { payload, type }: ActionType) => {
    switch (type) {
        case GET_ALL_FORUMS:
            return {
                ...state,
                forumsLoading: payload?.forumsLoading,
                forums: {
                    ...state.forums,
                    ...payload.forums,
                    forums: [
                        ...state.forums.forums,
                        ...(payload?.forums?.forums ?? [])
                    ]
                }
            };

        case GET_FORUM:
            return {
                ...state,
                ...payload
            };

        case GET_TOP_AUTHOR:
            return {
                ...state,
                ...payload
            };

        case GET_FEATURED_FORUMS:
            return {
                ...state,
                ...payload
            };

        case SAVE_FORUM_REQUEST:
            return {
                ...state,
                savingForum: true
            };

        case SAVE_FORUM_SUCCESS:
            return {
                ...state,
                savingForum: false
            };

        case SAVE_FORUM_FAILURE:
            return {
                ...state,
                savingForum: false
            };

        case GET_FORUM_COMMENTS:
            return {
                ...state,
                commentsLoading: payload?.commentsLoading,
                comments: {
                    ...state.comments,
                    ...payload.comments,
                    comments: [
                        ...state.comments.comments,
                        ...(payload?.comments?.comments ?? [])
                    ]
                }
            };

        //LIKES

        case LIKE_FORUM:
            return {
                ...state,
                forums: {
                    ...state.forums,
                    forums: state.forums.forums.map((forum) =>
                        forum._id === payload
                            ? {
                                  ...forum,
                                  has_liked:
                                      forum.has_liked === true ? null : true,
                                  dislikes:
                                      forum.has_liked === false
                                          ? (forum.dislikes ?? 1) - 1
                                          : forum.dislikes,
                                  likes:
                                      forum.has_liked === true
                                          ? (forum.likes ?? 1) - 1
                                          : (forum.likes ?? 0) + 1
                              }
                            : forum
                    )
                }
            };

        case LIKE_FORUM_DETAIL:
            return {
                ...state,
                forum: {
                    ...state.forum,
                    has_liked: state.forum.has_liked === true ? null : true,
                    dislikes:
                        state.forum.has_liked === false
                            ? (state.forum.dislikes ?? 1) - 1
                            : state.forum.dislikes,
                    likes:
                        state.forum.has_liked === true
                            ? (state.forum.likes ?? 1) - 1
                            : (state.forum.likes ?? 0) + 1
                }
            };

        case LIKE_COMMENT:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    comments: state.comments.comments.map((comment) =>
                        comment._id === payload
                            ? {
                                  ...comment,
                                  has_liked:
                                      comment.has_liked === true ? null : true,
                                  dislikes:
                                      comment.has_liked === false
                                          ? (comment.dislikes ?? 1) - 1
                                          : comment.dislikes,
                                  likes:
                                      comment.has_liked === true
                                          ? (comment.likes ?? 1) - 1
                                          : (comment.likes ?? 0) + 1
                              }
                            : comment
                    )
                }
            };

        //DISLIKES

        case DISLIKE_FORUM:
            return {
                ...state,
                forums: {
                    ...state.forums,
                    forums: state.forums.forums.map((forum) =>
                        forum._id === payload
                            ? {
                                  ...forum,
                                  has_liked:
                                      forum.has_liked === false ? null : false,
                                  likes:
                                      forum.has_liked === true
                                          ? (forum.likes ?? 1) - 1
                                          : forum.likes,
                                  dislikes:
                                      forum.has_liked === false
                                          ? (forum.dislikes ?? 1) - 1
                                          : (forum.dislikes ?? 0) + 1
                              }
                            : forum
                    )
                }
            };

        case DISLIKE_FORUM_DETAIL:
            return {
                ...state,
                forum: {
                    ...state.forum,
                    has_liked: state.forum.has_liked === false ? null : false,
                    dislikes:
                        state.forum.has_liked === false
                            ? (state.forum.dislikes ?? 1) - 1
                            : (state.forum.dislikes ?? 0) + 1,
                    likes:
                        state.forum.has_liked === true
                            ? (state.forum.likes ?? 1) - 1
                            : state.forum.likes
                }
            };

        case DISLIKE_COMMENT:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    comments: state.comments.comments.map((comment) =>
                        comment._id === payload
                            ? {
                                  ...comment,
                                  has_liked:
                                      comment.has_liked === false
                                          ? null
                                          : false,
                                  likes:
                                      comment.has_liked === true
                                          ? (comment.likes ?? 1) - 1
                                          : comment.likes,
                                  dislikes:
                                      comment.has_liked === false
                                          ? (comment.dislikes ?? 1) - 1
                                          : (comment.dislikes ?? 0) + 1
                              }
                            : comment
                    )
                }
            };

        case ADD_COMMENT_COUNT:
            return {
                ...state,
                forum: {
                    ...state.forum,
                    comments_count: (state.forum.comments_count ?? 0) + 1
                }
            };

        case ADD_REPLY_COUNT:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    comments: state.comments.comments.map((comment) =>
                        comment._id === payload
                            ? {
                                  ...comment,
                                  comments_count:
                                      (comment.comments_count ?? 0) + 1
                              }
                            : comment
                    )
                }
            };

        case RESET_FORUM_COMMENTS:
            return {
                ...state,
                comments: initialState.comments
            };

        case RESET_FORUMS:
            return {
                ...state,
                forums: initialState.forums,
                forum: initialState.forum,
                comments: initialState.comments
            };
        default:
            return state;
    }
};

export default forumReducer;
