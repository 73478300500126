import { ElementType, FunctionComponent } from 'react';
import { Form } from 'react-bootstrap';
import { KeyboardEventHandler } from 'react-select';

interface ISelectOptions {
    label: string;
    value?: string;
}

interface IProps {
    label?: string;
    type?: string;
    placeholder?: string;
    onChange?: React.ChangeEventHandler<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >;
    className?: string;
    required?: boolean;
    as?: ElementType<any>;
    rows?: number;
    selectOptions?: Array<ISelectOptions>;
    fill?: boolean;
    value?: string | number | string[];
    isInvalid?: boolean;
    error?: string;
    name?: string;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    defaultValue?: string;
    disabled?: boolean;
    suffix?: JSX.Element;
    onKeyDown?: KeyboardEventHandler;
}

const AppInput: FunctionComponent<IProps> = (props) => {
    return (
        <Form.Group
            className={
                `position-relative app-input-group ${
                    props.suffix ? 'disable-feedback' : ''
                }` + props.className
            }
            style={props.style}
            onKeyDown={props.onKeyDown}
        >
            {props.label ? (
                <Form.Label className="text-neutrif text-600 text-14 text-black">
                    {props.label}
                    {props.required ? (
                        <span className="text-red text-14"> *</span>
                    ) : (
                        ''
                    )}
                </Form.Label>
            ) : (
                ''
            )}

            <Form.Control
                type={props.type}
                placeholder={props.placeholder}
                onChange={props.onChange}
                className={
                    'app-input ' +
                    (props.fill
                        ? 'app-input-fill '
                        : 'app-input-transparent ') +
                    (props.suffix ? 'pr-suffix' : '')
                }
                as={props.as}
                rows={props.rows}
                value={props.value}
                isInvalid={props.isInvalid}
                name={props.name}
                defaultValue={props.defaultValue}
                style={props.inputStyle}
                disabled={props.disabled}
            >
                {props.selectOptions && props.as === 'select' ? (
                    <>
                        <option>{props.placeholder}</option>
                        {props.selectOptions.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </>
                ) : null}
            </Form.Control>

            {props.suffix && (
                <div className="position-absolute app-input-suffix">
                    {props.suffix}
                </div>
            )}

            <Form.Control.Feedback type="invalid">
                {props.error}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

AppInput.defaultProps = {
    className: ''
};

export default AppInput;
