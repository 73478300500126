import { Row } from 'react-bootstrap';
import AppButton from '../../components/AppButton';
import { Link } from 'react-router-dom';
import {
    Trophy,
    Guarantee,
    Shipping,
    CustomerSupport
} from '../../../assets/images/icons';

const ShopHero = () => {
    return (
        <div className="shop-hero">
            <div className="container-xxl">
                <Row xs={2} md={4}>
                    <div className="d-flex align-items-center justify-content-center py-3">
                        <Trophy fontSize={30} className="mr-3" />
                        <div>
                            <p className="text-14 text-dark-blue text-600 mb-0">
                                Top Healthy
                            </p>
                            <p className="text-12 text-gray mb-0">
                                Pure healthy
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center py-3">
                        <Guarantee fontSize={30} className="mr-3" />
                        <div>
                            <p className="text-14 text-dark-blue text-600 mb-0">
                                Verified products
                            </p>
                            <p className="text-12 text-gray mb-0">
                                Over 2 years
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center py-3">
                        <Shipping fontSize={30} className="mr-3" />
                        <div>
                            <p className="text-14 text-dark-blue text-600 mb-0">
                                Home Delivery
                            </p>
                            <p className="text-12 text-gray mb-0">
                                Within 1 week
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center py-3">
                        <CustomerSupport fontSize={30} className="mr-3" />
                        <div>
                            <p className="text-14 text-dark-blue text-600 mb-0">
                                24 / 7 Support
                            </p>
                            <p className="text-12 text-gray mb-0">
                                Dedicated support
                            </p>
                        </div>
                    </div>
                </Row>
            </div>
            <div className="shop-hero-container">
                <div className="container-xxl py-5 px-lg-5">
                    <div className="py-5 px-lg-5">
                        {/* <p className="text-white bg-yellow px-3 py-2 d-inline-block">
                            MONDAY SALES
                        </p> */}
                        <h1
                            className="text-white text-uppercase text-48"
                            style={{ lineHeight: 1.35 }}
                        >
                            Boost your <br />
                            immune system <br /> today
                        </h1>
                        <p className="text-light-gray mb-5">
                            {/* 24g of pure protein for enhanced lean muscle. */}
                        </p>
                        <Link to="/shop/search">
                            <AppButton variant="primary">Shop Now</AppButton>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopHero;
