import {
    GET_ALL_OFFERS,
    GET_PRODUCT_OFFERS,
    GET_TEMPLATE_OFFERS,
    GET_COACHING_OFFERS,
    GET_CONSULTATION_OFFERS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IOffer } from '../interfaces/Offer';

export interface IOfferState {
    allOffers: IOffer;
    productOffers: IOffer;
    templateOffers: IOffer;
    coachingOffers: IOffer;
    consultationOffers: IOffer;
}

const initialState: IOfferState = {
    allOffers: {},
    productOffers: {},
    templateOffers: {},
    coachingOffers: {},
    consultationOffers: {}
};

const offerReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_ALL_OFFERS:
            return {
                ...state,
                allOffers: action.payload
            };

        case GET_PRODUCT_OFFERS:
            return {
                ...state,
                productOffers: action.payload
            };
        case GET_TEMPLATE_OFFERS:
            return {
                ...state,
                templateOffers: action.payload
            };
        case GET_COACHING_OFFERS:
            return {
                ...state,
                coachingOffers: action.payload
            };
        case GET_CONSULTATION_OFFERS:
            return {
                ...state,
                consultationOffers: action.payload
            };

        default:
            return state;
    }
};

export default offerReducer;
