import { FunctionComponent, MouseEventHandler } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

type SocialType = 'Facebook' | 'Google' | 'LinkedIn';

interface IProps {
    variant: SocialType;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
}

const SocialButton: FunctionComponent<IProps> = (props) => {
    function getClassName(variant: SocialType) {
        switch (variant) {
            case 'Facebook':
                return 'facebook-btn';
            case 'Google':
                return 'google-btn';
            case 'LinkedIn':
                return 'linkedIn-btn';
        }
    }

    function getIcon(variant: SocialType) {
        switch (variant) {
            case 'Facebook':
                return <FaFacebookF className="text-white mx-2" size={18} />;
            case 'Google':
                return <FcGoogle className="text-white mx-2" size={18} />;
            case 'LinkedIn':
                return <FaLinkedinIn className="text-white mx-2" size={18} />;
        }
    }

    return (
        <button
            className={
                `social-btn ${getClassName(props.variant)} ` + props.className
            }
            onClick={props.onClick}
        >
            {getIcon(props.variant)}
            <span className="mr-2">{props.variant}</span>
        </button>
    );
};

SocialButton.defaultProps = {
    className: ''
};

export default SocialButton;
