import { Nav, NavItem } from 'react-bootstrap';
import { BiUser } from 'react-icons/bi';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { VscKey } from 'react-icons/vsc';
import { NavLink } from 'react-router-dom';

const SettingsNav = () => {
    return (
        <div className="container-xxl mb-5 settings-nav bg-2 p-3">
            <h2 className="text-uppercase text-18 text-dark-blue text-600 mb-3">
                Settings
            </h2>

            <Nav className="flex-column mx-n3">
                <NavItem>
                    <NavLink
                        exact
                        to="/dashboard/settings"
                        activeClassName="settings-nav-link-active text-dark-3 text-600"
                        className="text-neutrif text-16 text-gray-1 settings-nav-link d-block p-3 d-flex align-items-center"
                    >
                        <BiUser size={20} className="mr-3" />
                        Profile Settings
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to="/dashboard/settings/change-password"
                        activeClassName="settings-nav-link-active text-dark-3 text-600"
                        className="text-neutrif text-16 text-gray-1 settings-nav-link d-block p-3 d-flex align-items-center"
                    >
                        <VscKey size={20} className="mr-3" />
                        Change Password
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to="/dashboard/settings/address"
                        activeClassName="settings-nav-link-active text-dark-3 text-600"
                        className="text-neutrif text-16 text-gray-1 settings-nav-link d-block p-3 d-flex align-items-center"
                    >
                        <HiOutlineLocationMarker size={20} className="mr-3" />
                        Shipping Addresses
                    </NavLink>
                </NavItem>
            </Nav>
        </div>
    );
};

export default SettingsNav;
