import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Collapse } from 'react-bootstrap';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { formatNumber } from '../../../utils/functions';
import { Link } from 'react-router-dom';

const PaymentSummary = () => {
    const [buyDetailOpen, setBuyDetailOpen] = useState(true);
    const [applyDiscountOpen, setApplyDiscountOpen] = useState(true);

    const order = useSelector((state: StateType) => state.payment.order.order);
    const auth = useSelector((state: StateType) => state.auth);

    const handleBuyDetailToggle = () => {
        setBuyDetailOpen(!buyDetailOpen);
    };

    const handleApplyDiscountToggle = () => {
        setApplyDiscountOpen(!applyDiscountOpen);
    };

    return (
        <div className="summary-container p-4">
            <h2 className="text-600 text-24 text-dark-blue text-uppercase mb-3">
                Summary
            </h2>
            <div>
                <div
                    className="d-flex py-2 cursor-pointer text-hover-red"
                    onClick={handleBuyDetailToggle}
                >
                    <span className="text-neutrif text-18 text-dark-blue flex-grow-1">
                        Detail of the buyer
                    </span>
                    {buyDetailOpen ? <FiChevronUp /> : <FiChevronDown />}
                </div>
                <hr />
            </div>

            <Collapse in={buyDetailOpen}>
                <div>
                    <div className="d-flex align-items-center mb-2">
                        <span className="text-neutrif text-600 text-14 text-dark-2 mr-2">
                            Email
                        </span>
                        <span className="text-neutrif text-600 text-12 text-dark-2 flex-grow-1 text-right">
                            {order?.user?.email}
                        </span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <span className="text-neutrif text-600 text-14 text-dark-2 mr-2">
                            Full Name
                        </span>
                        <span className="text-neutrif text-600 text-12 text-dark-2 flex-grow-1 text-right">
                            {order?.user?.name}
                        </span>
                    </div>

                    {auth.authUser.mobile_num && (
                        <div className="d-flex align-items-center mb-2">
                            <span className="text-neutrif text-600 text-14 text-dark-2 mr-2">
                                Phone Number
                            </span>
                            <span className="text-neutrif text-600 text-12 text-dark-2 flex-grow-1 text-right">
                                {auth.authUser.mobile_num}
                            </span>
                        </div>
                    )}
                </div>
            </Collapse>
            <div>
                <div
                    className="d-flex py-2 cursor-pointer text-hover-red"
                    onClick={handleApplyDiscountToggle}
                >
                    <span className="text-neutrif text-18 text-dark-blue flex-grow-1">
                        Payment Details
                    </span>

                    {applyDiscountOpen ? <FiChevronUp /> : <FiChevronDown />}
                </div>
                <hr />
            </div>
            <Collapse in={applyDiscountOpen}>
                <div>
                    <div className="d-flex pt-2">
                        <p className="text-dark-2 text-14 text-600 mb-1">
                            Subtotal
                        </p>
                        <p className="flex-grow-1 text-right text-dark-2 text-14 text-600 mb-1">
                            Rs. {formatNumber(order?.sub_total)}
                        </p>
                    </div>

                    <p className="text-neutrif text-dark-3 text-12 mb-1">
                        (If you find any difficulty in purchasing the
                        product/template from Ox Strength, please contact us
                        through{' '}
                        <Link
                            to="/contact"
                            className="text-neutrif text-red text-underline"
                        >
                            our support
                        </Link>
                        . We will get back to you as quick as possible.)
                    </p>

                    {order?.coupon_discount_amount &&
                    order?.coupon_discount_percentage ? (
                        <div className="d-flex pt-2">
                            <p className="text-dark-2 text-14 text-600 mb-1">
                                Discount Code (
                                {order.coupon_discount_percentage}%)
                            </p>
                            <p className="flex-grow-1 text-right text-dark-2 text-14 text-600 mb-1">
                                - Rs.{' '}
                                {formatNumber(order.coupon_discount_amount)}
                            </p>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="d-flex pt-2">
                        <p className="text-dark-2 text-14 text-600 mb-1">
                            Order Total
                        </p>
                        <p className="flex-grow-1 text-right text-dark-2 text-18 text-600 mb-1">
                            Rs. {formatNumber(order?.total_amount)}
                        </p>
                    </div>
                    <p className="text-gray text-12 mb-1">
                        * Price is inclusive of all taxes
                    </p>
                </div>
            </Collapse>
        </div>
    );
};

export default PaymentSummary;
