import {
    SUBSCRIBE_NEWSFEED_FAIL,
    SUBSCRIBE_NEWSFEED_REQUEST,
    SUBSCRIBE_NEWSFEED_SUCCESS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';

export interface INewsfeedState {
    loading: boolean;
}

const initialState: INewsfeedState = {
    loading: false
};

const newsfeedReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case SUBSCRIBE_NEWSFEED_REQUEST:
            return {
                ...state,
                loading: true
            };

        case SUBSCRIBE_NEWSFEED_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case SUBSCRIBE_NEWSFEED_FAIL:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};

export default newsfeedReducer;
