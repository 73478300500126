import { ReactComponent as ArrowRight } from '../../../assets/images/ox-arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/images/ox-arrow-left.svg';
import { ReactComponent as ArrowNext } from '../../../assets/images/icons/arrow-next.svg';
import Slider from 'react-slick';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { Quote } from '../../../assets/images/icons';

interface IProps {}

const Feedback = (props: IProps) => {
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        fade: true,
        prevArrow: <> </>,
        nextArrow: <ArrowNext fill="#ed2b30" />
    };

    const testimonials = useSelector(
        (state: StateType) => state.testimonial.testimonials
    );

    return testimonials.length > 0 ? (
        <div className="feedback-container px-md-5 position-relative overflow-hidden">
            <Slider {...settings} adaptiveHeight>
                {testimonials.map(
                    ({ profile_pic, review, name, designation }) => (
                        <div className="py-5">
                            <div
                                className="container-xxl py-5 position-relative"
                                style={{ zIndex: 10 }}
                            >
                                <div className="d-flex align-items-start fb-main ml-auto mr-xxxl-auto flex-column flex-md-row py-5">
                                    <Image
                                        src={profile_pic}
                                        roundedCircle
                                        className="fb-image mx-md-5 mb-3"
                                        height={128}
                                        width={128}
                                    />

                                    <div className="position-relative fb-review mx-md-5 mr-5">
                                        <div className="text-dark-blue">
                                            <p className="text-24">{review}</p>
                                            <p className="text-700 text-18 mb-1">
                                                - {name}
                                            </p>
                                            <p className="pl-3">
                                                {designation}
                                            </p>
                                        </div>

                                        <Quote className="position-absolute fb-quote d-none d-lg-block" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </Slider>
            <div className="bg-arrow-right">
                <ArrowRight />
            </div>
            <div className="bg-arrow-left align-items-end d-none d-lg-flex">
                <ArrowLeft />
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Feedback;
