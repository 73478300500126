import { FunctionComponent, useState } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

interface IProps {
    min?: number;
    max?: number;
    value?: number;
    onChange?: (value: number) => void;
    disabled?: boolean;
}

const Counter: FunctionComponent<IProps> = (props) => {
    const [counter, setCounter] = useState<number>(props.value ?? 1);

    const handleIncrement = () => {
        if (props.max && counter >= props.max) {
            return;
        } else {
            setCounter(counter + 1);
            props.onChange && props.onChange(counter + 1);
        }
    };

    const handleDecrement = () => {
        if (props.min && counter <= props.min) {
            return;
        } else {
            setCounter(counter - 1);
            props.onChange && props.onChange(counter - 1);
        }
    };

    return (
        <div className="d-inline-block">
            <div className="counter-container d-flex align-items-center px-2">
                <span className="text-neutrif text-600 px-2">{counter}</span>
                <div className="d-flex flex-column">
                    <div
                        className={
                            `p-1 counter-icon-wrapper` +
                            (!props.disabled ? ' cursor-pointer' : ' opacity-0')
                        }
                        onClick={!props.disabled ? handleIncrement : undefined}
                    >
                        <FiChevronUp style={{ verticalAlign: 'bottom' }} />
                    </div>
                    <div
                        className={
                            `p-1 counter-icon-wrapper` +
                            (!props.disabled ? ' cursor-pointer' : ' opacity-0')
                        }
                        onClick={!props.disabled ? handleDecrement : undefined}
                    >
                        <FiChevronDown style={{ verticalAlign: 'top' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

Counter.defaultProps = {
    value: 1
};

export default Counter;
