import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';

const ContactDetail = () => {
    const { social, email, contact_num } = useSelector(
        (state: StateType) => state.contact.contact
    );

    return (
        <div className="summary-container p-4 h-100">
            <h2 className="text-600 text-18 text-dark-blue text-uppercase mb-5">
                Contact Detail
            </h2>

            <div className="mb-4">
                <p className="text-dark-3 mb-2">Address</p>
                <p className="text-dark-3 text-600 mb-0">Online</p>
            </div>

            <div className="mb-4">
                <p className="text-dark-3 mb-2">Open Hours</p>
                <p className="text-dark-3 text-600 mb-0">24 hours</p>
            </div>

            <div className="mb-4">
                <p className="text-dark-3 mb-2">Support</p>
                <p className="text-dark-3 text-600 mb-0">
                    {email}
                    <br />
                    {contact_num}
                </p>
            </div>

            <div>
                <p className="text-dark-3 mb-2">Follow Us On</p>
                <div className="d-flex">
                    {!!social?.facebook ? (
                        <a
                            href={social?.facebook}
                            target="#"
                            className="mr-4 mb-4"
                        >
                            <FaFacebookF size={22} className="text-dark-blue" />
                        </a>
                    ) : (
                        ''
                    )}

                    {!!social?.twitter ? (
                        <a
                            href={social?.twitter}
                            target="#"
                            className="mr-4 mb-4"
                        >
                            <FaTwitter size={22} className="text-dark-blue" />
                        </a>
                    ) : (
                        ''
                    )}

                    {!!social?.instagram ? (
                        <a
                            href={social?.instagram}
                            target="#"
                            className="mr-4 mb-4"
                        >
                            <FaInstagram size={22} className="text-dark-blue" />
                        </a>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactDetail;
