import { Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import AppBreadcrumbs, {
    IBreadCrumbItem
} from '../../components/AppBreadcrumbs';
import AppButton from '../../components/AppButton';
import AppCheckbox from '../../components/AppCheckbox';
import AppInput from '../../components/AppInput';
import AppProductCard from '../../components/AppProductCard';
import MainLayout from '../../layouts/MainLayout';
import { CgMenuBoxed } from 'react-icons/cg';
import GetNotified from '../home/GetNotified';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import {
    getProducts,
    resetProductState
} from '../../../actions/productActions';
import {
    getProductBrands,
    getProductCategories
} from '../../../actions/categoryActions';
import { $FIXME, defaultImage, sortFilter } from '../../../helpers/constants';
import queryString from 'query-string';
import { useDebouncedCallback } from 'use-debounce/lib';
import { AppProductCardSkeleton } from '../../components/AppSkeleton';
import { formatNumber } from '../../../utils/functions';
import AppNotFound from '../../components/AppNotFound';
import { IAddCartBody, Rate } from '../../../interfaces/Product';
import { addToCart } from '../../../actions/cartActions';

interface IProps {}

const ShopListing = (props: IProps) => {
    const breadcrumbItems: Array<IBreadCrumbItem> = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'Shop',
            link: '/shop/search',
            active: true
        }
    ];

    const { authenticated } = useSelector((state: StateType) => state.auth);

    const { products, productsLoading } = useSelector(
        (state: StateType) => state.product
    );
    const { productBrands, productCategories } = useSelector(
        (state: StateType) => state.category
    );

    const dispatch = useDispatch();

    const location = useLocation();

    const history = useHistory();

    const query: $FIXME = queryString.parse(location.search);

    const [selectedFilter, setSelectedFilter] = useState(
        query?.sort ?? sortFilter[1].option
    );

    useEffect(() => {
        dispatch(getProductCategories());
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            getProductBrands(
                query.category ??
                    productCategories.find((cat) =>
                        cat.subCategories?.some(
                            (subCat) => subCat._id === query.sub
                        )
                    )?._id
            )
        );
    }, [dispatch, query.category, query.sub, productCategories]);

    useEffect(() => {
        dispatch(resetProductState());
        dispatch(
            getProducts({
                sort: query.sort ?? sortFilter[1].option,
                q: query.query,
                parentCategory: query.category,
                category: query.sub,
                brand: query.brand
            })
        );
    }, [
        dispatch,
        query.sort,
        query.query,
        query.category,
        query.sub,
        query.brand
    ]);

    const handleSearch = useDebouncedCallback((value) => {
        history.push({
            pathname: `/shop/search`,
            search: new URLSearchParams({
                ...query,
                query: value
            }).toString()
        });
    }, 500);

    const handleDropdown = (e: $FIXME) => {
        history.push({
            pathname: `/shop/search`,
            search: new URLSearchParams({
                ...query,
                sort: e
            }).toString()
        });
        setSelectedFilter(e);
    };

    const handleLoadMore = () => {
        if (products.currentPage !== products.totalPage)
            dispatch(
                getProducts({
                    category: query.sub,
                    sort: selectedFilter,
                    page: (products.currentPage + 1).toString(),
                    q: query.query,
                    brand: query.brand,
                    parentCategory: query.category
                })
            );
    };

    const handleFilter = (filter: string, value: string) => {
        if (value === query[filter]) {
            delete query[filter];

            history.push({
                pathname: `/shop/search`,
                search: new URLSearchParams({
                    ...query
                }).toString()
            });
        } else {
            history.push({
                pathname: `/shop/search`,
                search: new URLSearchParams({
                    ...query,
                    [filter]: value
                }).toString()
            });
        }
    };

    const handleAddToCart = (id: string, rate?: Rate) => {
        if (authenticated) {
            const body: IAddCartBody = {
                model_id: id,
                onModel: 'Product',
                unit: 1,
                self_order: true,
                product_size: rate?.size
            };

            dispatch(addToCart(body));
        } else {
            history.push('/login', {
                from: location
            });
        }
    };

    return (
        <MainLayout backgroundColor="#F4F6FC">
            <div className="container-xxl container-xl shop-search-container mb-5">
                <AppBreadcrumbs items={breadcrumbItems} />

                <Row className="mb-5">
                    <Col lg={3} md={12}>
                        <div className="shop-filter-container mb-3">
                            <div className="shop-filter-head p-3 d-flex justify-content-between">
                                <h2 className="text-600 text-18 text-uppercase mb-0">
                                    FILTER BY CATEGORY
                                </h2>
                            </div>
                            <div className="py-3">
                                {productCategories.map((category, index) => (
                                    <div key={category._id}>
                                        <AppCheckbox
                                            containerClassName="px-3 py-2 mb-0"
                                            label={category.name ?? ''}
                                            checked={
                                                category._id === query.category
                                            }
                                            size="sm"
                                            onChange={() => {
                                                delete query.brand;
                                                delete query.sub;

                                                handleFilter(
                                                    'category',
                                                    category._id ?? ''
                                                );
                                            }}
                                        />

                                        {category.subCategories?.map(
                                            (sub, subIndex) => (
                                                <AppCheckbox
                                                    key={subIndex}
                                                    containerClassName="ml-4 py-1 mb-0"
                                                    label={sub.name ?? ''}
                                                    size="sm"
                                                    checked={
                                                        sub._id === query.sub
                                                    }
                                                    onChange={() => {
                                                        delete query.category;

                                                        if (
                                                            !category.subCategories?.some(
                                                                (subCate) =>
                                                                    subCate._id ===
                                                                    query.sub
                                                            )
                                                        ) {
                                                            delete query.brand;
                                                        }

                                                        handleFilter(
                                                            'sub',
                                                            sub._id ?? ''
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                        {productCategories.length !==
                                            index + 1 && <hr />}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {!!productBrands.length && (
                            <div className="shop-filter-container mb-3">
                                <div className="shop-filter-head p-3 d-flex justify-content-between">
                                    <h2 className="text-600 text-18 text-uppercase mb-0">
                                        FILTER BY BRANDS
                                    </h2>
                                    <CgMenuBoxed
                                        size={18}
                                        className="text-gray"
                                    />
                                </div>
                                <div className="py-3">
                                    {productBrands.map((brand, index) => (
                                        <AppCheckbox
                                            key={index}
                                            containerClassName="px-3 py-1"
                                            label={brand.name ?? ''}
                                            checked={brand._id === query.brand}
                                            onChange={() => {
                                                handleFilter(
                                                    'brand',
                                                    brand._id ?? ''
                                                );
                                            }}
                                            size="sm"
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </Col>

                    <Col className="shop-search-main">
                        <div className="d-flex flex-wrap mb-3">
                            <div className="d-flex align-items-center mr-3 mb-3">
                                <h2 className="text-dark-blue text-20 text-700 text-oswald text-uppercase mb-0 mr-3">
                                    ALL PRODUCTS
                                </h2>
                                <p className="text-inter text-500 text-14 text-gray mb-0">
                                    Found{' '}
                                    <span className="text-dark-blue text-700">
                                        {products.totalDocuments}
                                    </span>{' '}
                                    Results
                                </p>
                            </div>
                            <div className="d-flex mb-3 flex-grow-1 justify-content-end">
                                <div className="d-flex align-items-center">
                                    <span className="mr-3 text-inter text-500 text-14 text-gray text-nowrap">
                                        Sort By
                                    </span>
                                    <DropdownButton
                                        menuAlign="right"
                                        title={
                                            sortFilter.find(
                                                (sort) =>
                                                    sort.option ===
                                                    selectedFilter
                                            )?.label
                                        }
                                        variant="outline-secondary"
                                        size="sm"
                                        className="template-filter-dropdown mr-4"
                                        onSelect={handleDropdown}
                                    >
                                        {sortFilter.map((filter, index) => (
                                            <Dropdown.Item
                                                eventKey={filter.option}
                                                active={
                                                    filter.option ===
                                                    selectedFilter
                                                }
                                            >
                                                {filter.label}
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </div>
                                <div
                                    style={{ maxWidth: 320 }}
                                    className="w-100"
                                >
                                    <AppInput
                                        placeholder="Search products"
                                        className="w-100 mb-0"
                                        fill
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => handleSearch(e.target.value)}
                                        defaultValue={query.query}
                                    />
                                </div>
                            </div>
                        </div>

                        {!productsLoading && products.products.length === 0 ? (
                            <AppNotFound type="Product" />
                        ) : (
                            <Row sm={3} xs={2} className="pr-3">
                                {products.products.map((product, index) => {
                                    let rate = !!product?.rates?.length
                                        ? product.rates?.find(
                                              (rate) => (rate.stock ?? 0) > 0
                                          ) ?? product.rates[0]
                                        : undefined;

                                    return (
                                        <Link
                                            to={'/shop/product/' + product.slug}
                                        >
                                            <div className="pl-3 py-2 h-100">
                                                <AppProductCard
                                                    img={
                                                        product.images &&
                                                        product.images.length >
                                                            0
                                                            ? product.images[0]
                                                            : defaultImage
                                                    }
                                                    title={
                                                        product.title ?? 'N/A'
                                                    }
                                                    subtitle={
                                                        product.discount
                                                            ?.active ? (
                                                            <>
                                                                <s>
                                                                    Rs.{' '}
                                                                    {formatNumber(
                                                                        rate?.price
                                                                    )}
                                                                </s>
                                                                <br />
                                                                {'Rs. ' +
                                                                    formatNumber(
                                                                        rate?.discounted_price
                                                                    )}
                                                            </>
                                                        ) : (
                                                            'Rs. ' +
                                                            formatNumber(
                                                                rate?.discounted_price
                                                            )
                                                        )
                                                    }
                                                    key={index}
                                                    onAddCart={() =>
                                                        product._id
                                                            ? handleAddToCart(
                                                                  product._id,
                                                                  rate
                                                              )
                                                            : undefined
                                                    }
                                                    outOfStock={
                                                        rate?.stock === 0
                                                    }
                                                    ribbonText={
                                                        rate?.stock === 0
                                                            ? 'Out of stock'
                                                            : product.discount
                                                                  ?.active
                                                            ? product.discount
                                                                  .title
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                        </Link>
                                    );
                                })}
                            </Row>
                        )}

                        {productsLoading ? (
                            <Row sm={3} xs={2} className="pr-3">
                                {[...Array(9)].map((item, i) => (
                                    <div className="pl-3 py-2 h-100">
                                        <AppProductCardSkeleton key={i} />
                                    </div>
                                ))}
                            </Row>
                        ) : (
                            <> </>
                        )}

                        {products.currentPage === products.totalPage ||
                        products.totalDocuments === 0 ? (
                            ''
                        ) : (
                            <div className="d-flex justify-content-center justify-content-lg-end mt-4">
                                <AppButton
                                    variant="outline-primary"
                                    onClick={handleLoadMore}
                                    loading={productsLoading}
                                >
                                    LOAD MORE PRODUCTS
                                </AppButton>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
            <GetNotified />
        </MainLayout>
    );
};

export default ShopListing;
