import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { AiOutlineCalendar } from 'react-icons/ai';

interface IProps {
    label?: string;
    placeholder?: string;
    onChange: (
        date: Date | null,
        event: React.SyntheticEvent<any, Event>
    ) => void;
    className?: string;
    required?: boolean;
    value?: string;
    selected?: Date;
    error?: string;
    name?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
}

const AppDatePicker = (props: IProps) => {
    return (
        <Form.Group className={props.className} style={props.style}>
            {props.label ? (
                <Form.Label className="text-neutrif text-600 text-14 text-black">
                    {props.label}
                    {props.required ? (
                        <span className="text-red text-14"> *</span>
                    ) : (
                        ''
                    )}
                </Form.Label>
            ) : (
                ''
            )}
            <div className="position-relative">
                <ReactDatePicker
                    name={props.name}
                    placeholderText={props.placeholder}
                    disabled={props.disabled}
                    value={props.value}
                    selected={props.selected}
                    onChange={props.onChange}
                    onChangeRaw={(e) => {
                        e.preventDefault();
                    }}
                    className="app-date-picker"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <AiOutlineCalendar
                    size={24}
                    className="text-gray position-absolute"
                    style={{
                        right: 12,
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }}
                />
            </div>
            <div
                className={'invalid-feedback' + (props.error ? ' d-block' : '')}
            >
                {props.error}
            </div>
        </Form.Group>
    );
};

export default AppDatePicker;
