import { ActionType } from '../interfaces/ActionType';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_REQUEST,
    LOGIN_REQUEST,
    RESET_AUTH_STATE
} from '../helpers/constants';

export interface IAuthUser {
    name?: string;
    slug?: string;
    email?: string;
    id?: string;
    profile_pic?: string;
    mobile_num?: string;
    address?: string;
    is_active_member?: boolean;
}

export interface AuthState {
    redirectToReferrer: boolean;
    authenticated: boolean;
    authUser: IAuthUser;
    registerLoading: boolean;
    redirectLogin: boolean;
    loginLoading: boolean;
    loginError?: string;
    registerError?: string;
}

const initialState: AuthState = {
    redirectToReferrer: false,
    authenticated: false,
    authUser: {},
    registerLoading: false,
    redirectLogin: false,
    loginLoading: false
};

const authReducer = (state = initialState, action: ActionType) => {
    const { type, payload } = action;
    switch (type) {
        case REGISTER_REQUEST:
            return {
                ...state,
                registerLoading: true
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                redirectLogin: true,
                registerError: null,
                registerLoading: false
            };

        case REGISTER_FAIL:
            return {
                ...state,
                registerError: action.payload,
                registerLoading: false
            };

        case LOGIN_REQUEST:
            return {
                ...state,
                loginLoading: true
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                redirectToReferrer: true,
                authenticated: true,
                authUser: payload,
                loginError: null,
                loginLoading: false
            };

        case LOGIN_FAIL:
            return {
                ...state,
                loginError: action.payload,
                loginLoading: false
            };

        case RESET_AUTH_STATE:
            return initialState;

        default:
            return state;
    }
};

export default authReducer;
