import {
    GET_COACHING_CONSULTATIONS,
    GET_CONSULTAION_REVIEWS,
    GET_CONSULTATION,
    GET_CONSULTATIONS,
    GET_SUBSCRIBED_CONSULTATIONS,
    GET_TEMPLATE_CONSULTATIONS,
    GET_USER_CONSULTATIONS,
    RESET_CONSULTATION_REVIEWS,
    RESET_CONSULTATION_STATE,
    UPDATE_SUBSCRIBED_COACHING,
    UPDATE_SUBSCRIBED_CONSULTATION,
    UPDATE_SUBSCRIBED_TEMPLATE
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import {
    ISubscribedCoaching,
    ISubscribedCoachings
} from '../interfaces/Coaching';
import {
    Consultation,
    IConsultaionReview,
    IConsultation,
    ISubscribedConsultation,
    ISubscribedConsultations
} from '../interfaces/Consultation';
import {
    ISubscribedTemplate,
    ISubscribedTemplates
} from '../interfaces/Template';

export interface IConsultationState {
    consultations: IConsultation;
    consultationsLoading: boolean;
    consultation: Consultation;
    consultationLoading: boolean;
    consultationReviews: IConsultaionReview;
    consultationReviewsLoading: boolean;
    subscribedConsultations: ISubscribedConsultations;
    subscribedConsultationsLoading: boolean;
    templateConsultationsLoading: boolean;
    templateConsultations: ISubscribedTemplates;
    coachingConsultationsLoading: boolean;
    coachingConsultations: ISubscribedCoachings;
    userConsultations: ISubscribedConsultations;
    userConsultationsLoading: boolean;
}

const initialState: IConsultationState = {
    consultation: {},
    consultationLoading: false,
    consultations: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        consultations: []
    },
    consultationsLoading: false,
    consultationReviews: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        reviews: []
    },
    consultationReviewsLoading: false,
    subscribedConsultations: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        consultations: []
    },
    templateConsultations: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        templates: []
    },
    subscribedConsultationsLoading: false,
    templateConsultationsLoading: false,
    coachingConsultationsLoading: false,
    coachingConsultations: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        coachings: []
    },
    userConsultations: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        consultations: []
    },
    userConsultationsLoading: false
};

const consultationReducer = (
    state = initialState,
    { payload, type }: ActionType
): IConsultationState => {
    switch (type) {
        case GET_CONSULTATIONS:
            return {
                ...state,
                consultationsLoading: payload?.consultationsLoading,
                consultations: {
                    ...state.consultations,
                    ...payload.consultations,
                    consultations: [
                        ...state.consultations.consultations,
                        ...(payload?.consultations?.consultations ?? [])
                    ]
                }
            };

        case GET_CONSULTATION:
            return {
                ...state,
                ...payload
            };

        case GET_CONSULTAION_REVIEWS:
            return {
                ...state,
                consultationReviewsLoading: payload?.consultationReviewsLoading,
                consultationReviews: {
                    ...state.consultationReviews,
                    ...payload.consultationReviews,
                    reviews: [
                        ...state.consultationReviews.reviews,
                        ...(payload?.consultationReviews?.reviews ?? [])
                    ]
                }
            };

        case GET_SUBSCRIBED_CONSULTATIONS:
            return {
                ...state,
                ...payload
            };

        case GET_USER_CONSULTATIONS:
            return {
                ...state,
                ...payload,
                userConsultations: {
                    ...state.userConsultations,
                    ...payload.userConsultations,
                    consultations: [
                        ...state.userConsultations.consultations,
                        ...(payload?.userConsultations?.consultations ?? [])
                    ]
                }
            };

        case GET_TEMPLATE_CONSULTATIONS:
            return {
                ...state,
                ...payload
            };

        case GET_COACHING_CONSULTATIONS:
            return {
                ...state,
                ...payload
            };

        case RESET_CONSULTATION_STATE:
            return initialState;

        case RESET_CONSULTATION_REVIEWS:
            return {
                ...state,
                consultationReviews: {
                    ...state.consultationReviews,
                    reviews: []
                }
            };

        case UPDATE_SUBSCRIBED_CONSULTATION:
            let subscribedConsultation = payload as ISubscribedConsultation;

            return {
                ...state,
                subscribedConsultations: {
                    ...state.subscribedConsultations,
                    consultations:
                        state.subscribedConsultations.consultations.map(
                            (consultation) =>
                                consultation._id === subscribedConsultation._id
                                    ? {
                                          ...consultation,
                                          ...subscribedConsultation
                                      }
                                    : consultation
                        )
                },
                userConsultations: {
                    ...state.userConsultations,
                    consultations: state.userConsultations.consultations.map(
                        (consultation) =>
                            consultation._id === subscribedConsultation._id
                                ? {
                                      ...consultation,
                                      ...subscribedConsultation
                                  }
                                : consultation
                    )
                }
            };

        case UPDATE_SUBSCRIBED_TEMPLATE:
            let subscribedTemplate = payload as ISubscribedTemplate;

            return {
                ...state,
                templateConsultations: {
                    ...state.templateConsultations,
                    templates: state.templateConsultations.templates.map(
                        (template) =>
                            template._id === subscribedTemplate._id
                                ? {
                                      ...template,
                                      ...subscribedTemplate,
                                      template: template.template
                                  }
                                : template
                    )
                }
            };

        case UPDATE_SUBSCRIBED_COACHING:
            let subscribedCoaching = payload as ISubscribedCoaching;

            return {
                ...state,
                coachingConsultations: {
                    ...state.coachingConsultations,
                    coachings: state.coachingConsultations.coachings.map(
                        (coaching) =>
                            coaching._id === subscribedCoaching._id
                                ? {
                                      ...coaching,
                                      ...subscribedCoaching
                                  }
                                : coaching
                    )
                }
            };

        default:
            return state;
    }
};

export default consultationReducer;
