import { Form, Row, Col } from 'react-bootstrap';
import { ISubscribeNewsfeedBody } from '../../../interfaces/NewsFeed';
import AppButton from '../../components/AppButton';
import AppInput from '../../components/AppInput';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeNewsfeed } from '../../../actions/newsfeedActions';
import { StateType } from '../../../reducers';

interface IProps {}

const GetNotified = (props: IProps) => {
    const dispatch = useDispatch();

    const { loading } = useSelector((state: StateType) => state.newsfeed);

    const initialValues: ISubscribeNewsfeedBody = {
        email: '',
        first_name: '',
        last_name: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required')
    });

    return (
        <div className="get-notified-container px-md-5">
            <div className="container-xxl py-5 px-xxl-5">
                <h2 className="text-uppercase gn-title">
                    Get Customized Recipe and Diet Plan
                </h2>
                <p className="gn-desc">
                    {/* Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet. */}
                </p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        dispatch(subscribeNewsfeed(values, resetForm));
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        isValid,
                        errors
                    }) => (
                        <Form
                            className="pt-2"
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <Row>
                                <Col lg={3}>
                                    <AppInput
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                        error={errors.email}
                                        required
                                        placeholder="Email address"
                                        className="mr-3"
                                        inputStyle={{ color: '#fff' }}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <AppInput
                                        type="text"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.first_name}
                                        error={errors.first_name}
                                        required
                                        placeholder="Firstname"
                                        className="mr-3"
                                        inputStyle={{ color: '#fff' }}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <AppInput
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.last_name}
                                        error={errors.last_name}
                                        required
                                        placeholder="Lastname"
                                        className="mr-3"
                                        inputStyle={{ color: '#fff' }}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <AppButton
                                        variant="secondary"
                                        type="submit"
                                        disabled={loading || !isValid}
                                        loading={loading}
                                    >
                                        Get Notified
                                    </AppButton>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default GetNotified;
