import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTemplateCategories } from '../../../actions/categoryActions';
import { StateType } from '../../../reducers';
import AppAccordion from '../../components/AppAccordion';
import queryString from 'query-string';

const TemplateFilter = () => {
    const dispatch = useDispatch();
    const categories = useSelector(
        (state: StateType) => state.category.templateCategories
    );

    const location = useLocation();

    const query = queryString.parse(location.search);

    useEffect(() => {
        dispatch(getTemplateCategories());
    }, [dispatch]);

    return (
        <div className="template-filter-container h-100">
            <AppAccordion
                items={categories}
                defaultSelectedIndex={categories.findIndex((category) =>
                    category.subCategories?.some(
                        (subCategory) =>
                            subCategory._id === query.category?.toString()
                    )
                )}
            />
        </div>
    );
};

export default TemplateFilter;
